import {isAlphanumeric, isLowerAlphanumeric} from "./isAlphanumeric";
import {getBoolean} from "./Get";
import { SurveyQuestion } from "../../models";
import getBranchingRule from "./getBranchingRule";
import { destinationIDBefore } from "./validators.mocks";
import getQuestionContent from "./getQuestionContent";

export function checkIfAllValuesUndefined(value: Record<string, any>): boolean {
  let result = true;
  Object.keys(value).forEach( (key) => {
    if (value[key] !== undefined) {
      result = false;
    }
  });
  return result;
}

export interface TimeValidatorValue {
  hour: number;
  minute: number;
}

class ValidatorBase {
  value: any;
  errorMessage: string | undefined; // TODO make this string | null
  undefinedErrorMessage: string | undefined;

  noUndefined() {
    if (this.errorMessage === undefined) {
      if (this.value === undefined) {
        this.errorMessage = this.undefinedErrorMessage;
      }
    }
    return this;
  }

  noNull(message?: string) {
    if (this.errorMessage === undefined) {
      if (this.value === null) {
        this.errorMessage = message ?? 'Item cannot be empty'
      }
    }
    return this;
  }

  validate(): string | undefined {
    return this.errorMessage;
  }
}

/*
 Implements methods that check if a string matches certain conditions. Can be used
 with method chaining. You can either call validate() to get the message value at the end, if any, or
 directly access the `message` property.
 */
export class StringValidator extends ValidatorBase {

  constructor(value: string | undefined, undefinedErrorMessage? : string) {
    super();
    this.value = value;
    this.undefinedErrorMessage = undefinedErrorMessage ?? 'Value cannot be undefined'
  }

  noEmpty(message?: string): StringValidator {
    if (this.errorMessage === undefined) {
      const errorMessage = message ? message : 'Value cannot be empty'
      this.errorMessage = this.value === "" ?  errorMessage : undefined;
    }
    return this
  }

  // Checks that the string is greater than or equal to the min
  min(count: number, message?: string): StringValidator {
    // Run check only if error not already detected
    this.noUndefined();
    if (this.errorMessage === undefined) {
      const errorMessage = message ? message : 'Error'
      this.errorMessage = this.value!.length >= count ? undefined : errorMessage;
    }
    return this
  }

  // Checks that the string is less than or equal to the max
  max(count: number, message?: string): StringValidator {
    this.noUndefined();
    if (this.errorMessage === undefined) {
      const errorMessage = message ? message : 'Error'
      this.errorMessage = this.value!.length <= count ? undefined : errorMessage;
    }
    return this
  }

  alphaNum(message?: string): StringValidator {
    this.noUndefined();
    if (this.errorMessage === undefined) {
      const errorMessage = message ? message : 'Value must only contain letters and numbers'
      this.errorMessage = isAlphanumeric(this.value!) ? undefined : errorMessage;
    }
    return this
  }

  lowercaseAlphaNum(message?: string): StringValidator {
    this.noUndefined();
    if (this.errorMessage === undefined) {
      const errorMessage = message ? message : 'Value must only contain letters and numbers'
      this.errorMessage = isLowerAlphanumeric(this.value!) ? undefined : errorMessage;
    }
    return this
  }

  /**
   * Checks if string consists of ONLY whitespace
   */
  noSpace(message?: string): StringValidator {
    this.noUndefined();
    if (this.errorMessage === undefined) {
      const errorMessage = message ? message : 'Value cannot contain spaces'
      this.errorMessage = this.value!.trim().length !== 0 ? undefined : errorMessage;
    }
    return this
  }

  /**
   * Checks if a value is unique
   * @param values: the array of strings to check for uniqueness in
   * @param message: the message to return if it fails. Defaults to `Item is a duplicate`
   * @param valueIndex: the index for the value if it is already in the array
   * @param ignoreCase: defaults to false, set to true if check should be case-insensitive
   *
   */
  unique(values: string[], message?: string, valueIndex?: number, ignoreCase: boolean = false): StringValidator {
    if (this.errorMessage === undefined) {
      const otherValues = ignoreCase ? values.map(item => item.toLowerCase()) : [...values];
      if (valueIndex !== undefined) {
        // Splicing should be preformat for the length of most arrays of user input values
        otherValues.splice(valueIndex,1);
      }
      const errorMessage = message ? message : 'Item is a duplicate'
      this.errorMessage = !otherValues.includes(ignoreCase ? this.value.toLowerCase() : this.value) ? undefined : errorMessage;
    }
    return this
  }
}

/*
 Implements methods that check if a string matches certain conditions. Can be used
 with method chaining to check multiple conditions. You can either call validate()
 to get the message value at the end, if any, or directly access the `message` property.
 */
export class NumberValidator extends ValidatorBase {

  constructor(value: number | undefined, undefinedErrorMessage? : string) {
    super();
    this.value = value;
    this.undefinedErrorMessage = undefinedErrorMessage ?? 'Value cannot be undefined';
  }

  noNaN(message?: string) {
    this.noUndefined();
    if (this.errorMessage === undefined) {
      const errorMessage = message ? message : 'Value must contain at least one number'
      this.errorMessage = isNaN(this.value) ? errorMessage : undefined;
    }
    return this
  }

  // Checks that the value is greater than or equal to the min
  min(size: number | undefined, message?: string): NumberValidator {
    this.noUndefined();
    // Run check only if error not already detected
    if (this.errorMessage === undefined && size !== undefined) {
      const errorMessage = message ? message : 'Error'
      this.errorMessage = this.value >= size ? undefined : errorMessage;
    }
    return this
  }

  /**
   * Checks that the value is less than or equal to the max
   */
  max(size: number | undefined, message?: string): NumberValidator {
    this.noUndefined();
    // Run check only if error not already detected and size is correct
    if (this.errorMessage === undefined && size !== undefined) {
      const errorMessage = message ? message : 'Error'
      this.errorMessage = this.value <= size ? undefined : errorMessage;
    }
    return this
  }

  /**
   * Checks that the value is strictly less than the size given
   */
  strictlyLessThan(size: number | undefined, message?: string): NumberValidator {
    this.noUndefined();
    // Run check only if error not already detected and size is correct
    if (this.errorMessage === undefined && size !== undefined) {
      const errorMessage = message ? message : `Value must be less than ${size}`
      this.errorMessage = this.value < size ? undefined : errorMessage;
    }
    return this
  }

  /**
   * Checks that the value is strictly greater than the size given
   */
  strictlyGreaterThan(size: number | undefined, message?: string): NumberValidator {
    this.noUndefined();
    // Run check only if error not already detected and size is correct
    if (this.errorMessage === undefined && size !== undefined) {
      const errorMessage = message ? message : `Value must be greater than ${size}`
      this.errorMessage = this.value > size ? undefined : errorMessage;
    }
    return this
  }

}

export class TimeValidator extends ValidatorBase {

  constructor(value: TimeValidatorValue, undefinedErrorMessage?: string) {
    super();
    this.value = value;
    this.undefinedErrorMessage = undefinedErrorMessage ?? 'Value cannot be undefined';
  }

  noNaN(message?: string) {
    this.noUndefined();
    if (this.errorMessage === undefined) {
      const errorMessage = message ? message : 'Value must contain at least one number'
      this.errorMessage = isNaN(this.value.hour) || isNaN(this.value.minute) ? errorMessage : undefined;
    }
    return this
  }

  // Checks that the value is greater than or equal to the min
  min(hour: number | undefined, minute: number | undefined, message?: string): TimeValidator  {
    this.noUndefined();
    // Run check only if error not already detected
    if (this.errorMessage === undefined && hour !== undefined && minute !== undefined) {
      const errorMessage = message ? message : 'Error'
      if (this.value.hour > hour || ( this.value.hour === hour && this.value.minute >= minute)) {
        this.errorMessage = undefined;
      } else {
        this.errorMessage = errorMessage;
      }
    }
    return this
  }

  /**
   * Checks that the value is less than or equal to the max
   */
  max(hour: number | undefined, minute: number | undefined,  message?: string): TimeValidator {
    this.noUndefined();
    // Run check only if error not already detected and size is correct
    if (this.errorMessage === undefined && hour !== undefined && minute !== undefined) {
      const errorMessage = message ? message : 'Error'
      if (this.value.hour < hour || ( this.value.hour === hour && this.value.minute <= minute)) {
        this.errorMessage = undefined;
      } else {
        this.errorMessage = errorMessage;
      }
    }
    return this
  }

  /**
   * Checks that the value is strictly less than the size given
   */
  strictlyLessThan(hour: number | undefined, minute: number | undefined,  message?: string): TimeValidator  {
    this.noUndefined();
    // Run check only if error not already detected and size is correct
    if (this.errorMessage === undefined && hour !== undefined && minute !== undefined) {
      const errorMessage = message ? message : 'Error'
      if (this.value.hour < hour || ( this.value.hour === hour && this.value.minute < minute)) {
        this.errorMessage = undefined;
      } else {
        this.errorMessage = errorMessage;
      }
    }
    return this
  }

  /**
   * Checks that the value is strictly greater than the size given
   */
  strictlyGreaterThan(hour: number | undefined, minute: number | undefined,  message?: string): TimeValidator  {
    this.noUndefined();
    // Run check only if error not already detected and size is correct
    if (this.errorMessage === undefined && hour !== undefined && minute !== undefined) {
      const errorMessage = message ? message : 'Error'
      if (this.value.hour > hour || ( this.value.hour === hour && this.value.minute > minute)) {
        this.errorMessage = undefined;
      } else {
        this.errorMessage = errorMessage;
      }
    }
    return this
  }

}

export class BranchingValidator extends ValidatorBase {

  constructor(value: (SurveyQuestion | null)[]) {
    super();
    this.value = value;
  }

  ordered() {
    const orderValidationMessage = "Please check branching rules and re-save";
    this.value.forEach((question: SurveyQuestion, index: number, questions: (SurveyQuestion | null)[]) => {
      const branchingRule = getBranchingRule(question)
      if (branchingRule){
        const { defaultQuestionId, destinationQuestionId } = branchingRule;
        const foundDefault = questions.slice(index).find(q => defaultQuestionId === getQuestionContent(q)?.questionId)
        const foundDestination = questions.slice(index).find(q => destinationQuestionId === getQuestionContent(q)?.questionId)

        if (!foundDefault) {
          this.errorMessage = orderValidationMessage
        }
        if (destinationQuestionId !== '' && !foundDestination) {
          this.errorMessage = orderValidationMessage
        }
      }
    })
    return this;
  }

}