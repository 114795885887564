import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Color from 'color';
import BlankButton, { Props as BlankButtonProps } from './BlankButton';

interface Props extends BlankButtonProps {
  light?: boolean;
}

const BlockButton: FC<Props> = props => {
  const { light, ...rest } = props;

  if (light) {
    return <LightBlockButton {...rest} />;
  }
  return <BaseBlockButton {...rest} />;
};

export default styled(BlockButton)``;

const Root = styled(BlankButton as any)`
  padding: 0 40px 0 40px;
  min-height: 40px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: white;
  transition: background-color 0.2s, color 0.2s;
  border-radius: 7px;

  &:disabled {
    color: white;
    background-color: #baccde;
  }
`;

const baseBackgroundColor = '#0075E1';
const BaseBlockButton = styled(Root as any)`
  background-color: ${baseBackgroundColor};

  &:hover:not(:disabled) {
    background-color: ${Color(baseBackgroundColor)
      .darken(0.2)
      .string()};
  }

  &:active:not(:disabled) {
    background-color: ${Color(baseBackgroundColor)
      .darken(0.3)
      .string()};
  }
`;

const lightBackgroundColor = 'white';
const LightBlockButton = styled(Root as any)`
  background-color: ${lightBackgroundColor};
  color: #0075e1;

  &:hover:not(:disabled) {
    color: white;
    background-color: #7cbdf9;
  }

  &:active:not(:disabled) {
    color: white;
    background-color: #669bbc;
  }
`;
