import React, { useState, useEffect, FormEvent } from "react";
import styled from "styled-components/macro";
import { navigate } from "@reach/router";
import Page, { PageComponent } from "../components/Page";
import SiteSelectForm from "../components/SiteSelectForm";
import SpinnerOverlay from "../components/SpinnerOverlay";
import getStudySiteNames from "../api/getStudySiteNames";
import getCurrentUser from "../api/getCurrentUser";
import { userIsAdmin } from "../shared";

interface Props {
  studyName: string;
}

const SelectSite: PageComponent<Props> = (props) => {
  const { studyName, ...rest } = props;
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState<string[]>([]);
  const [selectedSite, setSelectedSite] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    load().finally(() => setLoading(false));
    async function load() {
      setLoading(true);
      const user = await getCurrentUser();
      if (userIsAdmin(user)) {
        setSites(await (await getStudySiteNames(studyName)).sort());
      } else {
        const assignedStudy = user.assignedStudies.find(
          (assigned) => assigned.studyName === studyName
        );
        if (!assignedStudy) {
          navigate("/");
          return;
        }
        setSites(assignedStudy.sites.sort());
      }
    }
  }, [studyName]);

  function handleValueChange(event: FormEvent<HTMLSelectElement>) {
    setSelectedSite(event.currentTarget.value);
  }

  function handleSubmit() {
    navigate(`/${studyName}/${selectedSite}/dashboard`);
  }

  return (
    <Page {...rest}>
      {loading && <SpinnerOverlay />}
      <SiteSelectForm
        value={selectedSite}
        onChange={handleValueChange}
        onSubmit={handleSubmit}
      >
        <option value="all">all</option>
        {sites.map((site) => (
          <option key={site} value={site}>
            {site}
          </option>
        ))}
      </SiteSelectForm>
    </Page>
  );
};

export default styled(SelectSite)`
  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
  align-items: center;
  justify-content: center;
`;
