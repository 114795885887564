import { StudySurvey, Weekday } from "../models";

export const SCREENTIME_EMA_NAME = "iOSScreenTimeEMA";

// This is the default implementation of the screentime ema. It's based off an exact survey
export const SCREENTIME_EMA_DEFAULT: StudySurvey = {
  exact: {
    surveyId: SCREENTIME_EMA_NAME,
    notificationHeader: "Screen Time Capture Activity",
    notificationBody: "Time to complete a quick research activity.",
    weekdaysAvailable: [
      Weekday.MONDAY,
      Weekday.TUESDAY,
      Weekday.WEDNESDAY,
      Weekday.THURSDAY,
      Weekday.FRIDAY,
      Weekday.SATURDAY,
      Weekday.SUNDAY,
    ],
    totalNumberOfDays: undefined,
    introStepHeader: "",
    introStepBody: "",
    completionStepEnabled: false,
    completionStepHeader: "",
    completionStepBody: "",
    emaQuestions: [],
    containsBranchingLogic: false,
    containsShuffledSequence: false,
    shuffleSequence: undefined,
    containsRiskAlerts: undefined,
    multipleSurveyBursts: false,
    daysWithinSurveyBurst: undefined,
    daysBetweenSurveyBursts: undefined,
    daysBeforeFirstSurveyDelivery: 2,
    weekDeliverySchedule: [
      {
        dayOfWeek: "MONDAY",
        exactDeliveriesForDay: [
          {
            surveyDeliveryHour: 14,
            surveyDeliveryMinute: 20,
            surveyExpirationHour: 23,
            surveyExpirationMinute: 59,
            repeatsEveryDay: true,
          },
        ],
      },
      {
        dayOfWeek: "TUESDAY",
        exactDeliveriesForDay: [
          {
            surveyDeliveryHour: 14,
            surveyDeliveryMinute: 20,
            surveyExpirationHour: 23,
            surveyExpirationMinute: 59,
            repeatsEveryDay: true,
          },
        ],
      },
      {
        dayOfWeek: "WEDNESDAY",
        exactDeliveriesForDay: [
          {
            surveyDeliveryHour: 14,
            surveyDeliveryMinute: 20,
            surveyExpirationHour: 23,
            surveyExpirationMinute: 59,
            repeatsEveryDay: true,
          },
        ],
      },
      {
        dayOfWeek: "THURSDAY",
        exactDeliveriesForDay: [
          {
            surveyDeliveryHour: 14,
            surveyDeliveryMinute: 20,
            surveyExpirationHour: 23,
            surveyExpirationMinute: 59,
            repeatsEveryDay: true,
          },
        ],
      },
      {
        dayOfWeek: "FRIDAY",
        exactDeliveriesForDay: [
          {
            surveyDeliveryHour: 14,
            surveyDeliveryMinute: 20,
            surveyExpirationHour: 23,
            surveyExpirationMinute: 59,
            repeatsEveryDay: true,
          },
        ],
      },
      {
        dayOfWeek: "SATURDAY",
        exactDeliveriesForDay: [
          {
            surveyDeliveryHour: 14,
            surveyDeliveryMinute: 20,
            surveyExpirationHour: 23,
            surveyExpirationMinute: 59,
            repeatsEveryDay: true,
          },
        ],
      },
      {
        dayOfWeek: "SUNDAY",
        exactDeliveriesForDay: [
          {
            surveyDeliveryHour: 14,
            surveyDeliveryMinute: 20,
            surveyExpirationHour: 23,
            surveyExpirationMinute: 59,
            repeatsEveryDay: true,
          },
        ],
      },
    ],
  },
  random: undefined,
};
