import React, {FC, FormEvent} from "react";
import styled from "styled-components/macro";
import noop from "../utils/noop";

export type TimeInputValue =  {
  hour: number;
  minute: number;
}

interface TimeInputProps {
  value?: TimeInputValue;
  onChange?: (value: TimeInputValue) => any;
  minHour?: number;
  maxHour?: number;
  hourStep?: number;
  minMin?: number;
  maxMin?: number;
  minStep?: number;
  displayMin?: boolean;
}

const TimeInput: FC<TimeInputProps> = props => {
  const {
    value = props.value ?? { hour: props.minHour ?? 0, minute: props.minHour ?? 0 },
    onChange = props.onChange ?? noop,
    minHour = props.minHour ?? 0,
    maxHour = props.maxHour ?? 23,
    hourStep = props.hourStep ?? 1,
    minMin = props.minMin ?? 0,
    maxMin = props.maxMin ?? 59,
    minStep = props.minStep ?? 1,
    displayMin = props.displayMin ?? true
  } = props;

  function getHours(): number[] {
    let hours = []
    for (let i = minHour; i <= maxHour; i+=hourStep) {
      hours.push(i);
    }
    return hours;
  }

  function getMinutes(): number[] {
    let minutes = []
    for (let i = minMin; i <= maxMin; i+=minStep) {
      minutes.push(i);
    }
    return minutes;
  }

  function updateHour(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      hour: parseInt(event.currentTarget.value)
    });
  }

  function updateMinute(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      minute: parseInt(event.currentTarget.value)
    });
  }

  const hours = getHours();
  const minutes = getMinutes();
  return <div>
   <select value={value.hour} onChange={updateHour}>
     {hours.map(hour => <option>{hour}</option>)}
   </select>
    {displayMin &&
      <div>
        :
        <select value={value.minute} onChange={updateMinute}>
          {minutes.map(minute => <option>{minute}</option>)}
        </select>
      </div>
    }
  </div>
}

export default styled(TimeInput)`
`;
