export interface TimeComparatorValue {
  hour: number;
  minute: number;
}

export function timeGreaterThen(valueOne: TimeComparatorValue, valueTwo: TimeComparatorValue): boolean {
  return (valueOne.hour > valueTwo.hour)
    || (valueOne.hour === valueTwo.hour && valueOne.minute > valueTwo.minute);
}

export function timeGreaterThenOrEqualTo(valueOne: TimeComparatorValue, valueTwo: TimeComparatorValue): boolean {
  return (valueOne.hour > valueTwo.hour)
    || (valueOne.hour === valueTwo.hour && valueOne.minute >= valueTwo.minute);
}

export function timeLessThen(valueOne: TimeComparatorValue, valueTwo: TimeComparatorValue): boolean {
  return (valueOne.hour < valueTwo.hour)
    || (valueOne.hour === valueTwo.hour && valueOne.minute < valueTwo.minute);
}

export function timeLessThenOrEqualTo(valueOne: TimeComparatorValue, valueTwo: TimeComparatorValue): boolean {
  return (valueOne.hour < valueTwo.hour)
    || (valueOne.hour === valueTwo.hour && valueOne.minute <= valueTwo.minute);
}
