import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import createStudy from "../api/createStudy";
import { getAssignedStudiesDetail } from "../api/getAssignedStudies";
import AddStudyModal, { AddStudyModalValue } from "../components/AddStudyModal";
import ArrowIcon from "../components/ArrowIcon";
import BlankButton from "../components/BlankButton";
import BlockButton from "../components/BlockButton";
import Column from "../components/Column";
import Footer from "../components/Footer";
import LoadingPage from "../components/LoadingPage";
import NavBar from "../components/NavBar";
import Page, { PageComponent } from "../components/Page";
import PlusIcon from "../components/PlusIcon";
import Row from "../components/Row";
import SpinnerOverlay from "../components/SpinnerOverlay";
import StudyTable from "../components/StudyTable";
import StudyTableRow from "../components/StudyTableRow";
import { StudyDetails, userIsAdmin } from "../shared";
import getErrorText from "../utils/getErrorText";
import useUser from "../utils/useUser";
import deactivateDevices from "../api/deactivateDevices";
import { navigateToCreateStudy } from "./StudyCreator";
import { ActiveSensors, SurveyPrefs } from "../models";
import ManageDevicesModal from "../components/ManageDevicesModal";
import { Value as DeviceFormValue } from "../components/DeviceForm";

interface Props {
  studyName: string;
  siteName: string;
}

const ManageStudies: PageComponent<Props> = (props) => {
  const { studyName, siteName, ...rest } = props;
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [details, setDetails] = useState<StudyDetails[]>([]);
  const [loadingUser, user] = useUser();
  const [savingStudy, setSavingStudy] = useState(false);
  const [addStudyModalOpen, setAddStudyModalOpen] = useState(false);
  const [studyToEdit, setStudyToEdit] = useState<SurveyPrefs | null>(null);
  const loading = loadingDetails || loadingUser;

  useEffect(() => {
    load();
    async function load() {
      const sortedDetails = (await getAssignedStudiesDetail()).sort((a, b) =>
        a.study.studyName > b.study.studyName ? 1 : -1
      );
      setDetails(sortedDetails);
      setLoadingDetails(false);
    }
  }, []);

  function handleBackClick() {
    window.history.back();
  }

  function handleAddStudyClick() {
    setAddStudyModalOpen(true);
  }

  function handleAddAutoEARSStudyClick() {
    navigateToCreateStudy(true);
  }

  function handleAddStudyClose() {
    setAddStudyModalOpen(false);
  }

  async function handleEditStudyClick(study: SurveyPrefs) {
    await navigateToCreateStudy(false, study, true);
  }
  function handleManageDevicesStudyClick(study: SurveyPrefs) {
    setStudyToEdit(study);
  }

  function handleEditStudyClose() {
    setStudyToEdit(null);
  }
  if (loading || !user) {
    return <LoadingPage />;
  }
  async function handleManageDevicesSubmit(value: DeviceFormValue) {
    if (!studyToEdit) {
      return;
    }
    if (loading || !user) {
      return <LoadingPage />;
    }
    try {
      setSavingStudy(true);
      const { studyName } = studyToEdit;
      const { devices } = value;
      if (!studyName) {
        throw Error("StudyName undefined, null, or empty");
      }
      const devicesIdsToDeactivate = devices
        .filter((device) => device.deactivated && !device.wasDeactivated)
        .map((device) => device.deviceId);
      if (devicesIdsToDeactivate.length > 0) {
        await deactivateDevices({
          studyName,
          deviceIds: devicesIdsToDeactivate,
        });
      }
      setStudyToEdit(null);
    } catch (error) {
      console.error(error);
      alert(getErrorText(error));
    } finally {
      setSavingStudy(false);
    }
  }

  async function handleAddStudySubmit(value: AddStudyModalValue) {
    try {
      setSavingStudy(true);
      await createStudy(value);
      setDetails(await getAssignedStudiesDetail());
      setAddStudyModalOpen(false);
      alert("Study created successfully!");
    } catch (error) {
      alert(getErrorText(error));
    } finally {
      setSavingStudy(false);
    }
  }

  if (loading || !user) {
    return <LoadingPage />;
  }

  return (
    <Page {...rest}>
      {savingStudy && <SpinnerOverlay />}
      {addStudyModalOpen && (
        <AddStudyModal
          onClose={handleAddStudyClose}
          onSubmit={handleAddStudySubmit}
        />
      )}
      {studyToEdit && (
        <ManageDevicesModal
          studyName={studyToEdit.studyName ?? ""}
          user={user}
          onClose={handleEditStudyClose}
          onSubmit={handleManageDevicesSubmit}
        />
      )}
      <NavBar
        user={user}
        selectedStudyName={studyName}
        selectedSiteName={siteName}
      />
      <Content>
        <TitleRow>
          <BlankButton onClick={handleBackClick}>
            <ArrowIcon />
          </BlankButton>
          <span>Manage Studies</span>
        </TitleRow>
        <StudyTable>
          {details.map((detail) => (
            <StudyTableRow
              key={detail.study.studyName}
              studyName={detail.study.studyName ?? ""}
              participantCount={detail.participants.length}
              sensors={
                (detail.study as SurveyPrefs)
                  .activeSensors as (ActiveSensors | null)[]
              }
              researcherCount={detail.researchers.length}
              siteCount={detail.study.studySites?.length ?? 0}
              user={user}
              onEditClick={() => handleEditStudyClick(detail.study)}
              onDevicesClick={() => handleManageDevicesStudyClick(detail.study)}
            />
          ))}
        </StudyTable>
        {userIsAdmin(user) && (
          <AddStudyRow>
            <AddStudyButton onClick={handleAddAutoEARSStudyClick}>
              Add Study
              <PlusIcon />
            </AddStudyButton>
          </AddStudyRow>
        )}
      </Content>
      <Footer studyName={studyName} siteName={siteName} />
    </Page>
  );
};

export default ManageStudies;

const Content = styled(Column as any)`
  align-items: center;
  flex: 1;
`;

const TitleRow = styled(Row as any)`
  color: #0b1c4a;
  width: 1000px;
  max-width: 100%;
  font-size: 56px;
  line-height: 64px;
  margin-bottom: 80px;
  align-items: center;

  ${BlankButton} {
    margin-right: 24px;
  }
`;

const AddStudyRow = styled(Row as any)`
  margin-top: 16px;
  justify-content: flex-end;
  width: 864px;
  max-width: 100%;
`;

const AddStudyButton = styled(BlockButton as any)`
  flex-direction: row;
  padding: 0 1em 0 1em;

  ${PlusIcon} {
    margin-left: 14px;
  }
`;
