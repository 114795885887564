import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import sso from "./api/sso";

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./ssoAuthConfig";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback(async (event: EventMessage) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult;
		const { account, accessToken }= payload;
		msalInstance.setActiveAccount(account);
		// After the SSO redirect back to EARS client, we need to send the access token to EARS server 
		// to verify it and log the user in the Dashboard.
		await sso({
			email: account?.username,
			token: accessToken 
		});
	}
});

Amplify.configure(awsExports);

ReactDOM.render(<App pca={msalInstance} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
