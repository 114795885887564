import React, { FC } from 'react';
import styled from 'styled-components/macro';
import failIconSrc from '../images/fail_icon.png';
import issueIconSrc from '../images/issue_icon.png';
import successIconSrc from '../images/success_icon.png';
import completeIconSrc from '../images/complete_icon_gold.png';
import deactivatedIconSrc from '../images/deactivated_icon.png';

export const FAIL = 'fail';
export const ISSUE = 'issue';
export const SUCCESS = 'success';
export const DEACT = 'deact';
export const COMPLETE = 'complete';
export type StatusIconType =
  | typeof FAIL
  | typeof ISSUE
  | typeof SUCCESS
  | typeof DEACT
  | typeof COMPLETE;

interface StatusIconProps {
  type: StatusIconType;
  style?: any;
}

const StatusIcon: FC<StatusIconProps> = props => {
  const { type, ...rest } = props;
  let imageSrc: string;
  let altText: string;

  switch (type) {
    case FAIL:
      imageSrc = failIconSrc;
      altText = 'Failure';
      break;
    case ISSUE:
      imageSrc = issueIconSrc;
      altText = 'Issue';
      break;
    case SUCCESS:
      imageSrc = successIconSrc;
      altText = 'Success';
      break;
    case DEACT:
      imageSrc = deactivatedIconSrc;
      altText = 'Deact';
      break;
    case COMPLETE:
      imageSrc = completeIconSrc;
      altText = 'Complete';
      break;
    default:
      imageSrc = deactivatedIconSrc;
      altText = 'Deact';
  }

  return <img {...rest} src={imageSrc} alt={altText} />;
};

export default styled(StatusIcon)`
  width: 20px;
  height: 20px;
`;
