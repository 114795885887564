import { SurveyQuestion } from "../models"
import getQuestionContent from "./getQuestionContent"

export const getRiskRule = (question: SurveyQuestion) => {
    if (question.bool?.riskRule){
        return {...question.bool.riskRule}
    }
    if (question.choice?.riskRule){
        return {...question.choice.riskRule}
    }
    if (question.scale?.riskRule){
        return {...question.scale.riskRule}
    }
    return undefined
}

export const anyRiskRules = (questions: (SurveyQuestion | null)[]) => {
    return questions.some(question => getRiskRule(question as SurveyQuestion))
}

export const hasRiskRules = (questions: (SurveyQuestion | null)[]) => {
    const questionIDs: String[] = []
    questions.forEach(question => {
        const questionID = getQuestionContent(question)?.questionId
        if (questionID && getRiskRule(question as SurveyQuestion)){
            questionIDs.push(questionID)
        }
    })
    return questionIDs
}

export default anyRiskRules;