import React, { Component } from 'react';
import styled from 'styled-components/macro';
import onClickOutsideDeco from 'react-onclickoutside';

interface FloatingMenuProps {
  onClickOutside?: () => any;
  enableOnClickOutside?: boolean;
  disableOnClickOutside?: boolean;
  stopPropagation?: boolean;
  preventDefault?: boolean;
  outsideClickIgnoreClass?: boolean;
  eventTypes?: string[];
}

class FloatingMenu extends Component<FloatingMenuProps> {
  handleClickOutside = () => {
    const { onClickOutside } = this.props;
    if (onClickOutside) {
      onClickOutside();
    }
  };

  render() {
    const {
      // These props are used by `onClickOutsideDeco` but shouldn't be passed to the DOM
      onClickOutside,
      enableOnClickOutside,
      disableOnClickOutside,
      stopPropagation,
      preventDefault,
      outsideClickIgnoreClass,
      eventTypes,
      ...rest
    } = this.props;
    return <ul {...rest} />;
  }
}

export default styled(onClickOutsideDeco(FloatingMenu))`
  background-color: #0075e1;
  padding: 8px;
  border-radius: 7px;
  min-width: 154px;

  > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    background-color: #ffffff;
    border-radius: 7px;
    align-items: center;
    color: #0b1c4a;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    padding: 0 5px 0 8px;
    margin: 0;
    cursor: pointer;
  }

  > li + li {
    margin-top: 8px;
  }

  > li a {
    text-decoration: none;
    color: #0b1c4a;
    flex: 1;
  }
`;
