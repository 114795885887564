import URLSearchParams from '@ungap/url-search-params'; // polyfill

export interface ParsedSearchParams {
  [key: string]: string | null;
}

/**
 * Parses a search param string into an object.
 * All resulting values have the type `string | null`.
 *
 * Example:
 *
 * ```ts
 * const { foo, blarb } = parseSearchString('?foo=bar&blarb=narb', ['foo', 'blarb']);
 * ```
 */
export default function parseSearchString(
  search: string,
  keys: string[]
): ParsedSearchParams {
  const urlSearchParams = new URLSearchParams(search);
  const parsedSearchParams: ParsedSearchParams = {};

  for (const key of keys) {
    parsedSearchParams[key] = urlSearchParams.get(key);
  }

  return parsedSearchParams;
}
