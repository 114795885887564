import React, {
  FC,
  useState,
  ChangeEvent,
  FormEvent,
  ChangeEventHandler,
  useRef,
  useEffect,
  createRef,
  RefObject
} from 'react';
import styled from "styled-components/macro";
import Row from "../components/Row"
import Column from "./Column";
import noop from "../utils/noop";
import Input from "./Input";
import XButton from "./XButton";
import PlusButton from "./PlusButton";
import Validation from "./Validation";

interface ListInputProps {
  value?: Array<string>;
  onChange?: (value: string[]) => any;
  validationPrefix?: string
  validation?: Record<string, string | undefined>;
  disabled?: boolean;
}

const ListInput: FC<ListInputProps> = props => {
  const {
    value = [''],
    onChange = noop,
    validation = {},
    validationPrefix = '',
    disabled = false,
    ...rest
  } = props;

  function addItem(_: FormEvent<HTMLButtonElement>) {
    onChange([...value, '']);
  }

  const handleEnterKey = (key: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onChange([...value, '']);
    }
  };

  const changeInput = (key: number) => (event: FormEvent<HTMLInputElement>) => {
    value[key] = event.currentTarget.value;
    onChange(value);
  }

  const removeInput = (key: number) => (event: FormEvent<HTMLButtonElement>) => {
    value.splice(key, 1);
    onChange(value);
  }


  return <Column {...rest}>
    {value.map((val, index) => {
      return (
      <Validation key={index} message={validation[validationPrefix+index]}>
        <ListRow>
            <Input
              value={val}
              onChange={changeInput(index)}
              onKeyPress={handleEnterKey(index)}
              error={validation[validationPrefix+index]}
              disabled={disabled}
            />
            <XButton
              onClick={removeInput(index)}
              disabled={disabled}
              />
        </ListRow>
      </Validation>
      )
      })
    }
    <PlusButton
      onClick={addItem}
      disabled={disabled}
      />

  </Column>;
}

export default styled(ListInput)`
`;

const ListRow = styled(Row as any)`
  min-width: 300px;
  > * {
    margin: 2px;
  }
  align-items: center;
`;
