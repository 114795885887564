import { ChoiceBranchingRule, ChoiceRiskRule } from "../models"

export const updatedChoiceBranchList = (oldChoiceID: string, newChoiceID: string, [...expectedAnswers]: string[]) => {
   expectedAnswers[expectedAnswers.indexOf(oldChoiceID)] = newChoiceID
   return expectedAnswers
}

export const updatedChoiceBranchingRule = (oldChoiceID: string, newChoiceID: string, currentBranchingRule: ChoiceBranchingRule | undefined) => {
    let newBranchingRule = currentBranchingRule
    if (!currentBranchingRule)
        return undefined
    const currentExpectedAnswers = currentBranchingRule?.expectedAnswerValues
    if (currentBranchingRule && currentExpectedAnswers && currentExpectedAnswers.length > 0){
        const newExpectedAnswers = updatedChoiceBranchList(oldChoiceID, newChoiceID, currentExpectedAnswers )
        newBranchingRule = {
            ...currentBranchingRule,
            expectedAnswerValues: newExpectedAnswers
        }
    }
    return newBranchingRule
}

export const removeChoiceIDFromBranching = (choiceID: string, [...expectedAnswers]: string[]) => {
    expectedAnswers.splice(expectedAnswers.indexOf(choiceID),1)
    return expectedAnswers
}

export const removedChoiceIDBranchingRule = (choiceID: string, currentBranchingRule: ChoiceBranchingRule | undefined) => {
    let newBranchingRule = currentBranchingRule
    if (!currentBranchingRule)
        return undefined
    const currentExpectedAnswers = currentBranchingRule?.expectedAnswerValues
    if (currentBranchingRule && currentExpectedAnswers && currentExpectedAnswers.length > 0){
        const newExpectedAnswers = removeChoiceIDFromBranching(choiceID, currentExpectedAnswers )
        newBranchingRule = {
            ...currentBranchingRule,
            expectedAnswerValues: newExpectedAnswers
        }
    }
    return newBranchingRule
}

export const removedChoiceIDRiskRule = (choiceID: string, currentRiskRule: ChoiceRiskRule | undefined) => {
    let newBranchingRule = currentRiskRule
    if (!currentRiskRule)
        return undefined
    const currentExpectedAnswers = currentRiskRule?.alertOn
    if (currentRiskRule && currentExpectedAnswers && currentExpectedAnswers.length > 0){
        const newExpectedAnswers = removeChoiceIDFromBranching(choiceID, currentExpectedAnswers )
        newBranchingRule = {
            ...currentRiskRule,
            alertOn: newExpectedAnswers
        }
    }
    return newBranchingRule
}