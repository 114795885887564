/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSurveyPrefs = /* GraphQL */ `
  mutation CreateSurveyPrefs(
    $input: CreateSurveyPrefsInput!
    $condition: ModelSurveyPrefsConditionInput
  ) {
    createSurveyPrefs(input: $input, condition: $condition) {
      id
      version
      studyName
      studySites
      activeSensors
      daysUntilDeactivation
      gameEnabled
      homeScreenEmaAvailable
      studySurveyList {
        random {
          surveyId
          notificationHeader
          notificationBody
          weekdaysAvailable
          totalNumberOfDays
          introStepHeader
          introStepBody
          completionStepEnabled
          completionStepHeader
          completionStepBody
          emaQuestions {
            scale {
              questionId
              questionHeader
              questionBody
              skipEnabled
              minValue
              maxValue
              minimumValueDescription
              maximumValueDescription
              shouldHideRanges
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerValue
                maximumExpectedAnswerValue
              }
              riskRule {
                alertOnMin
                alertOnMax
              }
            }
            choice {
              questionId
              questionHeader
              questionBody
              skipEnabled
              multipleChoice
              textChoices {
                textChoiceID
                textToDisplay
              }
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswerValues
              }
              riskRule {
                alertOn
              }
            }
            textEntry {
              questionId
              questionHeader
              questionBody
              skipEnabled
              maxCharacterLengthForInput
              textHint
            }
            bool {
              questionId
              questionHeader
              questionBody
              skipEnabled
              trueValue
              falseValue
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswer
              }
              riskRule {
                alertOn
              }
            }
            timeOfDay {
              questionId
              questionHeader
              questionBody
              skipEnabled
              defaultHour
              defaultMinute
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerHour
                minimumExpectedAnswerMinute
                maximumExpectedAnswerHour
                maximumExpectedAnswerMinute
              }
            }
            info {
              questionId
              questionHeader
              questionBody
              skipEnabled
            }
          }
          containsBranchingLogic
          containsShuffledSequence
          containsRiskAlerts
          shuffleSequence {
            startIndex
            endIndex
          }
          multipleSurveyBursts
          daysWithinSurveyBurst
          daysBetweenSurveyBursts
          daysBeforeFirstSurveyDelivery
          weekDeliverySchedule {
            dayOfWeek
            windowStartHour
            windowEndHour
            repeatsEveryDay
          }
        }
        exact {
          surveyId
          notificationHeader
          notificationBody
          weekdaysAvailable
          totalNumberOfDays
          introStepHeader
          introStepBody
          completionStepEnabled
          completionStepHeader
          completionStepBody
          emaQuestions {
            scale {
              questionId
              questionHeader
              questionBody
              skipEnabled
              minValue
              maxValue
              minimumValueDescription
              maximumValueDescription
              shouldHideRanges
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerValue
                maximumExpectedAnswerValue
              }
              riskRule {
                alertOnMin
                alertOnMax
              }
            }
            choice {
              questionId
              questionHeader
              questionBody
              skipEnabled
              multipleChoice
              textChoices {
                textChoiceID
                textToDisplay
              }
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswerValues
              }
              riskRule {
                alertOn
              }
            }
            textEntry {
              questionId
              questionHeader
              questionBody
              skipEnabled
              maxCharacterLengthForInput
              textHint
            }
            bool {
              questionId
              questionHeader
              questionBody
              skipEnabled
              trueValue
              falseValue
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswer
              }
              riskRule {
                alertOn
              }
            }
            timeOfDay {
              questionId
              questionHeader
              questionBody
              skipEnabled
              defaultHour
              defaultMinute
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerHour
                minimumExpectedAnswerMinute
                maximumExpectedAnswerHour
                maximumExpectedAnswerMinute
              }
            }
            info {
              questionId
              questionHeader
              questionBody
              skipEnabled
            }
          }
          containsBranchingLogic
          containsShuffledSequence
          containsRiskAlerts
          shuffleSequence {
            startIndex
            endIndex
          }
          multipleSurveyBursts
          daysWithinSurveyBurst
          daysBetweenSurveyBursts
          daysBeforeFirstSurveyDelivery
          weekDeliverySchedule {
            dayOfWeek
            exactDeliveriesForDay {
              surveyDeliveryHour
              surveyDeliveryMinute
              surveyExpirationHour
              surveyExpirationMinute
              repeatsEveryDay
            }
          }
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSurveyPrefs = /* GraphQL */ `
  mutation UpdateSurveyPrefs(
    $input: UpdateSurveyPrefsInput!
    $condition: ModelSurveyPrefsConditionInput
  ) {
    updateSurveyPrefs(input: $input, condition: $condition) {
      id
      version
      studyName
      studySites
      activeSensors
      daysUntilDeactivation
      gameEnabled
      homeScreenEmaAvailable
      studySurveyList {
        random {
          surveyId
          notificationHeader
          notificationBody
          weekdaysAvailable
          totalNumberOfDays
          introStepHeader
          introStepBody
          completionStepEnabled
          completionStepHeader
          completionStepBody
          emaQuestions {
            scale {
              questionId
              questionHeader
              questionBody
              skipEnabled
              minValue
              maxValue
              minimumValueDescription
              maximumValueDescription
              shouldHideRanges
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerValue
                maximumExpectedAnswerValue
              }
              riskRule {
                alertOnMin
                alertOnMax
              }
            }
            choice {
              questionId
              questionHeader
              questionBody
              skipEnabled
              multipleChoice
              textChoices {
                textChoiceID
                textToDisplay
              }
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswerValues
              }
              riskRule {
                alertOn
              }
            }
            textEntry {
              questionId
              questionHeader
              questionBody
              skipEnabled
              maxCharacterLengthForInput
              textHint
            }
            bool {
              questionId
              questionHeader
              questionBody
              skipEnabled
              trueValue
              falseValue
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswer
              }
              riskRule {
                alertOn
              }
            }
            timeOfDay {
              questionId
              questionHeader
              questionBody
              skipEnabled
              defaultHour
              defaultMinute
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerHour
                minimumExpectedAnswerMinute
                maximumExpectedAnswerHour
                maximumExpectedAnswerMinute
              }
            }
            info {
              questionId
              questionHeader
              questionBody
              skipEnabled
            }
          }
          containsBranchingLogic
          containsShuffledSequence
          containsRiskAlerts
          shuffleSequence {
            startIndex
            endIndex
          }
          multipleSurveyBursts
          daysWithinSurveyBurst
          daysBetweenSurveyBursts
          daysBeforeFirstSurveyDelivery
          weekDeliverySchedule {
            dayOfWeek
            windowStartHour
            windowEndHour
            repeatsEveryDay
          }
        }
        exact {
          surveyId
          notificationHeader
          notificationBody
          weekdaysAvailable
          totalNumberOfDays
          introStepHeader
          introStepBody
          completionStepEnabled
          completionStepHeader
          completionStepBody
          emaQuestions {
            scale {
              questionId
              questionHeader
              questionBody
              skipEnabled
              minValue
              maxValue
              minimumValueDescription
              maximumValueDescription
              shouldHideRanges
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerValue
                maximumExpectedAnswerValue
              }
              riskRule {
                alertOnMin
                alertOnMax
              }
            }
            choice {
              questionId
              questionHeader
              questionBody
              skipEnabled
              multipleChoice
              textChoices {
                textChoiceID
                textToDisplay
              }
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswerValues
              }
              riskRule {
                alertOn
              }
            }
            textEntry {
              questionId
              questionHeader
              questionBody
              skipEnabled
              maxCharacterLengthForInput
              textHint
            }
            bool {
              questionId
              questionHeader
              questionBody
              skipEnabled
              trueValue
              falseValue
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswer
              }
              riskRule {
                alertOn
              }
            }
            timeOfDay {
              questionId
              questionHeader
              questionBody
              skipEnabled
              defaultHour
              defaultMinute
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerHour
                minimumExpectedAnswerMinute
                maximumExpectedAnswerHour
                maximumExpectedAnswerMinute
              }
            }
            info {
              questionId
              questionHeader
              questionBody
              skipEnabled
            }
          }
          containsBranchingLogic
          containsShuffledSequence
          containsRiskAlerts
          shuffleSequence {
            startIndex
            endIndex
          }
          multipleSurveyBursts
          daysWithinSurveyBurst
          daysBetweenSurveyBursts
          daysBeforeFirstSurveyDelivery
          weekDeliverySchedule {
            dayOfWeek
            exactDeliveriesForDay {
              surveyDeliveryHour
              surveyDeliveryMinute
              surveyExpirationHour
              surveyExpirationMinute
              repeatsEveryDay
            }
          }
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurveyPrefs = /* GraphQL */ `
  mutation DeleteSurveyPrefs(
    $input: DeleteSurveyPrefsInput!
    $condition: ModelSurveyPrefsConditionInput
  ) {
    deleteSurveyPrefs(input: $input, condition: $condition) {
      id
      version
      studyName
      studySites
      activeSensors
      daysUntilDeactivation
      gameEnabled
      homeScreenEmaAvailable
      studySurveyList {
        random {
          surveyId
          notificationHeader
          notificationBody
          weekdaysAvailable
          totalNumberOfDays
          introStepHeader
          introStepBody
          completionStepEnabled
          completionStepHeader
          completionStepBody
          emaQuestions {
            scale {
              questionId
              questionHeader
              questionBody
              skipEnabled
              minValue
              maxValue
              minimumValueDescription
              maximumValueDescription
              shouldHideRanges
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerValue
                maximumExpectedAnswerValue
              }
              riskRule {
                alertOnMin
                alertOnMax
              }
            }
            choice {
              questionId
              questionHeader
              questionBody
              skipEnabled
              multipleChoice
              textChoices {
                textChoiceID
                textToDisplay
              }
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswerValues
              }
              riskRule {
                alertOn
              }
            }
            textEntry {
              questionId
              questionHeader
              questionBody
              skipEnabled
              maxCharacterLengthForInput
              textHint
            }
            bool {
              questionId
              questionHeader
              questionBody
              skipEnabled
              trueValue
              falseValue
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswer
              }
              riskRule {
                alertOn
              }
            }
            timeOfDay {
              questionId
              questionHeader
              questionBody
              skipEnabled
              defaultHour
              defaultMinute
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerHour
                minimumExpectedAnswerMinute
                maximumExpectedAnswerHour
                maximumExpectedAnswerMinute
              }
            }
            info {
              questionId
              questionHeader
              questionBody
              skipEnabled
            }
          }
          containsBranchingLogic
          containsShuffledSequence
          containsRiskAlerts
          shuffleSequence {
            startIndex
            endIndex
          }
          multipleSurveyBursts
          daysWithinSurveyBurst
          daysBetweenSurveyBursts
          daysBeforeFirstSurveyDelivery
          weekDeliverySchedule {
            dayOfWeek
            windowStartHour
            windowEndHour
            repeatsEveryDay
          }
        }
        exact {
          surveyId
          notificationHeader
          notificationBody
          weekdaysAvailable
          totalNumberOfDays
          introStepHeader
          introStepBody
          completionStepEnabled
          completionStepHeader
          completionStepBody
          emaQuestions {
            scale {
              questionId
              questionHeader
              questionBody
              skipEnabled
              minValue
              maxValue
              minimumValueDescription
              maximumValueDescription
              shouldHideRanges
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerValue
                maximumExpectedAnswerValue
              }
              riskRule {
                alertOnMin
                alertOnMax
              }
            }
            choice {
              questionId
              questionHeader
              questionBody
              skipEnabled
              multipleChoice
              textChoices {
                textChoiceID
                textToDisplay
              }
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswerValues
              }
              riskRule {
                alertOn
              }
            }
            textEntry {
              questionId
              questionHeader
              questionBody
              skipEnabled
              maxCharacterLengthForInput
              textHint
            }
            bool {
              questionId
              questionHeader
              questionBody
              skipEnabled
              trueValue
              falseValue
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                expectedAnswer
              }
              riskRule {
                alertOn
              }
            }
            timeOfDay {
              questionId
              questionHeader
              questionBody
              skipEnabled
              defaultHour
              defaultMinute
              branchingRule {
                destinationQuestionId
                defaultQuestionId
                minimumExpectedAnswerHour
                minimumExpectedAnswerMinute
                maximumExpectedAnswerHour
                maximumExpectedAnswerMinute
              }
            }
            info {
              questionId
              questionHeader
              questionBody
              skipEnabled
            }
          }
          containsBranchingLogic
          containsShuffledSequence
          containsRiskAlerts
          shuffleSequence {
            startIndex
            endIndex
          }
          multipleSurveyBursts
          daysWithinSurveyBurst
          daysBetweenSurveyBursts
          daysBeforeFirstSurveyDelivery
          weekDeliverySchedule {
            dayOfWeek
            exactDeliveriesForDay {
              surveyDeliveryHour
              surveyDeliveryMinute
              surveyExpirationHour
              surveyExpirationMinute
              repeatsEveryDay
            }
          }
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
