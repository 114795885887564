import React, { FC, FormEvent } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import BlockButton from './BlockButton';
import Input from './Input';
import Form from './Form';
import noop from '../utils/noop';

interface ForgottenPasswordFormValue {
  resetEmail: string;
}

interface ForgottenPasswordFormProps {
  value?: ForgottenPasswordFormValue;
  onChange?: (value: ForgottenPasswordFormValue) => any;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => any;
}

export const ForgottenPasswordFormDefaultValue: ForgottenPasswordFormValue = {
  resetEmail: '',
};

const ForgottenPasswordForm: FC<ForgottenPasswordFormProps> = props => {
  const {
    onChange = noop,
    onSubmit = noop,
    value = ForgottenPasswordFormDefaultValue,
    ...rest
  } = props;
  const disabled = value.resetEmail.length < 1;

  function handleUsernameChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      resetEmail: event.currentTarget.value
    });
  }

  return (
    <Column {...rest}>
      <Form onSubmit={onSubmit}>
        <UsernameInput
          placeholder="Email Address"
          value={value.resetEmail}
          onChange={handleUsernameChange}
        />
        <SubmitButton disabled={disabled}>Submit</SubmitButton>
      </Form>
    </Column>
  );
};

export default styled(ForgottenPasswordForm)`
  width: 352px;
  max-width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const UsernameInput = styled(Input as any)`
  width: 100%;
  margin-bottom: 16px;
`;

const PasswordInput = styled(Input as any)`
  width: 100%;
  margin-bottom: 16px;
`;

const SubmitButton = styled(BlockButton as any)`
  width: 159px;
`;
