import React, { FC } from 'react';
import styled from 'styled-components/macro';
import BgImage from './BgImage';
import EARS2LogoSrc from '../images/Ksana-Health-EARS-2point0-logo-reversed.png'

const Logo: FC = props => {
  return <>
    <BgImage {...props}  src={EARS2LogoSrc} alt="Ears" />
    </>;
};

export default styled(Logo)`
  min-height: 100px;
  min-width: 400px;
`;
