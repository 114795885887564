import { SurveyQuestion } from "../../models"

const getQuestionContent = (question: SurveyQuestion | null) => {

    if (question && question.bool) {
      return {...question.bool}
    } 
    if (question && question.choice) {
      return {...question.choice}
    } 
    if (question && question.info) {
      return {...question.info}
    }
    if (question && question.scale) {
      return {...question.scale}
    }
    if (question && question.textEntry) {
      return {...question.textEntry}
    }
    if (question && question.timeOfDay) {
      return {...question.timeOfDay}
    }
    return;
}

export default getQuestionContent