import dayjs from "dayjs";
import {Sensor} from "../shared/Sensor";
import {ActiveSensors} from "../models";


export type DeviceUpload = {
    deviceID: string,
    timestamp: number,
    sensorType: Sensor,
    yearmonth?: string,
    filesize?: string,
  }

export const filterUniqueDays = (uploadEvents: DeviceUpload[]) => {
    const uniqueDays: string[] = []
    uploadEvents.forEach(event => {
        const dayOfEvent = dayjs(event.timestamp*1000).format('YYYY-MM-DD')
        if (!uniqueDays.includes(dayOfEvent))
            uniqueDays.push(dayOfEvent)
    })
    return uniqueDays
}

// start timestamp epoch
export const allDaysSince = (start: number) => {
    const now = dayjs().endOf('day')
    let dayPointer = dayjs(start*1000)
    const daysSince: string[] = []
    
    while (dayPointer.isBefore(now)){
        daysSince.push(dayPointer.format('YYYY-MM-DD'))
        dayPointer = dayPointer.add(1,'day')
    }
    return daysSince
}

export const allDaysBtwn = (start: number, end: number) => {
  const endDay = dayjs(end*1000).add(1,'day').startOf('day')
  let dayPointer = dayjs(start*1000).startOf('day')
  const daysSince: string[] = []
  
  while (dayPointer.isBefore(endDay)){
      daysSince.push(dayPointer.format('YYYY-MM-DD'))
      dayPointer = dayPointer.add(1,'day')
  }
  return daysSince
}

export const lastSevenDays = (timestamp: number) => {
  let dayPointer = dayjs(timestamp*1000).startOf('day')
  const daysSince: string[] = [dayPointer.format('YYYY-MM-DD')]

  for (let i = 0; i < 6; i++){
    dayPointer = dayPointer.subtract(1,'day')
    daysSince.push(dayPointer.format('YYYY-MM-DD'))
  }
  
  return daysSince.reverse()
}


export const hasSensorDay = (
  uploadEvents: DeviceUpload[],
  day: string,
  sensor: Sensor
) => {
  return uploadEvents.some((upload) => {
    const includesDay =
      day === dayjs(upload.timestamp * 1000).format("YYYY-MM-DD");
    // some sensors from the db do not match type case
    const uploadSensorType = upload.sensorType?.toUpperCase() === "SCREENONTIME" ? ActiveSensors.APPUSAGE : upload.sensorType?.toUpperCase() === "FLEKSYKEYINPUT" ? ActiveSensors.KEYINPUT : upload?.sensorType;
    const includesSensor = uploadSensorType.toUpperCase() === sensor.toUpperCase();
    return includesDay && includesSensor;
  });
};

export const filterUploadEventsBtwnTimestamps = (uploads: DeviceUpload[], start: string, end: string) => {
  const startDay = dayjs(start, 'YYYY-MM-DD').startOf('day').valueOf()
  const endDay = dayjs(end, 'YYYY-MM-DD').endOf('day').valueOf()
  const filterStartDates = uploads.filter(upload => upload.timestamp * 1000 >= startDay)
  return filterStartDates.filter(upload => upload.timestamp * 1000 <= endDay)
}

export const filteredDaysPage = (currentPage: number, deviceDays: string[]) => {
  const itemsPerPage = 7;
  const start = Math.max(0, deviceDays.length - (currentPage * itemsPerPage))
  if (currentPage === 1 ) {
    return deviceDays.slice(start)
  } else {
    const end = deviceDays.length - ((currentPage -1) * itemsPerPage)
    return deviceDays.slice(start, end)
  }
}