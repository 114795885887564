import React, { FC } from 'react';
import styled, {StyledComponent} from 'styled-components/macro';
import Column from './Column';

interface VerticalSplitPageProps {
  leftChild: any,
  rightChild: any
}

const VerticalSplitPage: FC<VerticalSplitPageProps> = props => {
    return ( <SplitPageGrid>
              <LeftPane>
                {props.leftChild}
              </LeftPane>
              <RightPane>
                {props.rightChild}
              </RightPane>
          </SplitPageGrid>
    );
};


export type PageComponent<Props = {}> = FC<
  Props & { path: string; default?: boolean }
>;

export default VerticalSplitPage;

const SplitPageGrid = styled('div')`
  display: grid;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: auto;
  grid-template-areas: "LeftPane RightPane";
`;

const LeftPane = styled('div')`
  grid-area: LeftPane;
  background: linear-gradient(315deg, #0075e1 0%, #06dddd 100%);
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 100vh;
  padding: 1em;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;
const RightPane = styled('div')`
  grid-area: RightPane;
  background: lightgray;
  width: 100%;
  height: 100%;
  padding: 1em;
`;
