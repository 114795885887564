import React, { useState, useEffect } from "react";
import { calcDaysEnrolled } from "../utils/calcDaysEnrolled";
import styled from "styled-components/macro";
import { Parser as CSVParser } from "json2csv";
import { navigate } from "@reach/router";
import Page, { PageComponent } from "../components/Page";
import NavBar from "../components/NavBar";
import Column from "../components/Column";
import DropdownMenu from "../components/DropdownMenu";
import Row from "../components/Row";
import DropdownMenuHeader from "../components/DropdownMenuHeader";
import FloatingMenu from "../components/FloatingMenu";
import CalendarIcon from "../components/CalendarIcon";
import Footer from "../components/Footer";
import Chevron from "../components/Chevron";
import useUser from "../utils/useUser";
import SpinnerOverlay from "../components/SpinnerOverlay";
import UploadStatusPieChart from "../components/UploadStatusPieChart";
import Table from "../components/Table";
import BlockButton from "../components/BlockButton";
import StatusIcon, {
  StatusIconType,
  FAIL,
  ISSUE,
  SUCCESS,
  DEACT,
  COMPLETE,
} from "../components/StatusIcon";
import BlankButton from "../components/BlankButton";
import downloadString from "../utils/downloadString";
import { getDevicesStatus } from "../api/getStudyInfo";
import UploadStatModal, {
  UpdateStatModalHash,
} from "../components/UploadStatModal";
import Link from "../components/Link";
import LoadingPage from "../components/LoadingPage";
import CreateParticipantModal from "../components/CreateParticipantModal";
import createParticipants, { Participant } from "../api/createParticipants";
import { setHashRoute } from "../utils/hashRouter";
import { useDashboardSettings } from "../utils/useDashboardSettings";
import { DashboardSettings } from "../shared/DashboardSettings";
import { Device, DeviceStatuses } from "../shared/Types";

interface Props {
  studyName: string;
  siteName: string;
  chartType: string;
}

const Dashboard: PageComponent<Props> = (props) => {
  const { studyName, chartType, siteName, ...rest } = props;
  const dashboardSettings: DashboardSettings = useDashboardSettings(studyName);
  const [detailsDeviceId, setDetailsDeviceId] = useState<string | null>(null);
  const [participantId, setParticipantId] = useState<string | null>(null);
  const [participantOSVersion, setParticipantOSVersion] = useState<
    string | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [, user] = useUser();
  const [addParticipantValue, setAddParticipantValue] = useState<boolean>(
    false
  );
  const [savedParticipants, setSavedParticipants] = useState<
    Participant[] | null
  >(null);
  const [detailsStatus, setDetailsStatus] = useState<
    StatusIconType | undefined
  >(undefined);
  const [scrollPosition] = useState(0);
  const [devices, setDevices] = useState<DeviceStatuses>({
    completed: [],
    deactivated: [],
    stopped: [],
    withIssues: [],
    noIssues: [],
  });

  const baseUrl = `/${studyName}/${siteName}/dashboard`;
  const allUrl = baseUrl;
  const deactivatedUrl = `${baseUrl}/deactivated`;
  const stoppedUrl = `${baseUrl}/stopped`;
  const issueUrl = `${baseUrl}/with-issues`;
  const noIssueUrl = `${baseUrl}/without-issues`;
  const completeUrl = `${baseUrl}/completed`;

  const showPieChart = chartType === "all";
  const showDeactivatedTable =
    chartType === "all" || chartType === "deactivated";
  const showStoppedTable = chartType === "all" || chartType === "stopped";
  const showIssueTable = chartType === "all" || chartType === "with-issues";
  const showNoIssueTable =
    chartType === "all" || chartType === "without-issues";
  const showCompletedTable =
    dashboardSettings.completionDays &&
    (chartType === "all" || chartType === "completed");

  let title: string;
  if (chartType === "deactivated") {
    title = "Deactivated Participants";
  } else if (chartType === "stopped") {
    title = "Stopped Participants";
  } else if (chartType === "with-issues") {
    title = "Participants with Issues";
  } else if (chartType === "without-issues") {
    title = "Participants without Issues";
  } else if (chartType === "completed") {
    title = "Completed Participants";
  } else {
    title = "Participants";
  }
  if (siteName !== "all") {
    title += " - " + siteName;
  }

  useEffect(() => {
    setLoading(true);

    load()
      .then(() => setLoading(false))
      .catch(console.error);

    async function load() {
      const studyDevicesByStatus = await getDevicesStatus(studyName, siteName);
      setDevices(studyDevicesByStatus);
    }
  }, [studyName, siteName]);

  function handleExportStoppedClicked() {
    downloadString(
      getDeviceTableFilename("stopped-devices"),
      getDevicesIssuesCSV(devices.stopped),
      "text/csv"
    );
  }

  function handleExportWithIssuesClicked() {
    downloadString(
      getDeviceTableFilename("with-issues"),
      getDevicesIssuesCSV(devices.withIssues),
      "text/csv"
    );
  }

  function handleExportWithoutIssuesClicked() {
    downloadString(
      getDeviceTableFilename("without-issues"),
      getDevicesIssuesCSV(devices.noIssues),
      "text/csv"
    );
  }

  function handleExportCompletedClicked() {
    downloadString(
      getDeviceTableFilename("completed"),
      getDevicesIssuesCSV(devices.completed),
      "text/csv"
    );
  }

  function handleExportDeactivatedClicked() {
    downloadString(
      getDeviceTableFilename("deactivated"),
      getDevicesIssuesCSV(devices.deactivated),
      "text/csv"
    );
  }

  const getDevicesIssuesCSV = (devices: Device[]): string => {
    return new CSVParser({
      fields: ["deviceID", "participantID", "lastUpload", "site"],
    }).parse(
      devices.map((device) => ({
        deviceID: device.deviceID,
        participantID: device.participantID,
        lastUpload: device.lastUpload
          ? new Date(device.lastUpload * 1000).toDateString()
          : "N/A",
        site: device.site || "none",
      }))
    );
  };

  function getDeviceTableFilename(prefix: string) {
    const defaultEndDate = new Date();
    const defaultStartDate = new Date(
      new Date().setDate(defaultEndDate.getDate() - 1)
    );
    return `${[
      studyName,
      prefix,
      [
        defaultStartDate.getFullYear(),
        defaultStartDate.getMonth() + 1,
        defaultStartDate.getDate(),
      ].join("-"),
      "to",
      [
        defaultEndDate.getFullYear(),
        defaultEndDate.getMonth() + 1,
        defaultEndDate.getDate(),
      ].join("-"),
    ].join("_")}.csv`;
  }

  function handleDeviceClick(
    deviceId: string,
    participantId: string,
    deviceStatus: StatusIconType,
    osVersion?: string
  ) {
    setHashRoute(UpdateStatModalHash);
    setDetailsDeviceId(deviceId);
    setParticipantId(participantId);
    setDetailsStatus(deviceStatus);
    setParticipantOSVersion(osVersion);
  }

  function handleStatModalClose() {
    setDetailsDeviceId(null);
    setParticipantId(null);
    window.scrollTo(0, scrollPosition);
  }

  function handleAddParticipantClick() {
    let execute = true;
    const lower_name = studyName.toLowerCase();
    if (lower_name.includes("test") || lower_name.includes("pilot")) {
      execute = window.confirm(
        "You are about to add a participant to a project that may be a pilot or test project," +
          " do you wish to continue?"
      );
    }
    if (execute) {
      setAddParticipantValue(true);
    }
  }

  function handleAddParticipantClose() {
    setAddParticipantValue(false);
    setSavedParticipants(null);
  }

  if (!user || loading) {
    return (
      <LoadingPage>
        <SpinnerOverlay />
      </LoadingPage>
    );
  }

  return (
    <Page {...rest}>
      {detailsDeviceId && (
        <UploadStatModal
          studyName={studyName}
          siteName={siteName}
          deviceId={detailsDeviceId}
          participantId={participantId ?? "None"}
          onClose={handleStatModalClose}
          deviceStatus={detailsStatus || "fail"}
          osVersion={participantOSVersion || ""}
        />
      )}
      {addParticipantValue && (
        <CreateParticipantModal
          study={studyName}
          site={siteName}
          onClose={handleAddParticipantClose}
        />
      )}
      <NavBar
        selectedStudyName={studyName}
        selectedSiteName={siteName}
        user={user}
      />
      <Content>
        <HeaderRow>
          <Row>
            <h1>{title}</h1>
            <ParticipantsDropdownMenu
              label={(open: boolean | undefined) => (
                <Chevron flipped={open} light={open} scale={0.5} />
              )}
            >
              <li>
                <Link to={allUrl}>All Categories</Link>
              </li>
              <li>
                <Link to={stoppedUrl}>Stopped Uploading</Link>
              </li>
              <li>
                <Link to={issueUrl}>Uploaded with Issues</Link>
              </li>
              <li>
                <Link to={noIssueUrl}>Uploaded without Issues</Link>
              </li>
              <li>
                <Link to={deactivatedUrl}>Deactivated</Link>
              </li>
              {dashboardSettings.completionDays && (
                <li>
                  <Link to={completeUrl}>Completed</Link>
                </li>
              )}
            </ParticipantsDropdownMenu>
          </Row>
          {(studyName === "abcd" && siteName === "all") ||
          (studyName.includes("abcdyear", 0) && siteName === "all") ? (
            <div>Add participant unavailable, please select a site.</div>
          ) : (
            <AddParticipantsButton onClick={handleAddParticipantClick}>
              Add participant
            </AddParticipantsButton>
          )}
        </HeaderRow>
        <HeaderSubtitleRow />

        {showPieChart && (
          <UploadStatusPieChart
            loading={loading}
            stoppedCount={devices.stopped.length}
            deactivatedCount={devices.deactivated.length}
            issueCount={devices.withIssues.length}
            noIssueCount={devices.noIssues.length}
            completeCount={
              dashboardSettings.completionDays ? devices.completed.length : null
            }
            onStoppedClick={() => navigate(stoppedUrl)}
            onDeactivatedClick={() => navigate(deactivatedUrl)}
            onIssueClick={() => navigate(issueUrl)}
            onNoIssueClick={() => navigate(noIssueUrl)}
            onCompleteClick={() => navigate(completeUrl)}
          />
        )}

        {showStoppedTable && (
          <TableContainer>
            <TableTitle color="#E62D2D">Stopped Uploading</TableTitle>
            <Table color="#E62D2D">
              <thead>
                <tr>
                  <th>Device ID</th>
                  <th>Participant ID</th>
                  <th>Last Upload</th>
                  <th>Days Enrolled</th>
                  <th>Site</th>
                  <th>OS</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {devices.stopped.sort(sortTableRows).map((device) => (
                  <tr
                    key={device.deviceID}
                    onClick={() =>
                      handleDeviceClick(
                        device.deviceID,
                        device.participantID,
                        FAIL,
                        device.osVersion
                      )
                    }
                  >
                    <td>{device.deviceID}</td>
                    <td>{device.participantID}</td>
                    <td>
                      {device.lastUpload
                        ? new Date(device.lastUpload * 1000).toDateString()
                        : "N/A"}
                    </td>
                    <td>{calcDaysEnrolled(device) + " days"}</td>
                    <td>{device.site || "N/A"}</td>
                    <td>{device.deviceID.includes("-") ? "iOS" : "android"}</td>
                    <td>
                      <StatusIcon type="fail" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <ExportCsvRow onClick={handleExportStoppedClicked}>
              <BlankButton>Export CSV</BlankButton>
            </ExportCsvRow>
          </TableContainer>
        )}

        {showIssueTable && (
          <TableContainer>
            <TableTitle color="#06DDDD">Uploaded With Issues</TableTitle>
            <Table color="#06DDDD">
              <thead>
                <tr>
                  <th>Device ID</th>
                  <th>Participant ID</th>
                  <th>Last Upload</th>
                  <th>Days Enrolled</th>
                  <th>Site</th>
                  <th>OS</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {devices.withIssues.sort(sortTableRows).map((device) => {
                  return (
                    <tr
                      key={device.deviceID}
                      onClick={() =>
                        handleDeviceClick(
                          device.deviceID,
                          device.participantID,
                          ISSUE,
                          device.osVersion
                        )
                      }
                    >
                      <td>{device.deviceID}</td>
                      <td>{device.participantID}</td>
                      <td>
                        {device.lastUpload
                          ? new Date(device.lastUpload * 1000).toDateString()
                          : "N/A"}
                      </td>
                      <td>{calcDaysEnrolled(device) + " days"}</td>
                      <td>{device.site || "N/A"}</td>
                      <td>
                        {device.deviceID.includes("-") ? "iOS" : "android"}
                      </td>
                      <td>
                        <StatusIcon type="issue" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <ExportCsvRow onClick={handleExportWithIssuesClicked}>
              <BlankButton>Export CSV</BlankButton>
            </ExportCsvRow>
          </TableContainer>
        )}
        {showNoIssueTable && (
          <TableContainer>
            <TableTitle color="#0075E1">Uploaded Without Issues</TableTitle>
            <Table color="#0075E1">
              <thead>
                <tr>
                  <th>Device ID</th>
                  <th>Participant ID</th>
                  <th>Last Upload</th>
                  <th>Days Enrolled</th>
                  <th>Site</th>
                  <th>OS</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {devices.noIssues.sort(sortTableRows).map((device) => (
                  <tr
                    key={device.deviceID}
                    onClick={() =>
                      handleDeviceClick(
                        device.deviceID,
                        device.participantID,
                        SUCCESS,
                        device.osVersion
                      )
                    }
                  >
                    <td>{device.deviceID}</td>
                    <td>{device.participantID}</td>
                    <td>
                      {device.lastUpload
                        ? new Date(device.lastUpload * 1000).toDateString()
                        : "N/A"}
                    </td>
                    <td>{calcDaysEnrolled(device) + " days"}</td>
                    <td>{device.site || "N/A"}</td>
                    <td>{device.deviceID.includes("-") ? "iOS" : "android"}</td>
                    <td>
                      <StatusIcon type="success" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <ExportCsvRow onClick={handleExportWithoutIssuesClicked}>
              <BlankButton>Export CSV</BlankButton>
            </ExportCsvRow>
          </TableContainer>
        )}
        {showCompletedTable && (
          <TableContainer>
            <TableTitle color="#F1BB2B">Completed</TableTitle>
            <Table color="#F1BB2B">
              <thead>
                <tr>
                  <th>Device ID</th>
                  <th>Participant ID</th>
                  <th>Last Upload</th>
                  <th>Days Enrolled</th>
                  <th>Site</th>
                  <th>OS</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {devices.completed.sort(sortTableRows).map((device) => (
                  <tr
                    key={device.deviceID}
                    onClick={() =>
                      handleDeviceClick(
                        device.deviceID,
                        device.participantID,
                        COMPLETE,
                        device.osVersion
                      )
                    }
                  >
                    <td>{device.deviceID}</td>
                    <td>{device.participantID}</td>
                    <td>
                      {device.lastUpload
                        ? new Date(device.lastUpload * 1000).toDateString()
                        : "N/A"}
                    </td>
                    <td>{calcDaysEnrolled(device) + " days"}</td>
                    <td>{device.site || "N/A"}</td>
                    <td>{device.deviceID.includes("-") ? "iOS" : "android"}</td>
                    <td>
                      <StatusIcon type="complete" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <ExportCsvRow onClick={handleExportCompletedClicked}>
              <BlankButton>Export CSV</BlankButton>
            </ExportCsvRow>
          </TableContainer>
        )}
        {showDeactivatedTable && (
          <TableContainer>
            <TableTitle color="#0B1C4A">Deactivated</TableTitle>
            <Table color="#0B1C4A">
              <thead>
                <tr>
                  <th>Device ID</th>
                  <th>Participant ID</th>
                  <th>Last Upload</th>
                  <th>Days Enrolled</th>
                  <th>Site</th>
                  <th>OS</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {devices.deactivated.sort(sortTableRows).map((device) => (
                  <tr
                    key={device.deviceID}
                    onClick={() =>
                      handleDeviceClick(
                        device.deviceID,
                        device.participantID,
                        DEACT,
                        device.osVersion
                      )
                    }
                  >
                    <td>{device.deviceID}</td>
                    <td>{device.participantID}</td>
                    <td>
                      {device.lastUpload
                        ? new Date(device.lastUpload * 1000).toDateString()
                        : "N/A"}
                    </td>
                    <td>{calcDaysEnrolled(device) + " days"}</td>
                    <td>{device.site || "N/A"}</td>
                    <td>{device.deviceID.includes("-") ? "iOS" : "android"}</td>
                    <td>
                      <StatusIcon type="deact" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <ExportCsvRow>
              <BlankButton onClick={handleExportDeactivatedClicked}>
                Export CSV
              </BlankButton>
            </ExportCsvRow>
          </TableContainer>
        )}
      </Content>
      <Footer studyName={studyName} siteName={siteName} />
    </Page>
  );
};

export default Dashboard;

function sortTableRows(a: Device, b: Device): number {
  const lastUploadA = a.lastUpload ? new Date(a.lastUpload) : null;
  const lastUploadB = b.lastUpload ? new Date(b.lastUpload) : null;

  if (lastUploadA && lastUploadB) {
    return lastUploadB.getTime() - lastUploadA.getTime();
  } else if (lastUploadA) {
    return -1;
  } else if (lastUploadB) {
    return 1;
  } else {
    return 0;
  }
}

const Content = styled(Column as any)`
  align-self: center;
  width: 1300px;
  max-width: 100%;
  flex: 1;

  ${UploadStatusPieChart} {
    margin-bottom: 118px;
  }

  ${Table} {
    max-width: 100%;
    overflow-x: auto;
  }

  ${Table} td {
    cursor: pointer;
  }

  ${Table} th,
  ${Table} td {
    white-space: nowrap;
  }
`;

const HeaderRow = styled(Row as any)`
  justify-content: space-between;
  padding: 0 20px 0 25px;
`;

const ParticipantsDropdownMenu = styled(DropdownMenu as any)`
  ${DropdownMenuHeader} {
    padding: 0;
    width: 40px;
    height: 40px;
    margin-left: 16px;
  }

  /* prettier-ignore */
  ${DropdownMenuHeader}:not([data-open='true']) {
    background: #d9ecff;
  }

  ${FloatingMenu} {
    width: 190px;
  }
`;

const HeaderSubtitleRow = styled(Row as any)`
  align-items: center;
  color: #baccde;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 88px;
  padding: 0 20px 0 20px;
  ${CalendarIcon} {
    margin-right: 18px;
  }
`;

const TableTitle = styled(Row as any)<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 63px;

  ${Table} + & {
    margin-top: 48px;
  }
`;

const ExportCsvRow = styled(Row as any)`
  color: #0b1c4a;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 16px;
  margin-top: 6px;
`;

const AddParticipantsButton = styled(BlockButton as any)`
  color: #ffffff;
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 16px;
`;

const TableContainer = styled("div")`
  max-width: 100%;
  overflow-x: auto;
  margin: 0 20px 0 20px;
  padding-bottom: 20px;
`;
