import axios from './axios';
import { Researcher } from '../shared';

interface Options {
  studyName: string;
  studySites: string[];
  researchers: Researcher[];
}

export default async function createStudy(options: Options) {
  await axios.post('/study', options);
}
