import React, {FC, FormEvent, useEffect, useState} from 'react';
import noop from "../../utils/noop";
import timeToString from "../../utils/timeToString";
import Row from "../Row";
import XButton from "../XButton";
import PlusButton from "../PlusButton";
import TimeInput, {TimeInputValue} from "../TimeInput";
import Column from "../Column";
import styled from "styled-components/macro";
import {TimeValidator} from "../../shared/utils/Validators";
import {ValidatedInput} from "../Validation";
import {
  ExactDeliveriesForWeekday,
  ExactDeliverySurveySchedule,
  RandomizedDeliveryWindowForWeekday,
  Weekday
} from "../../models";
import Switch from "../Switch";
import {timeGreaterThen, timeLessThen} from "../../shared/utils/timeComparator";
import {alertMounter} from "../Alert";


//Random
export const RandomizedDeliveryForWeekdayDefaultValue: RandomizedDeliveryWindowForWeekday = {
  dayOfWeek: Weekday.MONDAY,
  windowStartHour: 0,
  windowEndHour: 2,
  repeatsEveryDay: false
}

interface RandomizedDeliveryForWeekdayProps {
  value?: RandomizedDeliveryWindowForWeekday;
  onChange?: (value: RandomizedDeliveryWindowForWeekday) => any;
  removeSchedule?: () => any;
  allSchedules?: (RandomizedDeliveryWindowForWeekday | null)[];
  onUpdateRepeatsEveryDay?: (value: RandomizedDeliveryWindowForWeekday[]) => any;
}

export const RandomizedDeliveryForWeekdayComponent: FC<RandomizedDeliveryForWeekdayProps> = props => {
  const {
    value = RandomizedDeliveryForWeekdayDefaultValue,
    onChange = noop,
    allSchedules = [],
    onUpdateRepeatsEveryDay = noop,
    ...rest
  } = props;

  /**
   * Update the start hour value. Also update the end hour to prevent invalid schedules
   */
  function updateStart(startHour: TimeInputValue) {
    if (value.repeatsEveryDay) {
      copyToWeek(true, startHour.hour, startHour.hour+2);
    } else {
      onChange({
        ...value,
        windowStartHour: startHour.hour,
        windowEndHour: startHour.hour + 2
      });
    }
  }

  function updateEnd(endValue: TimeInputValue) {
    if (value.repeatsEveryDay) {
      copyToWeek(true, value.windowStartHour, endValue.hour);
    } else {
      onChange({
        ...value,
        windowEndHour: endValue.hour
      })
    }
  }

  function handleRepeatsEveryDay(shouldRepeat: boolean) {
    copyToWeek(shouldRepeat, value.windowStartHour, value.windowEndHour)
  }

  function copyToWeek(repeatEveryDay: boolean, startValue: number, endValue: number) {
    if (startValue !== undefined && startValue < 3) {
      alertMounter({
        titleText: 'Start Time Error',
        message: 'To repeat this schedule every day the start time cannot be before 3am.'
      });
      return;
    }
    const newSchedule = Object.keys(Weekday).map(day => new RandomizedDeliveryWindowForWeekday({
      dayOfWeek: day as Weekday,
      repeatsEveryDay: repeatEveryDay,
      windowStartHour: startValue,
      windowEndHour: endValue,
    }));
    onUpdateRepeatsEveryDay(newSchedule);
  }

  // Lower bound hours for the time inputs
  const startMinHour = value.dayOfWeek === Weekday.SUNDAY ? 3 : 0
  const endMinHour = value.windowStartHour + 2

  return <Column>
    <Row gap={'10px'}>
      <LeftColumn>
        Start Hour
        <TimeInput
          onChange={updateStart}
          value={{hour: value.windowStartHour, minute: 0}}
          minHour={startMinHour}
          maxHour={22}
          displayMin={false}
        />
      </LeftColumn>
      <Column>
        End Hour
        <TimeInput
          onChange={updateEnd}
          hourStep={2}
          value={{hour: value.windowEndHour, minute: 0}}
          minHour={endMinHour}
          maxHour={24}
          displayMin={false}
        />
      </Column>
      <Column>
        Same Every Day
        <Switch
          onChange={handleRepeatsEveryDay}
          checked={value.repeatsEveryDay}
        />
      </Column>
    </Row>
    {value.dayOfWeek === Weekday.SUNDAY &&
    <Row>
      <p>Please be aware that surveys cannot be scheduled between 00:00 (12:00am) and 03:00 (3:00am) on Sunday
        mornings.</p>
    </Row>
    }
  </Column>
}

// Exact
const ExactDeliverySurveyScheduleDefaultValue: ExactDeliverySurveySchedule = {
  surveyDeliveryHour: 8,
  surveyDeliveryMinute: 0,
  surveyExpirationHour: 10,
  surveyExpirationMinute: 0,
  repeatsEveryDay: false
}

export const ExactDeliveriesForWeekdayDefaultValue: ExactDeliveriesForWeekday = {
  dayOfWeek: Weekday.MONDAY,
  exactDeliveriesForDay: []
}

interface ExactDeliveriesForWeekdayUIProps {
  value?: ExactDeliveriesForWeekday;
  onChange?: (value: ExactDeliveriesForWeekday) => any;
  canSwitch?: (value: boolean) => any;
  removeSchedule?: () => any;
  allSchedules?: (ExactDeliveriesForWeekday | null)[];
  onUpdateRepeatsEveryDay?: (value: ExactDeliveriesForWeekday[]) => any;
}

export const ExactDeliveriesForWeekdayUI: FC<ExactDeliveriesForWeekdayUIProps> = props => {
  const {
    value = ExactDeliveriesForWeekdayDefaultValue,
    onChange = noop,
    canSwitch = noop,
    allSchedules = [],
    onUpdateRepeatsEveryDay = noop,
    ...rest
  } = props;
  const [validation, setValidation] = useState<Array<Record<string, string | undefined>>>(
    value.exactDeliveriesForDay?.map((_) => {
      return {'surveyDelivery': undefined, 'surveyExpiration': undefined};
    }) ?? []);

  useEffect(() => {
    let validationIsGood = true;
    const [startMin, startMinErrorMsg] = value.dayOfWeek === 'SUNDAY'
      ? [3, 'Delivery cannot start before 03:00 (3:00 am).']
      : [0, 'Time cannot be before 00:00 (12:00 AM).']
    if (value.exactDeliveriesForDay) {
      // Handle case if len is 1. Check if all values are set
      if (value.exactDeliveriesForDay.length === 1) {
        const start = {
          hour: value.exactDeliveriesForDay[0].surveyDeliveryHour,
          minute: value.exactDeliveriesForDay[0].surveyDeliveryMinute
        };
        const end = {
          hour: value.exactDeliveriesForDay[0]?.surveyExpirationHour ?? 24,
          minute: value.exactDeliveriesForDay[0]?.surveyExpirationMinute ?? 0
        };
        const startError = new TimeValidator(start).noNaN()
          .min(startMin, 0, startMinErrorMsg)
          .validate();
        const endError = new TimeValidator(end).noNaN()
          .strictlyGreaterThan(start.hour, start.minute, 'Expiration must be after delivery')
          .max(23, 59, 'Time cannot be greater than 23:59 (11:59 PM).')
          .validate();
        setValidation([{'surveyDelivery': startError, 'surveyExpiration': endError}]);
        validationIsGood = validationIsGood && startError === undefined && endError === undefined;
      }
      // Handle case if len >= 2Check each delivery window for overlap and that all values are set.
      // it starts at 1 because i-1 is used to access the last delivery
      for (let i = 1; i < value.exactDeliveriesForDay.length; i++) {
        const start = {
          hour: value.exactDeliveriesForDay[i].surveyDeliveryHour,
          minute: value.exactDeliveriesForDay[i].surveyDeliveryMinute
        };
        const end = {
          hour: value.exactDeliveriesForDay[i]?.surveyExpirationHour ?? 24,
          minute: value.exactDeliveriesForDay[i]?.surveyExpirationMinute ?? 0
        };
        const previousEnd = {
          hour: value.exactDeliveriesForDay[i - 1]?.surveyExpirationHour ?? NaN,
          minute: value.exactDeliveriesForDay[i - 1]?.surveyExpirationMinute ?? NaN
        };
        const startError = new TimeValidator(start)
          .noNaN()
          .min(startMin, 0, startMinErrorMsg)
          .strictlyGreaterThan(previousEnd.hour, previousEnd.minute,
            'Window cannot overlap with a previous delivery.')
          .validate();
        const endError = new TimeValidator(end)
          .noNaN()
          .max(23, 59, 'Time cannot be greater than 23:59 (11:59 PM).')
          .strictlyGreaterThan(start.hour, start.minute, 'Expiration must be after delivery')
          .validate();
        const validationCopy = [...validation];
        validationCopy[i].surveyDelivery = startError;
        validationCopy[i].surveyExpiration = endError;
        setValidation(validationCopy);
        validationIsGood = validationIsGood && startError === undefined && endError === undefined;
      }
    }
    canSwitch(validationIsGood);
  }, [value]);

  function addDelivery(event: FormEvent<HTMLButtonElement>) {
    setValidation([...validation, {surveyDelivery: undefined, surveyExpiration: undefined}]);
    const newDelivery = {...ExactDeliverySurveyScheduleDefaultValue};
    onChange({
      ...value,
      exactDeliveriesForDay: [...value.exactDeliveriesForDay!, newDelivery]
    })
  }

  const updateStart = (key: number) => (event: FormEvent<HTMLInputElement>) => {
    const copy = [...value.exactDeliveriesForDay!];
    let previousDelivery = copy[key];
    if (previousDelivery) {
      copy[key] = new ExactDeliverySurveySchedule({
        surveyDeliveryHour: parseInt(event.currentTarget.value.split(':')[0] ?? ''),
        surveyDeliveryMinute: parseInt(event.currentTarget.value.split(':')[1] ?? ''),
        surveyExpirationHour: previousDelivery.surveyExpirationHour,
        surveyExpirationMinute: previousDelivery.surveyExpirationMinute,
        repeatsEveryDay: previousDelivery.repeatsEveryDay
      });
      if (previousDelivery.repeatsEveryDay) {
        copyToWeek(copy[key], previousDelivery);
      } else {
        onChange({
          ...value,
          exactDeliveriesForDay: copy
        });
      }
    }
  };

  const updateEnd = (key: number) => (event: FormEvent<HTMLInputElement>) => {
    const copy = [...value.exactDeliveriesForDay!];
    let previousDelivery = copy[key];
    if (previousDelivery) {
      copy[key] = new ExactDeliverySurveySchedule({
        surveyDeliveryHour: previousDelivery.surveyDeliveryHour,
        surveyDeliveryMinute: previousDelivery.surveyDeliveryMinute,
        surveyExpirationHour: parseInt(event.currentTarget.value.split(':')[0]),
        surveyExpirationMinute: parseInt(event.currentTarget.value.split(':')[1]),
        repeatsEveryDay: previousDelivery.repeatsEveryDay
      });
      if (previousDelivery.repeatsEveryDay) {
        copyToWeek(copy[key], previousDelivery);
      } else {
        onChange({
          ...value,
          exactDeliveriesForDay: copy
        });
      }
    }
  }

  const removeDelivery = (key: number) => (event: FormEvent<HTMLButtonElement>) => {
    const copy = [...value.exactDeliveriesForDay!];
    copy.splice(key, 1);
    onChange({
      ...value,
      exactDeliveriesForDay: copy
    });
  };

  const handleRepeatsSwitch = (key: number) => (repeatEveryDay: boolean) => {
    const previousSchedule = value.exactDeliveriesForDay[key];
    if (previousSchedule) {
      copyToWeek({...previousSchedule, repeatsEveryDay: repeatEveryDay}, previousSchedule);
    }
  }

  function copyToWeek(repeatingSchedule: ExactDeliverySurveySchedule, previousSchedule: ExactDeliverySurveySchedule) {
    if (repeatingSchedule.surveyDeliveryHour < 3) {
      alertMounter({
        titleText: 'Start Time Error',
        message: 'To repeat this schedule every day the start time cannot be before 3am.'
      });
      return;
    }
    let newAllSchedules: ExactDeliveriesForWeekday[] = [];
    let error = undefined;
    if (!repeatingSchedule.repeatsEveryDay) {
      allSchedules.forEach(day => {
        if (day) {
          const newExactDeliveries = day.exactDeliveriesForDay.map(schedule => {
            if (schedule.repeatsEveryDay && schedule.surveyDeliveryHour === repeatingSchedule.surveyDeliveryHour
              && schedule.surveyDeliveryMinute === repeatingSchedule.surveyDeliveryMinute && schedule.surveyExpirationHour === repeatingSchedule.surveyExpirationHour
              && schedule.surveyExpirationMinute === repeatingSchedule.surveyExpirationMinute) {
              return new ExactDeliverySurveySchedule({...schedule, repeatsEveryDay: false});
            } else {
              return schedule
            }
          });
          newAllSchedules.push(new ExactDeliveriesForWeekday({
            dayOfWeek: day.dayOfWeek,
            exactDeliveriesForDay: newExactDeliveries
          }));
        }
      });
    } else {
      // this only catches matches... it doesn't add anything if there aren't any matches
      Object.keys(Weekday).forEach(day => {
        let newDeliveries: ExactDeliverySurveySchedule[] = []
        let matchFound = false;
        const schedules = allSchedules.filter(item => item && item.dayOfWeek === day)
        if (schedules.length > 0) {
          const day = schedules[0]!
          day.exactDeliveriesForDay.forEach(schedule => {
            if (schedule && schedule.surveyExpirationHour !== undefined && schedule.surveyExpirationMinute !== undefined) {
              if (schedule.surveyDeliveryHour === previousSchedule.surveyDeliveryHour &&
                schedule.surveyDeliveryMinute === previousSchedule.surveyDeliveryMinute &&
                schedule.surveyExpirationHour === previousSchedule.surveyExpirationHour &&
                schedule.surveyExpirationMinute === previousSchedule.surveyExpirationMinute) {
                matchFound = true;
                newDeliveries.push(repeatingSchedule);
              } else if (timeLessThen({hour: schedule.surveyExpirationHour, minute: schedule.surveyExpirationMinute},
                {hour: repeatingSchedule.surveyDeliveryHour, minute: repeatingSchedule.surveyDeliveryMinute})
                || timeGreaterThen({hour: schedule.surveyDeliveryHour, minute: schedule.surveyDeliveryMinute},
                  {
                    hour: repeatingSchedule.surveyExpirationHour ?? 23,
                    minute: repeatingSchedule.surveyExpirationMinute ?? 59
                  })) {
                // if survey ends before start or survey starts after end it cannot be a match
                newDeliveries.push(schedule);
              } else {
                error = `Overlaps with a scheduling block on ${day.dayOfWeek} from 
                ${schedule.surveyDeliveryHour}:${schedule.surveyDeliveryMinute.toString().padStart(2, '0')} to 
                ${schedule.surveyExpirationHour}:${schedule.surveyExpirationMinute.toString().padStart(2, '0')}.`
              }
            }
          });
        }
        if (!matchFound) {
          newDeliveries.push({...repeatingSchedule, repeatsEveryDay: true});
        }
        newAllSchedules.push({dayOfWeek: day as Weekday, exactDeliveriesForDay: newDeliveries});
      })
    }
    if (!error) {
      onUpdateRepeatsEveryDay(newAllSchedules);
    } else {
      alertMounter({titleText: 'Cannot set same every dey', message: error});
    }
  }

  return <Column {...rest}>
    {value.exactDeliveriesForDay && value.exactDeliveriesForDay.map((item, key) => {
      return item ? <Row key={key} alignItems={'flex-start'} gap={'5px'} margin={'0 0 10px 0'}>
        <label>
          Survey Delivery
          <ValidatedInput
            message={validation[key].surveyDelivery}
            type={'time'}
            value={timeToString(item.surveyDeliveryHour, item.surveyDeliveryMinute)}
            onChange={updateStart(key)}
          />
        </label>
        <label>
          Survey Expiration
          <ValidatedInput
            message={validation[key].surveyExpiration}
            type={'time'}
            value={timeToString(item.surveyExpirationHour, item.surveyExpirationMinute)}
            onChange={updateEnd(key)}
          />
        </label>
        <label>
          &nbsp;
          <XButton onClick={removeDelivery(key)}/>
        </label>
        <Column>
          Same Every Day
          <Switch
            onChange={handleRepeatsSwitch(key)}
            checked={item.repeatsEveryDay}
          />
        </Column>
      </Row> : <span/>
    })}
    <PlusButton
      onClick={addDelivery}
    />
    {value.dayOfWeek === Weekday.SUNDAY &&
    <Row>
      <p>Please be aware that surveys cannot be scheduled between 12:00am and 3:00am on Sunday mornings.</p>
    </Row>
    }
  </Column>
}

const LeftColumn = styled(Column as any)`
  margin-right: 1em;
`;
