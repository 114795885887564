import axios from './axios';

interface Options {
  username: string;
  password: string;
}

export default async function login(options: Options): Promise<void> {
  await axios.post('/login', options);
  window.location.reload();
}
