import React, { FC } from 'react';
import styled from 'styled-components/macro';
import gearIconSrc from '../images/gear_icon.png';

const GearIcon: FC = props => {
  return <img {...props} src={gearIconSrc} alt="" />;
};

export default styled(GearIcon)`
  width: 16px;
  height: 16px;
`;
