import React, { FC, FormEvent, useState } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import Input from './Input';
import noop from '../utils/noop';
import Switch from './Switch';
import replaceWhere from '../utils/replaceWhere';
import SearchInput from './SearchInput';
import { SmallButton } from './SmallButton';
import InputModal from './InputModal';
import { beginEMACycle } from '../api/beginEMACycle';
import { DashboardSettings } from '../shared/StudyVariable';

export interface Props {
  value?: Value;
  onChange?: (value: Value) => any;
  dashboardSettings?: DashboardSettings;
  studyName: string;
}

export interface Value {
  devices: {
    deviceId: string;
    participantId: string;
    deactivated: boolean;
    wasDeactivated: boolean;
  }[];
}

export const defaultValue: Value = {
  devices: []
};


const DeviceForm: FC<Props> = props => {
  const { value = defaultValue, onChange = noop, dashboardSettings,studyName, ...rest } = props;
  const { devices } = value;
  const [search, setSearch] = useState('');
  const [participantIDForModal, setParticipantIDForModal] = useState('');
  const [participantIDModalMessage, setparticipantIDModalMessage] = useState('');

  const filteredDevices =
    search.length > 0
      ? devices.filter(device => device.deviceId.startsWith(search))
      : devices;

  function handleDeactivateToggle(deviceId: string) {
    onChange({
      devices: replaceWhere(
        devices,
        other => other.deviceId === deviceId && !other.wasDeactivated,
        other => ({ ...other, deactivated: !other.deactivated })
      )
    });
  }

  function handleSearchChange(event: FormEvent<HTMLInputElement>) {
    setSearch(event.currentTarget.value);
  }

  const confirmedRestart = () => {
    setparticipantIDModalMessage('sending restart...')
    beginEMACycle(studyName, participantIDForModal).then(res => {
      const successMessage = JSON.parse(res.data.Payload).message
      const errorMessage = JSON.parse(res.data.Payload).errorMessage
      console.log(successMessage, errorMessage)
      setparticipantIDModalMessage(successMessage ?? errorMessage)
    })
    return false;
  }


  return (
    <Column {...rest}>
      {participantIDForModal && (
            <InputModal 
              inputLabel= 'Restart EMA Cycle for this participant?'
              buttonText= "Restart"
              initValue= {participantIDForModal}
              type = 'text'
              disableInput= {true}
              validationError= {participantIDModalMessage}
              onSubmit= {() => confirmedRestart()}
              onClose= {() => {
                setParticipantIDForModal('')
                setparticipantIDModalMessage('')
              }}
            />)
          }
      <SearchRow>
        <SearchInput value={search} onChange={handleSearchChange} />
      </SearchRow>
      {filteredDevices.map(device => (
        <DeviceRow key={device.deviceId}>
          {dashboardSettings?.allowDeviceReset && (
            <SmallButton onClick={() => setParticipantIDForModal(device.participantId)}>restart</SmallButton>
          )}

          <Input readOnly value={device.deviceId + " - " + device.participantId} />
          <SwitchRow
            onClick={() => handleDeactivateToggle(device.deviceId)}
            disabled={device.wasDeactivated}
          >
            <label>Deactivated</label>
            <Switch
              disabled={device.wasDeactivated}
              checked={device.deactivated}
            />
          </SwitchRow>
        </DeviceRow>
      ))}
    </Column>
  );
};

export default styled(DeviceForm)``;

const SearchRow = styled(Row as any)`
  margin-bottom: 16px;
  > ${SearchInput} {
    flex: 1;
  }
`;

const DeviceRow = styled(Row as any)`
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 16px;
  }

  > ${Input} {
    flex: 1;
    margin-right: 16px;
  }
`;

const SwitchRow = styled(Row as any)<{ disabled?: boolean }>`
  align-items: center;

  > label {
    margin-right: 16px;
  }

  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;
