import React, { FC, FormEvent } from "react";
import noop from "../../utils/noop";
import timeToString from "../../utils/timeToString";
import Row from "../Row";
import { ValidatedInput } from "../Validation";
import {TimeOfDayBranchingRule} from "../../models";
import Select from "../Select";
import Required from "../Required";

/**
 * String must follow “HH:mm”.
 * Assigned string can range from “00:00” to “23:58”
 * @param minimumExpectedAnswerValue: string
 * @param maximumExpectedAnswerValue: string
 */
export const TimeOfDayBranchingRuleDefaultValue: TimeOfDayBranchingRule = {
  defaultQuestionId: '',
  destinationQuestionId: '',
  minimumExpectedAnswerHour: 0,
  minimumExpectedAnswerMinute: 1,
  maximumExpectedAnswerHour: 23,
  maximumExpectedAnswerMinute: 59,
}

interface TimeOfDayBranchingRuleProps {
  value?: TimeOfDayBranchingRule;
  surveyQuestionIDs: string[];
  onChange?: (value: TimeOfDayBranchingRule) => any;
  validation: Record<string, string | undefined>;
}

export const TimeOfDayBranchingRuleComponent: FC<TimeOfDayBranchingRuleProps> = props => {
  const {
    value = TimeOfDayBranchingRuleDefaultValue,
    onChange = noop,
    validation
  } = props;

  function updateMinimumExpectedAnswer(event: FormEvent<HTMLInputElement>) {
    const hour = parseInt(event.currentTarget.value.split(':')[0] ?? '');
    const minute = parseInt(event.currentTarget.value.split(':')[1] ?? '');

    onChange({
      ...value,
      minimumExpectedAnswerHour : hour,
      minimumExpectedAnswerMinute: minute
    })
  }

  function updateMaximumExpectedAnswer(event: FormEvent<HTMLInputElement>) {
    const hour = parseInt(event.currentTarget.value.split(':')[0] ?? '');
    const minute = parseInt(event.currentTarget.value.split(':')[1] ?? '');

    onChange({
      ...value,
      maximumExpectedAnswerHour: hour,
      maximumExpectedAnswerMinute: minute
    })
  }

  function selectDefault(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      defaultQuestionId: event.currentTarget.value
    })
  }

  function selectDestination(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      destinationQuestionId: event.currentTarget.value
    })
  }

  return <><Row alignItems={'center'} gap={'.5em'}>
      <h3>If answer is between: </h3>
      <ValidatedInput
          message={validation.timeInputs}
          value={timeToString(value.minimumExpectedAnswerHour, value.minimumExpectedAnswerMinute)}
          onChange={updateMinimumExpectedAnswer}
          type="time"
      />
      <span>and</span>
      <ValidatedInput
          message={validation.timeInputs}
          value={timeToString(value.maximumExpectedAnswerHour, value.maximumExpectedAnswerMinute)}
          onChange={updateMaximumExpectedAnswer}
          type="time"
      />
    </Row>
    <Row gap={'.5em'}>
      <span>go to question</span>
      <Required>
      <label>
      <select onChange={selectDestination} value={value.destinationQuestionId}>
        <option value="">---SELECT QUESTION---</option>
        {props.surveyQuestionIDs.map((id, idx) => {
          return (
            <option key={"survey" + id + idx} value={id}>
              {id}
            </option>
          );
        })}
        <option value={"completionPage"}>Completion Page</option>
      </select>
      </label>
      </Required>
    </Row>
    <Row gap={'.5em'}>
      <span>Otherwise go to</span>
      <Required>
      <label>
      <select onChange={selectDefault} value={value.defaultQuestionId}>
        <option value="">---SELECT QUESTION---</option>
        {props.surveyQuestionIDs.map((id, idx) => {
            return <option key={'survey' + id + idx} value={id}>{id}</option>
          }
        )}
        <option value={'completionPage'}>Completion Page</option>
      </select>
      </label>
      </Required>
    </Row></>
}
