import React, {ChangeEvent, FC } from "react";
import Modal, {ModalProps} from "./Modal";
import noop from "./../utils/noop";
import Column from "./Column";
import BlockButton from "./BlockButton";
import styled from "styled-components/macro";
import Color from "color";
import ReactDOM from "react-dom";


export function alertMounter(props: AlertProps) {
  const root = document.getElementById('modal-root');
  if (root) {
    ReactDOM.render(<Alert {...{...props, mountedNode: root}}/>, root);
  }
}

export interface AlertProps extends ModalProps {
  onSubmit?: (response: boolean) => any;
  titleText: string;
  message: string;
  okButtonText?: string;
  cancelButtonText?: string;
  mountedNode?: HTMLElement;
  showCancelButton?: boolean;
}

const Alert: FC<AlertProps> = props => {
  const {
    onSubmit = noop,
    titleText = 'Alert',
    message = 'Something Has Occurred',
    okButtonText = 'Okay',
    cancelButtonText = 'Dismiss',
    mountedNode = undefined,
    showCancelButton= true,
    ...rest
  } = props;

  const submit = (response: boolean) => (_: ChangeEvent<HTMLButtonElement>) => {
    if (mountedNode) {
      ReactDOM.unmountComponentAtNode(mountedNode);
    }
    onSubmit(response);
  }

  return (
    <Modal {...{ disableClose: true, ...rest}}>
      <Column>
        <h1>{titleText}</h1>
        <p>{message}</p>
        <BlockButton onClick={submit(true)} data-cy='okayButton'>{okButtonText}</BlockButton>
        {showCancelButton &&
          <CancelButton onClick={submit(false)} data-cy='cancelButton'>{cancelButtonText}</CancelButton>
        }
      </Column>
    </Modal>
  )

}

export default styled(Alert)`
`;

const CancelButton = styled(BlockButton as any) `
  background-color: lightgray;
  margin: 14px 0 0 0;
  
  &:hover:not(:disabled) {
    background-color: ${Color('lightgray')
            .darken(0.2)
            .string()};
  }
`