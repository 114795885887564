import React, { FC, MouseEventHandler } from 'react';
import styled from 'styled-components/macro';
import TrashIcon from './TrashIcon';
import Input, { InputProps } from './Input';
import BlankButton from './BlankButton';

export interface Props extends InputProps {
  disableDelete?: boolean;
  onDeleteClick?: MouseEventHandler<HTMLButtonElement>;
}

const TrashInput: FC<Props> = props => {
  const { onDeleteClick, disableDelete, ...rest } = props;

  return (
    <Input {...rest}>
      {!disableDelete && (
        <TrashButton onClick={onDeleteClick} disabled={props.disabled}>
          <TrashIcon />
        </TrashButton>
      )}
    </Input>
  );
};

export default styled(TrashInput)``;

const TrashButton = styled(BlankButton as any)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
`;
