import React, { FC } from 'react';
import styled from 'styled-components/macro';
import trashIconSrc from '../images/trash_icon.png';

const TrashIcon: FC = props => {
  return <img {...props} src={trashIconSrc} alt="Delete" />;
};

export default styled(TrashIcon)`
  height: 18px;
  width: 16px;
`;
