import React, {FC, FormEvent, useEffect, useState} from 'react';
import Column from "../Column";
import Row from "../Row";
import styled from "styled-components/macro";
import TextArea from "../TextArea";
import Switch from "../Switch";
import noop from "../../utils/noop";
import {TextEntryQuestion} from "../../models";
import Required from "../Required";
import {checkIfAllValuesUndefined, StringValidator} from "../../shared/utils/Validators";
import {ValidatedInput} from "../Validation";
import {branchingRuleReservedValues} from "./BranchingRule";

interface TextQuestionProps {
  value?: TextEntryQuestion;
  surveyQuestionIDs: string[];
  currentQuestionIndex: number;
  onChange?: (value: TextEntryQuestion) => any;
  canSwitch?: (value: boolean) => any;
}

export const TextQuestionDefaultValue: TextEntryQuestion = {
  questionId: "",
  questionHeader: "",
  questionBody: "",
  skipEnabled: true,
  maxCharacterLengthForInput: undefined,
  textHint: undefined
}

const TextQuestionComponent: FC<TextQuestionProps> = props => {
    const {
    value = TextQuestionDefaultValue,
    onChange = noop,
    canSwitch = noop,
    ...rest
  } = props;
  const [validation, setValidation] = useState<Record<string, string | undefined>>({});

  useEffect(() => {
    let validationIsGood = checkIfAllValuesUndefined(validation);
    [ new StringValidator(value.questionId).min(1).validate(),
      new StringValidator(value.questionHeader ?? '').min(1).validate()
    ].forEach((value) => {
      validationIsGood = validationIsGood && value === undefined ;
    });
    canSwitch(validationIsGood);
  },)

  function handleQuestionIdChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value, 'QuestionID must contain at least one character')
      .alphaNum('Question ID can only contain of numbers of letters.')
      .min(1, 'Question ID must contain at least one character.')
      .unique(props.surveyQuestionIDs, 'Question IDs must be unique (match found for another question)', props.currentQuestionIndex)
      .unique(branchingRuleReservedValues, 'This value is reserved and cannot be used as a Question ID')
      .validate();
    setValidation({
      ...validation,
      questionId: error
    });
    onChange({
      ...value,
      questionId: event.currentTarget.value
    });
  }

  function handleQuestionHeaderChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value)
      .min(1, 'Question Header must contain at least one character')
      .noSpace('Question Header value cannot contain only spaces')
      .validate();
    setValidation({
      ...validation,
      questionHeader: error
    });
    onChange({
      ...value,
      questionHeader: event.currentTarget.value
    });
  }

  function handleQuestionTextChange(event: FormEvent<HTMLTextAreaElement>) {
    onChange({
      ...value,
      questionBody: event.currentTarget.value
    });
  }

  function toggleSkip(newState: boolean) {
    onChange({
      ...value,
      skipEnabled: newState
    });
  }

  return (
    <Column>
      <h2>Text Question</h2>
      <Required>
        <label>Question ID</label>
      </Required>
      <ValidatedInput
        message={validation.questionId}
        value={value.questionId}
        onChange={handleQuestionIdChange}
        placeholder="QuestionID"
      />
      <Required>
        <label>Question header</label>
      </Required>
      <ValidatedInput
        message={validation.questionHeader}
        value={value.questionHeader ?? ''}
        onChange={handleQuestionHeaderChange}
        placeholder="Question Header"
      />
      <label>Question Body</label>
      <TextArea
        placeholder="Question Body"
        value={value.questionBody}
        onChange={handleQuestionTextChange}
      />
      <label>Maximum allowed length for answer</label>
      <ValidatedInput
        message={validation.maxCharacterLengthForInput}
        min={1}
        type='number'
        placeholder={'No Limit'}
        value={value.maxCharacterLengthForInput}
      />
      <SwitchRow>
        <Switch
          checked={value.skipEnabled}
          onChange={toggleSkip}
        />
        <label>Skippable</label>
      </SwitchRow>
    </Column>
  );
}


export default styled(TextQuestionComponent)`
  max-width: 100%;
  min-width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const SwitchRow = styled(Row as any)`
  margin-top: 16px;
`;