import React, { FC } from 'react';
import styled from 'styled-components/macro';
import chevronLightSrc from '../images/chevron_light.png';
import chevronDarkSrc from '../images/chevron_dark.png';

interface ChevronProps {
  light?: boolean;
  flipped?: boolean;
  scale?: number;
}

const Chevron: FC<ChevronProps> = props => {
  const { light, flipped, scale, ...rest } = props;
  const src = light ? chevronLightSrc : chevronDarkSrc;

  return <img {...rest} src={src} alt="" />;
};

export default styled(Chevron)<ChevronProps>`
  width: 31px;
  height: 18px;

  transform: rotate(${getRotation}deg) scale(${getScale});
`;

function getRotation({ flipped }: ChevronProps) {
  return flipped ? 180 : 0;
}

function getScale({ scale = 1 }: ChevronProps) {
  return `${scale}, ${scale}`;
}
