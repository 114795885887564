import Get from "./utils/Get";
import { parseSensor } from "./Sensor";
import { DashboardUser } from "./DashboardUser";
import { Participant } from "./Participant";
import { StudyDevice } from "./StudyDevice";
import { SurveyPrefs, ActiveSensors } from "../models";

export interface EMADay {
  emaDailyStart: string;
  emaDailyEnd: string;
}

export enum EMADayString {
  Mon = "Mon",
  Tue = "Tue",
  Wed = "Wed",
  Thu = "Thu",
  Fri = "Fri",
  Sat = "Sat",
  Sun = "Sun",
}

export type EmaWeekDay = {
  [key: string]: EMADay;
}[];

export interface StudyVariable {
  studyName: string;
  s3BucketName: string;
  includedSensors: ActiveSensors[];
  emaMoodIdentifiers: string[];
  studySites: string[];
  emaDailyStart: string;
  emaDailyEnd: string;
  emaHoursBetween: number;
  emaPhaseFrequency: number;
  emaPhaseBreak: number;
  emaWeekDays: EMADayString[];
  emaVariesDuringWeek: boolean;
  phaseAutoScheduled: boolean;
  emaWeekDay: EmaWeekDay;
  "aws:rep:deleting"?: boolean;
  "aws:rep:updateregion"?: string;
  "aws:rep:updatetime"?: number;
}

export interface OLDStudyDetails {
  study: StudyVariable;
  researchers: DashboardUser[];
  participants: Participant[];
  devices: StudyDevice[];
}

export interface DashboardSettings {
  studyName?: string;
  allowDeviceReset?: boolean;
  maxSurveys?: number;
  screenTime?: boolean;
}
export interface StudyDetails {
  study: SurveyPrefs;
  researchers: DashboardUser[];
  participants: Participant[];
  devices: StudyDevice[];
  dashboardSettings?: DashboardSettings;
}

export function parseStudyVariable(value: any): StudyVariable {
  return {
    studyName: Get.stringIn(value, "studyName"),
    phaseAutoScheduled: Get.booleanIn(value, "phaseAutoScheduled"),
    emaHoursBetween: Get.numberIn(value, "emaHoursBetween"),
    emaDailyStart: Get.stringIn(value, "emaDailyStart"),
    emaDailyEnd: Get.stringIn(value, "emaDailyEnd"),
    emaVariesDuringWeek: Get.booleanIn(value, "emaVariesDuringWeek"),
    emaPhaseFrequency: Get.numberIn(value, "emaPhaseFrequency"),
    s3BucketName: Get.stringIn(value, "s3BucketName"),
    includedSensors: Get.arrayIn(value, "includedSensors").map(parseSensor),
    emaPhaseBreak: Get.numberIn(value, "emaPhaseBreak"),
    emaWeekDays: Get.arrayIn(value, "emaWeekDays").map(
      (value, i) => Get.string(value, `emaWeekDays[${i}]`) as EMADayString
    ),
    emaMoodIdentifiers: Get.arrayIn(value, "emaMoodIdentifiers").map(
      (value, i) => Get.string(value, `emaMoodIdentifiers[${i}]`)
    ),
    emaWeekDay: Get.arrayIn(value, "emaWeekDay").map(
      (value) =>
        Get.object(value, "emaWeekDay") as {
          [key: string]: EMADay;
        }
    ),
    studySites: value.studySites
      ? Get.arrayIn(value, "studySites").map((value, i) =>
          Get.string(value, `studySites[${i}]`)
        )
      : [],
    "aws:rep:deleting": Get.booleanInOptional(value, "aws:rep:deleting"),
    "aws:rep:updateregion": Get.stringInOptional(value, "aws:rep:updateregion"),
    "aws:rep:updatetime": Get.numberInOptional(value, "aws:rep:updatetime"),
  };
}
