import { useState, useEffect } from 'react';
import { getStudyDashboardSettings } from '../api/getStudyDetails';

export const useDashboardSettings = (studyName: string) => {
    const [dashboardSettings, setDashboardSettings] = useState({})

    useEffect(() => {
        const onLoad = async () => {
            setDashboardSettings(await getStudyDashboardSettings(studyName))
        }
        onLoad()
    }, [studyName])
    
    

    return dashboardSettings
}