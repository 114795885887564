import React, { FC, MouseEventHandler } from 'react';
import styled from 'styled-components/macro';
import Modal, { ModalProps } from './Modal';
import Row from './Row';
import Switch from './Switch';
import Column from './Column';
import BlockButton from './BlockButton';
import noop from '../utils/noop';
import removeFromArray from '../utils/removeFromArray';

interface Props extends ModalProps {
  subtitle?: string;
  value?: Value;
  onChange?: (value: Value) => any;
  onSubmit?: MouseEventHandler<HTMLButtonElement>;
  allSites?: string[];
}

interface Value {
  sites: string[];
}

export const defaultValue: Value = {
  sites: []
};

const AddSiteModal: FC<Props> = props => {
  const {
    subtitle = '',
    value = defaultValue,
    onChange = noop,
    onSubmit = noop,
    allSites = [],
    ...rest
  } = props;
  const allSitesSelected =
    allSites.length > 0 && allSites.length === value.sites.length;

  function handleAllSitesToggle() {
    if (allSitesSelected) {
      onChange({ sites: [] });
    } else {
      onChange({ sites: allSites });
    }
  }

  function handleSiteToggle(site: string) {
    if (isSiteSelected(site)) {
      onChange({
        sites: removeFromArray(value.sites, site)
      });
    } else {
      onChange({
        sites: value.sites.concat(site)
      });
    }
  }

  function isSiteSelected(site: string) {
    return !!value.sites.includes(site);
  }

  return (
    <Modal {...rest}>
      <Title>Add Sites</Title>
      <Subtitle>{subtitle}</Subtitle>
      <AllSitesRow onClick={handleAllSitesToggle}>
        <span>all sites</span>
        <Switch checked={allSitesSelected} />
      </AllSitesRow>
      <SiteList>
        {allSites.map(site => (
          <SiteRow key={site} onClick={() => handleSiteToggle(site)}>
            <span>{site}</span>
            <Switch checked={isSiteSelected(site)} />
          </SiteRow>
        ))}
      </SiteList>
      <SaveButton onClick={onSubmit}>Save</SaveButton>
    </Modal>
  );
};

export default styled(AddSiteModal)`
  width: 416px;
  max-width: 100%;
  background-color: #0075e1;
  padding: 40px 0 32px 0;

  /* Use a slightly darker color for switches when they're turned on */
  [data-checked='true']${Switch} {
    background-color: #0e4a93;
  }
`;

const Title = styled('div')`
  color: white;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 2px;
`;

const Subtitle = styled('div')`
  color: white;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 6px;
`;

const AllSitesRow = styled(Row as any)`
  height: 30px;
  background-color: #06dddd;
  padding: 0 45px 0 45px;
  align-items: center;
  justify-content: space-between;
  color: #0e4a93;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  cursor: pointer;
`;

const SiteList = styled(Column as any)`
  padding: 0 22px 0 22px;
  margin-bottom: 24px;
`;

const SiteRow = styled(Row as any)`
  height: 40px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  align-items: center;
  justify-content: space-between;
  padding: 0 23px 0 23px;
  cursor: pointer;

  & + & {
    border-top: 1px solid #06dddd;
  }
`;

const SaveButton = styled(BlockButton as any).attrs({ light: true })`
  width: 110px;
  align-self: center;
`;
