import React, { FC, FormEventHandler } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import BlockButton from './BlockButton';
import Select from './Select';
import Form from './Form';

interface StudySelectFormProps {
  value?: string | number;
  onChange?: FormEventHandler<HTMLSelectElement>;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

const StudySelectForm: FC<StudySelectFormProps> = props => {
  const { value, onChange, onSubmit, ...rest } = props;

  return (
    <Column {...rest}>
      <Form onSubmit={onSubmit}>
        <Title>Select Study</Title>
        <StudySelect
          value={value}
          onChange={onChange}
          placeholder="Choose Study"
        >
          {props.children}
        </StudySelect>
        <SubmitButton disabled={!value || value === ''}>Go</SubmitButton>
      </Form>
    </Column>
  );
};

export default styled(StudySelectForm)`
  width: 352px;
  max-width: 100%;

  > ${Form} {
    align-items: center;
  }
`;

const Title = styled('h1')`
  color: white;
  font-size: 56px;
  line-height: 64px;
  margin: 0 0 32px 0;
  text-align: center;
  width: 100%;
`;

const StudySelect = styled(Select as any)`
  width: 100%;
  margin-bottom: 32px;
`;

const SubmitButton = styled(BlockButton as any)`
  width: 159px;
`;
