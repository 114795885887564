import axios from './axios';
import {SurveyPrefs} from "../models";

export default async function getStudies(): Promise<SurveyPrefs[]> {
  const response = await axios.get('/studies');
  return response.data.studies;
}

export async function getStudyNames(): Promise<string[]> {
  const response = await getStudies();
  return response.reduce((acc: string[], val) => {
    if (val.studyName !== undefined) {
      acc.push(val.studyName);
    }
    return acc;
  }, []);
}