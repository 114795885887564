import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Overlay from './Overlay';
import Spinner from './Spinner';

const SpinnerOverlay: FC = props => {
  return (
    <Overlay {...props}>
      <Spinner />
    </Overlay>
  );
};

export default styled(SpinnerOverlay)`
  z-index: 30;
`;
