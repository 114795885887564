import React, { FC, FormEvent } from "react";
import { ChoiceRiskRule } from "../../models";
import noop from "../../utils/noop";
import Checkbox from "../Checkbox";
import Column from "../Column";
import Row from "../Row";

export const ChoiceRiskRuleDefaultValue: ChoiceRiskRule = {
    alertOn: []
}

interface ChoiceRiskRuleProps {
    value?: ChoiceRiskRule;
    choiceQuestionIDs: string[];
    onChange?: (value: ChoiceRiskRule) => any
}

export const ChoiceRiskRuleComponent: FC<ChoiceRiskRuleProps> = props => {
    const {
        value = {...ChoiceRiskRuleDefaultValue},
        onChange = noop,
        ...rest
    } = props

    function checkboxChoice(item: string, selectedItems: string[]) {
        onChange({
          ...value,
          alertOn: selectedItems
        })
      } 

    return <Column rowGap={'5px'}>
        <Row gap={'.5em'}>
        <span>Risk Alert for any of these choices:</span>
        <Checkbox
            inputList={props.choiceQuestionIDs}
            selectedItems={value.alertOn}
            onChange={checkboxChoice}
        />
        </Row>
    </Column>
}