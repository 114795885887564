import React, { FC } from 'react';
import styled from 'styled-components/macro';
import appleIconSrc from '../images/apple_icon.png';

const AppleIcon: FC = props => {
  return <img {...props} src={appleIconSrc} alt="Apple" />;
};

export default styled(AppleIcon)`
  height: 29.11px;
  width: 23.54px;
`;
