import React, { FC, FormEventHandler } from 'react';
import styled, { css } from 'styled-components/macro';
import BlankButton from './BlankButton';

interface GroupButtonProps {
  active?: boolean;
  dark?: boolean;
  onClick?: FormEventHandler<HTMLButtonElement>;
}

const GroupButton: FC = props => {
  return <BlankButton {...props} />;
};

export default styled(GroupButton)<GroupButtonProps>`
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  outline: none;
  height: 42px;
  font-size: 14px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  flex: 1 1 100%;
  cursor: pointer;

  ${props => {
    const { dark, active } = props;
    const activeBgColor = dark
      ? 'linear-gradient(225deg, #0B1C4A 0%, #0E4A93 100%)'
      : '#0075E1';
    const inactiveBgColor = dark ? 'transparent' : 'white';
    const inactiveTextColor = dark ? '#0b1c4a' : '#0075E1';
    const borderColor = dark ? '#0b1c4a' : '#0075E1';
    const borderWidth = 2;

    return css`
      background: ${active ? activeBgColor : inactiveBgColor};
      color: ${active ? 'white' : inactiveTextColor};
      border-top: ${borderWidth}px solid ${borderColor};
      border-bottom: ${borderWidth}px solid ${borderColor};

      &:first-of-type {
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        border-left: ${borderWidth}px solid ${borderColor};
      }

      &:last-of-type {
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
        border-right: ${borderWidth}px solid ${borderColor};
      }
    `;
  }}
`;
