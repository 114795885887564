import axios from './axios';
import { StudyDevice } from '../shared';
import {ActiveSensors} from "../models";

export interface Options {
  studyName: string;
  site: string;
  dateRange?: Date[];
  deviceIds?: string[];
  participantIds?: string[];
  sensors?: ActiveSensors[];
  os?: string | null;
}

export default async function getStudyInfo(
  options: Options
): Promise<StudyDevice[]> {
  const { studyName } = options;
  const response = await axios.post(`/study/${studyName}/devices`, options);

  return response.data.studyDevices;
}

export const getDevicesStatus = async (studyName: string, siteName: string) => {
  const response = await axios.get(`/study/${studyName}/${siteName}/devices/status`);
  return response.data
}
