import axios from './axios';
import {SurveyPrefs} from "../models";

export default async function getStudyByName(
  studyName: String
): Promise<SurveyPrefs> {
  const response = await axios.get(`/study/${studyName}`);
  const { study } = response.data;
  return study;
}
