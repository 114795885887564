import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Page from './Page';
import SpinnerOverlay from './SpinnerOverlay';

const LoadingPage: FC = props => {
  return (
    <Page {...props} key="LOADING_PAGE">
      <SpinnerOverlay />
    </Page>
  );
};

export default styled(LoadingPage)``;
