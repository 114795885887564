import React, { FC, useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import styled from "styled-components/macro";
import { Parser as CSVParser } from "json2csv";
import Modal, { ModalProps } from "./Modal";
import Row, { Spacer } from "./Row";
import StatusIcon, { StatusIconType } from "./StatusIcon";
import AndroidIcon from "./AndroidIcon";
import AppleIcon from "./AppleIcon";
import DotPlot from "./DotPlot";
import BlankButton from "./BlankButton";
import Spinner from "./Spinner";
import Column from "./Column";
import BlockButton from "./BlockButton";
import downloadString from "../utils/downloadString";
import getStudyByName from "../api/getStudyByName";
import {
  listenForHashChange,
  stopListeningForHashChange,
  setHashRoute,
} from "../utils/hashRouter";
import { SurveyPrefs } from "../models";
import { useDeviceUploadEvents } from "../utils/useDeviceUploadEvents";
import { filteredDaysPage } from "../utils/dotplotUtils";
import Dot from "./Dot";
import checkOSScreenTimeSupport from "../shared/utils/checkOSScreenTimeSupport";
import { useDashboardSettings } from "../utils/useDashboardSettings";

interface Props extends ModalProps {
  deviceId: string;
  participantId: string;
  studyName: string;
  siteName: string;
  deviceStatus: StatusIconType;
  osVersion: string;
}

export const UpdateStatModalHash = "deviceStats";

const UploadStatModal: FC<Props> = (props) => {
  const {
    deviceId,
    participantId,
    studyName,
    siteName,
    deviceStatus,
    onClose,
    osVersion,
    ...rest
  } = props;

  const [study, setStudy] = useState<SurveyPrefs | null>(null);
  const {
    deviceUploads,
    allDeviceDays,
    loading: loadingUploadsEvents,
  } = useDeviceUploadEvents(studyName, deviceId);
  const settings = useDashboardSettings(study?.studyName ?? "");
  const [currentPage, setCurrentPage] = useState(1);
  const isApple = useMemo(() => deviceId.includes("-"), [deviceId]);
  const supportsScreentime = useMemo(
    () => checkOSScreenTimeSupport(osVersion, isApple),
    [deviceId, isApple]
  );

  const itemsPerPage = 7;
  const pageCount = Math.ceil(allDeviceDays.length / itemsPerPage);
  const hasNextPage = currentPage < pageCount;
  const hasPrevPage = currentPage > 1;

  useEffect(() => {
    load().catch(console.error);

    async function load() {
      const study = await getStudyByName(studyName);
      setStudy(study);
      setCurrentPage(1);
    }
  }, [siteName, studyName, deviceId]);

  function handleNextClick() {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function handleBackClick() {
    if (hasPrevPage) {
      setCurrentPage(currentPage - 1);
    }
  }

  function handleDownloadCSVClick() {
    if (!deviceUploads) {
      return;
    }

    const csv = new CSVParser({
      fields: ["date", "deviceID", "sensorType"],
    }).parse(
      deviceUploads.map((uploadEvent) => {
        return {
          date: dayjs.unix(uploadEvent.timestamp).format("ddd MMM D YYYY"),
          deviceID: uploadEvent.deviceID,
          sensorType: uploadEvent.sensorType,
        };
      })
    );

    const filename = `study_${deviceId}_upload.csv`;
    downloadString(filename, csv, "text/csv");
  }

  function handleClose() {
    setHashRoute("");
    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    listenForHashChange(UpdateStatModalHash, () => {
      setHashRoute("");
      if (onClose) {
        onClose();
      }
    });
    return () => stopListeningForHashChange();
  }, [onClose]);
  //TODO Make ABCD specific.
  return (
    <Modal onClose={handleClose} {...rest}>
      <TitleRow>
        <StatusIcon type={deviceStatus} />
        {deviceId}
      </TitleRow>
      {!loadingUploadsEvents ? ( // deviceUploads.length > 0
        <>
          <SubtitleRow columnGap="20px">
            <Column rowGap="5px" alignItems="center">
              {isApple ? <AppleIcon /> : <AndroidIcon />}
              <div style={{ fontSize: "18px" }}>
                {osVersion.length > 0 ? osVersion : "Unknown"}
              </div>
            </Column>
            <Row>
              <b>Last Upload</b>{" "}
              {deviceUploads &&
                dayjs(deviceUploads[0]?.timestamp * 1000).format(
                  "ddd MMM D, YYYY"
                )}
            </Row>
            <Spacer />
            <Row>
              <b>Participant ID</b> {participantId}
            </Row>
          </SubtitleRow>
          <DotPlot
            os={isApple ? "iOS" : "android"}
            visibleSensors={study ? study.activeSensors : []}
            uploadEvents={deviceUploads}
            days={filteredDaysPage(currentPage, allDeviceDays)}
            studyName={studyName}
            osVersion={osVersion}
          />
          <PaginationRow>
            <PaginateButton onClick={handleNextClick} disabled={!hasNextPage}>
              {"<"}
            </PaginateButton>
            <PaginateButton onClick={handleBackClick} disabled={!hasPrevPage}>
              {">"}
            </PaginateButton>
          </PaginationRow>
        </>
      ) : (
        <SpinnerBox>
          <Spinner />
        </SpinnerBox>
      )}
      <SubtitleRow>
        <Row>
          <Dot color={"#0075E1"} />
          Upload Event Successful
        </Row>
        <Spacer />
        <Row>
          <Dot color={"#818181"} />
          Missing Required Upload
        </Row>
      </SubtitleRow>
      <ButtonRow>
        <BlockButton onClick={handleDownloadCSVClick} disabled={!deviceUploads}>
          Download CSV
        </BlockButton>
        <BlockButton onClick={handleClose}>Close</BlockButton>
      </ButtonRow>
    </Modal>
  );
};

export default styled(UploadStatModal)`
  width: 1120px;
  max-width: 100%;
`;

const TitleRow = styled(Row as any)`
  color: #0b1c4a;
  font-size: 32px;
  line-height: 40px;
  padding: 0 60px 0 60px;
  margin-bottom: 24px;

  ${StatusIcon} {
    width: 40px;
    height: 40px;
    margin-right: 18px;
  }
`;

const SubtitleRow = styled(Row as any)<{ columnGap?: string }>`
  color: #0b1c4a;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  padding: 0 60px 0 60px;
  align-items: center;
  column-gap: ${(props) => (props.columnGap ? props.columnGap : "0px")};

  b {
    margin-right: 13px;
  }
`;

const SpinnerBox = styled(Column as any)`
  align-items: center;
  justify-content: center;
  height: 455px;
`;

const PaginateButton = styled(BlankButton as any)`
  font-size: 30px;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  &:disabled {
    opacity: 0;
  }
`;

const PaginationRow = styled(Row as any)`
  justify-content: space-between;
  padding-left: 200px;
  padding-right: 40px;
  margin-top: 16px;
`;

const ButtonRow = styled(Row as any)`
  margin-top: 52px;
  justify-content: center;
  align-items: center;

  ${BlockButton} {
    font-size: 14px;
    width: 159px;
    white-space: nowrap;
    word-wrap: break-word;
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 8px;
  }
`;
