import { useMemo } from 'react';
import parseSearchString, { ParsedSearchParams } from './parseSearchString';

/**
 * React hook for parsing URL search params.
 * Uses the `useMemo` hook to ensure parsing only happens when the `search` string changes.
 */
export default function useSearchParams(keys: string[]): ParsedSearchParams {
  const { search } = window.location;
  return useMemo<ParsedSearchParams>(() => parseSearchString(search, keys), [
    search,
    keys
  ]);
}
