import Get from './utils/Get';

export interface DashboardUser {
  email: string;
  password: string;
  admin: boolean;
  assignedStudies: DashboardUserStudy[];
  permissions: string[];
  singleSignOn?: boolean;
}

export interface DashboardUserStudy {
  studyName: string;
  sites: string[];
  studyPermissions: string[];
}

export function parseDashboardUserStudy(value: any): DashboardUserStudy {
  return {
    studyName: Get.stringIn(value, 'studyName'),
    sites: Get.arrayIn(value, 'sites').map((value, i) =>
      Get.string(value, `sites[${i}]`)
    ),
    // TODO make this arrayIn once everyone's accounts have the new fields
    studyPermissions: Get.arrayInOptional(value, 'studyPermissions') as string[] ?? [],
  };
}

export function parseDashboardUser(value: any): DashboardUser {
  return {
    email: Get.stringIn(value, 'email'),
    password: Get.stringIn(value, 'password'),
    admin: Get.booleanIn(value, 'admin'),
    assignedStudies: Get.arrayIn(value, 'assignedStudies').map(
      parseDashboardUserStudy
    ),
    // TODO make this arrayIn once everyone's accounts have the new fields
    permissions: Get.arrayInOptional(value, 'permissions') as string[] ?? []
  };
}

/**
 * Checks that the given user has at least one of the given permissions.
 * @param permissions: an array of string permission names.
 * @param user: the user to check
 * @returns a boolean indicating whether the check passed/failed.
 */
export function checkPermissions(permissions: string[], user: DashboardUser): boolean {
  return user.permissions.some(permission => permissions.includes(permission))
}

/**
 * Checks that the given user is a site admin
 * @param user: the user to check
 * @returns a boolean indicating whether the check passed/failed.
 */
export function userIsAdmin(user: DashboardUser): boolean {
  return checkPermissions(['admin'], user);
}

/**
 * Checks is a user has one of the given permissions for a specific study. If the user is a site admin this
 * should succeed regardless of whether they actually have one of the permissions.
 * @param permissions: an array of string permission names.
 * @param user: the user to check
 * @param studyName: the study name to check permissions on
 */
export function checkStudyPermissions(permissions: string[], user: DashboardUser, studyName: string): boolean {
  if (userIsAdmin(user)) return true;
  const study = user.assignedStudies.find(item => item.studyName === studyName)
  return study !== undefined &&  study.studyPermissions.some(permission => permissions.includes(permission))
}

/**
 * wraps the common functionality of checking if a user is a study admin. This also returns true if the user is a
 * site admin.
 * @param user: the user to check
 * @param studyName: the study name to scope the check to
 */
export function userIsStudyAdmin(user: DashboardUser, studyName: string): boolean {
  return checkStudyPermissions(['studyAdmin'], user, studyName);
}