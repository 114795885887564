import { FormEventHandler } from "react";
import styled from "styled-components/macro";

export interface Props {
  disabled?: boolean;
  onClick?: FormEventHandler<HTMLButtonElement>;
}

/**
 * A button with all the button styles removed.
 * Useful when an element should be a 'button' tag despite not looking like a button.
 */
const BlankButton = styled("button")<Props>`
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  border: none;
  background-color: inherit;
  color: inherit;
  height: auto;
  width: auto;
  ${(props) => (props.disabled ? "pointer-events: none;" : "")}
`;

export default BlankButton;
