import React, { FC, FormEvent } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import BlockButton from './BlockButton';
import Input from './Input';
import Form from './Form';
import noop from '../utils/noop';
import SSOSignInButton from './SsoSignInButton';

interface LoginFormValue {
  username: string;
  password: string;
}

interface LoginFormProps {
  value?: LoginFormValue;
  onChange?: (value: LoginFormValue) => any;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => any;
}

export const loginFormDefaultValue: LoginFormValue = {
  username: '',
  password: ''
};

const LoginForm: FC<LoginFormProps> = props => {
  const {
    onChange = noop,
    onSubmit = noop,
    value = loginFormDefaultValue,
    ...rest
  } = props;
  const disabled = value.username.length < 1 || value.password.length < 1;

  function handleUsernameChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      username: event.currentTarget.value
    });
  }

  function handlePasswordChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      password: event.currentTarget.value
    });
  }

  return (
    <Column {...rest}>
      <Form onSubmit={onSubmit}>
        <UsernameInput
          placeholder="Username"
          value={value.username}
          onChange={handleUsernameChange}
          data-cy="username"
        />
        <PasswordInput
          type="password"
          placeholder="Password"
          value={value.password}
          onChange={handlePasswordChange}
          data-cy="password"
        />
        <Row>
          <Column>
            <SubmitButton disabled={disabled}>Login</SubmitButton>
          </Column>
          <Column>
            <SSOSignInButton />
          </Column>
        </Row>
      </Form>
    </Column>
  );
};

export default styled(LoginForm)`
  width: 352px;
  max-width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const UsernameInput = styled(Input as any)`
  width: 100%;
  margin-bottom: 16px;
`;

const PasswordInput = styled(Input as any)`
  width: 100%;
  margin-bottom: 16px;
`;

const SubmitButton = styled(BlockButton as any)`
  width: 159px;
`;
