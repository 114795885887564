import axios from './axios';

interface Options {
  studyName: string;
  deviceIds: string[];
}

export default async function deactivateDevices(options: Options) {
  const { studyName, deviceIds } = options;
  await axios.post(`/study/${studyName}/deactivate-devices`, { deviceIds });
}
