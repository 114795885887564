/**
 * Set a hash on the current url
 * @param destination the hash value to add (without the #)
 */
export function setHashRoute(destination: string) {
  window.location.hash = destination;
}

/**
 * Get the current hash on the url, including the #
 */
export function getHashRoute(): string {
  return window.location.hash;
}

/**
 * @param value the value of the current hash to watch, excluding the # symbol
 * @param onChange a function to call if the location hash doesn't match the hash being watched
 */
export function listenForHashChange(value: string, onChange?: () => any) {
  window.onhashchange = () => {
    if (getHashRoute() !== `#${value}` && onChange) {
      onChange();
    }
  };
}

/**
 * Cleanup function
 */
export function stopListeningForHashChange() {
  window.onhashchange = null;
}
