import nanoid from "nanoid";
import React, { FC, FormEvent } from "react";
import styled from "styled-components/macro";
import Row from "../components/Row";
import noop from "../utils/noop";
import Column from "./Column";
import XButton from "./XButton";

interface CheckboxProps {
  inputList: string[];
  selectedItems?: string[];
  onChange?: (selectedItem: string, selectedItems: string[]) => any;
  confirmDelete?: boolean;
  disabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    inputList,
    onChange = noop,
    confirmDelete = false,
    selectedItems = [],
    disabled = false,
    ...rest
  } = props;

  /**
   * Handles the change event of the checkbox and either adds/removes the value from the selectedItems list.
   * If confirmDelete is true, the checkbox will not be unchecked unless the user uses the clear button.
   * @param event - The form event triggered by the checkbox.
   */
  function handleChecked(event: FormEvent<HTMLInputElement>) {
    console.log("ON CLICK");
    if (confirmDelete && !event.currentTarget.checked) {
      event.preventDefault();
      // don't change the selected items but still call the onChange function so the parent can react to the item being selected
      onChange(event.currentTarget.value, selectedItems);
    } else {
      let newVal = [];
      if (event.currentTarget.checked) {
        newVal = [...selectedItems, event.currentTarget.value];
      } else {
        newVal = selectedItems.filter(
          (item) => item !== event.currentTarget.value
        );
      }
      onChange(event.currentTarget.value, newVal);
    }
  }

  /**
   * Handles the clear action for a checkbox.
   * @param val - The value of the checkbox to remove
   * @returns A function that takes an event and performs the clear action.
   */
  const handleClear = (val: string) => (
    event: FormEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    let copyItems = [...selectedItems].filter((item) => item !== val);
    onChange(val, copyItems);
  };

  return (
    <Column {...rest}>
      {inputList.map((val, index) => {
        return (
          <CheckboxRow key={nanoid()}>
            <label>
              <CheckboxInput
                type={"checkbox"}
                name={val}
                value={val}
                checked={selectedItems.includes(val)}
                onClick={handleChecked}
                disabled={disabled}
              />
              {val}
            </label>
            <Spacer />
            {confirmDelete && <XButton onClick={handleClear(val)} />}
          </CheckboxRow>
        );
      })}
    </Column>
  );
};

export default styled(Checkbox)``;

const CheckboxRow = styled(Row as any)`
  > * {
    margin: 2px;
  }
`;

const CheckboxInput = styled("input")`
  margin-right: 0.5em;
`;

const Spacer = styled("div")`
  flex-grow: 1;
`;
