import {
  BooleanQuestion,
  ChoiceQuestion,
  ScaleQuestion,
  SurveyQuestion,
  TextEntryQuestion, TextInfo,
  TimeOfDayQuestion
} from "../../models";
import Column from "../Column";
import MultipleChoiceQuestion from "./ChoiceQuestion";
import TextQuestionComponent from "./TextQuestion";
import ScaleQuestionComponent from "./ScaleQuestion";
import TimeOfDayQuestionComponent from "./TimeOfDayQuestion";
import BooleanQuestionComponent from "./BooleanQuestion";
import React from "react";
import styled from "styled-components/macro";
import TextInfoQuestion from "./TextInfoQuestion";

export interface QuestionEditorProps {
  surveyQuestions: Array<SurveyQuestion | null >,
  currentQuestion: number,
  onChange: (questionValue: (TextEntryQuestion | BooleanQuestion | ScaleQuestion | ChoiceQuestion | TimeOfDayQuestion | TextInfo)) => void,
  questionIDs: string[],
  canSwitch: (value: boolean)  => any,
  showRiskToggle?: boolean
}

function QuestionEditor(props: QuestionEditorProps) {
  const question = props.surveyQuestions[props.currentQuestion];
  if (!question) {
   return <h2>Error! Question not found.</h2>;
  }
  return <Column>
    {question.choice &&
    <MultipleChoiceQuestion
      onChange={props.onChange}
      value={question.choice as ChoiceQuestion}
      surveyQuestionIDs={props.questionIDs}
      currentQuestionIndex={props.currentQuestion}
      canSwitch={props.canSwitch}
      showRiskToggle={props.showRiskToggle}
    />
    }
    {question.textEntry &&
    <TextQuestionComponent
      onChange={props.onChange}
      surveyQuestionIDs={props.questionIDs}
      currentQuestionIndex={props.currentQuestion}
      value={question.textEntry as TextEntryQuestion}
      canSwitch={props.canSwitch}
    />
    }
    {question.scale &&
    <ScaleQuestionComponent
      onChange={props.onChange}
      value={question.scale as ScaleQuestion}
      surveyQuestionIDs={props.questionIDs}
      currentQuestionIndex={props.currentQuestion}
      canSwitch={props.canSwitch}
      showRiskToggle={props.showRiskToggle}
    />
    }
    {question.timeOfDay &&
    <TimeOfDayQuestionComponent
      onChange={props.onChange}
      value={question.timeOfDay as TimeOfDayQuestion}
      surveyQuestionIDs={props.questionIDs}
      currentQuestionIndex={props.currentQuestion}
      canSwitch={props.canSwitch}
    />
    }
    {question.bool &&
    <BooleanQuestionComponent
      onChange={props.onChange}
      value={question.bool as BooleanQuestion}
      surveyQuestionIDs={props.questionIDs}
      currentQuestionIndex={props.currentQuestion}
      canSwitch={props.canSwitch}
      showRiskToggle={props.showRiskToggle}
    />
    }
    {question.info &&
    <TextInfoQuestion
      onChange={props.onChange}
      surveyQuestionIDs={props.questionIDs}
      currentQuestionIndex={props.currentQuestion}
      value={question.info as TextInfo}
      canSwitch={props.canSwitch}
    />}
  </Column>;
}

export default styled(QuestionEditor)`
`;