import { RouteComponentProps, navigate } from "@reach/router";
import Color from "color";
import nanoid from "nanoid";
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { getStudyDashboardSettings } from "../api/getStudyDetails";
import getStudyResearchers from "../api/getStudyResearchers";
import AddResearcherModal from "../components/AddResearcherModal";
import { alertMounter } from "../components/Alert";
import BlankButton from "../components/BlankButton";
import BlockButton from "../components/BlockButton";
import Checkbox from "../components/Checkbox";
import Column from "../components/Column";
import ListInput from "../components/ListInput";
import Page, { PageComponent } from "../components/Page";
import PlusIcon from "../components/PlusIcon";
import Required from "../components/Required";
import { ResearcherFormValue } from "../components/ResearcherForm";
import Row, { Spacer } from "../components/Row";
import Switch from "../components/Switch";
import { ValidatedInput } from "../components/Validation";
import ScreenTimeEMAControlPanel from "../components/ema/ScreenTimeEMAControlPanel";
import SelectEMAModal from "../components/ema/SelectEMATypeModal";
import StudySubmitModal from "../components/ema/StudySubmitModal";
import SurveyTable from "../components/ema/SurveyTable";
import { ActiveSensors, StudySurvey, SurveyPrefs } from "../models";
import { Researcher } from "../shared";
import {
  SCREENTIME_EMA_DEFAULT,
  SCREENTIME_EMA_NAME,
} from "../shared/Constants";
import { DashboardSettings } from "../shared/StudyVariable";
import {
  NumberValidator,
  StringValidator,
  checkIfAllValuesUndefined,
} from "../shared/utils/Validators";
import noop from "../utils/noop";
import {
  defaultMaxScreenTimePages,
  defaultMaxSurveys,
} from "../utils/settingsConstants";

const SENSOR_MAP: { [key: string]: string } = {
  Accelerometer: ActiveSensors.ACCEL,
  "Battery State": ActiveSensors.BATTERY,
  GPS: ActiveSensors.GPS,
  "Keyboard Usage": ActiveSensors.KEYNOCONTENT,
  "Keyboard Input": ActiveSensors.KEYINPUT,
  "App Usage (Android) & Screen-On Time (iOS)": ActiveSensors.APPUSAGE,
  "Call Status": ActiveSensors.CALLSTATUS,
  EMA: ActiveSensors.EMA,
  "Music Choice": ActiveSensors.MUS,
  "Motion Activity": ActiveSensors.MOTIONACTIVITY,
  "Light - Android Only": ActiveSensors.LIGHT,
};

export const SurveyPrefsDefaultValue: SurveyPrefs = {
  activeSensors: [],
  version: 0,
  studySurveyList: [],
  daysUntilDeactivation: 0,
  gameEnabled: false,
  id: nanoid(),
  studyName: "",
  studySites: [],
  homeScreenEmaAvailable: false,
};

export async function navigateToCreateStudy(
  createNew: Boolean = false,
  study: SurveyPrefs | null = null,
  getResearchers: boolean = false
) {
  const researchers =
    getResearchers && study ? await getStudyResearchers(study.studyName) : null;
  navigate(`/create-study`, {
    state: {
      study: study,
      createNew: createNew,
      researchers: researchers,
    },
  });
}

interface CreateStudyProps
  extends RouteComponentProps<{
    location: {
      state: {
        study: SurveyPrefs | null;
        createNew: boolean | null;
        researchers: ResearcherFormValue | null;
      };
    };
  }> {}

const StudyCreator: PageComponent<CreateStudyProps> = (props) => {
  const [surveyPrefs, setSurveyPrefs] = useState<SurveyPrefs>(
    loadSurveyPrefs()
  );
  const [useMultipleSites, setUseMultipleSites] = useState(
    (surveyPrefs.studySites && surveyPrefs.studySites.length > 0) ?? false
  );
  const [openSelectEMA, setOpenSelectEMA] = useState(false);
  const [openResearcherModal, setOpenResearcherModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [validation, setValidation] = useState<
    Record<string, string | undefined>
  >({});
  const [editingExistingStudy] = useState(loadEditingExistingStudy);
  const [researchers, setResearchers] = useState<ResearcherFormValue>(
    loadResearchers
  );
  const [
    dashboardSettings,
    setDashboardSettings,
  ] = useState<DashboardSettings>();
  const siteNamePrefix = "sitePrefix";

  const enableSurveyCreation = useMemo(() => {
    return surveyPrefs.activeSensors?.includes(ActiveSensors.EMA);
  }, [surveyPrefs.activeSensors]);

  // MARK -: Lifecycle methods
  useEffect(() => {
    sessionStorage.setItem("surveyPrefs", JSON.stringify(surveyPrefs));

    const loadDashboardSettings = async () => {
      const getDashboardSettings = await getStudyDashboardSettings(
        surveyPrefs.studyName
      );
      setDashboardSettings(getDashboardSettings);
    };

    loadDashboardSettings();
  }, [surveyPrefs]);

  useEffect(() => {
    sessionStorage.setItem("researchers", JSON.stringify(researchers));
  }, [researchers]);

  useEffect(() => {
    sessionStorage.setItem(
      "editingExistingStudy",
      JSON.stringify(editingExistingStudy)
    );
  }, [editingExistingStudy]);

  /**
   * Sets up the survey prefs object. Handles various start up states via route props
   */
  function loadSurveyPrefs(): SurveyPrefs {
    // Clean study from history to allow loading from session storage next time
    if (props.location?.state?.study || props.location?.state?.createNew) {
      window.history.replaceState(
        {
          ...props.location.state,
          study: null,
          createNew: false,
        },
        document.title
      );
    }
    // Check if a survey prefs object was passed in or if we're creating a new study
    if (props.location?.state?.study) {
      return props.location.state.study;
    } else if (props.location?.state?.createNew) {
      return { ...SurveyPrefsDefaultValue };
    }
    // If none of the returns are triggered, try load from session storage
    let prefs = JSON.parse(sessionStorage.getItem("surveyPrefs") as string);
    return prefs || { ...SurveyPrefsDefaultValue };
  }

  function loadResearchers(): ResearcherFormValue {
    // try to load researchers from session storage, from a passed in value, or set to empty value
    if (props.location?.state?.researchers) {
      window.history.replaceState(
        {
          ...props.location.state,
          researchers: null,
        },
        document.title
      );
      return props.location?.state?.researchers;
    }
    if (props.location?.state?.createNew) {
      return { researchers: [] };
    }
    return (
      JSON.parse(sessionStorage.getItem("researchers") as string) || {
        researchers: [],
      }
    );
  }

  function loadEditingExistingStudy(): boolean {
    if (props.location?.state?.createNew) {
      return false;
    }
    const savedValue = !!JSON.parse(
      sessionStorage.getItem("editingExistingStudy") as string
    );
    return savedValue || !!props.location?.state?.study;
  }

  /**
   *  Check that required values are set and data is not in a bad state
   *  @return an error message if there is an error or null if there is not one
   */
  function validateForm(): string | null {
    let error: string | null = null;

    // If a validation is already triggered display a message asking for it to be checked
    if (!checkIfAllValuesUndefined(validation)) {
      return "Please fill out all required fields and fix all highlighted fields before submitting.";
    }

    // check sites
    let sites: string[] = (surveyPrefs.studySites ?? []).filter(
      (site) => typeof site === "string"
    ) as string[];
    let siteErrors = sites.map((site, idx) => {
      return new StringValidator(site)
        .min(1)
        .unique(sites, "", idx, true)
        .validate();
    });
    if (siteErrors.some((item) => item !== undefined)) {
      return "One or more of the study sites contains an invalid value or is a duplicate.";
    }

    // check that ema is enabled if any surveys are present
    if (
      surveyPrefs.studySurveyList &&
      surveyPrefs.studySurveyList.length > 0 &&
      !surveyPrefs.activeSensors.includes(ActiveSensors.EMA)
    ) {
      return "Survey(s) are present but the EMA sensor is not enabled. Please enable the EMA sensor or remove all surveys.";
    }

    // check general required values
    let validators = [
      new StringValidator(surveyPrefs.studyName)
        .min(1, "Please verify the study name is filled out.")
        .validate(),
      new NumberValidator(surveyPrefs.daysUntilDeactivation ?? NaN)
        .noNaN(
          `Please verify a number is entered for "Days of Sensor Collection".`
        )
        .validate(),
    ];
    validators.forEach((value) => {
      if (value !== undefined) {
        error = value;
      }
    });

    return error;
  }

  function handleSubmitButton(_: FormEvent<HTMLButtonElement>) {
    let validationError = validateForm();
    if (validationError === null) {
      setOpenSubmitModal(true);
    } else {
      alertMounter({
        titleText: "Validation Error",
        message:
          validationError +
          " If this issue persists after attempting to fix it please contact Ksana Health for assistance",
        showCancelButton: false,
      });
    }
  }

  function handleCompletedForm() {
    sessionStorage.removeItem("surveyPrefs");
    sessionStorage.removeItem("researchers");
    sessionStorage.removeItem("editingExistingStudy");
    navigate("/");
  }

  const handleCancel = () => {
    const handleCancelResponse = (response: boolean) => {
      if (response) {
        handleCompletedForm();
      }
    };

    alertMounter({
      titleText: "Stop Editing This Study?",
      message: "Your edits will not be saved.",
      showCancelButton: true,
      cancelButtonText: "Continue Editing",
      okButtonText: "Yes",
      onSubmit: (response) => handleCancelResponse(response),
    });
  };

  //MARK -: Survey Methods
  //TODO restrict this to lowercase because of s3?
  function setStudyName(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value)
      .min(3, "Name must be more than three characters")
      .lowercaseAlphaNum("Name can only contain numbers and lowercase letters")
      .validate();
    setValidation({
      ...validation,
      studyName: error,
    });
    setSurveyPrefs({
      ...surveyPrefs,
      studyName: event.currentTarget.value,
    });
  }

  function setDaysOfCollection(event: FormEvent<HTMLInputElement>) {
    const days = parseInt(event.currentTarget.value);
    const error = new NumberValidator(days)
      .min(0, "Days of Collection must be at least 0")
      .validate();
    setValidation({
      ...validation,
      daysOfSensorCollection: error,
    });
    setSurveyPrefs({
      ...surveyPrefs,
      daysUntilDeactivation: parseInt(event.currentTarget.value),
    });
  }

  function updateActiveSensors(sensor: string, selectedValues: string[]) {
    const sensorValues = selectedValues.map((item) => SENSOR_MAP[item]);
    setSurveyPrefs({
      ...surveyPrefs,
      activeSensors: sensorValues as ActiveSensors[],
    });
  }

  function updateSiteNames(sites: string[]) {
    sites.forEach((site, index) => {
      const error = new StringValidator(site)
        .min(1, "Site name cannot be blank")
        .unique(sites, "Cannot have duplicate site names", index)
        .validate();
      setValidation({
        ...validation,
        [siteNamePrefix + index]: error,
      });
    });
    setSurveyPrefs({
      ...surveyPrefs,
      studySites: sites,
    });
  }

  /**
   * Handles toggling the screen time EMA on and off and makes certain the EMA sensor is also toggled on
   * It does _not_ toggle the EMA sensor off if the screen time EMA is toggled off
   * @param checked: whether the toggle was turned on or off
   */
  function toggleScreenTimeEMA(checked: boolean) {
    // check if we're already at the max limit if trying to add a survey and we're at the max, alert instead
    if (
      checked &&
      (dashboardSettings?.maxSurveys ?? defaultMaxSurveys) <=
        (surveyPrefs.studySurveyList ?? []).length
    ) {
      alertMounter({
        titleText: `The maximum number of unique survey schedules for your study has been reached.`,
        message: `Unable to add an additional survey schedule.`,
        onSubmit: (response: boolean) => noop,
      });
      return;
    }
    // get the current list of surveys, sensors, and max pages
    let EMAList = surveyPrefs.studySurveyList
      ? [...surveyPrefs.studySurveyList!]
      : [];
    let sensors = [...surveyPrefs.activeSensors, ActiveSensors.EMA];
    const maxScreenTimePages =
      surveyPrefs.maxScreenTimePages ?? defaultMaxScreenTimePages;

    // add or remove ema & update sensor list if needed
    if (checked) {
      EMAList.push(SCREENTIME_EMA_DEFAULT);
    } else {
      EMAList = EMAList.filter((ema) => {
        const surveyName = ema.exact?.surveyId ?? ema.random?.surveyId;
        return surveyName !== SCREENTIME_EMA_NAME;
      });
      setValidation({ ...validation, screentimeDelivery: undefined });
    }
    if (!surveyPrefs.activeSensors.includes(ActiveSensors.EMA)) {
      sensors = [...surveyPrefs.activeSensors, ActiveSensors.EMA];
    }
    // update the survey prefs object with necessary changes
    setSurveyPrefs({
      ...surveyPrefs,
      studySurveyList: EMAList,
      activeSensors: sensors,
      maxScreenTimePages: maxScreenTimePages,
    });
  }

  //MARK -: EMA methods
  function openSelectEMAModal() {
    setOpenSelectEMA(true);
  }

  function closeSelectEMAModal() {
    setOpenSelectEMA(false);
  }

  function setEMA(value: string) {
    closeSelectEMAModal();
    let randomized = value === "random";
    navigate(`/create-ema`, {
      state: {
        randomized: randomized,
        surveyIndex: surveyPrefs?.studySurveyList?.length ?? 0,
      },
    });
  }

  //MARK -: Researcher methods
  function handleResearcherSubmit(value: ResearcherFormValue) {
    setResearchers(value);
    setOpenResearcherModal(false);
  }

  return (
    <Page {...props}>
      {openSelectEMA && (
        <SelectEMAModal onClose={closeSelectEMAModal} onSubmit={setEMA} />
      )}
      {openResearcherModal && (
        <AddResearcherModal
          sites={
            (surveyPrefs.studySites?.filter(
              (item) => item !== null
            ) as string[]) ?? []
          }
          onClose={() => setOpenResearcherModal(false)}
          value={researchers}
          onSubmit={handleResearcherSubmit}
        />
      )}
      {openSubmitModal && (
        <StudySubmitModal
          StudyName={
            surveyPrefs.studyName === ""
              ? "Missing Name"
              : surveyPrefs.studyName ?? "Missing Name"
          }
          onClose={() => setOpenSubmitModal(false)}
          disableClose={true}
          surveyPrefs={surveyPrefs}
          researchers={researchers.researchers}
          handleGoodSubmission={handleCompletedForm}
          updateStudy={editingExistingStudy}
        />
      )}
      <StudyColumn width="800px">
        <TitleRow>
          <BlockButton light onClick={handleCancel}>{`< Cancel`}</BlockButton>
          <Spacer />
          <h1>
            {editingExistingStudy && "Update"}
            {!editingExistingStudy && "Create"} Study
          </h1>
          <Spacer />
          <BlockButton onClick={handleSubmitButton}>Submit</BlockButton>
        </TitleRow>
        <CenteredRow>
          <Required>
            <label>Please enter a name for your study</label>
          </Required>
          <Spacer />
          <ValidatedInput
            message={validation.studyName}
            placeholder="Study Name"
            value={surveyPrefs.studyName}
            onChange={setStudyName}
            disabled={editingExistingStudy}
          />
        </CenteredRow>
        <p>
          <strong>
            Would you like to be able to track participants on multiple assigned
            sites, or one single site?
          </strong>{" "}
          Researcher admins may assign other researchers to a subset of sites
          within a study. Researchers may designate each participant to a single
          site location.
        </p>
        <label>
          <input
            type={"radio"}
            name={"useMultipleSites"}
            onChange={() => setUseMultipleSites(true)}
            checked={useMultipleSites}
          />
          Multiple Sites
        </label>
        <label>
          <input
            type={"radio"}
            name={"useMultipleSites"}
            onChange={() => setUseMultipleSites(false)}
            checked={!useMultipleSites}
          />
          Single Site
        </label>
        {useMultipleSites && (
          <div>
            <p>Please provide the names of each site</p>
            <ListInput
              value={(surveyPrefs.studySites as Array<string>) ?? undefined}
              onChange={updateSiteNames}
              validation={validation}
              validationPrefix={siteNamePrefix}
              disabled={editingExistingStudy}
            />
          </div>
        )}
        <p>
          <strong>Active Sensors</strong>: The list of configured sensors EARS
          will collect for a given study. These variables should not be changed
          after installation. Please refer to the sensor descriptions (Appendix
          B) and sensor chart (Appendix C) to make your selection. Please
          indicate whether this sensor is optional for participants to grant
          permission based on finalized IRB.
        </p>
        <Row>
          <Checkbox
            inputList={Object.keys(SENSOR_MAP)}
            onChange={updateActiveSensors}
            selectedItems={Object.keys(SENSOR_MAP).filter(
              (item) =>
                surveyPrefs.activeSensors &&
                surveyPrefs.activeSensors.includes(
                  SENSOR_MAP[item] as ActiveSensors
                )
            )}
            disabled={editingExistingStudy}
          />
        </Row>
        <p>
          <Required>
            <strong>
              Days of Sensor and EMA Collection or Days Until Deactivation
            </strong>
          </Required>
          : Choose the number of days your study will collect sensor data and
          EMA responses. If study runs indefinitely, set this to 0.
        </p>
        <CenteredRow>
          <label>Days of Daily EMA sensor data collection</label>
          <Spacer />
          <ValidatedInput
            message={validation.daysOfSensorCollection}
            min={0}
            type={"number"}
            value={surveyPrefs.daysUntilDeactivation ?? undefined}
            onChange={setDaysOfCollection}
          />
        </CenteredRow>
        <p>
          <strong>Gamification Enablement</strong>: Choose whether you'd like to
          gamify EARS for your participants. As they answer surveys for
          consecutive days in a row, they will “level-up” and earn survey
          streaks, which can be seen on their EARS homepage.
        </p>
        <Switch
          checked={surveyPrefs.gameEnabled ?? undefined}
          onChange={() =>
            setSurveyPrefs({
              ...surveyPrefs,
              gameEnabled: !surveyPrefs.gameEnabled,
            })
          }
        />
        <p>
          <strong>Home Screen EMA</strong>: Do you want EMAs to appear on the
          home screen?
        </p>
        <Switch
          checked={surveyPrefs.homeScreenEmaAvailable ?? undefined}
          onChange={() =>
            setSurveyPrefs({
              ...surveyPrefs,
              homeScreenEmaAvailable: !surveyPrefs.homeScreenEmaAvailable,
            })
          }
        />
        <ScreenTimeEMAControlPanel
          surveyList={surveyPrefs.studySurveyList ?? []}
          toggleScreenTimeEMa={toggleScreenTimeEMA}
          updateScreenTimeDelivery={(newEmaList: StudySurvey[]) =>
            setSurveyPrefs({ ...surveyPrefs, studySurveyList: newEmaList })
          }
          passValidationUp={(
            deliveryError: string | undefined,
            maxPagesError: string | undefined
          ) => {
            setValidation({
              // TODO move all validation to one function as this has the potential to be confusing during subsequent changes
              ...validation,
              screentimeDelivery: deliveryError,
              maxPagesError: maxPagesError,
            });
          }}
          maximumPages={
            surveyPrefs.maxScreenTimePages ?? defaultMaxScreenTimePages
          }
          updateMaximumPages={(newMaxPages: number) =>
            setSurveyPrefs({ ...surveyPrefs, maxScreenTimePages: newMaxPages })
          }
        />
        <SurveyTable
          surveyList={surveyPrefs.studySurveyList ?? []}
          maxSurveys={dashboardSettings?.maxSurveys ?? defaultMaxSurveys}
          onAddEMA={openSelectEMAModal}
          updateSurveyList={(surveyList: StudySurvey[]) =>
            setSurveyPrefs({ ...surveyPrefs, studySurveyList: surveyList })
          }
          enableCreation={enableSurveyCreation}
        />
        <p>
          <strong>Researchers</strong>
        </p>
        {researchers.researchers.length > 0 && (
          <EMATable>
            {researchers.researchers.map(
              (item: Researcher | null, idx: number) => {
                if (item) {
                  const lastItem = researchers.researchers.length - 1;
                  return (
                    <EMARow key={idx} last={idx === lastItem}>
                      <p key={idx}>{item.email}</p>
                    </EMARow>
                  );
                } else {
                  return <></>;
                }
              }
            )}
          </EMATable>
        )}
        <AddEMAButton
          onClick={() => setOpenResearcherModal(!openResearcherModal)}
        >
          Manage Researchers{" "}
        </AddEMAButton>
      </StudyColumn>
      <SpacerColumn />
    </Page>
  );
};

export default styled(StudyCreator)`
  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const CenteredRow = styled(Row as any)`
  align-items: center;
`;

const TitleRow = styled(Row as any)`
  margin-bottom: 1em;
`;

export const EMAButton = styled(BlankButton as any)`
  padding: 0 0.5em 0 0.5em;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  outline: none;
  font-size: 18px;
  text-align: center;
  text-overflow: ellipsis;
  transition: background-color 0.2s, color 0.2s;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: black;
  background-color: white;

  &.clear {
    background-color: transparent !important;
  }

  &:hover {
    background-color: ${Color("#0075E1").darken(0.1).string()};
  }

  &:active {
    background-color: ${Color("#0075E1").darken(0.3).string()};
  }
`;

export const DuplicateButton = styled(BlankButton as any)`
  font-size: 14px;
  padding: 0.2em 0.2em 0.2em 0.2em;
  margin: 0 4px 0 4px;
`;

export const EMARow = styled(Row as any)<{ last: boolean }>`
  align-items: center;
  padding: 0.25em 1em 0.25em 1em;
  border-bottom: ${(item) => (item.last ? "none" : "1px solid black")};
`;

const SpacerColumn = styled(Column as any)`
  height: 120px;
`;

const AddEMAButton = styled(BlockButton as any)`
  margin: 1em 0 0 0;
  flex-direction: row;

  ${PlusIcon} {
    margin-left: 1em;
  }
`;

const StudyColumn = styled(Column as any)`
  top: 120px;
  bottom: 120px;
  margin-bottom: 120px;
  background-color: #e5ebf1;
  z-index: 3;
  border-radius: 7px;
  padding: 50px;
`;

export const EMATable = styled.div`
  background-color: #e3e3e3;
  padding: 0.25em 0.25em 0.25em 0.25em;
`;
