import React from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import NavBar from '../components/NavBar';
import Row from '../components/Row';
import Footer from '../components/Footer';
import useUser from '../utils/useUser';
import BlankButton from '../components/BlankButton';
import ArrowIcon from '../components/ArrowIcon';
import Column from '../components/Column';
import LoadingPage from '../components/LoadingPage';

interface Props {
  studyName: string;
  siteName: string;
}

const PrivacyPolicy: PageComponent<Props> = props => {
  const { studyName, siteName, ...rest } = props;
  const [loading, user] = useUser();

  function handleBackClick() {
    window.history.back();
  }

  if (loading || !user) {
    return <LoadingPage />;
  }

  return (
    <Page {...rest}>
      <NavBar
        user={user}
        selectedStudyName={studyName}
        selectedSiteName={siteName}
      />
      <Content>
        <TitleRow>
          <BlankButton onClick={handleBackClick}>
            <ArrowIcon />
          </BlankButton>
          <span>Privacy Policy</span>
        </TitleRow>
        <PolicyText>
          <p>Effective date: February 6, 2019</p>
          <p>
            The Center for Digital Mental Health ("us", "we", or "our") operates
            the EARS mobile application (the "App").
          </p>
          <p>
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our App and the choices
            you have associated with that data.
          </p>
          <p>
            We use your data to conduct research and improve the App. By using
            the App, you agree to the collection and use of information in
            accordance with this policy.
          </p>
          <h2>Information Collection And Use</h2>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our App to you.
          </p>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <p>
            While using our App, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). We make a significant effort to
            project your identity. Easily identifiable information such as names
            or addresses are removed and replaced with a randomly generated ID
            when possible. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>
              Data generated by the sensors and interactions on your device
            </li>
          </ul>
          <h4>Usage Data</h4>
          <p>
            When you access the App by or through a mobile device, we may
            collect certain information automatically, including, but not
            limited to, the type of mobile device you use, your mobile device
            unique ID, the IP address of your mobile device, your mobile
            operating system, the type of mobile Internet browser you use,
            unique device identifiers and other diagnostic data ("Usage Data").
          </p>
          <h4>Device Generated Data</h4>
          <p>
            ​When using the App to its full capabilities, we will collect
            several types of data from your device in the background. The App
            can only collect data that is already measured and logged by the
            device. The App does not change or extend the functional
            capabilities of your device. The types of data generated by the
            sensors and interaction on your device collected by the App include,
            but is not limited to, the following:
          </p>
          <ul>
            <li>
              <u>Keyboard Logger.</u> This keyboard logger collects text input
              across all applications on the mobile phone. The logger will
              install a specialized keyboard on the device, which collects every
              character that you type into your phone, including SMS, social
              media, searches, and emails (although NOT secure text fields).
            </li>
            <li>
              <u>Image Capture.</u> Designed to collect facial expression data
              the App is designed to collect, encrypt, and upload any pictures
              that you take with your camera. Our research projects are focused
              on people who directly consent therefore in most cases data will
              only be analyzed after photos that contain other people are
              removed using an automated facial recognition tool.
            </li>
            <li>
              <u>Geolocation.</u> The App will collect the location data from
              your device.
            </li>
            <li>
              <u>Device Usage Patterns.</u> We will also collect other aspects
              of mobile device usage depending on what your device collects.
              Including but not limited to applications used, application usage
              time, music (artist, track, time listened), call statistics
              (frequency, duration), and motion sensing data. We will collect
              the frequency and duration of each application used, as well as
              the total time that your screen is on.
            </li>
          </ul>
          <h3>Use of Data</h3>
          <p>
            The Center for Digital Mental Health uses the collected data for
            various purposes:
          </p>
          <ul>
            <li>To provide and maintain the App</li>
            <li>To notify you about changes to our App</li>
            <li>
              To allow you to participate in interactive features of our App
              when you choose to do so
            </li>
            <li>To provide customer care and support</li>
            <li>
              To provide analysis or valuable information so that we can improve
              the App
            </li>
            <li>To monitor the usage of the App</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
          <h3>Collection Of Data</h3>
          <p>
            ​When you install the App, by default data is collected and stored
            only on your device. Transfer of data will only occur as long as you
            are part of a participating study associated with the App and have
            completed the on device informed consent.
          </p>
          <h3>Transfer Of Data</h3>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </p>
          <p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            The Center for Digital Mental Health will take all steps reasonably
            necessary to ensure that your data is treated securely and in
            accordance with this Privacy Policy and no transfer of your Personal
            Data will take place to an organization or a country unless there
            are adequate controls in place including the security of your data
            and other personal information.
          </p>
          <h3>Disclosure Of Data</h3>
          <p>
            ​ Access to the raw data is limited to specific persons at 7Cups,
            Qntfy, and The Center for Digital Mental Health. Data collected from
            you while participating in a partnering study may eventually be
            shared with other researchers for scientific purposes only; however,
            this data will not contain identifying information that would allow
            others to easily associate the data with you. If a researcher needs
            access to any identity data they must first obtain ethics approval
            from their institution as well as meeting stringent requirements.
            When the results of this research are published or presented in any
            public forum, absolutely no information will be included that might
            reveal your identity.
          </p>
          <h3>Security Of Data</h3>
          <p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. All data collected by the App is governed by
            a University Institutional Review Board (research ethics committee)
            who have responsibility for oversight of the ethical management of
            your data.
          </p>
          <h3>Removal Of Data</h3>
          <p>
            If you at any time wish to remove your Personal Data collected
            through the use of the App, we can facilitate removal of said data
            from our servers if contacted directly. ​
          </p>
          <h3>Service Providers</h3>
          <p>
            We may employ third party companies and individuals to facilitate
            our App ("Service Providers"), to provide the App on our behalf, to
            perform App-related services or to assist us in analyzing how our
            App is used. These third parties have access to your Personal Data,
            strictly in the form of Usage Data, only to perform these tasks on
            our behalf and are obligated not to disclose or use it for any other
            purpose. ​
          </p>
          <h3>Analytics</h3>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our App.
          </p>
          <ul>
            <li>
              <b>Firebase</b>
              <p>Firebase is analytics service provided by Google Inc.</p>
              <p>
                You may opt-out of certain Firebase features through your mobile
                device settings, such as your device advertising settings or by
                following the instructions provided by Google in their Privacy
                Policy:{' '}
                <a
                  href="https://policies.google.com/privacy?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
              </p>
              <p>
                We also encourage you to review the Google's policy for
                safeguarding your data:
                <a
                  href="https://support.google.com/analytics/answer/6004245"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/analytics/answer/6004245
                </a>
                . For more information on what type of information Firebase
                collects, please visit please visit the Google Privacy {'&'}{' '}
                Terms web page:
                <a
                  href="https://policies.google.com/privacy?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
              </p>
            </li>
          </ul>
          <h3>Links To Other Sites</h3>
          <p>
            Our App may contain links to other sites that are not operated by
            us. If you click on a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h3>Changes To This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our App,
            prior to the change becoming effective and update the "effective
            date" at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h3>Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us: By visiting this page on our website:{' '}
            <a
              href="https://www.c4dmh.net/contact-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.c4dmh.net/contact-us
            </a>
          </p>
        </PolicyText>
      </Content>
      <Footer studyName={studyName} siteName={siteName} />
    </Page>
  );
};

export default styled(PrivacyPolicy)``;

const Content = styled(Column as any)`
  flex: 1;
  align-items: center;
`;

const PolicyText = styled(Column as any)`
  width: 700px;

  ul {
    list-style: inside;
    margin-bottom: 20px;
  }
`;

const TitleRow = styled(Row as any)`
  color: #0b1c4a;
  width: 1000px;
  max-width: 100%;
  font-size: 56px;
  line-height: 64px;
  margin-bottom: 80px;
  align-items: center;

  ${BlankButton} {
    margin-right: 24px;
  }
`;
