import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import Column from './Column';

interface Props {
  invisible?: boolean;
  color?: string;
}

const Dot: FC<Props> = props => {
  return (
    <Column {...props}>
      <Circle color={props.color} invisible={props.invisible} />
      <Popup color={props.color}>{props.children}</Popup>
    </Column>
  );
};

const Popup = styled(Column as any)<{ color?: string }>`
  display: none;
  background-color: ${props => props.color || 'white'};
  width: 120px;
  min-height: 50px;
  padding: 8px;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  position: absolute;
  z-index: 3;
  bottom: -5px;
  left: 0;
  transform: translate(0, 100%);
  justify-content: space-between;
`;

export default styled(Dot)<Props>`
  position: relative;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:hover > ${Popup} {
    display: ${props => (props.invisible ? 'none' : 'flex')};
  }
`;

const Circle = styled(Column as any)<Props>`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: 2;
  ${props => css`
    background-color: ${props.color || '#0075e1'};
    opacity: ${props.invisible ? 0 : 1};
  `}
`;
