import { SurveyQuestion } from "../models";
import { BranchingRules } from "../shared/Types";
import getQuestionContent from "./getQuestionContent";

const getBranchingRule = (
  question: SurveyQuestion
): BranchingRules | undefined => {
  if (question.bool?.branchingRule) {
    return { ...question.bool.branchingRule };
  }
  if (question.choice?.branchingRule) {
    return { ...question.choice.branchingRule };
  }
  if (question.info) {
    return undefined;
  }
  if (question.scale?.branchingRule) {
    return { ...question.scale.branchingRule };
  }
  if (question.textEntry) {
    return undefined;
  }
  if (question.timeOfDay?.branchingRule) {
    return { ...question.timeOfDay.branchingRule };
  }
  return undefined;
};

export const clearBranchingRule = (
  question: SurveyQuestion
): SurveyQuestion => {
  if (question.bool?.branchingRule) {
    return {
      ...question,
      bool: {
        ...question.bool,
        branchingRule: {
          ...question.bool.branchingRule,
          destinationQuestionId: "",
          defaultQuestionId: "",
        },
      },
    };
  }
  if (question.choice?.branchingRule) {
    return {
      ...question,
      choice: {
        ...question.choice,
        branchingRule: {
          ...question.choice.branchingRule,
          destinationQuestionId: "",
          defaultQuestionId: "",
        },
      },
    };
  }
  if (question.scale?.branchingRule) {
    return {
      ...question,
      scale: {
        ...question.scale,
        branchingRule: {
          ...question.scale.branchingRule,
          destinationQuestionId: "",
          defaultQuestionId: "",
        },
      },
    };
  }
  if (question.timeOfDay?.branchingRule) {
    return {
      ...question,
      timeOfDay: {
        ...question.timeOfDay,
        branchingRule: {
          ...question.timeOfDay.branchingRule,
          destinationQuestionId: "",
          defaultQuestionId: "",
        },
      },
    };
  }
  return { ...question };
};

export const questionsWithBlankBranchDestinations = (
  questions: (SurveyQuestion | null)[]
) => {
  const questionIDs: String[] = [];
  questions.forEach((question) => {
    const branchingRule = getBranchingRule(question as SurveyQuestion);
    const questionID = getQuestionContent(question)?.questionId;
    if (
      branchingRule?.defaultQuestionId === "" ||
      branchingRule?.destinationQuestionId === ""
    ) {
      if (questionID) {
        questionIDs.push(questionID);
      }
    }
  });
  return questionIDs;
};

export default getBranchingRule;
