import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import NavBar from '../components/NavBar';
import Row from '../components/Row';
import Footer from '../components/Footer';
import useUser from '../utils/useUser';
import BlankButton from '../components/BlankButton';
import ArrowIcon from '../components/ArrowIcon';
import Column from '../components/Column';
import LoadingPage from '../components/LoadingPage';
import EditAccountForm, {
  defaultValue as editAccountDefaultValue
} from '../components/EditAccountForm';
import getErrorText from '../utils/getErrorText';
import updateUser from '../api/updateUser';
import SpinnerOverlay from '../components/SpinnerOverlay';
import checkPasswordRequirements from "../shared/utils/checkPasswordRequirements";

interface Props {
  studyName: string;
  siteName: string;
}

const EditAccount: PageComponent<Props> = props => {
  const { studyName, siteName, ...rest } = props;
  const [loadingUser, user] = useUser();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [value, setValue] = useState(editAccountDefaultValue);
  const loading = loadingUser;

  useEffect(() => {
    if (!user) {
      return;
    }
    setValue({
      ...editAccountDefaultValue,
      email: user.email
    });
  }, [user]);

  function handleBackClick() {
    window.history.back();
  }

  async function handleSubmit() {
    const { email, password, passwordConfirmed } = value;

    if (
      (password.length > 0 || passwordConfirmed.length > 0) &&
      password !== passwordConfirmed
    ) {
      setError(new Error(`Passwords don't match.`));
      return;
    }
    let requirements = checkPasswordRequirements(password, email);
    if (requirements) {
      setError(new Error(requirements));
      return;
    }

    try {
      setSaving(true);
      await updateUser({
        email,
        password
      });
      setError(null);
      alert('Updated successfully');
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setSaving(false);
    }
  }

  if (loading || !user) {
    return <LoadingPage />;
  }

  const studyNames = user.assignedStudies.map(assigned => assigned.studyName);

  return (
    <Page {...rest}>
      {saving && <SpinnerOverlay />}
      <NavBar
        user={user}
        selectedStudyName={studyName}
        selectedSiteName={siteName}
      />
      <Content>
        <TitleRow>
          <BlankButton onClick={handleBackClick}>
            <ArrowIcon />
          </BlankButton>
          <span>My Account</span>
        </TitleRow>

        <EditAccountForm
          studyNames={studyNames}
          value={value}
          onChange={setValue}
          onSubmit={handleSubmit}
        />

        {error && <ErrorText>{getErrorText(error)}</ErrorText>}
      </Content>
      <Footer studyName={studyName} siteName={siteName} />
    </Page>
  );
};

export default styled(EditAccount)``;

const Content = styled(Column as any)`
  align-items: center;
  flex: 1;
`;

const TitleRow = styled(Row as any)`
  color: #0b1c4a;
  width: 1000px;
  max-width: 100%;
  font-size: 56px;
  line-height: 64px;
  margin-bottom: 80px;
  align-items: center;

  ${BlankButton} {
    margin-right: 24px;
  }
`;

const ErrorText = styled('div')`
  margin-top: 20px;
  color: #a00;
`;
