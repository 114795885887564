import React, {FC, RefObject} from 'react';
import styled from 'styled-components/macro';
import Row from './Row';

export interface InputProps {
  type?: string;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  min?: number;
  max?: number;
  ref?: RefObject<HTMLInputElement>;
  error?: any;
}

const Input: FC<InputProps> = props => {
  const {
    type,
    value,
    onChange,
    onKeyPress,
    placeholder,
    disabled,
    children,
    readOnly,
    name,
    min,
    max,
    ref,
    ...rest
  } = props;

  return (
    <Row {...rest}>
      <input
        type={type}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        name={name}
        min={min}
        max={max}
        ref={ref}
      />
      {children}
    </Row>
  );
};

export default styled(Input)<InputProps>`
  background-color: white;
  height: 40px;
  border-radius: 7px;
  overflow: hidden;
  border: ${props => props.error !== undefined ? '1px solid red' : 'none'};

  input {
    height: 100%;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    border: none;
    outline: none;
    flex: 1 1 auto;
    margin: 0 0 0 0;
    padding: 0 0 0 13px;
  }

  input::placeholder {
    color: #a5adbb;
  }

  input:disabled::placeholder {
    opacity: 0.5;
  }

  input:disabled,
  input:read-only {
    background-color: #cfcfcf;
  }
`;
