import { FC } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';

const Page = styled(Column as any)`
  min-height: 100vh;
`;

export type PageComponent<Props = {}> = FC<
  Props & { path: string; default?: boolean }
>;

export default Page;
