import {TextInfo} from "../../models";
import React, {FC, FormEvent, useEffect, useState} from "react";
import Column from "../Column";
import Required from "../Required";
import {ValidatedInput} from "../Validation";
import Row from "../Row";
import Switch from "../Switch";
import {checkIfAllValuesUndefined, StringValidator} from "../../shared/utils/Validators";
import {branchingRuleReservedValues} from "./BranchingRule";
import noop from "../../utils/noop";
import {QuestionTextInput} from "./ChoiceQuestion";
import styled from "styled-components/macro";

interface TextInfoProps {
  value?: TextInfo;
  surveyQuestionIDs: string[];
  currentQuestionIndex: number;
  onChange?: (value: TextInfo) => any;
  canSwitch?: (value: boolean) => any;
}

export const TextInfoDefaultValue: TextInfo = {
  questionId: "",
  questionHeader: "",
  questionBody: "",
  skipEnabled: true,
}

const TextInfoComponent: FC<TextInfoProps> = props =>  {
  const {
    value = TextInfoDefaultValue,
    onChange = noop,
    canSwitch = noop,
    ...rest
  } = props;
  const [validation, setValidation] = useState<Record<string, string | undefined>>({});

  useEffect(() => {
    let validationIsGood = checkIfAllValuesUndefined(validation);
    [ new StringValidator(value.questionId).min(1).validate(),
      new StringValidator(value.questionHeader ?? '').min(1).validate()
    ].forEach((value) => {
      validationIsGood = validationIsGood && value === undefined ;
    });
    canSwitch(validationIsGood);
  },)

  function handleQuestionIdChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value, 'QuestionID must contain at least one character')
      .alphaNum('Question ID can only contain of numbers of letters.')
      .min(1, 'Question ID must contain at least one character.')
      .unique(props.surveyQuestionIDs, 'Question IDs must be unique (match found for another question)', props.currentQuestionIndex)
      .unique(branchingRuleReservedValues, 'This value is reserved and cannot be used as a Question ID')
      .validate();
    setValidation({
      ...validation,
      questionId: error
    });
    onChange({
      ...value,
      questionId: event.currentTarget.value
    });
  }

  function handleQuestionHeaderChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value)
      .min(1, 'Question Header must contain at least one character')
      .noSpace('Question Header value cannot contain only spaces')
      .validate();
    setValidation({
      ...validation,
      questionHeader: error
    });
    onChange({
      ...value,
      questionHeader: event.currentTarget.value
    });
  }

  function handleQuestionTextChange(event: FormEvent<HTMLTextAreaElement>) {
    onChange({
      ...value,
      questionBody: event.currentTarget.value
    });
  }

  function toggleSkip(newState: boolean) {
    onChange({
      ...value,
      skipEnabled: newState
    });
  }
  return (
    <Column {...rest}>
      <h2>Info Text</h2> <Required>
        <label>Question ID</label>
      </Required>
      <ValidatedInput
        message={validation.questionId}
        value={value.questionId}
        onChange={handleQuestionIdChange}
        placeholder="QuestionID"
      />
      <Required>
                 <label>Question header</label>
                 </Required>
      <ValidatedInput
        message={validation.questionHeader}
        value={value.questionHeader ?? ''}
        onChange={handleQuestionHeaderChange}
        placeholder="Question Header"
      />
      <label>Question Body</label>
      <QuestionTextInput
        placeholder="Question Body"
        value={value.questionBody}
        onChange={handleQuestionTextChange}
      />
      <Row>
            <Switch
            checked={value.skipEnabled}
            onChange={toggleSkip}
            />
            <label>Skippable</label>
            </Row>
    </Column>
  );
}
export default styled(TextInfoComponent)`
  max-width: 100%;
  min-width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;
