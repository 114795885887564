import React, {FC, FormEvent, useEffect, useState} from 'react';
import Column from "../Column";
import Row from "../Row";
import styled from "styled-components/macro";
import TextArea from "../TextArea";
import Switch from "../Switch";
import noop from "../../utils/noop";
import Required from "../Required";
import {TimeOfDayBranchingRuleComponent, TimeOfDayBranchingRuleDefaultValue} from "./TimeOfDayBranchingRule";
import {TimeOfDayBranchingRule, TimeOfDayQuestion} from "../../models";
import {NumberValidator, StringValidator, TimeValidator} from "../../shared/utils/Validators";
import {ValidatedInput} from "../Validation";
import {branchingRuleReservedValues} from "./BranchingRule";

interface TimeOfDayQuestionProps {
  value?: TimeOfDayQuestion;
  surveyQuestionIDs: string[];
  currentQuestionIndex: number;
  onChange?: (value: TimeOfDayQuestion) => any;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => any;
  canSwitch?: (value: boolean) => any;
}

export const TimeOfDayQuestionDefaultValue: TimeOfDayQuestion = {
  questionId: "",
  questionHeader: "",
  questionBody: "",
  skipEnabled: true,
  defaultHour: 12,
  defaultMinute: 0,
  branchingRule: undefined
}
const TimeOfDayQuestionComponent: FC<TimeOfDayQuestionProps> = props => {
  const {
    value = TimeOfDayQuestionDefaultValue,
    onChange = noop,
    canSwitch = noop,
  } = props;
  const [validation, setValidation] = useState<Record<string, string | undefined>>({});
  const nextQuestion = props.surveyQuestionIDs.length > props.currentQuestionIndex+1 ? props.surveyQuestionIDs[props.currentQuestionIndex+1] : undefined

  const validateQuestionForm = () => {
    const validators = [];
    if (value.branchingRule) {
      validators.push(
        new StringValidator(value.branchingRule.defaultQuestionId).noEmpty().validate(),
        new StringValidator(value.branchingRule.destinationQuestionId).noEmpty().validate()
      )
    }
    const questionIdError = new StringValidator(value.questionId, 'QuestionID must contain at least one character')
      .alphaNum('Question ID can only contain of numbers of letters.')
      .min(1, 'Question ID must contain at least one character.')
      .unique(props.surveyQuestionIDs, 'Question IDs must be unique (match found for another question)', props.currentQuestionIndex)
      .unique(branchingRuleReservedValues, 'This value is reserved and cannot be used as a Question ID')
      .validate();
    validators.push(questionIdError);

    const qustionHeaderError = new StringValidator(value.questionHeader)
      .min(1, 'Question Header must contain at least one character')
      .noSpace('Question Header value cannot contain only spaces')
      .validate();
    validators.push(qustionHeaderError);

    const defaultHourError = new NumberValidator(value.defaultHour, 'Default Hour cannot be blank')
      .noNaN()
      .min(0, 'Default Hour must be greater or equal to than 0')
      .max(23, 'Default Hour must be less than or equal or 23')
      .validate();
    validators.push(defaultHourError);

    const defaultMinuteError = new NumberValidator(value.defaultMinute, 'Default Minute cannot be blank.')
      .noNaN()
      .min(0, 'Default Minute must be greater than or equal to 0')
      .max(59, 'Default Minute must be less than or equal to 59')
      .validate();
    validators.push(defaultMinuteError);

    let timeInputsError = undefined;
    if (value.branchingRule){
      const {
        maximumExpectedAnswerHour,
        maximumExpectedAnswerMinute,
        minimumExpectedAnswerHour,
        minimumExpectedAnswerMinute,
      } = value.branchingRule;

      timeInputsError = new TimeValidator({
        hour: maximumExpectedAnswerHour,
        minute: maximumExpectedAnswerMinute,
      })
        .min(
          minimumExpectedAnswerHour,
          minimumExpectedAnswerMinute,
          "Start time must be before end time."
        )
        .validate();
    }
    validators.push(timeInputsError);

    setValidation({
      ...validation,
      questionId: questionIdError,
      questionHeader: qustionHeaderError,
      defaultHour: defaultHourError,
      defaultMinute: defaultMinuteError,
      timeInputs: timeInputsError
    })
    return validators.every(validator => validator === undefined)
  }

  useEffect(() => {
    canSwitch(validateQuestionForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])


  function handleQuestionIdChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      questionId: event.currentTarget.value
    });
  }

  function handleQuestionHeaderChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      questionHeader: event.currentTarget.value
    });
  }

  function handleQuestionBodyChange(event: FormEvent<HTMLTextAreaElement>) {
    onChange({
      ...value,
      questionBody: event.currentTarget.value
    });
  }

  function handleDefaultHourChange(event: FormEvent<HTMLInputElement>) {
    const hour = parseInt(event.currentTarget.value);
    onChange({
      ...value,
      defaultHour: hour
    });
  }

  function handleDefaultMinuteChange(event: FormEvent<HTMLInputElement>) {
    const minute = parseInt(event.currentTarget.value);
    onChange({
      ...value,
      defaultMinute: minute
    });
  }

  function toggleSkip(newState: boolean) {
    onChange({
      ...value,
      skipEnabled: newState
    });
  }

  function toggleLogic(newState: boolean) {
    if (nextQuestion && newState) {
      onChange({
        ...value,
        branchingRule: {
          ...TimeOfDayBranchingRuleDefaultValue,
          defaultQuestionId: "",
          destinationQuestionId: ""
        }
      });
    } else {
      onChange({
        ...value,
        branchingRule: undefined
      });
    }
  }

  function updateBranchingRule(rule: TimeOfDayBranchingRule) {
    onChange({
      ...value,
      branchingRule: rule
    });
  }

  return (
    <Column>
      <h2>Time of Day Question</h2>
      <Required>
        <label>Question ID</label>
      </Required>
      <ValidatedInput
        message={validation.questionId}
        value={value.questionId}
        onChange={handleQuestionIdChange}
        placeholder="QuestionID"
      />
      <Required>
        <label>Question Header</label>
      </Required>
      <ValidatedInput
        message={validation.questionHeader}
        value={value.questionHeader ?? undefined!}
        onChange={handleQuestionHeaderChange}
        placeholder="Question Header"
      />
      <label>Question Body</label>
      <QuestionTimeOfDayInput
        placeholder="Question Body"
        value={value.questionBody}
        onChange={handleQuestionBodyChange}
      />
      <Required>
        <label>Default Hour (in 24-hour time)</label>
      </Required>
      <ValidatedInput
        message={validation.defaultHour}
        type='number'
        value={value.defaultHour ?? undefined!}
        onChange={handleDefaultHourChange}
        min={0}
        max={23}
      />
      <Required>
        <label>Default Minute</label>
      </Required>
      <ValidatedInput
        message={validation.defaultMinute}
        type='number'
        value={value.defaultMinute ?? undefined!}
        onChange={handleDefaultMinuteChange}
        min={0}
        max={59}
      />
      <Row>
        <Switch
          checked={value.skipEnabled}
          onChange={toggleSkip}
        />
        <label>Skippable</label>
      </Row>
      {nextQuestion &&
      <Row>
        <Switch
          checked={value.branchingRule !== undefined && value.branchingRule !== null}
          onChange={toggleLogic}
        />
        <label>Logic</label>
      </Row>
      }
      {value.branchingRule &&
      <>
        <TimeOfDayBranchingRuleComponent
          surveyQuestionIDs={props.surveyQuestionIDs.slice(props.currentQuestionIndex+1)}
          value={value.branchingRule}
          onChange={updateBranchingRule}
          validation={validation}
        />
      </>
      }
    </Column>
  );
}

export default styled(TimeOfDayQuestionComponent)`
  width: 500002px;
  max-width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const QuestionTimeOfDayInput = styled(TextArea as any)`
  width: 100%;
  margin-bottom: 16px;
`;

