import React, { FC, FormEvent, useState } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import Input from './Input';
import {
  sensorDescriptions, Sensor
} from '../shared';
import Switch from './Switch';
import PlusButton from './PlusButton';
import TrashIcon from './TrashIcon';
import BlankButton from './BlankButton';
import noop from '../utils/noop';
import removeFromArray from '../utils/removeFromArray';
import isBlank from '../utils/isBlank';
import {isLowerAlphanumeric} from '../shared/utils/isAlphanumeric';
import { formatStudyName } from '../shared/utils/formatFns';
import EmaForm, {
  Value as EmaFormValue,
  defaultValue as emaFormDefaultValue
} from './EmaForm';
import InputModal from './InputModal';
import {ActiveSensors} from "../models";

const visibleSensors = [...sensorDescriptions];
const sensorGroup1 = visibleSensors.slice(
  0,
  Math.ceil(visibleSensors.length / 2)
);
const sensorGroup2 = visibleSensors.slice(sensorGroup1.length);

export interface StudyFormValue extends EmaFormValue {
  studyName: string;
  includedSensors: Sensor[];
  studySites: string[];
}

interface Props {
  disableStudyName?: boolean;
  readonlySites?: string[];
  value?: StudyFormValue;
  onChange?: (value: StudyFormValue) => any;
}

export const defaultValue: StudyFormValue = {
  ...emaFormDefaultValue,
  studyName: '',
  includedSensors: [],
  studySites: []
};

const StudyForm: FC<Props> = props => {
  const {
    value = defaultValue,
    onChange = noop,
    readonlySites = [],
    disableStudyName,
    ...rest
  } = props;
  const { studyName, includedSensors, studySites } = value;

  const [showAddSiteModal, setShowAddSiteModal] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [addSiteError, setAddSiteError] = useState<string>('');

  function handleSiteNameChange(event: FormEvent<HTMLInputElement>) {
    const studyName = event.currentTarget.value;
    setShowWarning(!isLowerAlphanumeric(studyName))
    onChange({ ...value, studyName });
  }

  function handleAddSiteClick() {
    setShowAddSiteModal(true);
  }

  function handleAddSiteSubmit(siteName: string | number): boolean {
    siteName = siteName.toString();
    if (!siteName || isBlank(siteName)) {
      return true;
    }


    if (studySites.includes(siteName)) {
      setAddSiteError(`A site already exists with the name "${siteName}"`);
      return false;
    }
    setAddSiteError('');

    onChange({
      ...value,
      studySites: studySites.concat(siteName)
    });
    return true;
  }

  function handleAddSiteClose() {
    setShowAddSiteModal(false);
    setAddSiteError('');
  }

  function handleDeleteSiteClick(site: string) {
    onChange({
      ...value,
      studySites: removeFromArray(studySites, site)
    });
  }

  function handleSensorToggle(sensorId: Sensor) {
    if (includedSensors.includes(sensorId)) {
      onChange({
        ...value,
        includedSensors: removeFromArray(includedSensors, sensorId)
      });
    } else {
      onChange({
        ...value,
        includedSensors: includedSensors.concat(sensorId)
      });
    }
  }

  return (
    <Column {...rest}>
      <InputLabel>Study Name</InputLabel>
      <Input
        value={studyName}
        onChange={handleSiteNameChange}
        disabled={disableStudyName}
      />
      { showWarning && (
          <InputWarning>Study name must be lowercase and alphanumeric</InputWarning>
      )}
      <SensorSiteRow>
        <Column>
          <InputLabel>Sensors</InputLabel>
          <Row>
            <SwitchColumn>
              {sensorGroup1.map(sensor => (
                <SensorRow
                  key={sensor.id}
                  onClick={() => handleSensorToggle(sensor.id)}
                >
                  <Switch checked={includedSensors.includes(sensor.id)} />
                  <label>{sensor.name}</label>
                </SensorRow>
              ))}
            </SwitchColumn>
            <SwitchColumn>
              {sensorGroup2.map(sensor => (
                <SensorRow
                  key={sensor.id}
                  onClick={() => handleSensorToggle(sensor.id)}
                >
                  <Switch checked={includedSensors.includes(sensor.id)} />
                  <label>{sensor.name}</label>
                </SensorRow>
              ))}
            </SwitchColumn>
          </Row>
        </Column>
        <Column>
          <InputLabel>Sites</InputLabel>
          <SiteList>
            {studySites.map(site => (
              <SiteRow key={site}>
                <span>{site}</span>
                {!readonlySites.includes(site) && (
                  <BlankButton onClick={() => handleDeleteSiteClick(site)}>
                    <TrashIcon />
                  </BlankButton>
                )}
              </SiteRow>
            ))}
          </SiteList>
          <AddSiteRow>
            <PlusButton onClick={handleAddSiteClick} />
          </AddSiteRow>
        </Column>
      </SensorSiteRow>
      {includedSensors.includes(ActiveSensors.EMA) && (
        <EmaForm
          value={value}
          onChange={emaValue => onChange({ ...value, ...emaValue })}
        />
      )}

      {showAddSiteModal && (
        <AddSiteModal
          inputLabel="What is the name of the site?"
          onSubmit={handleAddSiteSubmit}
          onClose={handleAddSiteClose}
          validationError={addSiteError}
        />
      )}
    </Column>
  );
};

export default styled(StudyForm)`
  background-color: #e5ebf1;

  ${EmaForm} {
    margin-top: 16px;
  }
`;

const InputWarning = styled( 'p') `
  color: red;
`;

const InputLabel = styled('div')`
  color: #0b1c4a;
  font-family: Roboto;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;
`;

const SensorSiteRow = styled(Row as any)`
  margin-top: 24px;

  > ${Column} {
    flex: 1;
  }

  > ${Column} + ${Column} {
    margin-left: 60px;
  }
`;

const SwitchColumn = styled(Column as any)`
  & + & {
    margin-left: 33px;
  }
`;

const SensorRow = styled(Row as any)`
  align-items: center;
  cursor: pointer;

  & + & {
    margin-top: 16px;
  }

  label {
    margin-left: 8px;
    color: #0b1c4a;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    cursor: pointer;
  }
`;

const SiteRow = styled(Row as any)`
  justify-content: space-between;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  align-items: center;
  padding: 0 16px 0 13px;
  color: #baccde;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;

  & + & {
    margin-top: 8px;
  }
`;

const SiteList = styled(Column as any)``;

const AddSiteRow = styled(Row as any)`
  justify-content: flex-end;
  margin-top: 8px;
`;

const AddSiteModal = styled(InputModal as any)`
  width: 690px;
`;
