import React, { FC } from "react";
import styled from "styled-components/macro";
import Logo from "./Logo";
import TextLogo from "./TextLogo";
import Column from "./Column";
import Row from "./Row";
import Link from "./Link";
import EARSBunnyLogo from "./EARSBunnyLogo";
import { userIsAdmin } from "../shared";
import useUser from "../utils/useUser";

const fullYear = new Date().getFullYear();

interface Props {
  studyName: string;
  siteName: string;
}

const Footer: FC<Props> = (props) => {
  const { studyName, siteName, ...rest } = props;
  const [_loadingUser, user] = useUser();

  return (
    <Column {...rest}>
      <EARSBunnyLogo />
      <TextLogo dark />
      <ResearchDashboardText>
        Research Dashboard
        <br /> v 2.4.0
      </ResearchDashboardText>
      <LinkRow>
        <Link to={`/${studyName}/${siteName}/studies`}>Manage Studies</Link>
      </LinkRow>
      <LinkRow>
        <Link to={`/${studyName}/${siteName}/privacy`}>Privacy Policy</Link>
        <Link to={`/${studyName}/${siteName}/sitemap`}>Sitemap</Link>
        {user && userIsAdmin(user) && (
          <Link to={`/study-list`}>Study List</Link>
        )}
        <div>&copy;{fullYear}</div>
      </LinkRow>
    </Column>
  );
};

export default styled(Footer)`
  align-items: center;
  background-color: white;
  padding: 20px 20px 80px 20px;

  ${Logo} {
    height: 61px;
    width: 38px;
    margin-bottom: 13px;
  }

  ${TextLogo} {
    height: 25px;
    width: 94px;
    margin-bottom: 7px;
  }
`;

const ResearchDashboardText = styled("div")`
  color: #0b1c4a;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 12px;
`;

const LinkRow = styled(Row as any)`
  color: #0b1c4a;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 15px;

  a {
    color: #0b1c4a;
    text-decoration: none;
  }

  > * + * {
    margin-left: 15px;
  }

  & + & {
    margin-top: 8px;
  }
`;
