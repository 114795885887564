import React, { FC, useMemo, useState } from "react";
import styled from "styled-components/macro";
import isBlank from "../utils/isBlank";
import isEmail from "../utils/isEmail";
import noop from "../utils/noop";
import BlockButton from "./BlockButton";
import Modal, { ModalProps } from "./Modal";
import ResearcherForm, {
  ResearcherFormValue,
  defaultValue as researchFormDefaultValue,
} from "./ResearcherForm";
import Row from "./Row";
import nanoid from "nanoid";

interface Props extends ModalProps {
  onSubmit?: (value: ResearcherFormValue) => any;
  sites?: string[];
  value: ResearcherFormValue;
}

const AddResearcherModal: FC<Props> = (props) => {
  const { onSubmit = noop, sites = [], ...rest } = props;
  const [researchFormValue, setResearchFormValue] = useState(
    props.value ?? researchFormDefaultValue
  );

  /**
   * Represents the value of researchers for the research form.
   * It manipulates the passed in researchers and sets necessary metadata.
   */
  const researchersValue = useMemo(() => {
    const newResearchers = researchFormValue.researchers.filter(
      (researcher) => researcher.isNew
    );
    const existingResearchers = researchFormValue.researchers
      .filter((researcher) => !researcher.isNew)
      .map((researcher) => ({ ...researcher, isSaved: true }));

    // merge existing researchers with new researchers and assign unique ids if necessary
    let allResearchers = [...existingResearchers, ...newResearchers].map(
      (researcher) => {
        if (researcher.id === undefined) {
          return { ...researcher, id: nanoid() };
        }
        return researcher;
      }
    );

    return {
      researchers: [...allResearchers],
    };
  }, [researchFormValue]);

  function handleSubmitClick() {
    // Check for duplicate email addresses
    let emails = researchersValue.researchers.map(
      (researcher) => researcher.email
    );
    if (new Set(emails).size !== emails.length) {
      alert(`Duplicate email addresses found. Please remove duplicates.`);
      return;
    }
    // Validate researcher email addresses
    for (const researcher of researchFormValue.researchers) {
      if (isBlank(researcher.email)) {
        alert(`Researcher email cannot be blank.`);
        return;
      }
      if (!isEmail(researcher.email)) {
        alert(`Invalid email "${researcher.email}"`);
        return;
      }
    }
    onSubmit(researchFormValue);
  }

  return (
    <Modal {...rest}>
      <ResearcherForm
        value={researchersValue}
        onChange={setResearchFormValue}
        allSites={sites}
      />
      <ButtonRow>
        <BlockButton onClick={handleSubmitClick}>Save</BlockButton>
      </ButtonRow>
    </Modal>
  );
};

export default styled(AddResearcherModal)`
  width: 1200px;
  padding: 16px 28px 84px 28px;
`;

const ButtonRow = styled(Row as any)`
  margin-top: 100px;
  justify-content: center;

  ${BlockButton} {
    width: 237px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 8px;
  }
`;
