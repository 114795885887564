import styled from 'styled-components/macro';

interface RowProps {
  alignItems?: string;
  gap?: string;
  margin?: string;
}

const Row = styled.div<RowProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: ${props => props.alignItems ? props.alignItems : 'default'};
  gap: ${props => props.gap ? props.gap : 'default'};
  margin: ${props => props.margin ? props.margin : 'default'};
`;

export default Row;

export const Spacer = styled('div')`
  flex-grow: 1;
`;
