import React, { FC, FormEventHandler } from 'react';
import styled from 'styled-components/macro';
import Color from 'color';
import BlankButton from './BlankButton';

interface PlusButtonProps {
  disabled?: boolean;
  onClick?: FormEventHandler<HTMLButtonElement>;
  dark?: boolean;
}

const PlusButton: FC<PlusButtonProps> = props => {
  return props.dark ? (
    <DarkPlusButton {...props} />
  ) : (
    <LightPlusButton {...props} />
  );
};

export default styled(PlusButton)``;

const Root = styled(BlankButton as any)`
  border-radius: 7px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 40px;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:disabled {
    background-color: #baccde;
  }

  &:after {
    content: '+';
    display: block;
    position: relative;
  }
`;

const lightBackgroundColor = '#06DDDD';
const LightPlusButton = styled(Root as any)<PlusButtonProps>`
  background-color: ${lightBackgroundColor};

  &:hover {
    background-color: ${Color(lightBackgroundColor)
      .darken(0.1)
      .string()};
  }

  &:active {
    background-color: ${Color(lightBackgroundColor)
      .darken(0.3)
      .string()};
  }
`;

const darkBackgroundColor = '#0E4A93';
const DarkPlusButton = styled(Root as any)<PlusButtonProps>`
  background-color: ${darkBackgroundColor};

  &:hover {
    background-color: ${Color(darkBackgroundColor)
      .darken(0.1)
      .string()};
  }

  &:active {
    background-color: ${Color(darkBackgroundColor)
      .darken(0.3)
      .string()};
  }
`;
