import axios from './axios';
import { Researcher } from '../shared';

interface Options {
  studyName: string;
  researchers: Researcher[];
}

export default async function updateStudyResearchers(options: Options) {
  const { studyName, researchers } = options;
  await axios.post(`/study/${studyName}/researchers`, { researchers });
}
