import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Row from './Row';

const DotRow: FC = props => {
  return <Row {...props} />;
};

export default styled(DotRow)`
  height: 16px;
  background-color: #e5ebf1;
  padding: 0 40px 0 40px;
  justify-content: space-between;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    top: 50%;
    background-color: #0b1c4a;
    margin-bottom: 0.5px;
  }
`;
