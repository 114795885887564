import { BranchingRule } from "./BranchingRule";
import React, { FC, FormEvent, useEffect, useState } from "react";
import noop from "../../utils/noop";
import Row from "../Row";
import { ScaleBranchingRule } from "../../models";
import Column from "../Column";
import Required from "../Required";

/**
 * triggers if value in between the two two things
 * @param minimumExpectedAnswerValue: number
 * @param maximumExpectedAnswerValue: number
 */
export const ScaleBranchingDefaultValue: ScaleBranchingRule = {
  defaultQuestionId: "",
  destinationQuestionId: "",
  minimumExpectedAnswerValue: 0,
  maximumExpectedAnswerValue: 100,
};

interface ScaleBranchingRuleProps {
  value?: ScaleBranchingRule;
  surveyQuestionIDs: string[];
  onChange?: (value: ScaleBranchingRule) => any;
}

export const ScaleBranchingRuleComponent: FC<ScaleBranchingRuleProps> = (
  props
) => {
  const {
    value = {
      ...ScaleBranchingDefaultValue,
      defaultQuestionId: props.surveyQuestionIDs[0],
    },
    onChange = noop,
    ...rest
  } = props;

  function updateMinimumExpectedValue(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      minimumExpectedAnswerValue: parseInt(event.currentTarget.value),
    });
  }

  function updateMaximumExpectedValue(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      maximumExpectedAnswerValue: parseInt(event.currentTarget.value),
    });
  }

  function selectDefault(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      defaultQuestionId: event.currentTarget.value,
    });
  }

  function selectDestination(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      destinationQuestionId: event.currentTarget.value,
    });
  }

  return (
    <Column rowGap={"5px"}>
      <Row gap={".5em"}>
        <span>If answer is between</span>
        <input
          onChange={updateMinimumExpectedValue}
          type={"number"}
          value={value.minimumExpectedAnswerValue}
          placeholder={"minimum"}
        />
        <span>and</span>
        <input
          onChange={updateMaximumExpectedValue}
          type={"number"}
          value={value.maximumExpectedAnswerValue}
          placeholder={"maximum"}
        />
      </Row>
      <Row gap={".5em"}>
        <span>go to question</span>
        <Required>
          <label>
            <select
              onChange={selectDestination}
              value={value.destinationQuestionId}
            >
              <option value="">---SELECT QUESTION---</option>
              {props.surveyQuestionIDs.map((id, idx) => {
                return (
                  <option key={"survey" + id + idx} value={id}>
                    {id}
                  </option>
                );
              })}
              <option value={"completionPage"}>Completion Page</option>
            </select>
          </label>
        </Required>
      </Row>
      <Row gap={".5em"}>
        <span>Otherwise go to</span>
        <Required>
          <label>
            <select onChange={selectDefault} value={value.defaultQuestionId}>
              <option value="">---SELECT QUESTION---</option>
              {props.surveyQuestionIDs.map((id, idx) => {
                return (
                  <option key={"survey" + id + idx} value={id}>
                    {id}
                  </option>
                );
              })}
              <option value={"completionPage"}>Completion Page</option>
            </select>
          </label>
        </Required>
      </Row>
    </Column>
  );
};
