import styled from "styled-components/macro";

export default styled('span')`
  display: inline;
  label::after {
    content: '*';
    color: red;
  }
  strong::after {
    content: '*';
    color: red;
  }
  p::after {
    content: '*';
    color: red;
  }
  span::after {
    content: '*';
    color: red;
  }
`;
