import React, { FC, FormEvent } from "react";
import noop from "../../utils/noop";
import Row from "../Row";
import { ChoiceBranchingRule } from "../../models";
import Checkbox from "../Checkbox";
import Select from "../Select";
import Column from "../Column";
import Required from "../Required";

export const ChoiceBranchingRuleDefaultValue: ChoiceBranchingRule = {
  defaultQuestionId: "",
  destinationQuestionId: "",
  expectedAnswerValues: [],
};

interface ChoiceBranchingRuleProps {
  value?: ChoiceBranchingRule;
  surveyQuestionIDs: string[];
  choiceQuestionIDs: string[];
  onChange?: (value: ChoiceBranchingRule) => any;
}

export const ChoiceBranchingRuleComponent: FC<ChoiceBranchingRuleProps> = (
  props
) => {
  const {
    value = ChoiceBranchingRuleDefaultValue,
    onChange = noop,
    ...rest
  } = props;

  function checkboxChoice(item: string, selectedItems: string[]) {
    onChange({
      ...value,
      expectedAnswerValues: selectedItems,
    });
  }

  function selectDefault(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      defaultQuestionId: event.currentTarget.value,
    });
  }

  function selectDestination(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      destinationQuestionId: event.currentTarget.value,
    });
  }

  // set up values before return
  let ifPrompt = <span>If choices</span>;
  let skipPrompt = <span>are selected, go to question</span>;
  if (props.choiceQuestionIDs.length < 2) {
    ifPrompt = <span>If choice</span>;
    skipPrompt = <span>is selected, go to question</span>;
  }

  return (
    <Column rowGap={"5px"}>
      <Row gap={".5em"}>
        {ifPrompt}
        <Checkbox
          inputList={props.choiceQuestionIDs}
          selectedItems={value.expectedAnswerValues}
          onChange={checkboxChoice}
        />
      </Row>
      <Row gap={".5em"}>
        {skipPrompt}
        <Required>
          <label>
            <select
              onChange={selectDestination}
              value={value.destinationQuestionId}
            >
              <option value="">---SELECT QUESTION---</option>
              {props.surveyQuestionIDs.map((id, idx) => {
                return (
                  <option key={"survey" + id + idx} value={id}>
                    {id}
                  </option>
                );
              })}
              <option value={"completionPage"}>Completion Page</option>
            </select>
          </label>
        </Required>
      </Row>
      <Row gap={".5em"}>
        <span>Otherwise go to</span>
        <Required>
          <label>
            <select onChange={selectDefault} value={value.defaultQuestionId}>
              <option value="">---SELECT QUESTION---</option>
              {props.surveyQuestionIDs.map((id, idx) => {
                return (
                  <option key={"survey" + id + idx} value={id}>
                    {id}
                  </option>
                );
              })}
              <option value={"completionPage"}>Completion Page</option>
            </select>
          </label>
        </Required>
      </Row>
    </Column>
  );
};
