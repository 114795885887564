import React, { FC } from 'react';
import styled from 'styled-components/macro';
import iconSrc from '../images/calendar_icon.png';

interface CalendarIconProps {
  style?: any;
}

const CalendarIcon: FC<CalendarIconProps> = props => {
  return <img {...props} src={iconSrc} alt="" />;
};

export default styled(CalendarIcon)`
  background-color: white;
  width: 24px;
  border-radius: 2px;
  height: 24px;
`;
