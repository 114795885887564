import axios from './axios';

export type testParticipantOptions = "test" | "actual"
interface Options {
  studyName: string;
  site: string | null;
  participantIds: string[];
  testParticipant?: testParticipantOptions
}

export interface Participant {
  participantId: string;
  studyCode: string;
}

export default async function createParticipants(
  options: Options
): Promise<Participant[]> {
  const response = await axios.post('/participants', options);

  return response.data.participants;
}
