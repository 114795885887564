import { NumberValidator } from "../shared/utils/Validators";
import { ScheduleControlPanelValue } from "../components/ema/ScheduleControlPanel";

export const scheduleControlPanelValidation = (value: ScheduleControlPanelValue) => {
    const daysBetweenError = new NumberValidator(value.daysBetween)
        .noNaN()
        .min(0, "Must be at least 0")
        .validate();
    const daysWithinError = new NumberValidator(value.daysWithin)
        .noNaN()
        .min(0, "Must be at least 0")
        .validate();
    return {daysBetweenError, daysWithinError}

}