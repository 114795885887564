export const schema = {
    "models": {
        "SurveyPrefs": {
            "name": "SurveyPrefs",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "studyName": {
                    "name": "studyName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "studySites": {
                    "name": "studySites",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "activeSensors": {
                    "name": "activeSensors",
                    "isArray": true,
                    "type": {
                        "enum": "ActiveSensors"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "daysUntilDeactivation": {
                    "name": "daysUntilDeactivation",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "gameEnabled": {
                    "name": "gameEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "homeScreenEmaAvailable": {
                    "name": "homeScreenEmaAvailable",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "studySurveyList": {
                    "name": "studySurveyList",
                    "isArray": true,
                    "type": {
                        "nonModel": "StudySurvey"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "maxScreenTimePages": {
                    "name": "maxScreenTimePages",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SurveyPrefs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "studyName"
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "Weekday": {
            "name": "Weekday",
            "values": [
                "MONDAY",
                "TUESDAY",
                "WEDNESDAY",
                "THURSDAY",
                "FRIDAY",
                "SATURDAY",
                "SUNDAY"
            ]
        },
        "ActiveSensors": {
            "name": "ActiveSensors",
            "values": [
                "ACCEL",
                "BATTERY",
                "GPS",
                "APPUSAGE",
                "KEYINPUT",
                "SELFIE",
                "CALLSTATUS",
                "EMA",
                "MUS",
                "MOTIONACTIVITY",
                "KEYNOCONTENT",
                "LIGHT",
                "GPSANON"
            ]
        }
    },
    "nonModels": {
        "TextInfo": {
            "name": "TextInfo",
            "fields": {
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "questionHeader": {
                    "name": "questionHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "questionBody": {
                    "name": "questionBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "skipEnabled": {
                    "name": "skipEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ScaleBranchingRule": {
            "name": "ScaleBranchingRule",
            "fields": {
                "destinationQuestionId": {
                    "name": "destinationQuestionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "defaultQuestionId": {
                    "name": "defaultQuestionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "minimumExpectedAnswerValue": {
                    "name": "minimumExpectedAnswerValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "maximumExpectedAnswerValue": {
                    "name": "maximumExpectedAnswerValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ChoiceBranchingRule": {
            "name": "ChoiceBranchingRule",
            "fields": {
                "destinationQuestionId": {
                    "name": "destinationQuestionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "defaultQuestionId": {
                    "name": "defaultQuestionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expectedAnswerValues": {
                    "name": "expectedAnswerValues",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "BoolBranchingRule": {
            "name": "BoolBranchingRule",
            "fields": {
                "destinationQuestionId": {
                    "name": "destinationQuestionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "defaultQuestionId": {
                    "name": "defaultQuestionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expectedAnswer": {
                    "name": "expectedAnswer",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TimeOfDayBranchingRule": {
            "name": "TimeOfDayBranchingRule",
            "fields": {
                "destinationQuestionId": {
                    "name": "destinationQuestionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "defaultQuestionId": {
                    "name": "defaultQuestionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "minimumExpectedAnswerHour": {
                    "name": "minimumExpectedAnswerHour",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "minimumExpectedAnswerMinute": {
                    "name": "minimumExpectedAnswerMinute",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "maximumExpectedAnswerHour": {
                    "name": "maximumExpectedAnswerHour",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "maximumExpectedAnswerMinute": {
                    "name": "maximumExpectedAnswerMinute",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ScaleRiskRule": {
            "name": "ScaleRiskRule",
            "fields": {
                "alertOnMin": {
                    "name": "alertOnMin",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "alertOnMax": {
                    "name": "alertOnMax",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ScaleQuestion": {
            "name": "ScaleQuestion",
            "fields": {
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "questionHeader": {
                    "name": "questionHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "questionBody": {
                    "name": "questionBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "skipEnabled": {
                    "name": "skipEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "minValue": {
                    "name": "minValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "maxValue": {
                    "name": "maxValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "minimumValueDescription": {
                    "name": "minimumValueDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "maximumValueDescription": {
                    "name": "maximumValueDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "shouldHideRanges": {
                    "name": "shouldHideRanges",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "branchingRule": {
                    "name": "branchingRule",
                    "isArray": false,
                    "type": {
                        "nonModel": "ScaleBranchingRule"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "riskRule": {
                    "name": "riskRule",
                    "isArray": false,
                    "type": {
                        "nonModel": "ScaleRiskRule"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TextChoice": {
            "name": "TextChoice",
            "fields": {
                "textChoiceID": {
                    "name": "textChoiceID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "textToDisplay": {
                    "name": "textToDisplay",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ChoiceRiskRule": {
            "name": "ChoiceRiskRule",
            "fields": {
                "alertOn": {
                    "name": "alertOn",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ChoiceQuestion": {
            "name": "ChoiceQuestion",
            "fields": {
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "questionHeader": {
                    "name": "questionHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "questionBody": {
                    "name": "questionBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "skipEnabled": {
                    "name": "skipEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "multipleChoice": {
                    "name": "multipleChoice",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "textChoices": {
                    "name": "textChoices",
                    "isArray": true,
                    "type": {
                        "nonModel": "TextChoice"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "branchingRule": {
                    "name": "branchingRule",
                    "isArray": false,
                    "type": {
                        "nonModel": "ChoiceBranchingRule"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "riskRule": {
                    "name": "riskRule",
                    "isArray": false,
                    "type": {
                        "nonModel": "ChoiceRiskRule"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TextEntryQuestion": {
            "name": "TextEntryQuestion",
            "fields": {
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "questionHeader": {
                    "name": "questionHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "questionBody": {
                    "name": "questionBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "skipEnabled": {
                    "name": "skipEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "maxCharacterLengthForInput": {
                    "name": "maxCharacterLengthForInput",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "textHint": {
                    "name": "textHint",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "BoolRiskRule": {
            "name": "BoolRiskRule",
            "fields": {
                "alertOn": {
                    "name": "alertOn",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "BooleanQuestion": {
            "name": "BooleanQuestion",
            "fields": {
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "questionHeader": {
                    "name": "questionHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "questionBody": {
                    "name": "questionBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "skipEnabled": {
                    "name": "skipEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "trueValue": {
                    "name": "trueValue",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "falseValue": {
                    "name": "falseValue",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "branchingRule": {
                    "name": "branchingRule",
                    "isArray": false,
                    "type": {
                        "nonModel": "BoolBranchingRule"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "riskRule": {
                    "name": "riskRule",
                    "isArray": false,
                    "type": {
                        "nonModel": "BoolRiskRule"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TimeOfDayQuestion": {
            "name": "TimeOfDayQuestion",
            "fields": {
                "questionId": {
                    "name": "questionId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "questionHeader": {
                    "name": "questionHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "questionBody": {
                    "name": "questionBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "skipEnabled": {
                    "name": "skipEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "defaultHour": {
                    "name": "defaultHour",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "defaultMinute": {
                    "name": "defaultMinute",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "branchingRule": {
                    "name": "branchingRule",
                    "isArray": false,
                    "type": {
                        "nonModel": "TimeOfDayBranchingRule"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "RandomizedDeliveryWindowForWeekday": {
            "name": "RandomizedDeliveryWindowForWeekday",
            "fields": {
                "dayOfWeek": {
                    "name": "dayOfWeek",
                    "isArray": false,
                    "type": {
                        "enum": "Weekday"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "windowStartHour": {
                    "name": "windowStartHour",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "windowEndHour": {
                    "name": "windowEndHour",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "repeatsEveryDay": {
                    "name": "repeatsEveryDay",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ExactDeliverySurveySchedule": {
            "name": "ExactDeliverySurveySchedule",
            "fields": {
                "surveyDeliveryHour": {
                    "name": "surveyDeliveryHour",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "surveyDeliveryMinute": {
                    "name": "surveyDeliveryMinute",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "surveyExpirationHour": {
                    "name": "surveyExpirationHour",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "surveyExpirationMinute": {
                    "name": "surveyExpirationMinute",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "repeatsEveryDay": {
                    "name": "repeatsEveryDay",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ExactDeliveriesForWeekday": {
            "name": "ExactDeliveriesForWeekday",
            "fields": {
                "dayOfWeek": {
                    "name": "dayOfWeek",
                    "isArray": false,
                    "type": {
                        "enum": "Weekday"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "exactDeliveriesForDay": {
                    "name": "exactDeliveriesForDay",
                    "isArray": true,
                    "type": {
                        "nonModel": "ExactDeliverySurveySchedule"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ShuffleSequence": {
            "name": "ShuffleSequence",
            "fields": {
                "startIndex": {
                    "name": "startIndex",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "endIndex": {
                    "name": "endIndex",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SurveyQuestion": {
            "name": "SurveyQuestion",
            "fields": {
                "scale": {
                    "name": "scale",
                    "isArray": false,
                    "type": {
                        "nonModel": "ScaleQuestion"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "choice": {
                    "name": "choice",
                    "isArray": false,
                    "type": {
                        "nonModel": "ChoiceQuestion"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "textEntry": {
                    "name": "textEntry",
                    "isArray": false,
                    "type": {
                        "nonModel": "TextEntryQuestion"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "bool": {
                    "name": "bool",
                    "isArray": false,
                    "type": {
                        "nonModel": "BooleanQuestion"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "timeOfDay": {
                    "name": "timeOfDay",
                    "isArray": false,
                    "type": {
                        "nonModel": "TimeOfDayQuestion"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "info": {
                    "name": "info",
                    "isArray": false,
                    "type": {
                        "nonModel": "TextInfo"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "RandomizedDeliverySurvey": {
            "name": "RandomizedDeliverySurvey",
            "fields": {
                "surveyId": {
                    "name": "surveyId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "notificationHeader": {
                    "name": "notificationHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "notificationBody": {
                    "name": "notificationBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "weekdaysAvailable": {
                    "name": "weekdaysAvailable",
                    "isArray": true,
                    "type": {
                        "enum": "Weekday"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "totalNumberOfDays": {
                    "name": "totalNumberOfDays",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "introStepHeader": {
                    "name": "introStepHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "introStepBody": {
                    "name": "introStepBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "completionStepEnabled": {
                    "name": "completionStepEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "completionStepHeader": {
                    "name": "completionStepHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "completionStepBody": {
                    "name": "completionStepBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "emaQuestions": {
                    "name": "emaQuestions",
                    "isArray": true,
                    "type": {
                        "nonModel": "SurveyQuestion"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "containsBranchingLogic": {
                    "name": "containsBranchingLogic",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "containsShuffledSequence": {
                    "name": "containsShuffledSequence",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "containsRiskAlerts": {
                    "name": "containsRiskAlerts",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "shuffleSequence": {
                    "name": "shuffleSequence",
                    "isArray": false,
                    "type": {
                        "nonModel": "ShuffleSequence"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "multipleSurveyBursts": {
                    "name": "multipleSurveyBursts",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "daysWithinSurveyBurst": {
                    "name": "daysWithinSurveyBurst",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "daysBetweenSurveyBursts": {
                    "name": "daysBetweenSurveyBursts",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "daysBeforeFirstSurveyDelivery": {
                    "name": "daysBeforeFirstSurveyDelivery",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "weekDeliverySchedule": {
                    "name": "weekDeliverySchedule",
                    "isArray": true,
                    "type": {
                        "nonModel": "RandomizedDeliveryWindowForWeekday"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ExactDeliverySurvey": {
            "name": "ExactDeliverySurvey",
            "fields": {
                "surveyId": {
                    "name": "surveyId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "notificationHeader": {
                    "name": "notificationHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "notificationBody": {
                    "name": "notificationBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "weekdaysAvailable": {
                    "name": "weekdaysAvailable",
                    "isArray": true,
                    "type": {
                        "enum": "Weekday"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "totalNumberOfDays": {
                    "name": "totalNumberOfDays",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "introStepHeader": {
                    "name": "introStepHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "introStepBody": {
                    "name": "introStepBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "completionStepEnabled": {
                    "name": "completionStepEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "completionStepHeader": {
                    "name": "completionStepHeader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "completionStepBody": {
                    "name": "completionStepBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "emaQuestions": {
                    "name": "emaQuestions",
                    "isArray": true,
                    "type": {
                        "nonModel": "SurveyQuestion"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "containsBranchingLogic": {
                    "name": "containsBranchingLogic",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "containsShuffledSequence": {
                    "name": "containsShuffledSequence",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "containsRiskAlerts": {
                    "name": "containsRiskAlerts",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "shuffleSequence": {
                    "name": "shuffleSequence",
                    "isArray": false,
                    "type": {
                        "nonModel": "ShuffleSequence"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "multipleSurveyBursts": {
                    "name": "multipleSurveyBursts",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "daysWithinSurveyBurst": {
                    "name": "daysWithinSurveyBurst",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "daysBetweenSurveyBursts": {
                    "name": "daysBetweenSurveyBursts",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "daysBeforeFirstSurveyDelivery": {
                    "name": "daysBeforeFirstSurveyDelivery",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "weekDeliverySchedule": {
                    "name": "weekDeliverySchedule",
                    "isArray": true,
                    "type": {
                        "nonModel": "ExactDeliveriesForWeekday"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "StudySurvey": {
            "name": "StudySurvey",
            "fields": {
                "random": {
                    "name": "random",
                    "isArray": false,
                    "type": {
                        "nonModel": "RandomizedDeliverySurvey"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "exact": {
                    "name": "exact",
                    "isArray": false,
                    "type": {
                        "nonModel": "ExactDeliverySurvey"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.4.4",
    "version": "88467c3c3ac8300992fce6e378d99b61"
};