import React, {FC, FormEvent, useEffect, useState} from 'react';
import Column from "../Column";
import Row from "../Row";
import styled from "styled-components/macro";
import TextArea from "../TextArea";
import Switch from "../Switch";
import noop from "../../utils/noop";
import {BoolBranchingDefaultValue, BoolBranchingRuleComponent} from "./BoolBranchingRule";
import {BooleanQuestion, BoolBranchingRule} from "../../models";
import {checkIfAllValuesUndefined, StringValidator} from "../../shared/utils/Validators";
import {ValidatedInput, ValidatedTextArea} from "../Validation";
import Required from "../Required";
import {branchingRuleReservedValues} from "./BranchingRule";
import { BoolRiskRule } from '../../models'
import { BoolRiskRuleDefaultValue, BoolRiskRuleComponent} from './BoolRiskRule';

interface BooleanQuestionProps {
  value?: BooleanQuestion;
  surveyQuestionIDs: string[];
  currentQuestionIndex: number;
  onChange?: (value: BooleanQuestion) => any;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => any;
  canSwitch?: (value: boolean) => any;
  showRiskToggle?: boolean;
}

export const BooleanQuestionDefaultValue: BooleanQuestion = {
  questionId: "",
  questionHeader: "",
  questionBody: "",
  skipEnabled: true,
  trueValue: "Yes",
  falseValue: "No",
  riskRule: undefined,
  branchingRule: undefined
}
const BooleanQuestionComponent: FC<BooleanQuestionProps> = props => {
  const {
    value = BooleanQuestionDefaultValue,
    onChange = noop,
    canSwitch = noop,
    showRiskToggle,
    ...rest
  } = props;
  const [validation, setValidation] = useState<Record<string, string | undefined>>({});

  const nextQuestion = props.surveyQuestionIDs.length > props.currentQuestionIndex+1 ? props.surveyQuestionIDs[props.currentQuestionIndex+1] : undefined

  useEffect(() => {
    let validationIsGood = checkIfAllValuesUndefined(validation);
    let validationChecks =
    [ new StringValidator(value.questionId).min(1).validate(),
      new StringValidator(value.questionHeader ?? '').min(1).validate()
    ];
    if (value.branchingRule) {
      validationChecks.push(
        new StringValidator(value.branchingRule.defaultQuestionId).noEmpty().validate(),
        new StringValidator(value.branchingRule.destinationQuestionId).noEmpty().validate()
      )
    }
    console.log("validationChecks", validationChecks)
    validationChecks.forEach((value) => {
      validationIsGood = validationIsGood && value === undefined ;
    });
    canSwitch(validationIsGood);
  },)


  function handleQuestionIdChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value, 'QuestionID must contain at least one character')
      .alphaNum('Question ID can only contain of numbers of letters.')
      .min(1, 'Question ID must contain at least one character.')
      .unique(props.surveyQuestionIDs, 'Question IDs must be unique (match found for another question)', props.currentQuestionIndex)
      .unique(branchingRuleReservedValues, 'This value is reserved and cannot be used as a Question ID')
      .validate();
    setValidation({
      ...validation,
      questionId: error
    });
    onChange({
      ...value,
      questionId: event.currentTarget.value
    });
  }

  function handleQuestionHeaderChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value)
      .min(1, 'Question Header must contain at least one character')
      .noSpace('Question Header value cannot contain only spaces')
      .validate();
    setValidation({
      ...validation,
      questionHeader: error
    });
    onChange({
      ...value,
      questionHeader: event.currentTarget.value
    });
  }

  function handleBodyChange(event: FormEvent<HTMLTextAreaElement>) {
    onChange({
      ...value,
      questionBody: event.currentTarget.value
    });
  }

  function handleTrueValueChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value)
      .min(1, 'True value must contain at least one character')
      .noSpace('True value cannot contain only spaces')
      .validate();
    setValidation({
      ...validation,
      trueValue: error
    });
    onChange({
      ...value,
      trueValue: event.currentTarget.value
    });
  }

  function handleFalseValueChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value)
      .min(1, 'False value must contain at least one character')
      .noSpace('False Value cannot only spaces')
      .validate();
    setValidation({
      ...validation,
      falseValue: error
    });
    onChange({
      ...value,
      falseValue: event.currentTarget.value
    });
  }

  function toggleSkip(newState: boolean) {
    onChange({
      ...value,
      skipEnabled: newState
    });
  }

  function toggleLogic(newState: boolean) {
    if (nextQuestion && newState) {
      onChange({
        ...value,
        branchingRule: {
          ...BoolBranchingDefaultValue,
          defaultQuestionId: "",
          destinationQuestionId: ""
        }
      });
    } else {
      onChange({
        ...value,
        branchingRule: undefined
      });
    }
  }

  function toggleRisk (newState: boolean) {
    onChange({
      ...value,
      riskRule: newState ? {
        ...BoolRiskRuleDefaultValue
      } : undefined
    })
  }

  function updateBranchingRule(rule: BoolBranchingRule) {
    onChange({
      ...value,
      branchingRule: rule
    });
  }

  function updateRiskRule(rule: BoolRiskRule) {
    onChange({
      ...value,
      riskRule: rule
    })
  }

  return (
    <Column>
      <h2>Boolean Question</h2>
      <Required>
        <label>Question ID</label>
      </Required>
      <ValidatedInput
        message={validation.questionId}
        value={value.questionId}
        onChange={handleQuestionIdChange}
        placeholder="QuestionID"
      />
      <Required>
        <label>Question Header</label>
      </Required>
      <ValidatedInput
        message={validation.questionHeader}
        value={value.questionHeader!}
        onChange={handleQuestionHeaderChange}
        placeholder="Question Header"
      />
      <label>Question Body</label>
      <ValidatedTextArea
        message={validation.questionBody}
        placeholder="Question Body"
        value={value.questionBody ?? undefined}
        onChange={handleBodyChange}
      />
      <label>True Value</label>
      <ValidatedInput
        message={validation.trueValue}
        value={value.trueValue!}
        onChange={handleTrueValueChange}
      />
      <label>False Value</label>
      <ValidatedInput
        message={validation.falseValue}
        value={value.falseValue!}
        onChange={handleFalseValueChange}
      />
      {showRiskToggle && 
      <Row>
        <Switch
          checked={value.riskRule !== undefined && value.riskRule !== null}
          onChange={toggleRisk}
        />
        <label>Risk Alert</label>
      </Row>
      }
      {showRiskToggle && value.riskRule && 
        <Row>
          <BoolRiskRuleComponent
            value={value.riskRule}
            onChange={updateRiskRule}
          />
        </Row>
      }
      <Row>
        <Switch
          checked={value.skipEnabled}
          onChange={toggleSkip}
        />
        <label>Skippable</label>
      </Row>
      {nextQuestion &&
      <Row>
        <Switch
          checked={value.branchingRule !== undefined && value.branchingRule !== null}
          onChange={toggleLogic}
        />
        <label>Logic</label>
      </Row>
      }
      {value.branchingRule &&
        <Row>
          <BoolBranchingRuleComponent
            surveyQuestionIDs={props.surveyQuestionIDs.slice(props.currentQuestionIndex+1)}
            value={value.branchingRule || undefined}
            onChange={updateBranchingRule}
          />
        </Row>
      }
    </Column>
  );
}


export default styled(BooleanQuestionComponent)`
  width: 500002px;
  max-width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const QuestionBooleanInput = styled(TextArea as any)`
  width: 100%;
  margin-bottom: 16px;
`;

const Spacer = styled('div')`
  flex-grow: 1;
`;

const BooleanChoice = styled('li')`
    margin-bottom: .5em;
`;
