import fail from "./utils/fail";
import { ActiveSensors } from "../models";

export const SENSOR_RISK_EMA = "RISK";
export const SENSOR_DAILY = "DAILY";
export const SENSOR_SCREENTIME = "DeviceUsageEntry";

export type Sensor =
  | ActiveSensors
  | typeof SENSOR_RISK_EMA
  | typeof SENSOR_DAILY
  | typeof SENSOR_SCREENTIME;

type SensorDescriptor = {
  id: Sensor;
  name: string;
  studyVariableId?: string; // Used to parse the `includedSensors` column in the `studyVariables` table
  tracked: boolean; // <- I don't see this used anywhere... can we get rid of it?
};

// Descriptions of sensors used when they need to be displayed
export const sensorDescriptions: SensorDescriptor[] = [
  {
    id: ActiveSensors.ACCEL,
    name: "Accelerometer",
    studyVariableId: "accel",
    tracked: true,
  },
  {
    id: ActiveSensors.BATTERY,
    name: "Battery State",
    studyVariableId: "battery",
    tracked: true,
  },
  {
    id: ActiveSensors.CALLSTATUS,
    name: "Call Status",
    studyVariableId: "call",
    tracked: true,
  },
  {
    id: ActiveSensors.EMA,
    name: "EMA",
    studyVariableId: "ema",
    tracked: true,
  },
  {
    id: ActiveSensors.GPS,
    name: "GPS",
    studyVariableId: "gps",
    tracked: true,
  },
  {
    id: ActiveSensors.KEYINPUT,
    name: "Keystroke Data",
    studyVariableId: "keyboard",
    tracked: true,
  },
  {
    id: ActiveSensors.KEYNOCONTENT,
    name: "Keyboard Usage",
    studyVariableId: "keynocontent",
    tracked: true,
  },
  {
    id: ActiveSensors.MUS,
    name: "Music Choice",
    studyVariableId: "music",
    tracked: true,
  },
  {
    id: SENSOR_RISK_EMA,
    name: "Risk Survey",
    studyVariableId: "risk_ema",
    tracked: true,
  },
  {
    id: SENSOR_DAILY,
    name: "Daily EMA",
    studyVariableId: "daily",
    tracked: true,
  },
  {
    id: ActiveSensors.LIGHT,
    name: "Light Sensor",
    studyVariableId: "light",
    tracked: true,
  },
  {
    id: ActiveSensors.APPUSAGE,
    name: "App Usage",
    studyVariableId: "AppUsage",
    tracked: false,
  },
  {
    id: ActiveSensors.MOTIONACTIVITY,
    name: "Motion",
    studyVariableId: "motion",
    tracked: true,
  },
  {
    id: SENSOR_SCREENTIME,
    name: "Screen Time",
    studyVariableId: "ScreenTime",
    tracked: true,
  },
];

const sensorTypeSet = new Set<string>(
  sensorDescriptions.map((sensor) => sensor.id)
);

// Map study variable sensor names to sensor names used in upload events
const sensorStudyVariableTypeMap = new Map<string, Sensor>();
for (const sensor of sensorDescriptions) {
  if (sensor.studyVariableId) {
    sensorStudyVariableTypeMap.set(sensor.studyVariableId, sensor.id);
  }
}

export function isSensor(value: unknown): value is ActiveSensors {
  return typeof value === "string" && sensorTypeSet.has(value);
}

export function parseSensor(value: any): ActiveSensors {
  const parsedValue = sensorStudyVariableTypeMap.has(value)
    ? sensorStudyVariableTypeMap.get(value)
    : value;

  if (!isSensor(parsedValue)) {
    throw fail(`Invalid sensor "${parsedValue}".`);
  }

  return parsedValue;
}

export function getSensorStudyVariableId(sensor: ActiveSensors): string | null {
  const sensorDesc = sensorDescriptions.find(
    (other) => other.id === sensor || other.studyVariableId === sensor
  );
  if (!sensorDesc || !sensorDesc.studyVariableId) {
    return sensor;
  }
  return sensorDesc.studyVariableId;
}
