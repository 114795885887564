import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';

export interface BgImageProps {
  src: string;
  alt: string;
  fill?: 'contain' | 'cover';
}

const BgImage: FC<BgImageProps> = props => {
  const { src, alt, fill, ...rest } = props;

  return <Column {...rest} role="img" aria-label={alt} />;
};

export default styled(BgImage)<BgImageProps>`
  background-image: url('${props => props.src}');
  background-position: center;
  background-size: ${props => props.fill || 'contain'};
  background-repeat: no-repeat;
`;
