import React, { FC } from 'react';
import styled from 'styled-components/macro';
import locationIconSrc from '../images/location_icon.png';

const LocationIcon: FC = props => {
  return <img {...props} src={locationIconSrc} alt="" />;
};

export default styled(LocationIcon)`
  height: 18px;
  width: 16px;
`;
