import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import DropdownMenuHeader from './DropdownMenuHeader';
import FloatingMenu from './FloatingMenu';
import Column from './Column';

interface Props {
  label?: string | ((open: boolean) => any);
  style?: any;
  leftAlign?: boolean;
}

const DropdownMenu: FC<Props> = props => {
  const { label, children, ...rest } = props;
  const [open, setOpen] = useState(false);
  const labelContents = typeof label === 'function' ? label(open) : label;

  function handleToggleOpen() {
    if (!open) {
      setOpen(!open);
    }
  }

  function handleClickOutside() {
    setOpen(false);
  }

  return (
    <Column {...rest}>
      <DropdownMenuHeader
        active={open}
        onClick={handleToggleOpen}
        data-open={open}
      >
        {labelContents}
      </DropdownMenuHeader>
      {open && (
        //@ts-ignore
        <FloatingMenu onClickOutside={handleClickOutside}>
          {children}
        </FloatingMenu>
      )}
    </Column>
  );
};

export default styled(DropdownMenu)<Props>`
  ${FloatingMenu} {
    position: absolute;
    bottom: -10px;
    transform: translate(0, 100%);
    z-index: 2;
    ${props =>
      props.leftAlign
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}
  }
`;
