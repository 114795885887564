import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { PageComponent } from '../components/Page';
import logout from '../api/logout';

const Logout: PageComponent = () => {
  useEffect(() => {
    logout();
  }, []);
  return null;
};

export default styled(Logout)``;
