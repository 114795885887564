import { SurveyQuestion } from "../../models";
import { BranchingRules } from "../Types";

const getBranchingRule = (
  question: SurveyQuestion
): BranchingRules | undefined => {
  if (question.bool?.branchingRule) {
    return { ...question.bool.branchingRule };
  }
  if (question.choice?.branchingRule) {
    return { ...question.choice.branchingRule };
  }
  if (question.info) {
    return undefined;
  }
  if (question.scale?.branchingRule) {
    return { ...question.scale.branchingRule };
  }
  if (question.textEntry) {
    return undefined;
  }
  if (question.timeOfDay?.branchingRule) {
    return { ...question.timeOfDay.branchingRule };
  }
  return undefined;
};

export default getBranchingRule;
