import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Modal, { ModalProps } from './Modal';
import Row from './Row';
import BlockButton from './BlockButton';
import noop from '../utils/noop';
import Spinner from './Spinner';
import getStudyDetails from '../api/getStudyDetails';
import DeviceForm, { Value as DeviceFormValue } from './DeviceForm';
import { DashboardUser } from '../shared';
import { DashboardSettings } from '../shared/StudyVariable'

interface Props extends ModalProps {
  studyName: string;
  user: DashboardUser;
  onSubmit?: (value: DeviceFormValue) => any;
}

const ManageDevicesModal: FC<Props> = props => {
  const { studyName, user, onSubmit = noop, ...rest } = props;
  const [loading, setLoading] = useState(true);
  const [
    deviceFormValue,
    setDeviceFormValue
  ] = useState<DeviceFormValue | null>(null);
  const [dashboardSettings, setDashboardSettings] = useState<DashboardSettings>()

  useEffect(() => {
    load()
      .catch(error => console.error(error))
      .finally(() => {
        setLoading(false);
      });

    async function load() {
      const studyDetails = await getStudyDetails(studyName);
      const { devices, dashboardSettings } = studyDetails;

      setDashboardSettings(dashboardSettings)

      setDeviceFormValue({
        devices: devices.map(device => ({
          deviceId: device.deviceId,
          participantId: device.participantId,
          deactivated: !!device.deactivationDate,
          wasDeactivated: !!device.deactivationDate
        }))
      });
    }
  }, [studyName]);


  function handleSubmitClick() {
    if (deviceFormValue) {
      onSubmit(deviceFormValue);
    }
  }

  if (!deviceFormValue || loading) {
    return (
      <Modal {...rest}>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </Modal>
    );
  }
  if (deviceFormValue.devices.length === 0) {
    return (
    <Modal {...rest}>
      <h2>No Devices found in {studyName}</h2>
    </Modal>
    )
  }
  return (
    <Modal {...rest}>
      <DeviceForm value={deviceFormValue} onChange={setDeviceFormValue} dashboardSettings={dashboardSettings} studyName={studyName}/>
      <ButtonRow>
        <BlockButton onClick={handleSubmitClick}>Save</BlockButton>
      </ButtonRow>
    </Modal>
  );
}

export default styled(ManageDevicesModal)`
  min-width: 750px;
`;

const SpinnerContainer = styled(Row as any)`
  justify-content: center;
  align-items: center;
  height: 400px;
`;


const ButtonRow = styled(Row as any)`
  margin-top: 100px;
  justify-content: center;

  ${BlockButton} {
    width: 237px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 8px;
  }
`;
