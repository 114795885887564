import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface TableProps {
  color?: string;
}

const Table: FC = props => {
  return <table {...props} />;
};

export default styled(Table)<TableProps>`
  background-color: white;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  width: 100%;

  tbody {
    overflow: auto;
  }

  tbody,
  thead {
    tr {
      border-bottom: 2px solid ${props => props.color};
    }

    th,
    td {
      padding: 13px 35px 17px 35px;
      text-align: left;
    }

    th {
      color: #0b1c4a;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 17px;
    }

    td {
      color: #0b1c4a;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.2px;
      line-height: 16px;
    }
  }
`;
