import React, { useState, FC, FormEvent } from "react";
import { ScaleQuestion, ScaleRiskRule } from "../../models";
import { NumberValidator } from "../../shared/utils/Validators";
import noop from "../../utils/noop";
import Column from "../Column";
import Required from "../Required";
import Row from "../Row";
import { ValidatedInput } from "../Validation";

export const ScaleRiskRuleDefaultValue: ScaleRiskRule = {
    alertOnMin: 0,
    alertOnMax: 0
}

interface ScaleRiskRuleProps {
    value: ScaleRiskRule;
    validation: Record<string, string | undefined>;
    onChange?: (value: ScaleRiskRule) => any;
    questionValue: ScaleQuestion
}

export const ScaleRiskRuleComponent: FC<ScaleRiskRuleProps> = props => {
    const {
        value = {...ScaleRiskRuleDefaultValue},
        validation,
        onChange = noop,
        questionValue,
        ...rest
    } = props

    function updateMinRiskAlert(event: FormEvent<HTMLInputElement>) {
        onChange({
            ...value,
            alertOnMin: parseInt(event.currentTarget.value)
        })
    }

    function updateMaxRiskAlert(event: FormEvent<HTMLInputElement>) {
        onChange({
            ...value,
            alertOnMax: parseInt(event.currentTarget.value)
        })
    }

    return (
        <Column rowGap={'5px'}>
            <Row gap={'.5em'}>
                <span>Risk Alert if answer is between<br/>(endpoints are included)</span>
                <ValidatedInput
                    message={validation.alertOnMin}
                    onChange={updateMinRiskAlert}
                    type={'number'}
                    value={value.alertOnMin}
                />
                <span>and</span>
                <ValidatedInput
                    message={validation.alertOnMax}
                    onChange={updateMaxRiskAlert}
                    type={'number'}
                    value={value.alertOnMax}
                    placeholder={'maximum'}
                />
            </Row>
        </Column>
    )
}