import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { PieChart, Pie, Cell } from 'recharts';
import Row from './Row';
import StatusIcon, { ISSUE, SUCCESS, FAIL, DEACT, COMPLETE } from './StatusIcon';
import Column from './Column';

interface Props {
  loading?: boolean;
  stoppedCount: number;
  issueCount: number;
  noIssueCount: number;
  completeCount: number | null;
  deactivatedCount: number;
  onStoppedClick?: () => any;
  onIssueClick?: () => any;
  onNoIssueClick?: () => any;
  onDeactivatedClick?: () => any;
  onCompleteClick?: () => any;
}

const width = 400;

const UploadStatusPieChart: FC<Props> = props => {
  const {
    loading,
    stoppedCount,
    issueCount,
    noIssueCount,
    deactivatedCount,
    completeCount,
    onStoppedClick,
    onIssueClick,
    onNoIssueClick,
    onDeactivatedClick,
    onCompleteClick,
    ...rest
  } = props;

  const data = [
    {
      name: 'stopped uploading',
      value: stoppedCount,
      color: '#E52B2A',
      onClick: onStoppedClick
    },
    {
      name: 'uploading with issues',
      value: issueCount,
      color: '#06DDDD',
      onClick: onIssueClick
    },
    {
      name: 'uploading without issues',
      value: noIssueCount,
      color: '#0075e1',
      onClick: onNoIssueClick
    },
    {
      name: 'complete',
      value: completeCount,
      color: '#F1BB2B',
      onClick: onDeactivatedClick
    },
    {
      name: 'deactivated',
      value: deactivatedCount,
      color: '#F4EE68',
      onClick: onDeactivatedClick
    },
  ];

  return (
    <Row {...rest}>
      <PieChart width={width} height={width}>
        <Pie
          data={data}
          x={0}
          y={0}
          innerRadius={120}
          outerRadius={width / 2 - 5}
          fill="#8884d8"
          stroke="none"
          dataKey="value"
        >
          {data.map(entry => (
            <Cell
              stroke="#FFFFFF"
              strokeWidth={3}
              onClick={entry.onClick}
              key={entry.name}
              fill={entry.color}
            />
          ))}
        </Pie>
      </PieChart>
      <LabelColumn>
        <LabelRow onClick={onStoppedClick}>
          <StatusIcon type={FAIL} />
          <span>{stoppedCount} stopped uploading</span>
        </LabelRow>
        <LabelRow onClick={onIssueClick}>
          <StatusIcon type={ISSUE} />
          <span>{issueCount} uploading with issues</span>
        </LabelRow>
        <LabelRow onClick={onNoIssueClick}>
          <StatusIcon type={SUCCESS} />
          <span>{noIssueCount} uploading without issues</span>
        </LabelRow>
        { completeCount !== null  && (
            <LabelRow onClick={onCompleteClick}>
              <StatusIcon type={COMPLETE} />
              <span>{completeCount} Complete </span>
            </LabelRow>
        )}
        <LabelRow onClick={onDeactivatedClick}>
          <StatusIcon type={DEACT} />
          <span>{deactivatedCount} Deactivated</span>
        </LabelRow>
      </LabelColumn>
    </Row>
  );
};

export default styled(UploadStatusPieChart)<Props>`
  height: ${width}px;
  background-color: white;
  opacity: ${props => (props.loading ? 0 : 1)};
  transition: opacity 0.5s;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;

const LabelColumn = styled(Column as any)<{ hidden?: boolean }>`
  margin-left: 130px;
  align-self: center;

  @media (max-width: 1200px) {
    margin-left: 0;
    margin-top: 130px;
    align-self: auto;
  }
`;

const LabelRow = styled(Row as any)`
  align-items: center;
  color: #0b1c4a;
  font-size: 32px;
  line-height: 40px;
  cursor: pointer;

  img {
    width: 36px;
    height: 36px;
    margin-right: 36px;
  }

  & + & {
    margin-top: 32px;
  }
`;
