import React from 'react';

/**
 * @param destinationQuestionId: string: quesitonId to go to if the condition _is_ met
 * @param defaultQuestionId: string: questionId to go to if the condition is _not_ met
 */
export interface BranchingRule {
  destinationQuestionId: string,
  defaultQuestionId: string
}

export const branchingRuleReservedValues = ['completionPage'];

