import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';
import Modal, { ModalProps } from './Modal';
import StudyForm, {
  StudyFormValue,
  defaultValue as studyFormDefaultValue
} from './StudyForm';
import ResearcherForm, {
  ResearcherFormValue,
  defaultValue as researchFormDefaultValue
} from './ResearcherForm';
import Row from './Row';
import GroupButton from './GroupButton';
import BlockButton from './BlockButton';
import isEmail from '../utils/isEmail';
import isBlank from '../utils/isBlank';
import {isLowerAlphanumeric} from "../shared/utils/isAlphanumeric";
import noop from '../utils/noop';

export type AddStudyModalValue = StudyFormValue & ResearcherFormValue;

interface Props extends ModalProps {
  onSubmit?: (value: AddStudyModalValue) => any;
}

const SETTINGS_TAB = 'SETTINGS';
const RESEARCHERS_TAB = 'RESEARCHERS';
type Tab = typeof SETTINGS_TAB | typeof RESEARCHERS_TAB;

const AddStudyModal: FC<Props> = props => {
  const { onSubmit = noop, ...rest } = props;
  const [tab, setTab] = useState<Tab>(SETTINGS_TAB);
  const [studyFormValue, setStudyFormValue] = useState(studyFormDefaultValue);
  const [researchFormValue, setResearchFormValue] = useState(
    researchFormDefaultValue
  );

  function handleSettingsClick() {
    setTab(SETTINGS_TAB);
  }

  function handleResearchersClick() {
    setTab(RESEARCHERS_TAB);
  }

  function handleSubmitClick() {
    // Validate study name
    if (isBlank(studyFormValue.studyName)) {
      setTab(SETTINGS_TAB);
      alert('Study name cannot be blank.');
      return;
    }

    if (!isLowerAlphanumeric(studyFormValue.studyName)) {
      alert('A site name can only contain numbers and letters');
      return false;
    }

    // Valdate researcher email addresses
    for (const researcher of researchFormValue.researchers) {
      if (isBlank(researcher.email)) {
        setTab(RESEARCHERS_TAB);
        alert(`Researcher email cannot be blank.`);
        return;
      }
      if (!isEmail(researcher.email)) {
        setTab(RESEARCHERS_TAB);
        alert(`Invalid email "${researcher.email}"`);
        return;
      }
    }

    onSubmit({ ...researchFormValue, ...studyFormValue });
  }

  return (
    <Modal {...rest}>
      <TabRow>
        <GroupButton
          dark
          active={tab === SETTINGS_TAB}
          onClick={handleSettingsClick}
        >
          Settings
        </GroupButton>
        <GroupButton
          dark
          active={tab === RESEARCHERS_TAB}
          onClick={handleResearchersClick}
        >
          Researchers
        </GroupButton>
      </TabRow>
      {tab === SETTINGS_TAB && (
        <StudyForm value={studyFormValue} onChange={setStudyFormValue} />
      )}
      {tab === RESEARCHERS_TAB && (
        <ResearcherForm
          value={researchFormValue}
          onChange={setResearchFormValue}
          allSites={studyFormValue.studySites}
        />
      )}
      <ButtonRow>
        <BlockButton onClick={handleSubmitClick}>Save</BlockButton>
      </ButtonRow>
    </Modal>
  );
};

export default styled(AddStudyModal)`
  width: 1200px;
  padding: 16px 28px 84px 28px;
`;

const TabRow = styled(Row as any)`
  max-width: 691px;
  margin-bottom: 36px;
`;

const ButtonRow = styled(Row as any)`
  margin-top: 100px;
  justify-content: center;

  ${BlockButton} {
    width: 237px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 8px;
  }
`;
