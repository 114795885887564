import React, { FC, FormEvent, useState } from 'react';
import styled from 'styled-components/macro';
import { QRCode } from 'react-qr-svg';
import CreateParticipantForm, {
  Props as CreateParticipantFormProps
} from './CreateParticipantForm';
import Modal, { ModalProps } from './Modal';
import createParticipants, { Participant, testParticipantOptions } from '../api/createParticipants';
import BlockButton from './BlockButton';
import SpinnerOverlay from './SpinnerOverlay';

type Props = CreateParticipantFormProps &
  ModalProps & {
    className?: string;
  };

export * from './CreateParticipantForm';

const CreateParticipantModal: FC<Props> = props => {
  const { study, site="all", onClose, className, ...rest } = props;
  const [savedParticipant, setSavedParticipant] = useState<Participant[]>()
  const [submittng, setSubmitting] = useState<boolean>(false)

  const plural = savedParticipant && savedParticipant.length > 1;
  const participantsWithExtras = (savedParticipant || []).map(participant => {
    const formattedCode = formatParticipantCode(participant.studyCode);
    return {
      ...participant,
      formattedCode
    };
  });

  const handleAddParticipantSubmit = async (participantID: string, testParticipant: testParticipantOptions) => {
    if (!participantID)
      return
    try {
      setSubmitting(true)
      const participants = await createParticipants({
        testParticipant,
        studyName: study,
        site: site === 'all' ? null : site,
        participantIds: [participantID]
      });
      setSavedParticipant(participants)
    }
    catch (err) {
      //@ts-ignore
      alert(err.message)
    }
    finally {
      setSubmitting(false)
    }
  }

  if(submittng) {
    return <SpinnerOverlay/>
  }

  if (savedParticipant) {
    return (
      <Modal className={className} onClose={onClose}>
        <AddedHeader>Participant{plural ? 's' : ''} Added!</AddedHeader>
        <AddedTable>
          <thead>
            <tr>
              <th>Participant ID</th>
              <th>Participant Code</th>
            </tr>
          </thead>
          <tbody>
            {participantsWithExtras.map(participant => (
              <tr key={participant.studyCode}>
                <td>{participant.participantId}</td>
                <td>
                  {participant.formattedCode}
                </td>
                <td>
                  <QRCode
                    value={participant.studyCode}
                    style={{ width: 100 }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot />
        </AddedTable>
        <CloseButton light onClick={onClose}>
          Close
        </CloseButton>
      </Modal>
    );
  } else {
    return (
      <Modal className={className} onClose={onClose}>
        <CreateParticipantForm site={site} onSubmit={handleAddParticipantSubmit} study={study} {...rest} />
      </Modal>
    );
  }
};

export default styled(CreateParticipantModal)`
  background-color: #1378de;
`;

function formatParticipantCode(code: string): string {
  const chars = code.split('');
  let result = '';
  for (let i = 0; i < chars.length; i += 4) {
    result +=
      (chars[i] || '') +
      (chars[i + 1] || '') +
      (chars[i + 2] || '') +
      (chars[i + 3] || '') +
      ' ';
  }
  return result.toUpperCase();
}

const AddedHeader = styled('h1')`
  font-size: 32px;
  color: white;
  margin-bottom: 30px;
  text-align: center;
`;

const AddedTable = styled('table')`
  color: white;
  min-width: 500px;

  td,
  th {
    height: 50px;
  }

  th {
    text-align: left;
  }

  a {
    color: white;
  }
`;

const CloseButton = styled(BlockButton as any)`
  margin-top: 30px;
  width: 120px;
  align-self: center;
`;
