import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import Overlay from './Overlay';
import Column from './Column';
import BlankButton from './BlankButton';

// All modals are rendered into this element
const modalRootEl = document.createElement('div');
modalRootEl.id = 'modal-root';
document.body.appendChild(modalRootEl);

export interface ModalProps {
  disableClose?: boolean;
  onClose?: () => any;
  className?: string;
}

class Modal extends Component<ModalProps> {
  private prevOverflow: string = 'auto';

  // Disable body overflow when modal mounts. Re-enable it on unmount.
  componentDidMount() {
    this.prevOverflow = document.body.style.overflow || 'auto';

    document.body.style.overflow = 'none';
  }

  componentWillUnmount() {
    document.body.style.overflow = this.prevOverflow;
  }

  render() {
    const { disableClose, onClose, children, ...rest } = this.props;

    // Modals are rendered into the root of the page regardless of their position in the element tree.
    // This enables nesting modals.
    return ReactDOM.createPortal(
      <Overlay onClick={disableClose ? null : onClose}>
        <Column {...rest}>
          {!disableClose && (
            <CloseButton onClick={onClose}>{'\u2715' /* ✕ */}</CloseButton>
          )}
          {children}
        </Column>
      </Overlay>,
      modalRootEl
    );
  }
}

export default styled(Modal)`
  position: absolute;
  top: 120px;
  margin-bottom: 120px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #e5ebf1;
  z-index: 30000000;
  border-radius: 7px;
  padding: 50px;
  max-width: 100%;
`;

const CloseButton = styled(BlankButton as any)`
  position: absolute;
  right: 13px;
  top: 13px;
  width: 17px;
  height: 17px;
  line-height: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #2bdfe0;
  font-size: 15px;
`;
