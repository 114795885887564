import React, { FC, useState, useEffect, ChangeEvent } from "react";
import Page, { PageComponent } from "../components/Page";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import Column from "../components/Column";
import styled from "styled-components/macro";
import Row, { Spacer } from "../components/Row";
import {
  DuplicateButton,
  EMAButton,
  EMARow,
  EMATable,
  navigateToCreateStudy,
} from "./StudyCreator";
import { ListSurveyPrefssQuery } from "../API";
import { SurveyPrefs } from "../models";
import BlockButton from "../components/BlockButton";
import Alert, { AlertProps } from "../components/Alert";
import SpinnerOverlay from "../components/SpinnerOverlay";
import nanoid from "nanoid";
import { getAssignedStudiesDetail } from "../api/getAssignedStudies";

// exported as StudyListPage when styled below
const StudyListPageComponent: PageComponent = (props) => {
  return (
    <Page {...props}>
      <StudyList />
    </Page>
  );
};

const StudyList: FC = (props) => {
  const [studies, setStudies] = useState<SurveyPrefs[]>([]);
  const [loading, setLoading] = useState(true);
  const [alertProps, setAlertProps] = useState<AlertProps | null>(null);

  useEffect(() => {
    fetchSurveys().finally(() => setLoading(false));
  }, []);

  async function fetchSurveys() {
    setLoading(true);
    const sortedStudyDetails = (await getAssignedStudiesDetail()).sort((a, b) =>
      a.study.studyName > b.study.studyName ? 1 : -1
    );
    const sortedStudies = sortedStudyDetails.map(
      (studyDetail) => studyDetail.study
    );
    setStudies(sortedStudies);
    setLoading(false);
  }

  function createNew(event: ChangeEvent<HTMLButtonElement>) {
    event.preventDefault();
    navigateToCreateStudy(true);
  }

  const handleStudySelection = (idx: number) => async (
    _: ChangeEvent<HTMLButtonElement>
  ) => {
    await navigateToCreateStudy(false, studies[idx], true);
  };

  /**
   *
   * @param idx
   * @returns
   */
  const handleStudyDuplicate = (idx: number) => (
    _: ChangeEvent<HTMLButtonElement>
  ) => {
    const duplicate = {
      ...studies[idx],
      id: nanoid(),
      studyName: studies[idx].studyName + "1",
      version: 0,
    };
    // Remove keys from dynamo
    ["_version", "_deleted", "_lastChangedAt", "createdAt"].forEach(
      (extraKey) => delete duplicate[extraKey as keyof SurveyPrefs]
    );
    navigateToCreateStudy(true, duplicate);
  };

  return (
    <StudyColumn {...props}>
      {alertProps && (
        <Alert
          onSubmit={(response) => setAlertProps(null)}
          titleText={alertProps.titleText}
          message={alertProps.message}
        />
      )}
      {loading && <SpinnerOverlay />}
      {!loading && (
        <Column zIndex={1}>
          <CenteredRow>
            <h2>Open or Duplicate Study</h2>
          </CenteredRow>
          <EMATable>
            {studies.map((item, idx) => {
              const lastItem = studies.length - 1;
              return (
                <EMARow key={idx} last={idx === lastItem}>
                  <EMAButton onClick={handleStudySelection(idx)}>
                    Open {item.studyName}
                  </EMAButton>
                  <Spacer />
                  <DuplicateButton onClick={handleStudyDuplicate(idx)}>
                    <u>Duplicate</u>
                  </DuplicateButton>
                </EMARow>
              );
            })}
          </EMATable>
          <NewStudyButton onClick={createNew}>Create New Survey</NewStudyButton>
        </Column>
      )}
    </StudyColumn>
  );
};

export default styled(StudyList)``;

export const StudyListPage = styled(StudyListPageComponent)`
  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
  justify-content: center;
  align-items: center;
`;

const NewStudyButton = styled(BlockButton as any)`
  margin-top: 12px;
`;

const StudyColumn = styled(Column as any)`
  background-color: #e5ebf1;
  z-index: 1;
  border-radius: 7px;
  padding: 50px;
`;

const CenteredRow = styled(Row as any)`
  justify-content: center;
`;
