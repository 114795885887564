import React, { FC } from 'react';
import styled from 'styled-components/macro';
import BgImage from './BgImage';
import logoSrc from '../images/logo.png';

const EARSBunnyLogo: FC = props => {
  return <BgImage {...props} src={logoSrc} alt="Ears" />;
};

export default styled(EARSBunnyLogo)`
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
`;
