import React, { FC } from 'react';
import { useMsal } from "@azure/msal-react";
import styled from 'styled-components/macro';
import BlockButton from './BlockButton';
import { loginRequest } from "../ssoAuthConfig";

const SSOSignInButton : FC = () => {
	const { instance } = useMsal();

	const handleLogin = (event: React.FormEvent<HTMLButtonElement>) => {
		event.preventDefault();
		instance.loginRedirect(loginRequest);
	}
	return (
		<SignInButton onClick={(event: React.FormEvent<HTMLButtonElement>) => handleLogin(event)}>Sign in with SSO</SignInButton>
	)
};

export default styled(SSOSignInButton)``;

const SignInButton = styled(BlockButton as any)`
	padding: 0 35px 0 35px;
	margin-left: 2px;
`;