import {Weekday} from "../../models";
import Checkbox from "../Checkbox";
import React, {ChangeEvent} from "react";
import Row, {Spacer} from "../Row";
import Switch from "../Switch";
import { ValidatedInput } from "../Validation";
import styled from "styled-components/macro";
import Column from "../Column";
import Input from "../Input";
import noop from "../../utils/noop";
import validationError from "../../shared/utils/validationError";


export interface ScheduleControlPanelValue {
  multipleBursts: boolean,
  daysWithin?: number,
  daysBetween?: number,
}

export interface ScheduleControlPanelProps {
  daysOnChange: (key: string, values: string[]) => void,
  currentDays: Weekday[];
  value: ScheduleControlPanelValue,
  burstOnChange: (value: ScheduleControlPanelValue) => void,
  validation: Record<string, string | undefined>
}

enum BurstType {
  before,
  within,
  between,
}

function ScheduleControlPanel(props: ScheduleControlPanelProps) {
  const {
    value,
    daysOnChange = noop,
    burstOnChange = noop,
    currentDays = [],
    validation
  } = props;

  const updateSurveyBurstDays = (burstType: BurstType) => (event: ChangeEvent<HTMLInputElement>) => {
    const days = parseInt(event.currentTarget.value);
    if (burstType === BurstType.within) {
      burstOnChange({
        ...value,
        daysWithin: days
      });
    } else if (burstType === BurstType.between) {
      burstOnChange({
        ...value,
        daysBetween: days
      });
    }
  }

  function updateMultipleBursts(newState: boolean) {
    if (newState) {
      burstOnChange({
        ...value,
        multipleBursts: newState
      });
    } else {
      // It need to reset the daysWithin and daysBetween to undefined if the multipleBursts is turned off.
      burstOnChange({
        daysBetween: undefined,
        daysWithin: undefined,
        multipleBursts: newState
      });
    }
  }

  return <Column>
    <Checkbox
      inputList={Object.keys(Weekday)}
      onChange={props.daysOnChange}
      confirmDelete={true}
      selectedItems={currentDays}
    />
    <Row>
      <MarginSpan><strong>Schedule surveys in multiple bursts?</strong></MarginSpan>
      <Switch
        checked={value.multipleBursts}
        onChange={updateMultipleBursts}
      />
    </Row>
    {value.multipleBursts &&
    <Column>
      <TopMarginRow>
        <label>Days Within Survey Bursts</label>
        <Spacer/>
        <ValidatedInput 
          message={validation.daysWithinError}
          placeholder='None' 
          min={0} 
          type='number'
          value={value.daysWithin}
          onChange={updateSurveyBurstDays(BurstType.within)}
        />
      </TopMarginRow>
      <TopMarginRow>
        <label>Days Between Survey Deliveries</label>
        <Spacer/>
        <ValidatedInput 
          message={validation.daysBetweenError}
          placeholder='None' 
          min={0} 
          type='number'
          value={value.daysBetween}
          onChange={updateSurveyBurstDays(BurstType.between)}
        />
      </TopMarginRow>
    </Column>
    }
  </Column>
  ;
}

export default styled(ScheduleControlPanel)`
`;

const TopMarginRow = styled(Row as any)`
  margin: .5em 0 0 0;
`;

const MarginSpan = styled('span')`
  margin: 0 .5em 0 .2em;
`;
