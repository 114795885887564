import {SurveyPrefs} from "../models";
import {API} from "aws-amplify";
import * as mutations from "../graphql/mutations"
import { GraphQLResult, } from '@aws-amplify/api-graphql';
import Observable from "zen-observable-ts";

export default async function updateSurveyPrefs(prefs: SurveyPrefs): Promise<SurveyPrefs | null> {
  let newSurveyPrefs: SurveyPrefs | null = null;
  const updatedSurveyPrefs = {...prefs}
  updatedSurveyPrefs.version++;
  try {
    const result: GraphQLResult | Observable<object> = await API.graphql({query: mutations.updateSurveyPrefs, variables: {input: updatedSurveyPrefs}});
    // can be used to update the surveyPrefs object
    if ("data" in result && result.data &&  result.data.hasOwnProperty("updateSurveyPrefs") ) {
      if ("updateSurveyPrefs" in result.data) {
        newSurveyPrefs = (result.data as any).updateSurveyPrefs as SurveyPrefs;
      }
    }
  } catch (error) {
    throw(error);
  }
  return newSurveyPrefs
}