import React, { FC } from "react";
import { Router } from "@reach/router";
import { useDashboardSettings } from "./utils/useDashboardSettings";
import LoadingPage from "./components/LoadingPage";
import Logout from "./pages/Logout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ManageStudies from "./pages/ManageStudies";
import SelectStudy from "./pages/SelectStudy";
import AcceptInvite from "./pages/AcceptInvite";
import SelectSite from "./pages/SelectSite";
import EditAccount from "./pages/EditAccount";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ForgottenPassword from "./pages/ForgottenPassword";
import PasswordReset from "./pages/PasswordReset";
import CreateEMA from "./pages/CreateEMA";
import CreateStudy from "./pages/StudyCreator";
import MaintenancePage from "./pages/MaintenancePage";
import { DashboardSettings } from "./shared/DashboardSettings";
import useUser from "./utils/useUser";
import { StudyListPage } from "./pages/StudyList";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

type AppProps = {
  pca: IPublicClientApplication;
};

const App: FC<AppProps> = (props) => {
  const { pca } = props;
  const [loading, user] = useUser();
  const dashboardSettings: DashboardSettings = useDashboardSettings("all");

  if (loading) {
    return <LoadingPage />;
  }

  // Logged in routes
  if (user) {
    if (
      dashboardSettings.maintenanceMode &&
      !user.permissions.includes("admin")
    ) {
      return <MaintenancePage path="/" />;
    }

    return (
      <MsalProvider instance={pca}>
        <Router>
          <Logout path="/accept-invite" />
          <SelectStudy path="/" default />
          <SelectSite path="/:studyName" studyName="" />
          <EditAccount
            path="/:studyName/:siteName/account"
            studyName=""
            siteName=""
          />
          <Dashboard
            path="/:studyName/:siteName/dashboard"
            studyName=""
            siteName=""
            chartType="all"
          />
          <Dashboard
            path="/:studyName/:siteName/dashboard/:chartType"
            studyName=""
            siteName=""
            chartType=""
          />
          <ManageStudies
            path="/:studyName/:siteName/studies"
            studyName=""
            siteName=""
          />
          <PrivacyPolicy
            path="/:studyName/:siteName/privacy"
            siteName=""
            studyName=""
          />
          <CreateStudy path="/create-study" />
          <CreateEMA path="/create-ema" />
          <StudyListPage path="study-list" />
        </Router>
      </MsalProvider>
    );
  }

  // Logged out routes
  return (
    <MsalProvider instance={pca}>
      <Router>
        <Login path="/" default />
        <AcceptInvite path="/accept-invite" />
        <ForgottenPassword path="/forgotten-password" />
        <PasswordReset path="/password-reset/" />
      </Router>
    </MsalProvider>
  );
};

export default App;
