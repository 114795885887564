import React, { FC, MouseEventHandler } from 'react';
import styled from 'styled-components/macro';
import Row from './Row';
import TrashIcon from './TrashIcon';
import BlankButton from './BlankButton';

interface Props {
  onDeleteClick?: MouseEventHandler<HTMLButtonElement>;
}

const TrashRow: FC<Props> = props => {
  const { onDeleteClick, ...rest } = props;

  return (
    <Row {...rest}>
      <span>{props.children}</span>
      <BlankButton onClick={onDeleteClick}>
        <TrashIcon />
      </BlankButton>
    </Row>
  );
};

export default styled(TrashRow)`
  height: 40px;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 13px;
  border-radius: 8px;
  color: #0b1c4a;
  font-size: 16px;
  line-height: 24px;
`;
