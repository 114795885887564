import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { StudySurvey } from "../../models";
import { SCREENTIME_EMA_NAME } from "../../shared/Constants";
import { TimeValidator } from "../../shared/utils/Validators";
import timeToString from "../../utils/timeToString";
import Row, { Spacer } from "../Row";
import Switch from "../Switch";
import { ValidatedInput } from "../Validation";
import { defaultMaxScreenTimePages } from "../../utils/settingsConstants";

interface ScreenTimeEMAControlPanelProps {
  surveyList: StudySurvey[];
  toggleScreenTimeEMa: (checked: boolean) => any;
  updateScreenTimeDelivery: (newEMAList: StudySurvey[]) => any;
  passValidationUp: (
    deliveryErrMsg: string | undefined,
    maxPagesError: string | undefined
  ) => any;
  maximumPages: number;
  updateMaximumPages: (newMaxPages: number) => any;
}

export default function ScreenTimeEMAControlPanel(
  props: ScreenTimeEMAControlPanelProps
) {
  const {
    surveyList,
    toggleScreenTimeEMa,
    updateScreenTimeDelivery,
    passValidationUp,
    maximumPages,
    updateMaximumPages,
  } = props;
  const [configureMaxPages, setConfigureMaxPages] = useState<boolean>(false);
  const [deliveryValidation, setDeliveryValidation] = useState<
    string | undefined
  >(undefined);
  const [maxPagesValidation, setMaxPagesValidation] = useState<
    string | undefined
  >(undefined);

  /**
   * A useEffect that sets the configureMaxPages state to true if the maximumPages is not 8.
   * Only runs once on component mount.
   */
  useEffect(() => {
    setConfigureMaxPages(maximumPages !== defaultMaxScreenTimePages);
  }, []);

  /**
   * A useMemo that parses out the screen time EMA object, delivery hour, delivery minute, and a boolean indicating if the ScreenTimeEMA exisits.
   */
  const [screentimeEMA, deliveryHour, deliveryMinute, checked] = useMemo(() => {
    const ema = surveyList.find(
      (item) => item.exact && item.exact!.surveyId === SCREENTIME_EMA_NAME
    );
    const checked = ema !== undefined;
    let deliveryHour = undefined;
    let deliveryMinute = undefined;
    if (checked) {
      deliveryHour =
        ema?.exact?.weekDeliverySchedule[0]?.exactDeliveriesForDay[0]
          .surveyDeliveryHour;
      deliveryMinute =
        ema?.exact?.weekDeliverySchedule[0]?.exactDeliveriesForDay[0]
          .surveyDeliveryMinute;
    }
    return [ema, deliveryHour, deliveryMinute, checked];
  }, [surveyList]);

  /**
   * Validate the form by checking delivery time and undefined values
   */
  const validate = useCallback(() => {
    // guard against undefined values being expected.
    if (!screentimeEMA) return;
    if (deliveryHour === undefined || deliveryMinute === undefined) {
      const errMsg = "Please make certain to set every value";
      setDeliveryValidation(errMsg);
      passValidationUp(errMsg, maxPagesValidation);
      return;
    }
    const deliveryTime = {
      hour: deliveryHour,
      minute: deliveryMinute,
    };
    const startError = new TimeValidator(deliveryTime)
      .noNaN()
      .min(3, 0, "Delivery cannot start before 03:00 (3:00 am)")
      .strictlyLessThan(23, 58, "Delivery must be before the 23:58 (11:58 PM)")
      .validate();
    setDeliveryValidation(startError);
    const maxPagesError =
      maximumPages < 1 || maximumPages > defaultMaxScreenTimePages
        ? `Maximum pages must be between 1 and ${defaultMaxScreenTimePages}`
        : undefined;
    setMaxPagesValidation(maxPagesError);
    passValidationUp(startError, maxPagesError);
  }, [deliveryHour, deliveryMinute, maximumPages]);

  /**
   * Run validation any time the values change
   */
  useEffect(() => {
    validate();
  }, [validate, deliveryHour, deliveryMinute]);

  /**
   * Updates the delivery of the screen time EMA based on the provided event.
   * @param event - The form event containing the updated value.
   */
  function updateDelivery(event: FormEvent<HTMLInputElement>) {
    if (!screentimeEMA) {
      return;
    }
    const surveyDeliveryHour = parseInt(
      event.currentTarget.value.split(":")[0]
    );
    const surveyDeliveryMinute = parseInt(
      event.currentTarget.value.split(":")[1]
    );
    // deal with the insanely nested data structure....
    const newEMA = {
      ...screentimeEMA,
      exact: {
        ...screentimeEMA.exact,
        weekDeliverySchedule: screentimeEMA.exact!.weekDeliverySchedule.map(
          (schedule) => {
            if (!schedule) {
              return null;
            }
            return {
              ...schedule,
              exactDeliveriesForDay: schedule.exactDeliveriesForDay.map(
                (delivery) => {
                  return {
                    ...delivery,
                    surveyDeliveryHour: surveyDeliveryHour,
                    surveyDeliveryMinute: surveyDeliveryMinute,
                  };
                }
              ),
            };
          }
        ),
      },
    } as StudySurvey;
    const newEMAList = surveyList.filter(
      (item) => (item.exact ? item.exact.surveyId : "") !== SCREENTIME_EMA_NAME
    );
    console.log(surveyList.length, newEMAList.length);
    newEMAList.push(newEMA);
    updateScreenTimeDelivery(newEMAList);
  }

  /**
   * Handles the change event for the maximum pages input field.
   * Updates the maximum pages value if the new value is between 1 and defaultMaxScreenTimePages (inclusive).
   *
   * @param event - The change event object.
   */
  const maxPagesOnChange = (event: FormEvent<HTMLInputElement>) => {
    const newMaxPages = parseInt(event.currentTarget.value);
    if (newMaxPages > 0 && newMaxPages <= defaultMaxScreenTimePages) {
      updateMaximumPages(newMaxPages);
    } else if (newMaxPages < 1) {
      updateMaximumPages(1);
    } else {
      updateMaximumPages(defaultMaxScreenTimePages);
    }
  };

  /**
   * Toggles the maximum pages value and updates the state.
   * If the switch is toggled off, the maximum pages value is reset to defaultMaxScreenTimePages.
   *
   * @param checked - A boolean indicating whether the switch is checked or not.
   */
  const toggleMaxPages = (checked: boolean) => {
    if (!checked) {
      updateMaximumPages(defaultMaxScreenTimePages);
    }
    setConfigureMaxPages(checked);
  };

  return (
    <>
      <p>
        <strong>Screen Time Activity - iOS Only</strong>: Enable this feature to
        prompt participants daily with a brief activity to capture and upload
        their daily app and device usage data. Enabling this feature will count
        towards your study's maximum scheduled surveys total and a daily
        reminder notification will be scheduled for delivery at a specified time
        of your choosing.
      </p>
      <div style={{ marginBottom: "1em" }}>
        <Switch checked={checked} onChange={toggleScreenTimeEMa} />
      </div>
      {checked && (
        <Container>
          <TopRow>
            <span> Delivery Time </span>
            <Spacer />
            <label>
              <ValidatedInput
                message={deliveryValidation}
                type={"time"}
                value={timeToString(deliveryHour, deliveryMinute)}
                onChange={updateDelivery}
              />
            </label>
          </TopRow>
          <ControlRow>
            <p>Set Screenshot Limits</p>
            <Spacer />
            <Switch checked={configureMaxPages} onChange={toggleMaxPages} />
          </ControlRow>
          {configureMaxPages && (
            <>
              <p>
                You may limit how many pages will be displayed per capture
                period that a participant is required to screenshot and submit.
              </p>
              <p>
                Each page shows the top 5 or 6 apps used by the participant
                depending on screen size. The apps are shown in descending order
                of usage. The default and max number of pages that may be shown
                is {defaultMaxScreenTimePages}. For example, if a participant
                used 8 unique apps within a capture period, two pages of app
                usage would be displayed that they will need to screenshot and
                submit. If a participant used 100 unique apps within a capture
                period, only the most used apps would be displayed across{" "}
                {defaultMaxScreenTimePages} pages.
              </p>
              <p>
                Limiting the number of pages will reduce the amount of unique
                apps displayed for submission, but may be useful for reducing
                participant burden.
              </p>
              <ControlRow>
                Maximum Pages
                <Spacer />
                <ValidatedInput
                  message={maxPagesValidation}
                  type={"number"}
                  value={maximumPages}
                  onChange={maxPagesOnChange}
                />
              </ControlRow>
            </>
          )}
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  background-color: #e3e3e3;
  padding: 0.75em 0.25em 0.75em 0.25em;
`;

const TopRow = styled(Row as any)`
  align-items: center;
  padding-bottom: 0.25em;
  border-bottom: 1px solid black;
`;
const ControlRow = styled(Row as any)`
  align-items: center;
  padding-top: 0.25em;
`;
