import React, {FC} from "react";
import Modal, {ModalProps} from "../Modal";
import SelectEMATypeForm, {SelectEMATypeFormProps} from "./SelectEMATypeForm";
import styled from "styled-components/macro";

type Props = ModalProps & SelectEMATypeFormProps;

const SelectEMATypeModal: FC<Props> = props =>  {
 const { onClose, ...rest } = props;
 return <Modal onClose={onClose}>
  <SelectEMATypeForm {...rest}/>
 </Modal>
}

export default styled(SelectEMATypeModal)`
 background-color: #e6e6e6;
`;
