import React, {MouseEvent, useState} from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import ForgottenPasswordForm , { ForgottenPasswordFormDefaultValue } from '../components/ForgottenPasswordForm';
import Logo from '../components/Logo';
import TextLogo from '../components/TextLogo';
import submitForgottenPassword from '../api/submitForgottenPassword';
import getErrorText from '../utils/getErrorText';
import SpinnerOverlay from '../components/SpinnerOverlay';
import {Redirect} from "@reach/router";
import BlockButton from "../components/BlockButton";

const ForgottenPassword: PageComponent = props => {
  const [resetEmail, setResetEmail] = useState(ForgottenPasswordFormDefaultValue);
  const [resetError, setResetError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [goHome, setGoHome] = useState(false)

  async function handleSubmit() {
    try {
      setLoading(true);
      await submitForgottenPassword(resetEmail);
      setSubmitted(true);
    } catch (error) {
      setResetError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleGoHome(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setGoHome(true );
  }

  if (goHome) {
    return <Redirect to={"/"}/>;
  }

  return (
    <Page {...props}>
      {loading && <SpinnerOverlay />}
      <Logo />
      <h1>Forgotten Password Reset Form</h1>
      {resetError && <ErrorText>{getErrorText(resetError)}</ErrorText>}
      {submitted &&
        <div>
          <SuccessText><h4>Password Reset Submitted.</h4><p>If a user with the email address provided exists
          <br/>an email has been sent to them.</p></SuccessText>
            <HomeButton onClick={handleGoHome}>Return to the login page</HomeButton>
        </div>
      }
      {!submitted &&
        <ForgottenPasswordForm
          value={resetEmail}
          onChange={setResetEmail}
          onSubmit={handleSubmit}
        />
      }
    </Page>
  );
}

export default styled(ForgottenPassword)`
  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
  justify-content: center;
  align-items: center;

  ${Logo} {
    height: 122.64px;
    width: 76.62px;
    margin-bottom: 26px;
  }

  ${TextLogo} {
    height: 50.46px;
    width: 188.44px;
    margin-bottom: 8px;
  }

  > h1 {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
  }
`;

const ErrorText = styled('div')`
  color: #a00;
  margin-bottom: 40px;
`;

const SuccessText= styled('div')`
  text-align: center;
  color: white;
  margin-bottom: 40px;
`;

const HomeButton = styled(BlockButton as any)`
  margin: auto;
`;