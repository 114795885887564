import styled from "styled-components/macro";

interface ColumnProps {
  width?: string;
  zIndex?: number;
  rowGap?: string;
  alignItems?:
    | "normal"
    | "center"
    | "flex-start"
    | "flex-end"
    | "stretch"
    | "baseline";
}

const Column = styled.div<ColumnProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: ${(props) => (props.width ? props.width : "auto")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "auto")};
  row-gap: ${(props) => (props.rowGap ? props.rowGap : "normal")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "normal")};
  }
`;

export default Column;
