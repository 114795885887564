import React, {FC} from "react";
import styled from "styled-components/macro";
import Column from "../Column";
import Row from "../Row";
import BlockButton from "../BlockButton";
import noop from "../../utils/noop";

export interface SelectEMATypeFormProps {
  onSubmit?: (surveyType: string) => any;
}

const SelectEMATypeForm: FC<SelectEMATypeFormProps> = props =>  {
  const {onSubmit = noop } = props;

  return <Column>
      <h1>Select Survey Delivery Schedule</h1>
      <h3>Randomized Survey</h3>
      <p>Surveys are delivered randomly inside of 2 hour blocks</p>
      <BlockButton onClick={() => onSubmit('random')}>Create Random Survey</BlockButton>
      <h3>Exact Survey</h3>
      <p>You set the exact time a survey is delivered and when it expires</p>
      <BlockButton onClick={() => onSubmit('exact')}>Create Exact Survey</BlockButton>
  </Column>
}


export default styled(SelectEMATypeForm)`
`;
