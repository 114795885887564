import React, {FC} from 'react';
import styled from "styled-components/macro";
import Column from "./Column";
import Input, {InputProps as InputIputProps} from "./Input";
import TextArea, {InputProps as TextAreaInputProps} from "./TextArea";

export enum ValidationErrorTypes {
  ERROR,
  WARNING
}

interface ValidationProps {
  errorType?: ValidationErrorTypes;
  message?: string;
}

/*
Wrap an input in a validation object. Default type is Error.
 */
const Validation: FC<ValidationProps> = props => {
  const {
    errorType = ValidationErrorTypes.ERROR,
    message = null,
    ...rest
  } = props;

  return (
    <Column {...{...rest, width: '33%'}} >
        {props.children}
        {message !== undefined &&
          <ErrorMessage errorType={errorType}>{message}</ErrorMessage>
        }
    </Column>
  )
}

interface  ValidatedInputProps extends ValidationProps , InputIputProps{
};

export const ValidatedInput: FC<ValidatedInputProps> = props => {
  const {
    errorType = ValidationErrorTypes.ERROR,
    message = undefined,
    ...rest
  } = props;

  return (
    <Column >
      <Input error={message} {...rest}/>
      {message !== undefined &&
        <ErrorMessage errorType={errorType} >{message}</ErrorMessage>
      }
    </Column>
  )
}

interface  ValidatedTextAreaProps extends ValidationProps , TextAreaInputProps{
};

export const ValidatedTextArea: FC<ValidatedTextAreaProps> = props => {
  const {
    errorType = ValidationErrorTypes.ERROR,
    message = undefined,
    ...rest
  } = props;

  return (
    <Column >
      <TextArea error={message} {...rest}/>
      {message !== undefined &&
        <ErrorMessage errorType={errorType} >{message}</ErrorMessage>
      }
    </Column>
  )
}
export default styled(Validation)`
`;

const ErrorMessage = styled.p<{errorType: ValidationErrorTypes}>`
  color: ${props => props.errorType == ValidationErrorTypes.WARNING ? 'yellow' : 'red'};
  min-width: 0;
  max-width: 100%;
  word-wrap: break-word;
`;

