import React, { useState, useEffect, FormEvent } from "react";
import styled from "styled-components/macro";
import { navigate } from "@reach/router";
import Page, { PageComponent } from "../components/Page";
import StudySelectForm from "../components/StudySelectForm";
import SpinnerOverlay from "../components/SpinnerOverlay";
import BlockButton from "../components/BlockButton";
import { getAssignedStudyNames } from "../api/getAssignedStudies";
import getCurrentUser from "../api/getCurrentUser";
import MessageBox from "../components/MessageBox";
import { navigateToCreateStudy } from "../pages/StudyCreator";
import { userIsAdmin } from "../shared";
import { getStudyNames } from "../api/getStudies";
import getStudyByName from "../api/getStudyByName";

const SelectStudy: PageComponent = (props) => {
  const [loading, setLoading] = useState(true);
  const [assignedStudies, setAssignedStudies] = useState<string[]>([]);
  const [selectedStudy, setSelectedStudy] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    load();
    async function load() {
      const user = await getCurrentUser();
      if (userIsAdmin(user)) {
        setAssignedStudies((await getStudyNames()).sort());
        console.log(assignedStudies);
      } else {
        setAssignedStudies((await getAssignedStudyNames()).sort());
      }
      setLoading(false);
    }
  }, []);

  function handleValueChange(event: FormEvent<HTMLSelectElement>) {
    setSelectedStudy(event.currentTarget.value);
  }

  async function handleSubmit() {
    const user = await getCurrentUser();
    if (userIsAdmin(user) && selectedStudy) {
      const study = await getStudyByName(selectedStudy);

      //If there are no sites, skip site selection screen
      if (study.studySites && study.studySites.length !== 0) {
        navigate(`/${selectedStudy}`);
      } else {
        navigate(`/${selectedStudy}/all/dashboard`);
      }
    } else {
      const assignedStudy = user.assignedStudies.find(
        (assigned) => assigned.studyName === selectedStudy
      );
      if (!assignedStudy) {
        navigate("/");
        return;
      }

      //If there are no sites, skip site selection screen
      if (assignedStudy.sites.length !== 0) {
        navigate(`/${selectedStudy}`);
      } else {
        navigate(`/${selectedStudy}/all/dashboard`);
      }
    }
  }

  /*
   TODO add assertion that user has the `createStudy` permission before showing the create study dialog. Also need to make the forms accept that...
   */

  return (
    <Page {...props}>
      {loading && <SpinnerOverlay />}
      {assignedStudies.length ? (
        <StudySelectForm
          value={selectedStudy}
          onChange={handleValueChange}
          onSubmit={handleSubmit}
        >
          {assignedStudies &&
            assignedStudies.map((studyName) => (
              <option key={studyName} value={studyName}>
                {studyName}
              </option>
            ))}
        </StudySelectForm>
      ) : (
        !loading && (
          <>
            <MessageBox
              title="Please Create A Study"
              message="Welcome to EARS! To get started, please create a study."
            />
            <BlockButton onClick={() => navigateToCreateStudy()}>
              Create Study
            </BlockButton>
          </>
        )
      )}
    </Page>
  );
};

export default styled(SelectStudy)`
  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
  align-items: center;
  justify-content: center;
`;
