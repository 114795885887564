import React, { FC } from 'react';
import styled from 'styled-components/macro';

const StudyTable: FC = props => {
  return (
    <table {...props}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Researchers</th>
          <th>Sensors</th>
          <th>Sites</th>
          <th>Participants</th>
          <th />
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
      <tfoot />
    </table>
  );
};

export default styled(StudyTable)`
  border-collapse: collapse;
  text-align: left;
  width: 864px;
  max-width: 100%;
  background-color: white;

  tr {
    height: 40px;
  }

  tbody tr:first-child td:first-child {
    border-top-left-radius: 8px;
  }

  tbody tr:first-child td:last-child {
    border-top-right-radius: 8px;
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  tbody td:last-child {
    width: 50px;
    text-align: right;
    cursor: pointer;
  }

  td:first-child,
  th:first-child {
    padding-left: 22px;
  }

  td:last-child,
  th:last-child {
    padding-right: 22px;
  }

  th {
    color: #0b1c4a;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  td {
    color: #0b1c4a;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 16px;
  }

  td:first-child {
    font-weight: 500;
    margin-left: 22px;
  }

  tbody {
    background-color: #e5ebf1;
    border-radius: 8px;
  }

  tr {
    border-radius: 8px;
  }

  td {
    position: relative;
  }

  tr:not(:first-child) td:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #06dddd;
  }

  tr:not(:first-child) td:first-child:after {
    left: 22px;
  }

  tr:not(:first-child) td:last-child:after {
    right: 22px;
  }
`;
