/**
 * Creates an error with an optional http status code.
 *
 * @param message - The error message.
 * @param status - The HTTP status code. Defaults to 400.
 * @returns An error
 */
export default function validationError(
  message: string,
  status: number = 400,
): Error & { status: number } {
  return Object.assign(new Error(message), { status });
}
