import axios from './axios';

interface Options {
  email: string;
  password: string;
}

export default async function updateUser(options: Options): Promise<void> {
  await axios.post('/user', options);
}
