import React, { FC } from 'react';
import styled from 'styled-components/macro';
import androidIconSrc from '../images/android_icon.png';

const AndroidIcon: FC = props => {
  return <img {...props} src={androidIconSrc} alt="Android" />;
};

export default styled(AndroidIcon)`
  width: 23.54px;
`;
