import React, { FC, FormEventHandler } from 'react';
import styled from 'styled-components/macro';
import Color from 'color';
import BlankButton from './BlankButton';

interface XButtonProps {
  disabled?: boolean;
  onClick?: FormEventHandler<HTMLButtonElement>;
  dark?: boolean;
}

const XButton: FC<XButtonProps> = props => {
  return props.dark ? (
    <DarkxButton {...props} data-cy="xbutton"/>
  ) : (
    <LightxButton {...props} data-cy="xbutton"/>
  );
};

export default styled(XButton)``;

const Root = styled(BlankButton as any)`
  border-radius: 7px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 30px;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:disabled {
    background-color: #baccde;
  }

  &:after {
    content: 'x';
    display: block;
    position: relative;
    top: -2px;
  }
`;

const lightBackgroundColor = '#dd0606';
const LightxButton = styled(Root as any)<XButtonProps>`
  background-color: ${lightBackgroundColor};

  &:hover {
    background-color: ${Color(lightBackgroundColor)
      .darken(0.1)
      .string()};
  }

  &:active {
    background-color: ${Color(lightBackgroundColor)
      .darken(0.3)
      .string()};
  }
`;

const darkBackgroundColor = '#dd0606';
const DarkxButton = styled(Root as any)<XButtonProps>`
  background-color: ${darkBackgroundColor};

  &:hover {
    background-color: ${Color(darkBackgroundColor)
      .darken(0.1)
      .string()};
  }

  &:active {
    background-color: ${Color(darkBackgroundColor)
      .darken(0.3)
      .string()};
  }
`;
