import React, {FC, FormEvent, ChangeEvent, useState, useEffect} from 'react';
import Column from "../Column";
import Row from "../Row";
import PlusButton from "../PlusButton";
import XButton from "../XButton";
import styled from "styled-components/macro";
import TextArea from "../TextArea";
import Switch from "../Switch";
import noop from "../../utils/noop";
import {ChoiceBranchingRuleComponent, ChoiceBranchingRuleDefaultValue} from "./ChoiceBranchingRule";
import {ChoiceQuestion, ChoiceBranchingRule, ChoiceRiskRule} from '../../models'
import Required from "../Required";
import {checkIfAllValuesUndefined, StringValidator} from "../../shared/utils/Validators";
import {ValidatedInput} from "../Validation";
import {branchingRuleReservedValues} from "./BranchingRule";
import { ChoiceRiskRuleComponent, ChoiceRiskRuleDefaultValue } from "./ChoiceRiskRule"
import { removedChoiceIDBranchingRule, removedChoiceIDRiskRule, updatedChoiceBranchingRule } from '../../utils/choiceQuestionUtils';

interface MultipleChoiceQuestionProps {
  value?: ChoiceQuestion;
  surveyQuestionIDs: string[];
  currentQuestionIndex: number;
  onChange?: (value: ChoiceQuestion) => any;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => any;
  canSwitch?: (value: boolean) => any;
  showRiskToggle?: boolean;
}

export const ChoiceQuestionDefaultValue: ChoiceQuestion = {
  questionId: "",
  questionHeader: "",
  questionBody: "",
  skipEnabled: true,
  multipleChoice: false,
  textChoices: [],
  branchingRule: undefined
};

const ChoiceQuestionComponent: FC<MultipleChoiceQuestionProps> = props => {
    const {
    value = ChoiceQuestionDefaultValue,
    onChange = noop,
    canSwitch = noop,
    showRiskToggle,
    ...rest
  } = props;
  const [validation, setValidation] = useState<Record<string, string | undefined>>({});
  const [choiceValidation, setChoiceValidation] = useState<Array<Record<string, string | undefined>>>(
    value.textChoices?.map((_) => {
      return {'textChoiceID': undefined, 'textToDisplay': undefined};
    }) ?? []);

  const nextQuestion = props.surveyQuestionIDs.length > props.currentQuestionIndex+1 ? props.surveyQuestionIDs[props.currentQuestionIndex+1] : undefined

  useEffect(() => {
    setChoiceValidation(value.textChoices?.map((_) => {
      return {'textChoiceID': undefined, 'textToDisplay': undefined};
    }) ?? [])
  }, [value.questionId])
  

  useEffect(() => {
    let validationIsGood = checkIfAllValuesUndefined(validation);
    // check the high level values
    let validationChecks =
    [ new StringValidator(value.questionId).min(1).validate(),
      new StringValidator(value.questionHeader ?? '').min(1).validate()
    ];
    if (value.branchingRule) {
      validationChecks.push(
        new StringValidator(value.branchingRule.defaultQuestionId).noEmpty().validate(),
        new StringValidator(value.branchingRule.destinationQuestionId).noEmpty().validate()
      )
    }
    validationChecks.forEach((value) => {
      validationIsGood = validationIsGood && value === undefined ;
    });
    // check the values inside each text choice
    value.textChoices?.forEach((choice) => {
      if (choice) {
        validationIsGood = validationIsGood &&
          new StringValidator(choice.textChoiceID).min(1).validate() === undefined;
        validationIsGood = validationIsGood &&
          new StringValidator(choice.textToDisplay).min(1).validate() === undefined;
      }
    });
    // check for errors in the text choice validations
    choiceValidation.forEach((choiceVali) => {
      const check = checkIfAllValuesUndefined(choiceVali);
      validationIsGood = validationIsGood && checkIfAllValuesUndefined(choiceVali);
    });
    canSwitch(validationIsGood);
  },)


  function handleQuestionIdChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value, 'QuestionID must contain at least one character')
      .alphaNum('Question ID can only contain of numbers of letters.')
      .min(1, 'Question ID must contain at least one character.')
      .unique(props.surveyQuestionIDs, 'Question IDs must be unique (match found for another question)', props.currentQuestionIndex)
      .unique(branchingRuleReservedValues, 'This value is reserved and cannot be used as a Question ID')
      .validate();
    setValidation({
      ...validation,
      questionId: error
    });
    onChange({
      ...value,
      questionId: event.currentTarget.value
    });
  }

  function handleQuestionHeaderChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value)
      .min(1, 'Question Header must contain at least one character')
      .noSpace('Question Header value cannot contain only spaces')
      .validate();
    setValidation({
      ...validation,
      questionHeader: error
    });
    onChange({
      ...value,
      questionHeader: event.currentTarget.value
    });
  }

  function handleQuestionBodyChange(event: FormEvent<HTMLTextAreaElement>) {
    onChange({
      ...value,
      questionBody: event.currentTarget.value
    });
  }

  function addTextChoices(_: FormEvent<HTMLButtonElement>) {
    setChoiceValidation([...choiceValidation, {textChoiceID: undefined, textToDisplay: undefined}]);
    onChange({
      ...value,
      textChoices: [...value.textChoices!, {textChoiceID: "", textToDisplay:""}]
    });
  }

  function toggleSelect(newState: boolean) {
    onChange({
      ...value,
      multipleChoice: newState
    });
  }

  function toggleLogic(newState: boolean) {
    if (nextQuestion && newState) {
      onChange({
        ...value,
        branchingRule: {
          ...ChoiceBranchingRuleDefaultValue,
          defaultQuestionId: "",
          destinationQuestionId: ""
        }
      });
    } else {
      onChange({
        ...value,
        branchingRule: undefined
      });
    }
  }

  function toggleRisk (newState: boolean) {
    onChange({
      ...value,
      riskRule: newState ? {
        ...ChoiceRiskRuleDefaultValue
      } : undefined
    })
  }

  function toggleSkip(newState: boolean) {
    onChange({
      ...value,
      skipEnabled: newState
    });
  }

  const updateTextChoice = (key: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const error = new StringValidator(event.currentTarget.value, 'Choice Text must contain at least one character')
      .min(1, 'Choice Text must contain at least one character.')
      .validate();
    const choiceValidations = [...choiceValidation];
    choiceValidations[key].textToDisplay= error;
    setChoiceValidation(choiceValidations);
    const choices = [...value.textChoices!]
    if (choices[key]) {
      choices[key] = {...choices[key]!, textToDisplay: event.currentTarget.value};
    }
    onChange({
      ...value,
      textChoices: choices
    });
  }

  const updateTextChoiceId = (key: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const choiceIDs = value.textChoices?.filter(item => item !== null && item.textChoiceID !== undefined).map(item => item!.textChoiceID) as string[];
    const error = new StringValidator(event.currentTarget.value, 'Choice ID must contain at least one character')
      .alphaNum('Choice ID can only contain of numbers of letters.')
      .min(1, 'Choice ID must contain at least one character.')
      .unique(choiceIDs, 'Choice IDs must be unique to each choice', key)
      .validate();
    const choiceValidations = [...choiceValidation];
    choiceValidations[key].textChoiceID = error;
    setChoiceValidation(choiceValidations);
    const choices = [...value.textChoices!];

    //update branching rule
    const oldChoiceID = choices[key].textChoiceID;
    const newChoiceID = event.currentTarget.value;
    let newBranchingRule = updatedChoiceBranchingRule(oldChoiceID, newChoiceID, value.branchingRule)

    if (choices[key]) {
      choices[key] = {...choices[key]!, textChoiceID: event.currentTarget.value};
    }
    

    onChange({
      ...value,
      textChoices: choices,
      branchingRule: newBranchingRule
    });
  }

  const removeTextChoice = (key: number) => (event: ChangeEvent<HTMLButtonElement>) => {
    const choices = [...value.textChoices!];

    //update branching rule
    const choiceID = choices[key].textChoiceID;
    let newBranchingRule = removedChoiceIDBranchingRule(choiceID, value.branchingRule)

    //update risk rule
    let newRiskRule =  removedChoiceIDRiskRule(choiceID, value.riskRule)


    choices.splice(key, 1);
    const choiceValidations = [...choiceValidation];
    choiceValidations.splice(key, 1);
    setChoiceValidation(choiceValidations);
    onChange({
      ...value,
      textChoices: choices,
      branchingRule: newBranchingRule,
      riskRule: newRiskRule
    });
  }


  function updateBranchingRule(rule: ChoiceBranchingRule) {
    onChange({
      ...value,
      branchingRule: rule
    })
  }

  function updateRiskRule(rule: ChoiceRiskRule) {
    onChange({
      ...value,
      riskRule: rule
    })
  }

  return (
    <Column>
      <h2>Choice Question</h2>
      <Required>
        <label>Question ID</label>
      </Required>
      <ValidatedInput
        message={validation.questionId}
        value={value.questionId}
        onChange={handleQuestionIdChange}
        placeholder="Question ID"
      />
      <Required>
        <label>Question Header</label>
      </Required>
      <ValidatedInput
        message={validation.questionHeader}
        value={value.questionHeader!}
        onChange={handleQuestionHeaderChange}
        placeholder="Question Header"
      />
      <label>Question Body</label>
      <QuestionTextInput
        value={value.questionBody}
        onChange={handleQuestionBodyChange}
        placeholder="Question Body"
      />
      <Row>
        <ol>
        { value.textChoices && value.textChoices.map((choice, index) => {
          return <TextChoice key={index+value.questionId}>
            <MultipleChoiceRow>
              <ValidatedInput
                message={choiceValidation[index]?.textChoiceID}
                onChange={updateTextChoiceId(index)}
                value={choice?.textChoiceID}
                placeholder={'Choice ID'}
              />
              <ValidatedInput
                message={choiceValidation[index]?.textToDisplay}
                onChange={updateTextChoice(index)}
                value={choice?.textToDisplay}
                placeholder={'Choice Text'}
              />
              <XButton
                onClick={removeTextChoice(index)}
              />
              <Required><span/></Required>
            </MultipleChoiceRow>
          </TextChoice>
          })
        }
        </ol>
      </Row>
      <PlusButton
        onClick={addTextChoices}
      />
      {showRiskToggle && 
      <Row>
        <Switch
          checked={value.riskRule !== undefined && value.riskRule !== null}
          onChange={toggleRisk}
        />
        <label>Risk Alert</label>
      </Row>
      }
      {showRiskToggle && value.riskRule && 
        <Row>
          <ChoiceRiskRuleComponent
            value={value.riskRule}
            choiceQuestionIDs={value.textChoices.map((item) => item?.textChoiceID!)}
            onChange={updateRiskRule}
          />
        </Row>
      }
      <Row>
        <Switch
          checked={value.skipEnabled}
          onChange={toggleSkip}
        />
        <Label>Skippable</Label>
      </Row>
      <Row>
        <Switch
          checked={value.multipleChoice}
          onChange={toggleSelect}
        />
        <Label>Select All</Label>
      </Row>
      {nextQuestion && value.textChoices && value.textChoices.length > 0 &&
        <Row>
          <Switch
            checked={value.branchingRule !== undefined && value.branchingRule !== null}
            onChange={toggleLogic}
          />
          <Label>Logic</Label>
        </Row>
      }
      {value.branchingRule &&
        <div>
          {value.textChoices && value.textChoices.length > 0 &&
            <ChoiceBranchingRuleComponent
              surveyQuestionIDs={props.surveyQuestionIDs.slice(props.currentQuestionIndex+1)}
              choiceQuestionIDs={value.textChoices.map((item) => item?.textChoiceID!)}
              value={value.branchingRule}
              onChange={updateBranchingRule}
            />
          }
        </div>
      }
    </Column>
  );

}


export default styled(ChoiceQuestionComponent)`
  min-width: 100%;
`;


export const QuestionTextInput = styled(TextArea as any)`
  width: 100%;
  margin-bottom: 16px;
`;

const Label = styled('label')`
    margin-left: .5em;
`;

const TextChoice = styled('li')`
    margin-bottom: .5em;
`;

const MultipleChoiceRow = styled(Row as any)`
  > * {
    margin: 2px;
  }
`;

