import React, { FC, useEffect, useState } from "react";
import styled from "styled-components/macro";
import Row from "./Row";
import Column from "./Column";
import DropdownMenu from "./DropdownMenu";
import Logo from "./Logo";
import profileImagePlaceholderSrc from "../images/profile_image_placeholder.png";
import Link from "./Link";
import DropdownMenuHeader from "./DropdownMenuHeader";
import Chevron from "./Chevron";
import GearIcon from "./GearIcon";
import logout from "../api/logout";
import { DashboardUser, userIsAdmin, userIsStudyAdmin } from "../shared";
import { navigate } from "@reach/router";
import getStudySiteNames from "../api/getStudySiteNames";
import getStudies from "../api/getStudies";
import getErrorText from "../utils/getErrorText";
import EARSBunnyLogo from "./EARSBunnyLogo";

interface Props {
  selectedStudyName: string;
  selectedSiteName: string;
  user: DashboardUser;
}

const NavBar: FC<Props> = (props) => {
  const { selectedStudyName, selectedSiteName, user, ...rest } = props;
  const [studyNames, setStudyNames] = useState<string[]>(
    user.assignedStudies.map((assigned) => assigned.studyName).sort()
  );
  const [siteNames, setSiteNames] = useState<string[]>([]);

  useEffect(() => {
    load().catch((error) => {
      console.error(error);
      alert(getErrorText(error));
    });
    async function load() {
      if (userIsAdmin(user)) {
        const [studies, siteNames] = await Promise.all([
          getStudies(),
          getStudySiteNames(selectedStudyName),
        ]);

        setSiteNames(siteNames.sort());
        setStudyNames(studies.map((study) => study.studyName).sort());
        // const assignedStudy = user.assignedStudies.find(
        //   study => study.studyName === selectedStudyName
        // );
        // if (!assignedStudy) {
        //   navigate('/');
        //   return;
        // }
        // setSiteNames(assignedStudy.sites);
      } else {
        const assignedStudy = user.assignedStudies.find(
          (study) => study.studyName === selectedStudyName
        );
        if (!assignedStudy) {
          navigate("/");
          return;
        }
        setSiteNames(assignedStudy.sites.sort());
      }
    }
  }, [selectedStudyName, user]);

  return (
    <Row {...rest}>
      <Link to={`/${selectedStudyName}/${selectedSiteName}/dashboard`}>
        <EARSBunnyLogo />
      </Link>
      <MenuRow>
        {/* Don't render site options if there are no sites */}
        {siteNames.length > 0 && (
          <DropdownColumn>
            <MenuLabel>Site</MenuLabel>
            <DropdownMenu
              label={(open) => (
                <>
                  {selectedSiteName}
                  <Chevron flipped={open} light={open} scale={0.6} />
                </>
              )}
            >
              <li>
                <Link to={`/${selectedStudyName}/all/dashboard`}>all</Link>
              </li>
              {siteNames.map((siteName) => (
                <li key={siteName}>
                  <Link to={`/${selectedStudyName}/${siteName}/dashboard`}>
                    {siteName}
                  </Link>
                </li>
              ))}
            </DropdownMenu>
          </DropdownColumn>
        )}
        <DropdownColumn>
          <MenuLabel>Study</MenuLabel>
          <DropdownMenu
            label={(open) => (
              <>
                {selectedStudyName}
                <Chevron flipped={open} light={open} scale={0.6} />
              </>
            )}
          >
            {studyNames.map((studyName) => (
              <li key={studyName}>
                <Link to={`/${studyName}/all/dashboard`}>{studyName}</Link>
              </li>
            ))}
            {
              <li>
                <ManageStudiesLink
                  to={`/${selectedStudyName}/${selectedSiteName}/studies`}
                >
                  <span>Manage Studies</span>
                  <GearIcon />
                </ManageStudiesLink>
              </li>
            }
          </DropdownMenu>
        </DropdownColumn>
        <ProfileDropdownMenu label={() => <ProfileImage />}>
          <li>
            <Link to={`/${selectedStudyName}/${selectedSiteName}/account`}>
              My Account
            </Link>
          </li>
          {userIsStudyAdmin(user, selectedStudyName) && (
            <li>
              <Link to={`/${selectedStudyName}/${selectedSiteName}/studies`}>
                Manage Studies
              </Link>
            </li>
          )}
          {(selectedStudyName == "cocospringexternaltest" ||
            selectedStudyName == "cocospring") && (
            <li>
              <a
                href="http://ksana.portalis.io/portal/dashboard"
                target="_blank"
                rel="noopener noreferrer"
              >
                Service Portal
              </a>
            </li>
          )}
          {/* {(selectedStudyName != 'cocospringexternaltest' && selectedStudyName != 'cocospring') && }
          <li>
              <a
                  href="https://knowledgebase.ksanahealth.com/en/knowledge"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                 Ksana Health Help Center
              </a>
          </li>
          */}
          {selectedStudyName != "cocospringexternaltest" &&
            selectedStudyName != "cocospring" && (
              <li>
                <a
                  href="https://share.hsforms.com/1g-kAC_w6TVSJnDBs4BZN-w3vef7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Report an Issue
                </a>
              </li>
            )}
          <li onClick={logout}>Logout</li>
        </ProfileDropdownMenu>
      </MenuRow>
    </Row>
  );
};
//TODO change the above link to a common fixes pages on c4dmh

export default styled(NavBar)`
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 50px 51px 50px 56px;
  width: 1300px;
  max-width: 100%;
  align-self: center;

  ${Logo} {
    height: 84px;
    width: 53px;
  }
`;

const ProfileImage = styled("img").attrs({ src: profileImagePlaceholderSrc })`
  width: 41px;
  height: 41px;
`;

const MenuLabel = styled("div")`
  color: #a4cef5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  margin-bottom: 7px;
  padding-left: 5px;
`;

const DropdownColumn = styled(Column as any)`
  & + & {
    margin-left: 80px;
  }
`;

const MenuRow = styled(Row as any)`
  align-items: center;
`;

const ProfileDropdownMenu = styled(DropdownMenu as any)`
  margin-left: 130px;
  ${DropdownMenuHeader} {
    padding: 5px 5px 5px 5px;
  }
`;

const ManageStudiesLink = styled(Link as any)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
