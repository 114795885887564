import React, { FC, FormEvent, useState } from 'react';
import styled from 'styled-components/macro';
import nanoid from 'nanoid';
import { Researcher } from '../shared';
import Column from './Column';
import Row from './Row';
import noop from '../utils/noop';
import Switch from './Switch';
import LocationIcon from './LocationIcon';
import PlusButton from './PlusButton';
import TrashInput from './TrashInput';
import TrashRow from './TrashRow';
import replaceWhere from '../utils/replaceWhere';
import removeFromArray from '../utils/removeFromArray';
import AddSiteModal, {
  defaultValue as addSiteModalDefaultValue
} from './AddSiteModal';
import isEmail from '../utils/isEmail';

interface Props {
  value?: ResearcherFormValue;
  allSites?: string[];
  onChange?: (value: ResearcherFormValue) => any;
}

export interface ResearcherFormValue {
  researchers: (Researcher & { isSaved?: boolean })[];
}

export const defaultValue: ResearcherFormValue = {
  researchers: []
};

export function validateValue(value: ResearcherFormValue): Error[] | null {
  const errors: Error[] = [];
  for (const researcher of value.researchers) {
    if (!isEmail(researcher.email)) {
      errors.push(new Error(`Invalid email "${researcher.email}"`));
    }
  }
  return errors.length ? errors : null;
}

const ResearcherForm: FC<Props> = props => {
  const {
    value = defaultValue,
    onChange = noop,
    allSites = [],
    ...rest
  } = props;
  const { researchers } = value;
  const [
    siteModalResearcher,
    setSiteModalResearcher
  ] = useState<Researcher | null>(null);
  const [siteModalValue, setSiteModalValue] = useState(
    addSiteModalDefaultValue
  );

  function updateResearcher(
    researcher: Researcher,
    changes: Partial<Researcher>
  ) {
    onChange({
      researchers: replaceWhere(
        researchers,
        other => other.id === researcher.id,
        researcher => ({ ...researcher, ...changes })
      )
    });
  }

  function handleResearcherEmailChange(
    researcher: Researcher,
    event: FormEvent<HTMLInputElement>
  ) {
    updateResearcher(researcher, { email: event.currentTarget.value.trim() });
  }

  function handleAddSiteClick(researcher: Researcher) {
    setSiteModalValue({ sites: researcher.assignedSites });
    setSiteModalResearcher(researcher);
  }

  function handleAddSiteModalClose() {
    setSiteModalResearcher(null);
  }

  function handleAddSiteModalSubmit() {
    if (siteModalResearcher) {
      updateResearcher(siteModalResearcher, {
        assignedSites: siteModalValue.sites
      });
    }
    setSiteModalResearcher(null);
  }

  function handleResearcherDeleteClick(researcher: Researcher) {
    onChange({
      researchers: researchers.filter(other => other.id !== researcher.id)
    });
  }

  function handleSiteDeleteClick(researcher: Researcher, site: string) {
    updateResearcher(researcher, {
      assignedSites: removeFromArray(researcher.assignedSites, site)
    });
  }

  function handleAddResearcherClick() {
    onChange({
      researchers: researchers.concat({
        id: nanoid(),
        email: '',
        assignedSites: [],
        studyPermissions: [],
        isSaved: false,
        isNew: true
      })
    });
  }

  function doesSiteExist(site: string) {
    return !!allSites.find(other => other === site);
  }

  return (
    <Column {...rest}>
      {!!siteModalResearcher && (
        <AddSiteModal
          value={siteModalValue}
          subtitle={siteModalResearcher.email}
          onChange={setSiteModalValue}
          onClose={handleAddSiteModalClose}
          onSubmit={handleAddSiteModalSubmit}
          allSites={allSites}
        />
      )}
      <Title>Researchers</Title>
      {researchers.map(researcher => (
        <ResearcherEntry key={researcher.id}>
          <ResearcherHeader>
            <ResearcherEmailInput
              placeholder="Email"
              value={researcher.email}
              onChange={(event: React.FormEvent<HTMLInputElement>) => handleResearcherEmailChange(researcher, event)}
              disabled={researcher.isSaved}
              disableDelete={researcher.isSaved}
              onDeleteClick={() => handleResearcherDeleteClick(researcher)}
            />
          </ResearcherHeader>
          <ResearcherSitesLabel>
            <LocationIcon /> <span>Researcher Site(s)</span>
          </ResearcherSitesLabel>
          <ResearcherSites>
            {researcher.assignedSites.filter(doesSiteExist).map(site => (
              <ResearcherSite
                key={site}
                onDeleteClick={() => handleSiteDeleteClick(researcher, site)}
              >
                {site}
              </ResearcherSite>
            ))}
            <PlusButton dark onClick={() => handleAddSiteClick(researcher)} />
          </ResearcherSites>
        </ResearcherEntry>
      ))}
      <AddResearcherRow>
        <PlusButton onClick={handleAddResearcherClick} />
      </AddResearcherRow>
    </Column>
  );
};

export default styled(ResearcherForm)`
  background-color: #e5ebf1;
  //width: 668px;
  max-width: 100%;
`;

const Title = styled('div')`
  color: #0b1c4a;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 17px;
`;

const ResearcherEntry = styled(Column as any)`
  border-bottom: 1px solid #06dddd;

  & + & {
    padding-top: 12px;
  }
`;

const ResearcherHeader = styled(Row as any)`
  align-items: center;

  ${Switch} {
    margin-left: 16px;
    margin-right: 8px;
  }
`;

const ResearcherEmailInput = styled(TrashInput as any).attrs({ type: 'email' })`
  flex: 1;
`;

const ResearcherSitesLabel = styled(Row as any)`
  height: 40px;
  align-items: center;
  color: #0b1c4a;
  font-size: 17px;
  line-height: 40px;

  ${LocationIcon} {
    height: 20px;
    width: 14px;
    margin-right: 16px;
  }
`;

const ResearcherSites = styled(Row as any)`
  flex-wrap: wrap;
  padding-left: 32px;
  margin-bottom: 8px;
`;

const ResearcherSite = styled(TrashRow as any)`
  width: 184px;
  margin-right: 8px;
  margin-bottom: 8px;
`;

const AddResearcherRow = styled(Row as any)`
  margin-top: 20px;
  justify-content: center;
`;
