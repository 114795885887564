import React, { FC } from 'react';
import styled from 'styled-components/macro';
import plusIconSrc from '../images/plus_icon.png';

const PlusIcon: FC = props => {
  return <img {...props} src={plusIconSrc} alt="Add" />;
};

export default styled(PlusIcon)`
  width: 16px;
  height: 16px;
`;
