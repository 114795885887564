import React, {EventHandler, FC, MouseEventHandler} from 'react';
import styled from 'styled-components/macro';
import EllipsisIcon from './EllipsisIcon';
import {ActiveSensors} from "../models";
import BlockButton from "./BlockButton";
import Row from "./Row";
import PlusIcon from "./PlusIcon";
import {DashboardUser, userIsStudyAdmin} from "../shared";

interface StudyTableRowProps {
  studyName: string;
  sensors: (ActiveSensors | null | string)[];
  researcherCount: number;
  siteCount: number;
  participantCount: number;
  user: DashboardUser
  onEditClick?: MouseEventHandler<HTMLButtonElement>;
  onDevicesClick?: MouseEventHandler<HTMLButtonElement>;
}

const StudyTableRow: FC<StudyTableRowProps> = props => {
  const {
    studyName,
    sensors,
    researcherCount,
    siteCount,
    participantCount,
    onEditClick,
    onDevicesClick,
    user,
    ...rest
  } = props;

  return (
    <tr {...rest}>
      <td>{studyName}</td>
      <td>{researcherCount} Researchers</td>
      <td>{sensors.length} Sensors</td>
      {siteCount > 0 &&
        <td>{siteCount} Sites</td>
      }
      {siteCount === 0 &&
        <td> No Sites</td>
      }
      <td>{participantCount} Participants</td>
      <td>
        <Row gap={'10px'}>
          <RowButton onClick={onDevicesClick}>Manage Devices</RowButton>
          {userIsStudyAdmin(user, studyName) &&
          <RowButton onClick={onEditClick}>Edit</RowButton>
          }
        </Row>
      </td>
    </tr>
  );
};

export default styled(StudyTableRow)``;

const RowButton = styled(BlockButton as any)`
  flex-direction: row;
  padding: 0 10px 0 10px;
  margin-top: 3px;
  width: 100%;

  ${PlusIcon} {
    margin-left: 14px;
  }
`;
