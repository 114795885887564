import UnwrapArray from './UnwrapArray';

/**
 * Takes an array, a predicate function, and a replace function.
 * Returns a new array where every value that returns `true` when passed to `predicate`
 * is replaced with the result `replace`.
 *
 * Example:
 *
 * ```ts
 * const array = [1,2,3,4,5];
 * const result = replaceWhere(
 *   array,
 *   v => v > 1 && v < 5,
 *   v => v * 10
 * ); // [1, 20, 30, 40, 5]
 * ```
 */
export default function replaceWhere<T extends any[], R>(
  array: T,
  predicate: (value: UnwrapArray<T>, index: number, array: T) => boolean,
  replace: (value: UnwrapArray<T>, index: number, array: T) => UnwrapArray<T>
): T {
  return array.map((value, index, self) => {
    if (predicate(value, index, self as T)) {
      return replace(value, index, self as T);
    }
    return value;
  }) as T;
}
