/**
 * Checks if the password given fits the password requirements
 * @param password: the password to check
 * @param email: a user's email address - if provided used to check that it's not in the user's password
 * @returns an error message as a string if the password does not satisfy the requirements | null if it does
 */
export default function checkPasswordRequirements(password: string, email?: string): string | null {
  if (password.length < 8) {
    return "Password must be at least 8 characters long";
  }
  if (password.search(/(.)\1\1+/) !== -1) { // 3 repeating characters
    return "Password cannot have 3 consecutive repeating characters (aaa, !!!)";
  }
  if (email && substringCompare(password, email.split('@')[0])) { // 3 repeating characters
    return "Password cannot contain 3 consecutive characters from your username" ;
  }
  let score = 0;
  if (password.search(/\d/) !== -1) { score += 1; } // Number
  if (password.search(/[A-Z]/) !== -1) { score += 1; } // UPPERCASE
  if (password.search(/[a-z]/) !== -1) { score += 1; } // lowercase
  if (password.search(/\W/) !== -1) { score += 1; } // Special character (counts non-english unicode as special)
  if (score < 3) {
    return "Password must contain at least 3 of the following: an uppercase letter, a lowercase letter, a number, " +
      "a special character."
  }
  return null
}

/**
 * Checks if strings share any substrings
 * @param string1: A string
 * @param string2: A string
 * @param substringLength: The length of the substring to look for. The default is 3
 * @returns: True if a match is found | False otherwise
 */
export function substringCompare(string1: string, string2: string, substringLength: number = 3): boolean {
  if (string1.length < substringLength || string2.length < substringLength) {
    return false
  }
  const matchString = string1.length > string2.length ? string2 : string1
  const compareString = string1.length <= string2.length ? string2 : string1
  let curIndex = 0
  const matchSubstrings = []
  while (curIndex + substringLength !== matchString.length + 1) {
    matchSubstrings.push(matchString.slice(curIndex, curIndex + substringLength))
    curIndex += 1
  }
  let returnVal = false;
  for (const substring of matchSubstrings) {
    returnVal = returnVal || (compareString.search(substring) !== -1)
  }
  return returnVal
}

/*
- At least 8 characters long
- 3 of the following 4 types of characters:
    An Uppercase letter
    A lowercase letter
    A number 0-9
    A Special character !@#$%^&*()
- You MAY create a pass-phrase including spaces if desired
- May NOT contain any part of your user name or email address
- May NOT contain repeating or consecutive numbers or letters: aaa, 111, abc, 123
May NOT be a re-use of your last 10 passwords
 */
