import { useState, useEffect } from 'react';
import axios from '../api/axios';

import { allDaysSince, DeviceUpload } from './dotplotUtils';

export const getDeviceUploadEvents = async (studyName: string, deviceID: string) => {
    const response = await axios.post(`/study/${studyName}/upload-events/device`, {
        deviceID
    });
    return response.data;
}

export const useDeviceUploadEvents = (studyName: string, deviceID: string) => {
    const [deviceUploads, setDeviceUploads] = useState<DeviceUpload[]>([])
    const [allDeviceDays, setAllDeviceDays] = useState<string[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const onLoad = async () => {
            const res = await getDeviceUploadEvents(studyName, deviceID)
            setDeviceUploads(res)
            const allDays = res.length > 0 ? allDaysSince(res[res.length - 1].timestamp) : []//allDaysBtwn(res[0].timestamp, res[res.length - 1].timestamp)
            setAllDeviceDays(allDays)
            setLoading(false)
         }

        onLoad()
    }, [studyName, deviceID])

    return {deviceUploads, allDeviceDays, loading}
}

export default useDeviceUploadEvents