/**
 * Checks if the device supports Screen Time. This function expects the osVersion to be
 * parsable by parseInt. If the osVersion is not parsable (i.e does not start with an int),
 * this function will return false.
 * @param osVersion a string representation of the os version (e.g. '15.1', '16.0', '16.0.1, '17', '17.0.1 iOS' are all valid)
 * @param isAppleDevice a boolean representing whether the device is an Apple device
 * @returns true if the device supports Screen Time, false if it does not or osVersion is not parsable
 */
export default function checkOSScreenTimeSupport(
  osVersion: string,
  isAppleDevice: boolean
): boolean {
  if (!isAppleDevice) {
    return false;
  }
  return (parseInt(osVersion) ?? 0) >= 17;
}
