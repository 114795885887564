import React, {FC, FormEvent, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import BlockButton from './BlockButton';
import Input from './Input';
import Form from './Form';
import noop from '../utils/noop';
import checkPasswordRequirements from "../shared/utils/checkPasswordRequirements";

interface PasswordResetFormValue {
  password: string;
}

interface PasswordResetFormProps {
  value?: PasswordResetFormValue;
  onChange?: (value: PasswordResetFormValue) => any;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => any;
}

export const PasswordResetFormDefaultValue: PasswordResetFormValue = {
  password: '',
};

const PasswordResetForm: FC<PasswordResetFormProps> = props => {
  const {
    onChange = noop,
    onSubmit = noop,
    value = PasswordResetFormDefaultValue,
    ...rest
  } = props;
  const [checkPassword, setCheckPassword] = useState("");
  const [disabled, setDisabled] = useState(true)
  const [matchError, setMatchError] = useState(true)
  const [passwordCheckError, setPasswordCheckError] = useState('')
  useEffect(() => {
    const match = value.password === checkPassword;
    setMatchError(!match && value.password.length > 0 && checkPassword.length > 0)
    if (value.password.length > 0) { setPasswordCheckError(checkPasswordRequirements(value.password) || ''); };
    setDisabled (value.password.length < 1 || !match || passwordCheckError !== '');
  });

  function handlePasswordChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      password: event.currentTarget.value
    });
  }
 function handleCheckPasswordChange(event: FormEvent<HTMLInputElement>) {
    setCheckPassword(event.currentTarget.value)
  }

  return (
    <Column {...rest}>
      <Form onSubmit={onSubmit}>
        <PasswordInput
          type="password"
          placeholder="Password"
          value={value.password}
          onChange={handlePasswordChange}
        />
        <PasswordInput
          type="password"
          placeholder="Confirm Password"
          value={checkPassword}
          onChange={handleCheckPasswordChange}
        />
        {matchError &&
          <ErrorText>Passwords must match</ErrorText>
        }
        {passwordCheckError !== '' &&
        <ErrorText>{passwordCheckError}</ErrorText>
        }
        <SubmitButton disabled={disabled}>Submit</SubmitButton>
      </Form>
    </Column>
  );
};

export default styled(PasswordResetForm)`
  width: 352px;
  max-width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const PasswordInput = styled(Input as any)`
  width: 100%;
  margin-bottom: 16px;
`;

const SubmitButton = styled(BlockButton as any)`
  width: 159px;
`;

const ErrorText = styled('div')`
  color: #a00;
  margin-bottom: 20px;
`;
