import React, { FC } from 'react';
import styled from 'styled-components/macro';
import BgImage from './BgImage';
import textLogoSrc from '../images/logo_text.png';
import textLogoDarkSrc from '../images/logo_text_dark.png';

interface TextLogoProps {
  dark?: boolean;
}

const TextLogo: FC<TextLogoProps> = props => {
  const { dark, ...rest } = props;
  const src = dark ? textLogoDarkSrc : textLogoSrc;

  return <BgImage {...rest} src={src} alt="Ears" />;
};

export default styled(TextLogo)`
  height: 100px;
  width: 100px;
`;
