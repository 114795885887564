import { useState, useEffect } from 'react';
import getCurrentUser from '../api/getCurrentUser';
import isLoggedIn from '../api/isLoggedIn';
import { DashboardUser } from '../shared';

/**
 * React hook for loading current dashboard user.
 */
export default function useUser(): [boolean, DashboardUser | null] {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<DashboardUser | null>(null);

  useEffect(() => {
    onMount();
  }, []);

  async function onMount() {
    const loggedInState = await isLoggedIn();
    setUser(loggedInState ? await getCurrentUser() : null);
    setLoading(false);
  }

  return [loading, user];
}
