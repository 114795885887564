import React, {FC, FormEvent, useEffect, useState} from 'react';
import Column from "../Column";
import Row from "../Row";
import styled from "styled-components/macro";
import TextArea from "../TextArea";
import Switch from "../Switch";
import Input from "../Input";
import noop from "../../utils/noop";
import Required from "../Required";
import {ScaleBranchingDefaultValue, ScaleBranchingRuleComponent} from "./ScaleBranchingRule";
import {ScaleQuestion, ScaleBranchingRule} from "../../models";
import {checkIfAllValuesUndefined, NumberValidator, StringValidator} from "../../shared/utils/Validators";
import {ValidatedInput} from "../Validation";
import {branchingRuleReservedValues} from "./BranchingRule";
import { ScaleRiskRuleComponent, ScaleRiskRuleDefaultValue } from './ScaleRiskRule';
import { ScaleRiskRule } from '../../models';

interface ScaleQuestionProps {
  value?: ScaleQuestion;
  surveyQuestionIDs: string[];
  currentQuestionIndex: number;
  onChange?: (value: ScaleQuestion) => any;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => any;
  canSwitch?: (value: boolean) => any;
  showRiskToggle?: boolean;
}

export const ScaleQuestionDefaultValue: ScaleQuestion = {
  questionId: "",
  questionHeader: "",
  questionBody: "",
  skipEnabled: true,
  minValue: 0,
  maxValue: 100,
  minimumValueDescription: "",
  maximumValueDescription: "",
  shouldHideRanges: false,
  riskRule: undefined,
  branchingRule: undefined
}

export const ScaleQuestionComponent: FC<ScaleQuestionProps> = props => {
  const {
    value = ScaleQuestionDefaultValue,
    onChange = noop,
    canSwitch = noop,
    showRiskToggle,
    ...rest
  } = props;

  const [validation, setValidation] = useState<Record<string, string | undefined>>({});
  const nextQuestion = props.surveyQuestionIDs.length > props.currentQuestionIndex+1 ? props.surveyQuestionIDs[props.currentQuestionIndex+1] : undefined

  useEffect(function validateRiskInputs(){
    if (!value.riskRule) return
    const minRiskValidation = new NumberValidator(value.riskRule?.alertOnMin)
      .noNaN()
      .max(value.riskRule?.alertOnMax, "Must be less than alert max")
      .min(value.minValue, "Must be within allowable responses")
      .max(value.maxValue, "Must be within allowable responses")
      .validate()
    const maxRiskValidation = new NumberValidator(value.riskRule?.alertOnMax)
      .noNaN()
      .min(value.riskRule?.alertOnMin, "Must be greater than alert min")
      .min(value.minValue, "Must be within allowable responses")
      .max(value.maxValue, "Must be within allowable responses")
      .validate()

    setValidation({
      ...validation,
      alertOnMin: minRiskValidation,
      alertOnMax: maxRiskValidation
    })
    
  }, [value.minValue, value.maxValue, value.riskRule] );

  useEffect(() => {
    let validationIsGood = checkIfAllValuesUndefined(validation);
    const validationRules = [
      new StringValidator(value.questionId).min(1).validate(),
      new StringValidator(value.questionHeader ?? "").min(1).validate(),
      new NumberValidator(value.minValue).noNaN().validate(),
      new NumberValidator(value.maxValue).noNaN().validate(),
    ];
    if (value.riskRule) {
      validationRules.push(
        new NumberValidator(value.riskRule?.alertOnMin)
        .noNaN()
        .min(0)
        .min(value.minValue)
        .max(value.riskRule?.alertOnMax)
        .validate()
      )
      validationRules.push(
        new NumberValidator(value.riskRule?.alertOnMax)
        .noNaN()
        .min(value.riskRule.alertOnMin)
        .max(value.maxValue)
        .validate()
      )
    }
    if (value.branchingRule) {
      validationRules.push(
        new StringValidator(value.branchingRule.defaultQuestionId).noEmpty().validate(),
        new StringValidator(value.branchingRule.destinationQuestionId).noEmpty().validate()
      )
    }
    validationRules.forEach((value) => {
      validationIsGood = validationIsGood && value === undefined ;
    });
    canSwitch(validationIsGood);
  });

  function handleQuestionIdChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value, 'QuestionID must contain at least one character')
      .alphaNum('Question ID can only contain of numbers of letters.')
      .min(1, 'Question ID must contain at least one character.')
      .unique(props.surveyQuestionIDs, 'Question IDs must be unique (match found for another question)', props.currentQuestionIndex)
      .unique(branchingRuleReservedValues, 'This value is reserved and cannot be used as a Question ID')
      .validate();
    setValidation({
      ...validation,
      questionId: error
    });
    onChange({
      ...value,
      questionId: event.currentTarget.value
    });
  }

  function handleQuestionHeaderChange(event: FormEvent<HTMLInputElement>) {
    const error = new StringValidator(event.currentTarget.value)
      .min(1, 'Question Header must contain at least one character')
      .noSpace('Question Header value cannot contain only spaces')
      .validate();
    setValidation({
      ...validation,
      questionHeader: error
    });
    onChange({
      ...value,
      questionHeader: event.currentTarget.value
    });
  }

  function handleBodyChange(event: FormEvent<HTMLTextAreaElement>) {
    onChange({
      ...value,
      questionBody: event.currentTarget.value
    });
  }

  function handleMinimumValueDescriptionChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      minimumValueDescription: event.currentTarget.value
    });
  }

  function handleMaximumValueDescriptionChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      maximumValueDescription: event.currentTarget.value
    });
  }

  function handleMinValueChange (event: FormEvent<HTMLInputElement>) {
    const minValue = parseInt(event.currentTarget.value);
    const error = new NumberValidator(minValue, 'Minimum value must contain at least one character.')
      .noNaN()
      .strictlyLessThan(value.maxValue, 'Minimum Value must be less than Maximum Value')
      .validate();
    setValidation({
      ...validation,
      minValue: error
    });
    onChange({
      ...value,
      minValue: minValue
    });
  }

  function handleMaxValueChange (event: FormEvent<HTMLInputElement>) {
    const maxValue = parseInt(event.currentTarget.value);
    const error = new NumberValidator(maxValue, 'Minimum value must contain at least one character.')
      .noNaN()
      .strictlyGreaterThan(value.minValue, 'Maximum Value must be greater than Minimum Value')
      .validate();
    setValidation({
      ...validation,
      maxValue: error
    });
    onChange({
      ...value,
      maxValue: parseInt(event.currentTarget.value)
    });
  }

  function toggleHideRanges(newState: boolean) {
    onChange({
      ...value,
      shouldHideRanges: newState
    });
  }

  function toggleSkip(newState: boolean) {
    onChange({
      ...value,
      skipEnabled: newState
    });
  }

  function toggleLogic(newState: boolean) {
    if (nextQuestion && newState) {
      onChange({
        ...value,
        branchingRule: {
          ...ScaleBranchingDefaultValue,
          defaultQuestionId: "",
          destinationQuestionId: ""
        }
      });
    } else {
      onChange({
        ...value,
        branchingRule: undefined
      });
    }
  }

  function toggleRisk (newState: boolean) {
    onChange({
      ...value,
      riskRule: newState ? {
        ...ScaleRiskRuleDefaultValue
      } : undefined
    })
    setValidation({
      ...validation,
      alertOnMin: undefined,
      alertOnMax: undefined
    })
  }

  function updateBranchingRule(rule: ScaleBranchingRule) {
    onChange({
      ...value,
      branchingRule: rule
    });
  }

  function updateRiskRule(rule: ScaleRiskRule) {

    onChange({
      ...value,
      riskRule: rule
    })
  }

  return (
    <Column>
      <h2>Scale Question</h2>
      <Required>
        <label>Question ID</label>
      </Required>
      <ValidatedInput
        message={validation.questionId}
        value={value.questionId}
        onChange={handleQuestionIdChange}
        placeholder="QuestionID"
      />
      <Required>
        <label>Question header</label>
      </Required>
      <ValidatedInput
        message={validation.questionHeader}
        value={value.questionHeader ?? undefined}
        onChange={handleQuestionHeaderChange}
        placeholder="Question Header"
      />
      <label>Question Body</label>
      <QuestionScaleInput
        placeholder="Question Body"
        value={value.questionBody}
        onChange={handleBodyChange}
      />
      <Required>
        <label>Minimum Value</label>
      </Required>
      <ValidatedInput
        message={validation.minValue}
        type='number'
        value={value.minValue}
        onChange={handleMinValueChange}
      />
      <label>Minimum Value Description</label>
      <Input
        value={value.minimumValueDescription ?? undefined}
        onChange={handleMinimumValueDescriptionChange}
        placeholder="Minimum Value Description"
      />
      <Required>
        <label>Maximum Value</label>
      </Required>
      <ValidatedInput
        message={validation.maxValue}
        type='number'
        value={value.maxValue}
        onChange={handleMaxValueChange}
      />
      <label>Maximum Value Description</label>
      <Input
        value={value.maximumValueDescription ?? undefined!}
        onChange={handleMaximumValueDescriptionChange}
        placeholder="Maximum Value Description"
      />
      {showRiskToggle && 
      <Row>
        <Switch
          checked={value.riskRule !== undefined && value.riskRule !== null}
          onChange={toggleRisk}
        />
        <label>Risk Alert</label>
      </Row>
      }
      {showRiskToggle && value.riskRule && 
        <Row>
          <ScaleRiskRuleComponent
            value={value.riskRule}
            validation={validation}
            questionValue={value}
            onChange={updateRiskRule}
          />
        </Row>
      }
      <Row>
        <Switch
          checked={value.shouldHideRanges ?? undefined!}
          onChange={toggleHideRanges}
        />
        <label>Hide Ranges</label>
      </Row>
      <Row>
        <Switch
          checked={value.skipEnabled}
          onChange={toggleSkip}
        />
        <label>Skippable</label>
      </Row>
      {nextQuestion &&
      <Row>
        <Switch
          checked={value.branchingRule !== undefined && value.branchingRule !== null}
          onChange={toggleLogic}
        />
        <label>Logic</label>
      </Row>
      }
      {value.branchingRule &&
      <Row>
        <ScaleBranchingRuleComponent
          surveyQuestionIDs={props.surveyQuestionIDs.slice(props.currentQuestionIndex+1)}
          value={value.branchingRule}
          onChange={updateBranchingRule}
        />
      </Row>
      }
    </Column>
  );
}


export default styled(ScaleQuestionComponent)`
  width: 500002px;
  max-width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const QuestionScaleInput = styled(TextArea as any)`
  width: 100%;
  margin-bottom: 16px;
`;

const Spacer = styled('div')`
  flex-grow: 1;
`;

const ScaleChoice = styled('li')`
    margin-bottom: .5em;
`;
