import React, {MouseEvent, useState} from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import AcceptInviteForm, {
  defaultValue as acceptInviteFormDefaultValue
} from '../components/AcceptInviteForm';
import Logo from '../components/Logo';
import TextLogo from '../components/TextLogo';
import getErrorText from '../utils/getErrorText';
import SpinnerOverlay from '../components/SpinnerOverlay';
import Alert, {AlertProps} from "../components/Alert";
import useSearchParams from '../utils/useSearchParams';
import acceptInvite from '../api/acceptInvite';
import {Redirect} from "@reach/router";
import BlockButton from "../components/BlockButton";


const searchParamKeys = [
  'invite_token',
  'researcher_email',
  'admin',
  'study_name'
];

const AcceptInvite: PageComponent = props => {
  const searchParams = useSearchParams(searchParamKeys);

  const inviteToken = searchParams.invite_token || '';
  const researcherEmail = searchParams.researcher_email || '';
  const studyName = searchParams.study_name || '';
  const admin = searchParams.admin === 'true';

  const [acceptInviteFormValue, setAcceptInviteFormValue] = useState(
    acceptInviteFormDefaultValue
  );
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [goHome, setGoHome] = useState(false)
  const [success, setSuccess] = useState(false)
  const [alertProps, setAlertProps] = useState<AlertProps | null>({
    titleText:"Acceptable Use Policy",
    message:"Please read this acceptable use policy carefully before using the EARS website operated by Ksana Health, Inc. Any violations may result in the immediate suspension or termination of your account. To report a violation of this policy, please contact security@ksanahealth.com.",
    showCancelButton: false,
    okButtonText: "I Accept",
  });

  async function handleSubmit() {
    try {
      const { password, passwordConfirmed } = acceptInviteFormValue;
      if (password !== passwordConfirmed) {
        throw new Error(`Passwords don't match.`);
      }
      setLoading(true);
      await acceptInvite({
        inviteToken,
        password
      });
      setSuccess(true)
    } catch (error) {
      setSubmitError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleGoHome(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setGoHome(true );
  }

  if (goHome) {
    return <Redirect to={"/"}/>;
  }

  return (
    <Page {...props}>
      {loading && <SpinnerOverlay />}
      <Logo />
      <h1>Research Dashboard Study Invite: {studyName}</h1>
      {submitError && <ErrorText>{getErrorText(submitError)}</ErrorText>}
      {success && <div>
          <SuccessText><h4>Signed up Successfully!</h4></SuccessText>
          <BlockButton onClick={handleGoHome}>Click here to go to the login page</BlockButton>
      </div>
      }
     {alertProps &&
      <Alert
        onSubmit={(response => setAlertProps(null))}
        titleText={alertProps.titleText}
        message={alertProps.message}
        showCancelButton={alertProps.showCancelButton}
        okButtonText={alertProps.okButtonText}
      />
    }
      
      {!success &&
        <AcceptInviteForm
            email={researcherEmail}
            admin={admin}
            value={acceptInviteFormValue}
            onChange={setAcceptInviteFormValue}
            onSubmit={handleSubmit}
        />
      }
    </Page>
  );
};

export default styled(AcceptInvite)`
  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
  justify-content: center;
  align-items: center;

  ${Logo} {
    height: 122.64px;
    width: 76.62px;
    margin-bottom: 26px;
  }

  ${TextLogo} {
    height: 50.46px;
    width: 188.44px;
    margin-bottom: 8px;
  }

  > h1 {
    color: white;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1.4em;
    text-align: center;
    margin: 0 0 1.5em 0;
  }
`;

const ErrorText = styled('div')`
  color: #a00;
  margin-bottom: 40px;
`;

const SuccessText= styled('div')`
  text-align: center;
  color: white;
  margin-bottom: 40px;
`;
