import validationError from './validationError';

function typeError(
  expectedType: string,
  value: unknown,
  valueName?: string
): Error {
  return validationError(
    `Expected ${valueName ||
      'value'} to be of type ${expectedType}. Got ${value}.`
  );
}

/**
 * Throws if `value` isn't a string.
 */
export function getString(value: unknown, valueName?: string): string {
  if (typeof value !== 'string') {
    throw typeError('string', value, valueName);
  }
  return value;
}

/**
 * Throws if `value` isn't a number.
 */
export function getNumber(value: unknown, valueName?: string): number {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    throw typeError('number', value, valueName);
  }
  return value;
}

/**
 * Throws if `value` isn't a number or if `value` is a decimal value.
 */
export function getInteger(value: unknown, valueName?: string): number {
  if (typeof value !== 'number' || Number.isNaN(value) || value % 1 !== 0) {
    throw typeError('number', value, valueName);
  }
  return value;
}

/**
 * Throws if `value` isn't a boolean.
 */
export function getBoolean(value: unknown, valueName?: string): boolean {
  if (typeof value !== 'boolean') {
    throw typeError('boolean', value, valueName);
  }
  return value;
}

/**
 * Throws if `value` isn't an object.
 */
export function getObject(
  value: unknown,
  valueName?: string
): Record<string, unknown> {
  if (value === null || typeof value !== 'object' || Array.isArray(value)) {
    throw typeError('object', value, valueName);
  }
  return value as Record<string, unknown>;
}

/**
 * Throws if `value` isn't an array.
 */
export function getArray(value: unknown, valueName?: string): unknown[] {
  if (!Array.isArray(value)) {
    throw typeError('array', value, valueName);
  }
  return value;
}

/**
 * Throws if `value` isn't an date.
 */
export function getDate(value: unknown, valueName?: string): Date {
  if (typeof value === 'number' || typeof value === 'string') {
    value = new Date(value);
  }
  if (!(value instanceof Date)) {
    throw typeError('array', value, valueName);
  }
  return value;
}

export default class From {
  static string = getString;
  static number = getNumber;
  static boolean = getBoolean;
  static object = getObject;
  static array = getArray;
  static date = getDate;

  static stringIn = (value: Record<string, unknown>, key: string) =>
    getString(value[key], key);
  static numberIn = (value: Record<string, unknown>, key: string) =>
    getNumber(value[key], key);
  static booleanIn = (value: Record<string, unknown>, key: string) =>
    getBoolean(value[key], key);
  static objectIn = (value: Record<string, unknown>, key: string) =>
    getObject(value[key], key);
  static arrayIn = (value: Record<string, unknown>, key: string) =>
    getArray(value[key], key);
  static dateIn = (value: Record<string, unknown>, key: string) =>
    getDate(value[key], key);

  static stringInOptional = (value: Record<string, unknown>, key: string) =>
    key in value ? getString(value[key], key) : undefined;
  static numberInOptional = (value: Record<string, unknown>, key: string) =>
    key in value ? getNumber(value[key], key) : undefined;
  static booleanInOptional = (value: Record<string, unknown>, key: string) =>
    key in value ? getBoolean(value[key], key) : undefined;
  static objectInOptional = (value: Record<string, unknown>, key: string) =>
    key in value ? getObject(value[key], key) : undefined;
  static arrayInOptional = (value: Record<string, unknown>, key: string) =>
    key in value ? getArray(value[key], key) : undefined;
  static dateInOptional = (value: Record<string, unknown>, key: string) =>
    key in value ? getDate(value[key], key) : undefined;
}
