import axios from './axios';
import {StudyVariable, StudyDetails, OLDStudyDetails} from '../shared';
import {SurveyPrefs} from "../models";

export async function getAssignedStudyNames(): Promise<string[]> {
  const response = await getAssignedStudies();
  return response.reduce((acc: string[], val) => {
    if (val.studyName !== undefined) {
      acc.push(val.studyName);
    }
    return acc;
  }, []);

}

export default async function getAssignedStudies(): Promise<SurveyPrefs[]> {
  const response = await axios.get('/studies/assigned');
  return response.data.studies;
}

export async function getAssignedStudiesDetail(): Promise<StudyDetails[]> {
  const response = await axios.get('/studies/assigned/details');
  return response.data.studyDetails;
}

export async function getOLDAssignedStudies(): Promise<StudyVariable[]> {
  const response = await axios.get('/oldstudies/assigned');
  return response.data.studies;
}

export async function getOLDAssignedStudiesDetail(): Promise<OLDStudyDetails[]> {
  const response = await axios.get('/oldstudies/assigned/details');
  return response.data.studyDetails;
}
