import React, { FC, useState, FormEvent } from 'react';
import styled from 'styled-components/macro';
import Input from './Input';
import Modal, { ModalProps } from './Modal';
import Column from './Column';
import BlockButton from './BlockButton';

export interface InputModalProps {
  inputLabel: string;
  buttonText?: string;
  validationError?: string;
  type?: string;
  initValue?: string | number;
  disableInput?: boolean;
  /**
   * function to call when the submission button is pressed.
   * Returning true indicates that the modal should be closed, while false
   * is to indicate that the modal should stay open
   */
  onSubmit: (value: string | number) => boolean;
}

const InputModal: FC<ModalProps & InputModalProps> = ({
  inputLabel,
  buttonText,
  validationError,
  type = 'text',
  onSubmit,
  onClose,
  initValue = '',
  disableInput = false,
  ...rest
}) => {
  const [value, setValue] = useState<string | number>(initValue);

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (onSubmit(value)) {
      if (onClose) {
        onClose();
      }
    }
  }

  return (
    <Modal onClose={onClose} {...rest}>
      <ModalForm>
        <label>{inputLabel}</label>
        <Input
          value={value}
          onChange={e => setValue(e.target.value)}
          type={type}
          disabled={disableInput}
        />
        <ValidationError>{validationError}</ValidationError>
        <BlockButton onClick={handleSubmit}>
          {buttonText || 'Submit'}
        </BlockButton>
      </ModalForm>
    </Modal>
  );
};

export default styled(InputModal)`
  label {
    margin-bottom: 5px;
  }

  ${BlockButton} {
    color: #ffffff;
    background-color: #0075e1;
    margin-top: 10px;
    width: 60px;
    align-self: center;
  }
`;

const ModalForm = styled(Column as any).attrs({ as: 'form' })``;

const ValidationError = styled.span`
  height: 30px;
  color: red;
`;
