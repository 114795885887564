import dayjs from "dayjs";
import { Device } from "../shared/Types";

export const calcDaysEnrolled = (device: Device) => {
  const { startDate, deactivationDate } = device;
  if (!startDate) return 0;

  // in ...dashboardDB tables:
  // start dates are milliseconds
  // deactivation dates are in seconds
  const end = deactivationDate ? dayjs.unix(deactivationDate) : dayjs();
  const start = dayjs(startDate);
  return end.diff(start, 'day');
  };