import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import Row from './Row';

interface SwitchProps {
  checked?: boolean;
  onChange?: (checked: boolean) => any;
  disabled?: boolean;
}

const Switch: FC<SwitchProps> = props => {
  const { checked, onChange, disabled, ...rest } = props;

  function handleClick() {
    if (onChange) {
      onChange(!checked);
    }
  }

  return (
    <Root
      {...rest}
      disabled={disabled}
      checked={checked}
      onClick={handleClick}
      data-checked={checked}
    >
      <Handle />
    </Root>
  );
};

export default styled(Switch)``;

const Root = styled(Row as any)<SwitchProps>`
  height: 16px;
  width: 32px;
  border-radius: 12px;
  transition: padding-left 0.2s, background-color 0.2s;

  ${props => css`
    background-color: ${props.checked ? '#0075E1' : '#baccde'};
    padding-left: ${props.checked ? 16 : 0}px;
    cursor: ${props.disabled ? 'default' : 'pointer'};
    opacity: ${props.disabled ? 0.5 : 1};

    > div {
      border-color: ${props.checked ? '#0075E1' : '#baccde'};
    }
  `}
`;

const Handle = styled('div')`
  height: 16px;
  width: 16px;
  border: 1px solid #0075e1;
  background-color: white;
  border-radius: 8px;
  transition: border-color 0.2s;
`;
