import { Configuration, PopupRequest } from "@azure/msal-browser";

const clientId = process.env.REACT_APP_MSAL_CLIENT_ID || "";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId,
		authority: "https://login.microsoftonline.com/organizations/",
		redirectUri: "/",
		postLogoutRedirectUri: "/"
	}
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [`${clientId}/.default`]
};