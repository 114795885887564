import React, { FC, FormEvent } from "react";
import { BoolRiskRule } from "../../models";
import noop from "../../utils/noop";
import Column from "../Column";
import Row from "../Row";

export const BoolRiskRuleDefaultValue: BoolRiskRule = {
    alertOn: true,
}

interface BoolRiskRuleProps {
    value?: BoolRiskRule;
    onChange?: (value: BoolRiskRule) => any
}

export const BoolRiskRuleComponent: FC<BoolRiskRuleProps> = props => {
    const {
        value = {...BoolRiskRuleDefaultValue},
        onChange = noop,
        ...rest
    } = props

    function selectChoice(event: FormEvent<HTMLSelectElement>) {
        onChange({
          ...value,
          alertOn: event.currentTarget.value == 'True'
        })
      }

    return <Column rowGap={'5px'}>
        <Row gap={'.5em'}>
            <span>Risk Alert if</span>
                <select 
                onChange={selectChoice} 
                value={value.alertOn ? 'True' : 'False'}>
                <option value={'True'}>True</option>
                <option value={'False'}>False</option>
            </select>
            <span> is selected</span>
        </Row>
    </Column>
}