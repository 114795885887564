import styled from "styled-components/macro";
import BlankButton from "./BlankButton";
import Color from "color";

const baseBackgroundColor = '#0075E1';

export const SmallButton = styled(BlankButton as any)`
  padding: 0 .5em 0 .5em;
  margin: 0 .5em 0 .5em;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.2s, color 0.2s;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: black;
  background-color: #e6e6e6;
  
  &.clear {
    background-color: transparent !important;
  }
  
  &:disabled {
    color: white;
    background-color: #baccde;
  }

  &:hover:not(:disabled) {
    background-color: ${Color(baseBackgroundColor)
  .darken(0.1)
  .string()};
  }

  &:active:not(:disabled) {
    background-color: ${Color(baseBackgroundColor)
  .darken(0.3)
  .string()};
  }
`;