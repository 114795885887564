import React from "react";
import styled from "styled-components";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.1em;
    text-align: justify;
    color: white;
    background-color: #424242;
    border-radius: 10px;
    margin: 1em 3em;
    padding: 1.5em;
`

const Title = styled.h2`
    text-align: center;
    margin-bottom: 1em;
`


const MessageBox = ({title = 'Title', message = 'Message.'}) => {
   return (
       <Box>
           <Title>{title}</Title>
           {message}
       </Box>
   )
}

export default MessageBox;