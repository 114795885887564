import Color from "color";
import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import createStudy from "../../api/createStudy";
import updateStudyResearchers from "../../api/updateStudyResearchers";
import updateSurveyPrefs from "../../api/updateSurveyPrefs";
import { SurveyPrefs } from "../../models";
import { Researcher } from "../../shared";
import noop from "../../utils/noop";
import { recursiveRemoveProperties } from "../../utils/recursiveRemoveProperties";
import BlockButton from "../BlockButton";
import Column from "../Column";
import Modal, { ModalProps } from "../Modal";
import Spinner from "../Spinner";

interface Props extends ModalProps {
  onClose?: () => any;
  StudyName: string;
  surveyPrefs: SurveyPrefs;
  researchers: Researcher[];
  handleGoodSubmission?: () => any;
  updateStudy: boolean;
}

const StudySubmitModal: FC<Props> = (props) => {
  const {
    onClose = noop,
    handleGoodSubmission = onClose,
    StudyName,
    surveyPrefs,
    researchers,
    updateStudy,
    ...rest
  } = props;
  const [submissionError, setSubmissionError] = useState("");
  const [additionalErrorInfo, setAdditionalErrorInfo] = useState("");
  const [uploading, setUploading] = useState(false);
  const [successfullyUpdated, setSuccessfullyUpdated] = useState(false);

  /**
   * Performs the upload operation after preparing the data for upload.
   * @returns {Promise<void>} A promise that resolves when the upload operation is complete.
   */
  async function performUpload() {
    let errors: unknown[] = [];
    const sites: string[] =
      (surveyPrefs.studySites?.filter((item) => item !== null) as string[]) ??
      [];
    const dedupedSensors = Array.from(new Set(surveyPrefs.activeSensors));
    const prefsCopy = { ...surveyPrefs, activeSensors: dedupedSensors };
    recursiveRemoveProperties(prefsCopy, [
      "__typename",
      "createdAt",
      "updatedAt",
      "_deleted",
      "_lastChangedAt",
    ]);
    const options = {
      studyName: StudyName,
      studySites: sites,
      researchers: researchers,
      surveyPrefs: prefsCopy,
    };
    try {
      if (updateStudy) {
        await updateStudyResearchers({
          studyName: StudyName,
          researchers: researchers,
        });
        await updateSurveyPrefs(prefsCopy);
      } else {
        await createStudy(options);
      }
      setSuccessfullyUpdated(true);
    } catch (createError) {
      errors.push(createError);
      setAdditionalErrorInfo(
        createError?.response?.data?.error?.message ??
          "No additional information available"
      );
      setSubmissionError(`Failed to ${updateStudy ? "update" : "create"} ${
        surveyPrefs.studyName
      }. Please try again and reach out 
                         to Ksana Health if this issue persists.`);
      setSuccessfullyUpdated(false);
    }
  }

  async function handleSubmit() {
    setSubmissionError("");
    if (!uploading) {
      setUploading(true);
      await performUpload();
      setUploading(false);
    }
  }

  if (uploading) {
    return (
      <Modal {...rest}>
        <Column>
          {updateStudy && <h1>Updating Study...</h1>}
          {!updateStudy && <h1>Creating Study...</h1>}
          <Spinner />
        </Column>
      </Modal>
    );
  }

  return (
    <Modal {...rest}>
      {!submissionError && !successfullyUpdated && (
        <Column>
          {updateStudy && <h1>Update {StudyName}</h1>}
          {!updateStudy && <h1>Create {StudyName}</h1>}

          <p>Are you sure you want to submit this survey?</p>
          <BlockButton onClick={handleSubmit}>Submit Survey</BlockButton>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
        </Column>
      )}
      {!submissionError && successfullyUpdated && (
        <Column>
          <h1>Submited {StudyName}</h1>
          <p>Success!</p>
          <BlockButton onClick={handleGoodSubmission}>Close</BlockButton>
        </Column>
      )}
      {submissionError && (
        <Column>
          <h1>Submit {StudyName}</h1>
          <h2>Error Occured</h2>
          <p>{submissionError}</p>
          <p>{additionalErrorInfo}</p>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
        </Column>
      )}
    </Modal>
  );
};

export default styled(StudySubmitModal)``;

const CancelButton = styled(BlockButton as any)`
  background-color: lightgray;
  margin: 14px 0 0 0;

  &:hover:not(:disabled) {
    background-color: ${Color("red").darken(0.2).string()};
  }
`;
