import axios from './axios';

interface Options {
  inviteToken: string;
  password: string;
}

export default async function acceptInvite(options: Options) {
  await axios.post('/accept-invite', options);
}
