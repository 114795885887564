import axios from 'axios';
import logout from './logout';

const apiAxios = axios.create({
  baseURL: '/api'
});

// Allow axios to be accessed through the console during development.
if (process.env.NODE_ENV !== 'production') {
  (window as unknown as Window & { axios: any }).axios = apiAxios;
}

// Logout automatically if a 401 unauthorized response is received.
apiAxios.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      await logout();
    }
    throw error;
  }
);

export default apiAxios;
