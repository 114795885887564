import React, { useState } from "react";
import styled from "styled-components/macro";
import Page, { PageComponent } from "../components/Page";
import LoginForm, { loginFormDefaultValue } from "../components/LoginForm";
import Logo from "../components/Logo";
import TextLogo from "../components/TextLogo";
import login from "../api/login";
import getErrorText from "../utils/getErrorText";
import SpinnerOverlay from "../components/SpinnerOverlay";
import Link from "../components/Link";
import KsanaLogo from "../components/KsanaLogo";

const Login: PageComponent = (props) => {
  const [credentials, setCredentials] = useState(loginFormDefaultValue);
  const [loginError, setLoginError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);
      await login(credentials);
    } catch (error) {
      setLoginError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page {...props}>
      {loading && <SpinnerOverlay />}
      <Logo />
      <h1>
        Research Dashboard
        <br />v 2.4.0
      </h1>
      {loginError && <ErrorText>{getErrorText(loginError)}</ErrorText>}
      <LoginForm
        value={credentials}
        onChange={setCredentials}
        onSubmit={handleSubmit}
      />
      <Link to={"/forgotten-password"}>Forgot Password?</Link>
    </Page>
  );
};

export default styled(Login)`
  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
  justify-content: center;
  align-items: center;

  ${Logo} {
    height: 122.64px;
    width: 76.62px;
    margin-bottom: 26px;
  }

  ${TextLogo} {
    height: 50.46px;
    width: 188.44px;
    margin-bottom: 8px;
  }

  ${Link} {
    margin-top: 10px;
  }

  > h1 {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
  }
`;

const ErrorText = styled("div")`
  color: #a00;
  margin-bottom: 40px;
`;
