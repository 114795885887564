import React, { FC, FormEvent } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import Input from './Input';
import noop from '../utils/noop';
import toggleArrayValue from '../utils/toggleArrayValue';
import { EMADay, EMADayString } from '../shared';

export interface Props {
  value?: Value;
  onChange?: (value: Value) => any;
}

export interface Value {
  emaDailyStart: string;
  emaDailyEnd: string;
  emaHoursBetween: number;
  emaPhaseFrequency: number;
  emaPhaseBreak: number;
  emaWeekDays: EMADayString[];
  emaVariesDuringWeek: boolean;
  phaseAutoScheduled: boolean;
  emaWeekDay: {
    [key: string]: EMADay;
  }[];
}

const defaultEMADay: EMADay = {
  emaDailyStart: '08:00:00',
  emaDailyEnd: '23:59:59'
};

export const defaultValue: Value = {
  emaDailyStart: '08:00:00',
  emaDailyEnd: '23:59:59',
  emaHoursBetween: 2,
  emaPhaseFrequency: 7,
  emaPhaseBreak: 30,
  emaVariesDuringWeek: false,
  emaWeekDays: Object.values(EMADayString),
  phaseAutoScheduled: false,
  emaWeekDay: [
    {
      [EMADayString.Mon]: defaultEMADay,
      [EMADayString.Tue]: defaultEMADay,
      [EMADayString.Wed]: defaultEMADay,
      [EMADayString.Thu]: defaultEMADay,
      [EMADayString.Fri]: defaultEMADay,
      [EMADayString.Sat]: defaultEMADay,
      [EMADayString.Sun]: defaultEMADay
    }
  ]
};

const allWeekDays = Object.values(EMADayString);

const EmaForm: FC<Props> = props => {
  const { value = defaultValue, onChange = noop, ...rest } = props;
  const {
    emaDailyStart,
    emaDailyEnd,
    emaPhaseFrequency,
    emaPhaseBreak,
    emaVariesDuringWeek,
    emaWeekDays,
    emaWeekDay,
    phaseAutoScheduled
  } = value;

  function handleChange(change: Partial<Value>) {
    onChange({ ...value, ...change });
  }

  function handleStartDateChange(event: FormEvent<HTMLInputElement>) {
    handleChange({ emaDailyStart: event.currentTarget.value });
  }

  function handleEndDateChange(event: FormEvent<HTMLInputElement>) {
    handleChange({ emaDailyEnd: event.currentTarget.value });
  }

  function handleVariesDuringWeekToggle() {
    handleChange({ emaVariesDuringWeek: !emaVariesDuringWeek });
  }

  function handleFrequencyChange(event: FormEvent<HTMLInputElement>) {
    handleChange({
      emaPhaseFrequency: parseInt(event.currentTarget.value, 10) || 0
    });
  }

  function handleAutoScheduleToggle() {
    handleChange({ phaseAutoScheduled: !phaseAutoScheduled });
  }

  function handleToggleWeekday(day: EMADayString) {
    handleChange({
      emaWeekDays: toggleArrayValue(emaWeekDays, day)
    });
  }

  function handleDayStartTimeChange(
    day: EMADayString,
    event: FormEvent<HTMLInputElement>
  ) {
    handleChange({
      emaWeekDay: [
        {
          ...(emaWeekDay[0] || {}),
          [day]: {
            ...((emaWeekDay[0] || {})[day] || defaultEMADay),
            emaDailyStart: event.currentTarget.value
          }
        }
      ]
    });
  }

  function handleDayEndTimeChange(
    day: EMADayString,
    event: FormEvent<HTMLInputElement>
  ) {
    handleChange({
      emaWeekDay: [
        {
          ...(emaWeekDay[0] || {}),
          [day]: {
            ...((emaWeekDay[0] || {})[day] || defaultEMADay),
            emaDailyEnd: event.currentTarget.value
          }
        }
      ]
    });
  }

  function handlePhaseBreakChange(event: FormEvent<HTMLInputElement>) {
    handleChange({
      emaPhaseBreak: parseInt(event.currentTarget.value, 10) || 0
    });
  }

  return (
    <Row {...rest}>
      <FormColumn>
        <CheckboxRow onClick={handleVariesDuringWeekToggle}>
          <input type="checkbox" checked={emaVariesDuringWeek} />
          <label>EMA scheduling period varies during week</label>
        </CheckboxRow>
        <DatesRow hidden={emaVariesDuringWeek}>
          <InputRow>
            <label>Start:</label>
            <Input value={emaDailyStart} onChange={handleStartDateChange} />
          </InputRow>
          <InputRow>
            <label>End:</label>
            <Input value={emaDailyEnd} onChange={handleEndDateChange} />
          </InputRow>
        </DatesRow>
        <WeekDayList hidden={!emaVariesDuringWeek}>
          {allWeekDays.map(day => {
            const { emaDailyStart, emaDailyEnd } =
              (emaWeekDay[0] || {})[day] || defaultEMADay;
            const enabled = emaWeekDays.includes(day);
            return (
              <WeekDayRow key={day} faded={!enabled}>
                <input
                  type="checkbox"
                  disabled={!emaVariesDuringWeek}
                  checked={enabled}
                  onChange={() => handleToggleWeekday(day)}
                />
                <label>{day} Start:</label>
                <Input
                  disabled={!emaVariesDuringWeek}
                  value={emaDailyStart}
                  onChange={event => handleDayStartTimeChange(day, event)}
                />
                <label>{day} End:</label>
                <Input
                  disabled={!emaVariesDuringWeek}
                  value={emaDailyEnd}
                  onChange={event => handleDayEndTimeChange(day, event)}
                />
              </WeekDayRow>
            );
          })}
        </WeekDayList>
      </FormColumn>
      <FormColumn>
        <InputColumn>
          <label>Number of days EMA survey will appear (EMA Phase)</label>
          <Input
            type="number"
            value={emaPhaseFrequency}
            onChange={handleFrequencyChange}
          />
        </InputColumn>
        <CheckboxRow onClick={handleAutoScheduleToggle}>
          <input type="checkbox" checked={phaseAutoScheduled} />
          <label>EMA Auto Schedule</label>
        </CheckboxRow>
        {phaseAutoScheduled && (
          <InputColumn>
            <label>Number of days between EMA phases</label>
            <Input value={emaPhaseBreak} onChange={handlePhaseBreakChange} />
          </InputColumn>
        )}
      </FormColumn>
    </Row>
  );
};

export default styled(EmaForm)`
  background-color: #e5ebf1;
`;

const FormColumn = styled(Column as any)`
  & + & {
    margin-left: 30px;
  }
`;

const InputRow = styled(Row as any)`
  align-items: center;

  > label + ${Input}, > label + input,
  > ${Input} + label,
  > input + label {
    margin-left: 16px;
  }
`;

const InputColumn = styled(Column as any)`
  > label + ${Input}, > label + input,
  > ${Input} + label,
  > input + label {
    margin-top: 16px;
  }
`;

const DatesRow = styled(Row as any)<{ hidden?: boolean }>`
  display: ${props => (props.hidden ? 'none' : 'flex')};

  * + & {
    margin-top: 16px;
  }

  ${InputRow} + ${InputRow} {
    margin-left: 16px;
  }
`;

const CheckboxRow = styled(InputRow as any)`
  cursor: pointer;

  * + & {
    margin-top: 16px;
  }
`;

const WeekDayList = styled(Column as any)<{ hidden?: boolean }>`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  margin-top: 16px;
`;

const WeekDayRow = styled(Row as any)<{ faded?: boolean }>`
  align-items: center;
  opacity: ${props => (props.faded ? 0.5 : 1)};
  transition: opacity 0.3s;

  & + & {
    margin-top: 16px;
  }

  label {
    margin-right: 16px;
    margin-left: 16px;
    width: 75px;
  }
`;
