import { navigate } from "@reach/router";
import React from "react";
import styled from "styled-components/macro";
import { StudySurvey } from "../../models";
import {
  DuplicateButton,
  EMAButton,
  EMARow,
  EMATable,
} from "../../pages/StudyCreator";
import { SCREENTIME_EMA_NAME } from "../../shared/Constants";
import { defaultMaxSurveys } from "../../utils/settingsConstants";
import { alertMounter } from "../Alert";
import BlockButton from "../BlockButton";
import Column from "../Column";
import PlusIcon from "../PlusIcon";
import { Spacer } from "../Row";
import XButton from "../XButton";

interface SurveyTableProps {
  surveyList: StudySurvey[];
  maxSurveys: number;
  onAddEMA: () => any;
  updateSurveyList: (surveyList: StudySurvey[]) => any;
  enableCreation: boolean;
}

export default function SurveyTable(props: SurveyTableProps) {
  const {
    surveyList,
    maxSurveys,
    onAddEMA,
    updateSurveyList,
    enableCreation,
  } = props;

  function openEMA(idx: number) {
    let randomized = surveyList[idx].random;
    navigate(`/create-ema`, {
      state: { randomized: randomized, surveyIndex: idx },
    });
  }

  function duplicateEMA(key: number) {
    const EMAList = [...surveyList];
    if (EMAList[key]) {
      const surveyType = EMAList[key].random ? "random" : "exact";
      // create dupe with same values but update name
      const dupe = {
        ...EMAList[key],
        [surveyType]: {
          ...EMAList[key]![surveyType],
          surveyId: EMAList[key]![surveyType]!.surveyId + "1",
        },
      };
      EMAList.push(dupe as StudySurvey);
      updateSurveyList(EMAList);
    }
  }

  function removeEMA(key: number) {
    let EMAList = [...surveyList];
    const surveyTitle =
      EMAList[key].exact?.surveyId ??
      EMAList[key].random?.surveyId ??
      "MISSING TITLE";
    // remove from temp list after getting title.
    EMAList.splice(key, 1);
    // show a confirmation before removing and only remove if the answer is yes
    alertMounter({
      titleText: `Delete Survey?`,
      message: `Confirm deletion of '${surveyTitle}' survey. This cannot be undone.`,
      showCancelButton: true,
      cancelButtonText: "Cancel",
      okButtonText: "Delete",
      // if response is affirmative, update the survey list
      onSubmit: (response: boolean) => {
        if (response) updateSurveyList(EMAList);
      },
    });
  }

  return (
    <Column>
      <p>
        <strong>Study Surveys</strong> - The "EMA" sensor must be enabled to add
        surveys.
      </p>
      {surveyList && surveyList.length > 0 && (
        <EMATable data-cy="studyTable">
          {surveyList.map((item: StudySurvey | null, idx: number) => {
            const lastItem = surveyList.length - 1;
            if (item && surveyList) {
              const surveyTitle =
                item.random?.surveyId ??
                item.exact?.surveyId ??
                "Blank Survey ID";
              const isScreenTimeEMA = surveyTitle === SCREENTIME_EMA_NAME;
              return (
                <EMARow key={idx} last={idx === lastItem} data-cy="EMARow">
                  {!isScreenTimeEMA && (
                    <>
                      <EMAButton onClick={() => openEMA(idx)}>
                        {surveyTitle}
                      </EMAButton>
                      <Spacer />
                      <DuplicateButton onClick={() => duplicateEMA(idx)}>
                        <u>Duplicate</u>
                      </DuplicateButton>
                      <XButton onClick={() => removeEMA(idx)} />
                    </>
                  )}
                  {isScreenTimeEMA && (
                    <>
                      <EMAButton disabled={true}>{surveyTitle}</EMAButton>
                      <Spacer />
                      <div style={{ zIndex: -10000 }}>
                        <XButton onClick={() => removeEMA(idx)} />
                      </div>
                    </>
                  )}
                </EMARow>
              );
            } else {
              return <></>;
            }
          })}
        </EMATable>
      )}
      {surveyList && surveyList.length >= (maxSurveys ?? defaultMaxSurveys) ? (
        <p>
          <strong>This study has reached the maximum number of surveys.</strong>
        </p>
      ) : (
        <AddEMAButton onClick={onAddEMA} disabled={!enableCreation}>
          Add Survey <PlusIcon />
        </AddEMAButton>
      )}
    </Column>
  );
}

const AddEMAButton = styled(BlockButton as any)`
  margin: 1em 0 0 0;
  flex-direction: row;

  ${PlusIcon} {
    margin-left: 1em;
  }
`;
