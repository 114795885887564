import React, {FC, FormEvent, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import BlockButton from './BlockButton';
import Input from './Input';
import Form from './Form';
import noop from '../utils/noop';
import Row from './Row';
import checkPasswordRequirements from "../shared/utils/checkPasswordRequirements";

interface AcceptInviteFormValue {
  password: string;
  passwordConfirmed: string;
}

interface AcceptInviteFormProps {
  email: string;
  admin: boolean;
  value?: AcceptInviteFormValue;
  onChange?: (value: AcceptInviteFormValue) => any;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => any;
}

export const defaultValue: AcceptInviteFormValue = {
  password: '',
  passwordConfirmed: ''
};

const AcceptInviteForm: FC<AcceptInviteFormProps> = props => {
  const {
    onChange = noop,
    onSubmit = noop,
    value = defaultValue,
    email,
    admin,
    ...rest
  } = props;
  const [disabled, setDisabled] = useState(true)
  const [passwordCheckError, setPasswordCheckError] = useState('')
  const [matchError, setMatchError] = useState(true)

  useEffect(() => {
    const match = value.password === value.passwordConfirmed;
    setMatchError(!match && value.password.length > 0 && value.passwordConfirmed.length > 0)
    if ( value.password.length > 0) { setPasswordCheckError(checkPasswordRequirements(value.password) || ''); };
    setDisabled (value.password.length < 1 || !match || passwordCheckError !== '');
  });

  function handlePasswordChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      password: event.currentTarget.value
    });
  }

  function handlePasswordConfirmedChange(event: FormEvent<HTMLInputElement>) {
    onChange({
      ...value,
      passwordConfirmed: event.currentTarget.value
    });
  }

  return (
    <Column {...rest}>
      <Form onSubmit={onSubmit}>
        <Input disabled value={email} />
        <Input
          type="password"
          placeholder="Password"
          value={value.password}
          onChange={handlePasswordChange}
        />
        <Input
          type="password"
          placeholder="Password Confirmed"
          value={value.passwordConfirmed}
          onChange={handlePasswordConfirmedChange}
        />
        <AdminRow>
          <label>Admin</label>
          <input type="checkbox" readOnly checked={admin} />
        </AdminRow>
        <SubmitButton disabled={disabled}>Register</SubmitButton>
        {matchError &&
        <ErrorText>Passwords must match</ErrorText>
        }
        {passwordCheckError !== '' && !matchError &&
        <ErrorText>{passwordCheckError}</ErrorText>
        }
      </Form>
    </Column>
  );
};

export default styled(AcceptInviteForm)`
  width: 352px;
  max-width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  ${Input} {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const SubmitButton = styled(BlockButton as any)`
  width: 159px;
`;

const AdminRow = styled(Row as any)`
  margin-bottom: 16px;
  align-items: center;

  input {
    margin-left: 16px;
    opacity: 0.5;
  }

  label {
    color: white;
  }
`;

const ErrorText = styled('div')`
  color: #a00;
  margin: 20px auto 20px auto;
`;
