import React, { FC, ChangeEventHandler } from 'react';
import styled from 'styled-components/macro';
import Row from './Row';

interface SelectProps {
  disabled?: boolean;
  value?: string | number | null;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  placeholder?: string;
}

const Select: FC<SelectProps> = props => {
  const { value, disabled, onChange, placeholder, children, ...rest } = props;

  return (
    <Root
      {...rest}
      showingPlaceholder={placeholder ? !value || value === '' : false}
    >
      <select value={value || ''} disabled={disabled} onChange={onChange}>
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {children}
      </select>
      <SelectIcon disabled={!!disabled} />
    </Root>
  );
};

export default styled(Select)``;

const Root = styled(Row as any)<{ showingPlaceholder: boolean }>`
  height: 40px;

  select {
    -webkit-appearance: none;
    flex: 1 1 auto;
    height: 100%;
    padding: 0 0 0 13px;
    color: ${props => (props.showingPlaceholder ? '#BACCDE' : '#0B1C4A')};
    border-radius: 7px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
`;

const SelectIcon = styled(Row as any)<{ disabled: boolean }>`
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  background-color: ${props => (props.disabled ? '#BBB' : '#0075e1')};
  border-radius: 7px;
  pointer-events: none;

  &:after {
    content: '';
    display: block;
    width: 33%;
    height: 33%;
    border-top: 2px solid white;
    border-left: 2px solid white;
    transform: translate(0, -20%) rotate(225deg);
    transition: transform 0.2s;
  }
`;
