import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import Row from './Row';

interface DropdownMenuHeaderProps {
  active?: boolean;
  style?: any;
  onClick?: () => any;
}

const DropdownMenuHeader: FC = props => {
  return <Row {...props} />;
};

export default styled(DropdownMenuHeader)<DropdownMenuHeaderProps>`
  color: #0b1c4a;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  justify-content: center;
  align-items: center;
  background-color: #0075e1;
  border-radius: 7px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;

  ${props => css`
    background-color: ${props.active ? '#0075e1' : 'white'};
    color: ${props.active ? 'white' : '#0b1c4a'};
  `}
`;
