import {
  ShuffleSequence,
  SurveyQuestion,
} from "../../models";
import React, {ChangeEvent} from "react";
import Row, {Spacer} from "../Row";
import Switch from "../Switch";
import styled from "styled-components/macro";
import Column from "../Column";
import XButton from "../XButton";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {alertMounter} from "../Alert";
import BlankButton from "../BlankButton";
import {SmallButton} from "../SmallButton";
import {BooleanQuestionDefaultValue} from "./BooleanQuestion";
import {ChoiceQuestionDefaultValue} from "./ChoiceQuestion";
import {ScaleQuestionDefaultValue} from "./ScaleQuestion";
import {TextQuestionDefaultValue} from "./TextQuestion";
import {TimeOfDayQuestionDefaultValue} from "./TimeOfDayQuestion";
import noop from "../../utils/noop";
import {TextInfoDefaultValue} from "./TextInfoQuestion";
import { branchedFrom } from "../../utils/branchingFrom";
import { DashboardSettings } from "../../shared/DashboardSettings";
import getBranchingRule, { clearBranchingRule } from "../../utils/getBranchingRule";

export enum QUESTIONTYPES {
  Choice,
  Bool,
  Scale,
  TimeOfDay,
  Text,
  TextInfo
}
export interface QuestionControlPanelValue {
  questions: Array<SurveyQuestion | null>,
  shuffleSequence?: ShuffleSequence,
  containsRiskAlerts?: boolean,
  currentQuestion?: number
}

const QuestionControlPanelDefaultValue: QuestionControlPanelValue = {
  questions: [],
  shuffleSequence: undefined,
  containsRiskAlerts: undefined,
  currentQuestion: undefined
}

interface QuestionControlPanelProps {
  value: QuestionControlPanelValue
  onChange?: (value: QuestionControlPanelValue) => any
  toggleContainsRiskAlerts?: (riskAlertToggle?: boolean | undefined) => any
  setCanSwitch?: (value: boolean)  => any
  dashboardSettings?: DashboardSettings
}

const newQuestionDefaultValue: SurveyQuestion = {
  bool: undefined,
  choice: undefined,
  scale: undefined,
  textEntry: undefined,
  timeOfDay: undefined,
  info: undefined,
};

function QuestionControlPanel(props: QuestionControlPanelProps) {
  const {
    value =  {...QuestionControlPanelDefaultValue},
    onChange = noop,
    toggleContainsRiskAlerts = noop,
    setCanSwitch = noop,
    dashboardSettings,
    ...rest
  } = props;

  function updateContainsShuffleSequence(newState: boolean) {
    const shuffle = newState ? (value.shuffleSequence ?? {startIndex: 0, endIndex: 0}) : undefined
    onChange({
      ...value,
      shuffleSequence: shuffle,
    })
  }

  function getQuestionID(question: SurveyQuestion): string {
    let id = '';
    if (question.bool) {
      id = question.bool.questionId ?? 'QuestionID';
    } else if (question.choice) {
      id = question.choice.questionId ?? 'QuestionID';
    } else if (question.scale) {
      id = question.scale.questionId ?? 'QuestionID';
    } else if (question.textEntry) {
      id = question.textEntry.questionId ?? 'QuestionID';
    } else if (question.timeOfDay) {
      id = question.timeOfDay.questionId ?? 'QuestionID';
    } else if (question.info) {
      id = question.info.questionId ?? 'QuestionID';
    }
    return id || 'QuestionID';
  }

  function removeQuestion(key: number) {
    const branchingQuestions = branchedFrom(key, value.questions)
    const currentQuestionID = getQuestionID(value.questions[key] as SurveyQuestion)
    if (branchingQuestions.length > 0){
      alertMounter({
        titleText: `Cannot Delete Question "${currentQuestionID}"`,
        message: `Change or remove the branching logic in the following questions before deleting: ${branchingQuestions}`,
        showCancelButton: false,
      });
      return
    }
    const questionsCopy = [...value.questions]
    questionsCopy.splice(key, 1);
    onChange({
      ...value,
      questions: questionsCopy
    })
  }

  function addQuestion(questionType: QUESTIONTYPES) {
    let newQuestion: SurveyQuestion;
    switch (questionType) {
      case QUESTIONTYPES.Bool:
        newQuestion = new SurveyQuestion({...newQuestionDefaultValue, bool: BooleanQuestionDefaultValue});
        break;
      case QUESTIONTYPES.Choice:
        newQuestion = new SurveyQuestion({...newQuestionDefaultValue, choice: ChoiceQuestionDefaultValue});
        break;
      case QUESTIONTYPES.Scale:
        newQuestion = new SurveyQuestion({...newQuestionDefaultValue, scale: ScaleQuestionDefaultValue});
        break;
      case QUESTIONTYPES.Text:
        newQuestion = new SurveyQuestion({...newQuestionDefaultValue, textEntry: TextQuestionDefaultValue});
        break;
      case QUESTIONTYPES.TimeOfDay:
        newQuestion = new SurveyQuestion({...newQuestionDefaultValue, timeOfDay: TimeOfDayQuestionDefaultValue});
        break;
      case QUESTIONTYPES.TextInfo:
        newQuestion = new SurveyQuestion({...newQuestionDefaultValue, info: TextInfoDefaultValue});
        break;
    }
    const questionsCopy = [...value.questions]
    questionsCopy.push(newQuestion);
    onChange({
      questions: questionsCopy,
      currentQuestion: value.questions.length
    })
  }

  function moveQuestionUp(key: number) {
    const branchingQuestions = branchedFrom(key, value.questions)
    const currentQuestionID = getQuestionID(value.questions[key] as SurveyQuestion)
    if (branchingQuestions.length > 0){
      alertMounter({
        titleText: `Cannot Move Question "${currentQuestionID}"`,
        message: `Change or remove the branching logic in the following questions before moving: ${branchingQuestions}`,
        showCancelButton: false,
      });
      return
    }
    if (key > 0 && key < value.questions.length) {
      const tempQuestion = value.questions[key - 1];
      const questionsCopy = [...value.questions];
      questionsCopy[key - 1] = clearBranchingRule(questionsCopy[key] as SurveyQuestion);
      questionsCopy[key] = tempQuestion
      onChange({
        ...value,
        questions: questionsCopy
      });
    }
  }

  function moveQuestionDown(key: number) {
    const branchingQuestions = branchedFrom(key, value.questions)
    const currentQuestionID = getQuestionID(value.questions[key] as SurveyQuestion)
    if (branchingQuestions.length > 0){
      alertMounter({
        titleText: `Cannot Move Question "${currentQuestionID}"`,
        message: `Change or remove the branching logic in the following questions before moving: ${branchingQuestions}`,
        showCancelButton: false,
      });
      return
    }
    if (key >= 0 && key < value.questions.length - 1) {
      const tempQuestion = value.questions[key + 1];
      const questionsCopy = [...value.questions];
      questionsCopy[key + 1] = clearBranchingRule(questionsCopy[key] as SurveyQuestion);
      questionsCopy[key] = tempQuestion;
      onChange({
        ...value,
        questions: questionsCopy
      });
    }
  }

  function updateCurrentQuestion(index: number) {
    onChange({
      ...value,
      currentQuestion: index
    });
  }

  const updateShuffleSequence = (start: boolean) => (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.currentTarget.value);
    const sequenceStart = start ? newValue : value.shuffleSequence ? value.shuffleSequence.startIndex : 0;
    const sequenceEnd = !start ? newValue: value.shuffleSequence ? value.shuffleSequence.endIndex : 0;
    onChange({
      ...value,
      shuffleSequence: {startIndex: sequenceStart, endIndex: sequenceEnd}
    });
  }

  return <Column>
    {value.questions.map((question, key) => {
      if (question) {
        return <QuestionRow key={key}>
          <Stack>
            <QuestionSmallButton onClick={() => moveQuestionUp(key)}>Λ</QuestionSmallButton>
            <QuestionSmallButton onClick={() => moveQuestionDown(key)}>V</QuestionSmallButton>
          </Stack>
          <SmallButton
            onClick={() => updateCurrentQuestion(key)}>
            {getQuestionID(question)}
          </SmallButton>
          <Spacer/>
          <XButton
            onClick={() => removeQuestion(key)}
          />
        </QuestionRow>
      }
    })}
    <QuestionRow>
      <MarginSpan><strong>Randomize the order of a sequence of questions? </strong></MarginSpan>
      <Switch
        checked={value.shuffleSequence !== undefined}
        onChange= {updateContainsShuffleSequence}
      />
    </QuestionRow>
    {value.shuffleSequence !== undefined &&
    <QuestionRow>
      <MarginSpan>Randomize the order of questions </MarginSpan>
      <select
        value={value.shuffleSequence?.startIndex}
        onChange={updateShuffleSequence(true)}
      >
        {value.questions.map((question, key) => {
          if (question) {
            return <option key={key} value={key}>{getQuestionID(question)}</option>
          }
        })}
      </select>
      <MarginSpan>to</MarginSpan>
      <select
        value={value.shuffleSequence?.endIndex}
        onChange={updateShuffleSequence(false)}
      >
        {value.questions.map((question, key) => {
          if (question) {
            return <option key={key} value={key}>{getQuestionID(question)}</option>
          }
        })}
      </select>
    </QuestionRow>
    }
    {dashboardSettings?.allowRiskAlerts && (
      <QuestionRow>
        <MarginSpan><strong>Some questions trigger risk alerts?</strong></MarginSpan>
        <Switch
        checked={value.containsRiskAlerts}
        onChange= {() => toggleContainsRiskAlerts(!value.containsRiskAlerts)}
      />
      </QuestionRow>
    )}
    <CenteredRow>
      <Tippy trigger={'click'} interactive={true} content={
        <Column>
          <button onClick={() => addQuestion(QUESTIONTYPES.Choice)}>
            Add choice question
          </button>
          <button onClick={() => addQuestion(QUESTIONTYPES.Text)}>
            Add text question
          </button>
          <button onClick={() => addQuestion(QUESTIONTYPES.Bool)}>
            Add boolean question
          </button>
          <button onClick={() => addQuestion(QUESTIONTYPES.Scale)}>
            Add scale question
          </button>
          <button onClick={() => addQuestion(QUESTIONTYPES.TimeOfDay)}>
            Add time of day question
          </button>
          <button onClick={() => addQuestion(QUESTIONTYPES.TextInfo)}>
            Add info text
          </button>
        </Column>
      }>
        <LargeTextButton>+</LargeTextButton>
      </Tippy>
    </CenteredRow>
  </Column>
}

export default styled(QuestionControlPanel)`
`;

const CenteredRow = styled(Row as any)`
  justify-content: space-around;
`;

const LargeTextButton = styled(BlankButton as any)`
  font-size: 2em;
  
  &:hover {
    color: white;
  }
  
  &:active {
    color: white;
  }
`;

const QuestionRow = styled(Row as any)`
  margin: 0 0 5px 0;
`;

const Stack = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1em 0 .25em;
`;

const MarginSpan = styled('span')`
  margin: 0 .4em 0 .4em;
`;

const QuestionSmallButton = styled(SmallButton as any)`
  margin-left: .1em;
`
