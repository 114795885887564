import axios from './axios';
import { StudyDetails} from '../shared';

export default async function getStudyDetails(
  studyName: string
): Promise<StudyDetails> {
  const response = await axios.get(`/study/${studyName}/details`);
  return response.data.studyDetails;
}

export const getStudyDashboardSettings = async (studyName: string) => {
  const response = await axios.get(`/study/${studyName}/dashboardSettings`);
  return response.data;
}
