/**
 * Downloads a string as a file.
 */
export default function downloadString(
  filename: string,
  data: string,
  mimeType: string = 'text/plain'
) {
  const el = document.createElement('a');
  el.setAttribute(
    'href',
    `data:${mimeType};charset=utf-8,${encodeURIComponent(data)}`
  );
  el.setAttribute('download', filename);
  el.style.display = 'none';
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}
