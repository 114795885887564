import { SurveyQuestion } from "../models"

const questionTypeIsNull = (question: SurveyQuestion | null, questionType: string) => {
    switch (questionType){
      case "scale":
        return question?.scale === null || question?.scale === undefined
      case "choice":
        return question?.choice === null || question?.choice === undefined
      case "textEntry":
        return question?.textEntry === null || question?.textEntry === undefined
      case "bool":
        return question?.bool === null || question?.bool === undefined
      case "timeOfDay":
        return question?.timeOfDay === null || question?.timeOfDay === undefined
      case "info":
        return question?.info === null || question?.info === undefined
      default:
        return false;
    }
}

export default questionTypeIsNull;