import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Input, { InputProps } from './Input';
import searchIconSrc from '../images/search_icon.png';

const SearchInput: FC<InputProps> = props => {
  return (
    <Input {...props} placeholder={props.placeholder || 'Search Device ID'}>
      <img src={searchIconSrc} alt="Search" />
    </Input>
  );
};

export default styled(SearchInput)`
  img {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translate(0, -50%);
    width: 22px;
    height: 22px;
    pointer-events: none;
  }

  input {
    padding-left: 50px;
  }

  input:disabled + img {
    opacity: 0.5;
  }
`;
