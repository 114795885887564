import axios from './axios';

interface Options {
  token: string;
  password: string;
}

export default async function submitPasswordReset(options: Options): Promise<void> {
  await axios.post('/submit-password-reset', options);
}
