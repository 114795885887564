import React, { FC } from 'react';
import styled from 'styled-components/macro';
import arrowIconSrc from '../images/arrow_icon.png';

const ArrowIcon: FC = props => {
  return <img {...props} src={arrowIconSrc} alt="Back" />;
};

export default styled(ArrowIcon)`
  width: 36px;
  height: 25px;
`;
