import { SurveyQuestion } from "../models"
import getBranchingRule from "./getBranchingRule"
import getQuestionContent from "./getQuestionContent"

export const branchedFrom = (key: number, questions: (SurveyQuestion | null)[]) => {
    const questionIDs: String[] = []
    const targetID = getQuestionContent(questions[key])?.questionId
    questions.forEach(question => {
        const branchingRule = getBranchingRule(question as SurveyQuestion)
        if (branchingRule) {
            const { defaultQuestionId, destinationQuestionId } = branchingRule;
            if (defaultQuestionId === targetID || destinationQuestionId === targetID) {
                const questionFrom = getQuestionContent(question)?.questionId
                if (questionFrom){
                    questionIDs.push(questionFrom)
                }
            }
        }
    })
    return questionIDs
}