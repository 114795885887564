import React, { useState, MouseEvent } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import PasswordResetForm, { PasswordResetFormDefaultValue } from "../components/PasswordResetForm";
import Logo from '../components/Logo';
import TextLogo from '../components/TextLogo';
import submitPasswordReset from '../api/submitPassworReset'
import getErrorText from '../utils/getErrorText';
import SpinnerOverlay from '../components/SpinnerOverlay';
import {Redirect, useLocation} from "@reach/router";
import BlockButton from "../components/BlockButton";

interface Props {
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PasswordReset: PageComponent<Props> = props => {
  const {  ...rest } = props;
  const [password, setPassword] = useState(PasswordResetFormDefaultValue);
  const [resetError, setResetError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false)
  const [goHome, setGoHome] = useState(false)
  const token = useQuery().get('token');

  function handleGoHome(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setGoHome(true );
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      // type cast only acceptable because this method is only reachable if token is not null
      await submitPasswordReset({'token': token as string, 'password': password.password})
      setSuccess(true)
    } catch (error) {
      setResetError(error);
    } finally {
      setLoading(false);
    }
  }

  if (goHome) {
    return <Redirect to={"/"}/>;
  }

  return (
    <Page {...props}>
      {loading && <SpinnerOverlay />}
      <Logo />
      <h1>Forgotten Password Reset Form</h1>
      {token &&
        <div>
        {resetError && <ErrorText>{getErrorText(resetError)}</ErrorText>}
        {success && <div>
          <SuccessText><h4>Password Reset Successfully!</h4></SuccessText>
          <BlockButton onClick={handleGoHome}>Return to the login page</BlockButton>
          </div>
        }
        {!success &&
          <PasswordResetForm
          value={password}
          onChange={setPassword}
          onSubmit={handleSubmit}
          />
        }
        </div>
      }
      {!token && <ErrorText>Missing token</ErrorText>}
    </Page>
  );
};

export default styled(PasswordReset)`
  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
  justify-content: center;
  align-items: center;

  ${Logo} {
    height: 122.64px;
    width: 76.62px;
    margin-bottom: 26px;
  }

  ${TextLogo} {
    height: 50.46px;
    width: 188.44px;
    margin-bottom: 8px;
  }

  > h1 {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
  }
`;

const ErrorText = styled('div')`
  text-align: center;
  color: #a00;
  margin-bottom: 40px;
`;

const SuccessText= styled('div')`
  text-align: center;
  color: white;
  margin-bottom: 40px;
`;
