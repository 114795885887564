import React, { FC, useMemo } from "react";
import dayjs from "dayjs";
import styled from "styled-components/macro";
import DotRow from "./DotRow";
import Dot from "./Dot";
import Column from "./Column";
import Row from "./Row";
import {
  Sensor,
  SENSOR_DAILY,
  SENSOR_RISK_EMA,
  SENSOR_SCREENTIME,
  sensorDescriptions,
  DashboardSettings,
} from "../shared";
import { ActiveSensors } from "../models";
import { hasSensorDay } from "../utils/dotplotUtils";
import { useDashboardSettings } from "../utils/useDashboardSettings";
import checkOSScreenTimeSupport from "../shared/utils/checkOSScreenTimeSupport";

const androidOnlySensors = new Set<Sensor>([ActiveSensors.LIGHT]);
const iOSOnlySensors = new Set<Sensor>([
  ActiveSensors.BATTERY,
  ActiveSensors.CALLSTATUS,
  SENSOR_SCREENTIME,
]);

export type DeviceUpload = {
  deviceID: string;
  timestamp: number;
  sensorType: Sensor;
  yearmonth?: string;
  filesize?: string;
};

interface Props {
  os: string;
  uploadEvents: DeviceUpload[];
  days: string[];
  visibleSensors: Sensor[];
  studyName: string;
  osVersion: string;
}

// hack to support historical study data streams
const EARS1withRisk = [
  "brendantest",
  "maps",
  "yess",
  "columbiabrains",
  "conte",
  "asmart",
  "bsmart",
];
const EARS1withDaily = [
  "brendantest",
  "maps",
  "yess",
  "columbiaed",
  "columbiabrains",
  "socialprocesses",
  "tiger",
  "pms",
  "conte",
  "asmart",
  "bsmart",
];

const DotPlot: FC<Props> = (props) => {
  const {
    uploadEvents = [],
    days = [],
    os,
    visibleSensors = [],
    studyName,
    osVersion,
    ...rest
  } = props;
  const dashboardSettings: DashboardSettings = useDashboardSettings(studyName);

  /**
   * Create list of sensors to show based on study and OS
   */
  const plotSensors = useMemo(() => {
    let sensors = sensorDescriptions.filter(
      (sensor) =>
        visibleSensors.includes(sensor.id) &&
        checkIfOSSupportsSensor(sensor.id, os)
    );
    if (EARS1withRisk.includes(studyName.toLowerCase())) {
      sensors.push({
        id: SENSOR_RISK_EMA,
        name: "Risk Survey",
        studyVariableId: "risk_ema",
        tracked: true,
      });
    }

    if (EARS1withDaily.includes(studyName.toLowerCase())) {
      sensors.push({
        id: SENSOR_DAILY,
        name: "Daily EMA",
        studyVariableId: "daily",
        tracked: true,
      });
    }

    if (dashboardSettings.screenTime && os === "iOS") {
      sensors.push({
        id: SENSOR_SCREENTIME,
        name: "Screen Time",
        studyVariableId: "daily",
        tracked: true,
      });
    }
    return sensors;
  }, [studyName, dashboardSettings.screenTime, visibleSensors, os]);

  /**
   * Check if OS supports sensor
   * @param sensor the sensor to check
   * @param os the os of the device
   * @returns true if the OS supports the sensor, false otherwise
   */
  function checkIfOSSupportsSensor(sensor: Sensor, os: string) {
    if (os === "iOS" && androidOnlySensors.has(sensor)) {
      return false;
    }
    if (os === "android" && iOSOnlySensors.has(sensor)) {
      return false;
    }
    return true;
  }

  return (
    <Column {...rest}>
      {plotSensors.map((sensor) => {
        if (
          os === "iOS" &&
          sensor.id === SENSOR_SCREENTIME &&
          !checkOSScreenTimeSupport(osVersion, os === "iOS")
        ) {
          return (
            <PlotRow key={sensor.id}>
              <RowLabel>{sensor.name}</RowLabel>
              <WarningRow>
                <b>OS version does not support Screen Time</b>
              </WarningRow>
            </PlotRow>
          );
        }

        return (
          <PlotRow key={sensor.id}>
            <RowLabel>{sensor.name}</RowLabel>
            <DotRow>
              {days.map((day, i) => {
                let color: string;
                let popupText: string;

                if (hasSensorDay(uploadEvents, day, sensor.id)) {
                  color = "#0075E1";
                  popupText = `${sensor.name} Uploaded`;

                  return (
                    <Dot key={i} color={color}>
                      <div>{day}</div>
                      <div>{popupText}</div>
                    </Dot>
                  );
                } else if (
                  [
                    ActiveSensors.ACCEL,
                    ActiveSensors.GPS,
                    ActiveSensors.KEYINPUT,
                    ActiveSensors.KEYNOCONTENT,
                  ].includes(sensor.id as ActiveSensors)
                ) {
                  popupText = `Required sensor ${sensor.name} missing.`;
                  return (
                    <Dot key={i} color={"#818181"}>
                      <div>{day}</div>
                      <div>{popupText}</div>
                    </Dot>
                  );
                } else {
                  return <Dot key={i} invisible />;
                }
              })}
            </DotRow>
          </PlotRow>
        );
      })}
      <DateRow>
        {days.map((day, i) => (
          <span key={i}>{dayjs(day).format("MM/D")}</span>
        ))}
      </DateRow>
    </Column>
  );
};

export default styled(DotPlot)`
  background-color: #e5ebf1;
  margin-top: 49px;
`;

const PlotRow = styled(Row as any)`
  & + & {
    margin-top: 24px;
  }

  ${DotRow} {
    flex: 1;
  }
`;

const RowLabel = styled("div")`
  width: 160px;
  color: #0b1c4a;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const DateRow = styled(PlotRow as any)`
  justify-content: space-between;
  margin: 0 20px 0 180px;

  > span {
    width: 50px;
    text-align: center;
  }

  > *:first-child {
    /* transform: translate(-25%, 0); */
    font-weight: bold;
  }

  > *:last-child {
    /* transform: translate(25%, 0); */
    font-weight: bold;
  }
`;

const WarningRow = styled(Row as any)`
  color: #ff4d4f;
  font-size: 17px;
  font-weight: 300;
  align-items: center;
  flex-direction: row;
`;
