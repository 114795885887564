// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Weekday = {
  "MONDAY": "MONDAY",
  "TUESDAY": "TUESDAY",
  "WEDNESDAY": "WEDNESDAY",
  "THURSDAY": "THURSDAY",
  "FRIDAY": "FRIDAY",
  "SATURDAY": "SATURDAY",
  "SUNDAY": "SUNDAY"
};

const ActiveSensors = {
  "ACCEL": "ACCEL",
  "BATTERY": "BATTERY",
  "GPS": "GPS",
  "APPUSAGE": "APPUSAGE",
  "KEYINPUT": "KEYINPUT",
  "SELFIE": "SELFIE",
  "CALLSTATUS": "CALLSTATUS",
  "EMA": "EMA",
  "MUS": "MUS",
  "MOTIONACTIVITY": "MOTIONACTIVITY",
  "KEYNOCONTENT": "KEYNOCONTENT",
  "LIGHT": "LIGHT",
  "GPSANON": "GPSANON"
};

const { SurveyPrefs, TextInfo, ScaleBranchingRule, ChoiceBranchingRule, BoolBranchingRule, TimeOfDayBranchingRule, ScaleRiskRule, ScaleQuestion, TextChoice, ChoiceRiskRule, ChoiceQuestion, TextEntryQuestion, BoolRiskRule, BooleanQuestion, TimeOfDayQuestion, RandomizedDeliveryWindowForWeekday, ExactDeliverySurveySchedule, ExactDeliveriesForWeekday, ShuffleSequence, SurveyQuestion, RandomizedDeliverySurvey, ExactDeliverySurvey, StudySurvey } = initSchema(schema);

export {
  SurveyPrefs,
  Weekday,
  ActiveSensors,
  TextInfo,
  ScaleBranchingRule,
  ChoiceBranchingRule,
  BoolBranchingRule,
  TimeOfDayBranchingRule,
  ScaleRiskRule,
  ScaleQuestion,
  TextChoice,
  ChoiceRiskRule,
  ChoiceQuestion,
  TextEntryQuestion,
  BoolRiskRule,
  BooleanQuestion,
  TimeOfDayQuestion,
  RandomizedDeliveryWindowForWeekday,
  ExactDeliverySurveySchedule,
  ExactDeliveriesForWeekday,
  ShuffleSequence,
  SurveyQuestion,
  RandomizedDeliverySurvey,
  ExactDeliverySurvey,
  StudySurvey
};