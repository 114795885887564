import React, { FC, FormEvent, FormEventHandler } from 'react';
import styled from 'styled-components/macro';

interface FormProps {
  onSubmit?: FormEventHandler<HTMLFormElement>;
  style?: any;
}

const Form: FC<FormProps> = props => {
  const { onSubmit } = props;

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
  }

  return <form {...props} onSubmit={handleSubmit} />;
};

export default styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;
