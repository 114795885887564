import React, { FC, FormEvent } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Input from './Input';
import noop from '../utils/noop';
import BlockButton from './BlockButton';

export interface Props {
  studyNames: string[];
  value: Value;
  onChange?: (value: Value) => any;
  onSubmit?: () => any;
}

export interface Value {
  email: string;
  password: string;
  passwordConfirmed: string;
}

export const defaultValue: Value = {
  email: '',
  password: '',
  passwordConfirmed: ''
};

const EditAccountForm: FC<Props> = props => {
  const {
    studyNames,
    value,
    onChange = noop,
    onSubmit = noop,
    ...rest
  } = props;

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    onSubmit();
  }

  function handleEmailChange(event: FormEvent<HTMLInputElement>) {
    onChange({ ...value, email: event.currentTarget.value });
  }

  function handlePasswordChange(event: FormEvent<HTMLInputElement>) {
    onChange({ ...value, password: event.currentTarget.value });
  }

  function handlePasswordConfirmedChange(event: FormEvent<HTMLInputElement>) {
    onChange({ ...value, passwordConfirmed: event.currentTarget.value });
  }

  return (
    <Column {...rest} as="form" onSubmit={handleSubmit}>
      <Label>Email</Label>
      <Input value={value.email} onChange={handleEmailChange} />
      <Label>Password</Label>
      <Input
        value={value.password}
        onChange={handlePasswordChange}
        type="password"
      />
      <Label>Password Confirmed</Label>
      <Input
        value={value.passwordConfirmed}
        onChange={handlePasswordConfirmedChange}
        type="password"
      />
      <Label>My Studies</Label>
      <StudyList>
        {studyNames.map((studyName, i) => (
          <li key={i}>{studyName}</li>
        ))}
      </StudyList>
      <BlockButton onClick={handleSubmit}>Save</BlockButton>
    </Column>
  );
};

const Label = styled('label')`
  color: #0b1c4a;
  font-family: Roboto;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 20px;
`;

export default styled(EditAccountForm)`
  width: 416px;
  padding: 40px 32px 32px 32px;
  max-width: 100%;
  border-radius: 5px;
  background-color: #e5ebf1;

  ${Input} + ${Label} {
    margin-top: 10px;
  }
`;

const StudyList = styled('ul')`
  padding: 0;
  margin: 0 0 20px 0;
  list-style: none;

  li {
    color: #0b1c4a;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  li + li {
    margin-top: 8px;
  }
`;
