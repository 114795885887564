type ResponseError = Error & {
  response?: { data: { error: { message: string } } };
};

export default function getErrorText(error: ResponseError) {
  if (error.response) {
    return error.response.data.error.message;
  }
  return error.message;
}
