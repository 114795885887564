import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { PageComponent } from "../components/Page";
import Row from "../components/Row";
import VerticalSplitPage from "../components/VerticalSplitPage";
import BlockButton from "../components/BlockButton";
import Required from "../components/Required";
import {
  ExactDeliveriesForWeekdayUI,
  RandomizedDeliveryForWeekdayComponent,
  RandomizedDeliveryForWeekdayDefaultValue,
} from "../components/ema/EMADelivery";
import { RouteComponentProps } from "@reach/router";
import {
  BooleanQuestion,
  ChoiceQuestion,
  ExactDeliveriesForWeekday,
  ExactDeliverySurvey,
  RandomizedDeliverySurvey,
  RandomizedDeliveryWindowForWeekday,
  ScaleQuestion,
  ShuffleSequence,
  StudySurvey,
  SurveyQuestion,
  TextEntryQuestion,
  TextInfo,
  TimeOfDayQuestion,
  Weekday,
} from "../models";
import { SurveyQuestionTypes, BranchingRules } from "../shared/Types";
import { navigateToCreateStudy, SurveyPrefsDefaultValue } from "./StudyCreator";
import Switch from "../components/Switch";
import QuestionEditor from "../components/ema/QuestionEditor";
import ScheduleControlPanel, {
  ScheduleControlPanelValue,
} from "../components/ema/ScheduleControlPanel";
import { SmallButton } from "../components/SmallButton";
import QuestionControlPanel, {
  QuestionControlPanelValue,
} from "../components/ema/QuestionControlPanel";
import { ValidatedInput, ValidatedTextArea } from "../components/Validation";
import {
  BranchingValidator,
  NumberValidator,
  StringValidator,
} from "../shared/utils/Validators";
import { scheduleControlPanelValidation } from "../utils/formValidation";
import { alertMounter } from "../components/Alert";
import questionTypeIsNull from "../utils/questionTypeIsNull";
import getQuestionContent from "../utils/getQuestionContent";
import getBranchingRule, {
  questionsWithBlankBranchDestinations,
} from "../utils/getBranchingRule";
import { useDashboardSettings } from "../utils/useDashboardSettings";
import { hasRiskRules } from "../utils/riskRulesUtils";
import { SCREENTIME_EMA_NAME } from "../shared/Constants";

export const RandomizedDefaultSurveyValue: StudySurvey = {
  random: {
    surveyId: "",
    notificationHeader: "",
    notificationBody: "",
    weekdaysAvailable: [],
    totalNumberOfDays: undefined,
    introStepHeader: "",
    introStepBody: "",
    completionStepEnabled: true,
    completionStepHeader: "",
    completionStepBody: "",
    emaQuestions: [],
    containsBranchingLogic: false,
    containsShuffledSequence: false,
    shuffleSequence: undefined,
    containsRiskAlerts: undefined,
    multipleSurveyBursts: false,
    daysWithinSurveyBurst: undefined,
    daysBetweenSurveyBursts: undefined,
    daysBeforeFirstSurveyDelivery: undefined,
    weekDeliverySchedule: [],
  },
  exact: undefined,
};

export const ExactDefaultSurveyValue: StudySurvey = {
  exact: {
    surveyId: "",
    notificationHeader: "",
    notificationBody: "",
    weekdaysAvailable: [],
    totalNumberOfDays: undefined,
    introStepHeader: "",
    introStepBody: "",
    completionStepEnabled: false,
    completionStepHeader: "",
    completionStepBody: "",
    emaQuestions: [],
    containsBranchingLogic: false,
    containsShuffledSequence: false,
    shuffleSequence: undefined,
    containsRiskAlerts: undefined,
    multipleSurveyBursts: false,
    daysWithinSurveyBurst: undefined,
    daysBetweenSurveyBursts: undefined,
    daysBeforeFirstSurveyDelivery: undefined,
    weekDeliverySchedule: [],
  },
  random: undefined,
};

export const updateBranchingForIDChanges = (
  oldQuestion: SurveyQuestionTypes | undefined,
  updatedQuestion: SurveyQuestionTypes,
  currentQuestions: Array<SurveyQuestion | null>
) => {
  const questionsCopy = [...currentQuestions];

  const oldQuestionID = oldQuestion?.questionId;
  const newQuestionID = updatedQuestion.questionId;

  if (oldQuestionID === newQuestionID) return questionsCopy;

  questionsCopy.forEach((question, index) => {
    const questionCopy = { ...questionsCopy[index] } as SurveyQuestion;
    const questionTypeCopy:
      | SurveyQuestionTypes
      | undefined = getQuestionContent(questionCopy);
    const branchingRuleCopy: BranchingRules | undefined = getBranchingRule(
      questionCopy
    );

    Object.keys(question as string).forEach((questionType) => {
      if (!questionTypeIsNull(question, questionType) && branchingRuleCopy) {
        questionsCopy[index] = {
          ...questionsCopy[index],
          [questionType]: {
            ...questionTypeCopy,
            branchingRule: {
              ...branchingRuleCopy,
              defaultQuestionId:
                branchingRuleCopy?.defaultQuestionId === oldQuestionID
                  ? newQuestionID
                  : branchingRuleCopy?.defaultQuestionId,
              destinationQuestionId:
                branchingRuleCopy?.destinationQuestionId === oldQuestionID
                  ? newQuestionID
                  : branchingRuleCopy?.destinationQuestionId,
            },
          },
        };
      }
    });
  });

  return questionsCopy;
};

enum DETAILEDITORTYPES {
  Schedule,
  Question,
}

interface CreateEMAProps
  extends RouteComponentProps<{
    location: { state: { randomized: boolean; surveyIndex: number } };
  }> {}

const CreateEMA: PageComponent<CreateEMAProps> = (props) => {
  // TODO make this a modal form rather than a page... reduces some of the complexity
  const [displaySchedule, setDisplaySchedule] = useState(false);
  const [displayQuestions, setDisplayQuestions] = useState(false);
  const [validation, setValidation] = useState<
    Record<string, string | undefined>
  >({});
  const [canSwitchDetailEditor, setCanSwitchDetailEditor] = useState(true);

  const [currentQuestion, setCurrentQuestion] = useState<number | undefined>(
    undefined
  );
  const [currentDay, setCurrentDay] = useState<Weekday | undefined>(undefined);
  const [currentDetailEditorSubject, setcurrentDetailEditorSubject] = useState<
    DETAILEDITORTYPES | undefined
  >(undefined);
  const [randomized] = useState(props.location?.state.randomized || false);

  const [studySurvey, setStudySurvey] = useState<StudySurvey>(LoadSurvey());
  const [surveyPrefs, setSurveyPrefs] = useState(
    () =>
      JSON.parse(sessionStorage.getItem("surveyPrefs") as string) ?? {
        ...SurveyPrefsDefaultValue,
      }
  );

  const dashboardSettings = useDashboardSettings(surveyPrefs.studyName);
  const questions = getQuestions();

  // MARK -: utility functions
  useEffect(() => {
    sessionStorage.setItem("surveyPrefs", JSON.stringify(surveyPrefs));
  }, [surveyPrefs]);

  useEffect(() => {
    if (currentDay && !randomized && studySurvey.exact!.weekDeliverySchedule) {
      const scheduleExists = studySurvey.exact!.weekDeliverySchedule.some(
        (item) => item && item.dayOfWeek === currentDay
      );
      if (!scheduleExists) {
        addExactSchedule();
      }
    } else if (
      currentDay &&
      randomized &&
      studySurvey.random!.weekDeliverySchedule
    ) {
      const scheduleExists = studySurvey.random!.weekDeliverySchedule.some(
        (item) => item && item.dayOfWeek === currentDay
      );
      if (!scheduleExists) {
        addRandomizedSchedule();
      }
    }
  }, [currentDay]);

  useEffect(() => {
    if (props.location) {
      const surveyListCopy = [...surveyPrefs.studySurveyList];
      surveyListCopy[props.location.state.surveyIndex] = studySurvey;
      setSurveyPrefs({
        ...surveyPrefs,
        studySurveyList: [...surveyListCopy],
      });
    }
  }, [studySurvey]);

  function LoadSurvey(): StudySurvey {
    const index = props?.location?.state.surveyIndex ?? 0;
    return (
      JSON.parse(sessionStorage.getItem("surveyPrefs") as string)
        .studySurveyList[index] ||
      (randomized
        ? { ...RandomizedDefaultSurveyValue, exact: null }
        : { ...ExactDefaultSurveyValue, random: null })
    );
  }

  function validateForm(): boolean {
    const validators = [];

    const errorMsg = "Cannot be Blank";
    //surveyId, notificationHeader, introStepHeader, completionStepHeader
    const surveyIdError = new StringValidator(
      studySurvey.exact?.surveyId ?? studySurvey.random?.surveyId
    )
      .min(1, errorMsg)
      .validate();
    const notificationHeaderError = new StringValidator(
      studySurvey.exact?.notificationHeader ??
        studySurvey.random?.notificationHeader ??
        ""
    )
      .min(1, errorMsg)
      .validate();
    const introStepHeaderError = new StringValidator(
      studySurvey.exact?.introStepHeader ??
        studySurvey.random?.introStepHeader ??
        ""
    )
      .min(1, errorMsg)
      .validate();
    const totalNumberOfDaysError =
      !studySurvey.exact?.totalNumberOfDays &&
      !studySurvey.random?.totalNumberOfDays
        ? undefined
        : new NumberValidator(
            studySurvey.exact?.totalNumberOfDays ??
              studySurvey.random?.totalNumberOfDays
          )
            .min(0, "Total days cannot be negative")
            .noNaN("Input must be a number")
            .validate();

    const branchingError = new BranchingValidator(questions)
      .ordered()
      .validate();

    validators.push(
      surveyIdError,
      notificationHeaderError,
      introStepHeaderError,
      totalNumberOfDaysError,
      branchingError
    );

    let completionStepHeaderError = undefined;
    if (
      studySurvey.exact?.completionStepEnabled ??
      studySurvey.random?.completionStepEnabled
    ) {
      completionStepHeaderError = new StringValidator(
        studySurvey.exact?.completionStepHeader ??
          studySurvey.random?.completionStepHeader ??
          ""
      )
        .min(1, errorMsg)
        .validate();
      validators.push(completionStepHeaderError);
    }

    let scheduleControlErrors = undefined;
    if (
      studySurvey.exact?.multipleSurveyBursts ??
      studySurvey.random?.multipleSurveyBursts
    ) {
      const scheduleControlValue = {
        multipleBursts:
          studySurvey.exact?.multipleSurveyBursts ??
          studySurvey.random!.multipleSurveyBursts,
        daysBefore:
          studySurvey.exact?.daysBeforeFirstSurveyDelivery ??
          studySurvey.random?.daysBeforeFirstSurveyDelivery,
        daysWithin:
          studySurvey.exact?.daysWithinSurveyBurst ??
          studySurvey.random?.daysWithinSurveyBurst,
        daysBetween:
          studySurvey.exact?.daysBetweenSurveyBursts ??
          studySurvey.random?.daysBetweenSurveyBursts,
        randomized: randomized,
      };
      scheduleControlErrors = scheduleControlPanelValidation(
        scheduleControlValue
      );
      const { daysBetweenError, daysWithinError } = scheduleControlErrors;
      validators.push(daysBetweenError, daysWithinError);
    }

    // merge all valdiation Records into state
    setValidation({
      ...validation,
      surveyId: surveyIdError,
      notificationHeader: notificationHeaderError,
      introStepHeader: introStepHeaderError,
      completionStepHeader: completionStepHeaderError,
      totalNumberOfDays: totalNumberOfDaysError,
      questions: branchingError,
      ...scheduleControlErrors,
    });
    return validators.every((validator) => validator === undefined);
  }

  function handleSubmit() {
    const blankBranchDestinations = questionsWithBlankBranchDestinations(
      questions
    );
    if (blankBranchDestinations.length > 0) {
      alertMounter({
        titleText: "Branching Logic Must Be Set",
        message: `Change or remove the branching logic in the following questions before saving: ${blankBranchDestinations.join(
          ", "
        )}`,
        showCancelButton: false,
      });
      return;
    }
    if (validateForm()) {
      const _ = navigateToCreateStudy();
    } else {
      alertMounter({
        titleText: "Validation Error",
        message:
          "Please fill out all required fields and fix all highlighted fields before leaving the current detail editor.",
        showCancelButton: false,
      });
    }
  }

  function tryToChangeDetailEditorTo(newType: DETAILEDITORTYPES) {
    if (canSwitchDetailEditor) {
      setcurrentDetailEditorSubject(newType);
    } else {
      alertMounter({
        titleText: "Validation Error",
        message:
          "Please fill out all required fields and fix all highlighted fields before leaving the current detail editor.",
        showCancelButton: false,
      });
    }
  }

  // MARK -: Questions
  function getQuestions(): Array<SurveyQuestion | null> {
    return (
      studySurvey.random?.emaQuestions ?? studySurvey.exact?.emaQuestions ?? []
    );
  }

  function toggleContainsRiskAlerts(riskAlertToggle?: boolean | undefined) {
    const hasCurrentRiskAlert = hasRiskRules([...questions]);
    if (!riskAlertToggle && hasCurrentRiskAlert.length > 0) {
      alertMounter({
        titleText: `Cannot Toggle Risk Alerts`,
        message: `This cannot be turned off because risk alerts are currently setup for at least one question in this study. Question IDs: ${hasCurrentRiskAlert.join(
          ", "
        )}`,
        showCancelButton: false,
      });
      return;
    }
    if (randomized) {
      setStudySurvey({
        ...studySurvey,
        random: {
          ...studySurvey.random,
          containsRiskAlerts: riskAlertToggle,
        } as RandomizedDeliverySurvey,
      });
    } else {
      setStudySurvey({
        ...studySurvey,
        exact: {
          ...studySurvey.exact,
          containsRiskAlerts: riskAlertToggle,
        } as ExactDeliverySurvey,
      });
    }
  }

  function updateQuestions(
    newQuestionsValue?: Array<SurveyQuestion | null>,
    newShuffle?: ShuffleSequence
  ) {
    const surveyQuestions = newQuestionsValue
      ? newQuestionsValue
      : studySurvey.random?.emaQuestions ?? studySurvey.exact?.emaQuestions;
    const containsShuffle = newShuffle !== undefined;
    if (randomized) {
      setStudySurvey({
        ...studySurvey,
        random: {
          ...studySurvey.random,
          emaQuestions: surveyQuestions,
          shuffleSequence: newShuffle,
          containsShuffledSequence: containsShuffle,
        } as RandomizedDeliverySurvey,
      });
    } else {
      setStudySurvey({
        ...studySurvey,
        exact: {
          ...studySurvey.exact,
          emaQuestions: surveyQuestions,
          shuffleSequence: newShuffle,
          containsShuffledSequence: containsShuffle,
        } as ExactDeliverySurvey,
      });
    }
  }

  //TODO move into QuestionEditor
  const updateQuestion = (key: number) => (
    updatedQuestionValue:
      | TextEntryQuestion
      | BooleanQuestion
      | ScaleQuestion
      | ChoiceQuestion
      | TimeOfDayQuestion
      | TextInfo
  ) => {
    const oldQuestion = getQuestions()[key];
    const oldQuestionValue = getQuestionContent(oldQuestion);

    const questionsCopy = [
      ...updateBranchingForIDChanges(oldQuestionValue, updatedQuestionValue, [
        ...questions,
      ]),
    ];
    const question = questionsCopy[key];

    if (question) {
      if (question.bool) {
        questionsCopy[key] = new SurveyQuestion({
          ...question,
          bool: updatedQuestionValue as BooleanQuestion,
        });
      } else if (question.choice) {
        questionsCopy[key] = new SurveyQuestion({
          ...question,
          choice: updatedQuestionValue as ChoiceQuestion,
        });
      } else if (question.scale) {
        questionsCopy[key] = new SurveyQuestion({
          ...question,
          scale: updatedQuestionValue as ScaleQuestion,
        });
      } else if (question.textEntry) {
        questionsCopy[key] = new SurveyQuestion({
          ...question,
          textEntry: updatedQuestionValue as TextEntryQuestion,
        });
      } else if (question.timeOfDay) {
        questionsCopy[key] = new SurveyQuestion({
          ...question,
          timeOfDay: updatedQuestionValue as TimeOfDayQuestion,
        });
      } else if (question.info) {
        questionsCopy[key] = new SurveyQuestion({
          ...question,
          info: updatedQuestionValue as TextInfo,
        });
      }
      updateQuestions(questionsCopy);
    }
  };

  function handleQuestionControlPanel(panelValue: QuestionControlPanelValue) {
    if (canSwitchDetailEditor) {
      if (
        panelValue.currentQuestion !== undefined &&
        panelValue.currentQuestion !== currentQuestion
      ) {
        setCurrentQuestion(panelValue.currentQuestion);
        tryToChangeDetailEditorTo(DETAILEDITORTYPES.Question);
      }
      updateQuestions(panelValue.questions, panelValue.shuffleSequence);
    } else {
      tryToChangeDetailEditorTo(DETAILEDITORTYPES.Question);
    }
  }

  function getQuestionIDs(): string[] {
    return questions.map((item) => {
      let id = "";
      if (item) {
        if (item.bool) {
          id = item.bool.questionId ?? "";
        } else if (item.textEntry) {
          id = item.textEntry.questionId ?? "";
        } else if (item.choice) {
          id = item.choice.questionId ?? "";
        } else if (item.scale) {
          id = item.scale.questionId ?? "";
        } else if (item.timeOfDay) {
          id = item.timeOfDay.questionId ?? "";
        } else if (item.info) {
          id = item.info.questionId ?? "";
        }
      }
      return id;
    });
  }

  //MARK -: Schedule
  function changeDay(key: string, values: string[]) {
    if (canSwitchDetailEditor) {
      const day = (key as unknown) as Weekday;
      setCurrentDay(day);
      if (randomized) {
        let schedules = studySurvey.random!.weekDeliverySchedule;
        if (values.length < 7) {
          schedules = schedules.map((item) => {
            return item ? { ...item, repeatsEveryDay: false } : null;
          });
        }
        // filter out schedules for days not available
        schedules = schedules.filter(
          (item) => item && values.includes(item?.dayOfWeek)
        );
        setStudySurvey({
          ...studySurvey,
          random: {
            ...studySurvey.random,
            weekdaysAvailable: values,
            weekDeliverySchedule: schedules,
          } as RandomizedDeliverySurvey,
        });
      } else {
        // if not all days are selected turn off repeat
        let schedules = studySurvey.exact!.weekDeliverySchedule;
        if (values.length < 7) {
          // filter out schedules for days not available. Need to leave the object intact for display purposes.
          schedules = schedules.map((item) => {
            if (item != null) {
              if (!values.includes(item.dayOfWeek)) {
                return { ...item, exactDeliveriesForDay: [] };
              }
            }
            return item;
          });
          schedules = schedules.map((item) => {
            if (item) {
              const newDeliveries = item.exactDeliveriesForDay.map(
                (delivery) => {
                  return { ...delivery, repeatsEveryDay: false };
                }
              );
              return {
                dayOfWeek: item.dayOfWeek,
                exactDeliveriesForDay: newDeliveries,
              };
            }
            return null;
          });
        }
        setStudySurvey({
          ...studySurvey,
          exact: {
            ...studySurvey.exact,
            weekdaysAvailable: values,
            weekDeliverySchedule: schedules,
          } as ExactDeliverySurvey,
        });
      }
    }
    tryToChangeDetailEditorTo(DETAILEDITORTYPES.Schedule);
  }

  function handleScheduleControlPanel(panelValue: ScheduleControlPanelValue) {
    if (randomized) {
      setStudySurvey({
        ...studySurvey,
        random: {
          ...studySurvey.random,
          multipleSurveyBursts: panelValue.multipleBursts,
          daysBetweenSurveyBursts: panelValue.daysBetween,
          daysWithinSurveyBurst: panelValue.daysWithin,
        } as RandomizedDeliverySurvey,
      });
    } else {
      setStudySurvey({
        ...studySurvey,
        exact: {
          ...studySurvey.exact,
          multipleSurveyBursts: panelValue.multipleBursts,
          daysBetweenSurveyBursts: panelValue.daysBetween,
          daysWithinSurveyBurst: panelValue.daysWithin,
        } as ExactDeliverySurvey,
      });
    }
  }

  function addExactSchedule() {
    if (currentDay) {
      const schedules = [...studySurvey!.exact!.weekDeliverySchedule!];
      schedules.push({
        dayOfWeek: currentDay,
        exactDeliveriesForDay: [],
      });
      setStudySurvey({
        ...studySurvey,
        exact: {
          ...studySurvey.exact,
          weekDeliverySchedule: schedules,
        } as ExactDeliverySurvey,
      });
    }
  }

  function addRandomizedSchedule() {
    if (currentDay) {
      const schedules = [...studySurvey!.random!.weekDeliverySchedule!];
      schedules.push({
        dayOfWeek: currentDay,
        windowStartHour:
          RandomizedDeliveryForWeekdayDefaultValue.windowStartHour,
        windowEndHour: RandomizedDeliveryForWeekdayDefaultValue.windowEndHour,
        repeatsEveryDay: false,
      });
      setStudySurvey({
        ...studySurvey,
        random: {
          ...studySurvey.random,
          weekDeliverySchedule: schedules,
        } as RandomizedDeliverySurvey,
      });
    }
  }

  const removeSchedule = (key: number) => () => {
    if (randomized) {
      const schedules = [...studySurvey!.random!.weekDeliverySchedule!];
      schedules.splice(key, 1);
      setStudySurvey({
        ...studySurvey,
        random: {
          ...studySurvey.random,
          weekDeliverySchedule: schedules,
        } as RandomizedDeliverySurvey,
      });
    } else {
      const schedules = [...studySurvey!.exact!.weekDeliverySchedule!];
      schedules.splice(key, 1);
      setStudySurvey({
        ...studySurvey,
        exact: {
          ...studySurvey.exact,
          weekDeliverySchedule: schedules,
        } as ExactDeliverySurvey,
      });
    }
  };

  const updateSchedule = (key: number) => (
    schedule: RandomizedDeliveryWindowForWeekday | ExactDeliveriesForWeekday
  ) => {
    if (randomized) {
      const schedules = [...studySurvey!.random!.weekDeliverySchedule!];
      schedules[key] = schedule as RandomizedDeliveryWindowForWeekday;
      setStudySurvey({
        ...studySurvey,
        random: {
          ...studySurvey.random,
          weekDeliverySchedule: schedules,
        } as RandomizedDeliverySurvey,
      });
    } else {
      const schedules = [...studySurvey!.exact!.weekDeliverySchedule!];
      schedules[key] = schedule as ExactDeliveriesForWeekday;
      setStudySurvey({
        ...studySurvey,
        exact: {
          ...studySurvey.exact,
          weekDeliverySchedule: schedules,
        } as ExactDeliverySurvey,
      });
    }
  };

  // replaces the entire schedule property
  function replaceSchedule(
    schedule: RandomizedDeliveryWindowForWeekday[] | ExactDeliveriesForWeekday[]
  ) {
    const allWeekdays = Object.keys(Weekday).map((item) => item as Weekday);
    if (randomized) {
      setStudySurvey({
        ...studySurvey,
        random: {
          ...studySurvey.random,
          weekDeliverySchedule: schedule,
          weekdaysAvailable: allWeekdays,
        } as RandomizedDeliverySurvey,
      });
    } else {
      setStudySurvey({
        ...studySurvey,
        exact: {
          ...studySurvey.exact,
          weekDeliverySchedule: schedule,
          weekdaysAvailable: allWeekdays,
        } as ExactDeliverySurvey,
      });
    }
  }

  // MARK -: Text Fields
  function updateSurveyID(event: FormEvent<HTMLInputElement>) {
    const surveyIDs = surveyPrefs.studySurveyList.map(
      (survey: StudySurvey) =>
        survey.exact?.surveyId ?? survey.random?.surveyId ?? ""
    );
    const error = new StringValidator(event.currentTarget.value)
      .min(3, "EMA ID must contain than three characters")
      .alphaNum("EMA ID can only contain numbers and letters")
      .unique(
        surveyIDs,
        "EMA ID cannot be the same as another EMA ID in the study",
        undefined,
        true
      )
      .unique(
        [SCREENTIME_EMA_NAME],
        "EMA ID is reserved and cannot be used",
        undefined,
        true
      )
      .validate();
    setValidation({
      ...validation,
      surveyId: error,
    });
    if (randomized) {
      setStudySurvey({
        ...studySurvey,
        random: {
          ...studySurvey.random,
          surveyId: event.currentTarget.value,
        } as RandomizedDeliverySurvey,
      });
    } else {
      setStudySurvey({
        ...studySurvey,
        exact: {
          ...studySurvey.exact,
          surveyId: event.currentTarget.value,
        } as ExactDeliverySurvey,
      });
    }
  }

  const updateNumProp = (
    prop: keyof RandomizedDeliverySurvey | keyof ExactDeliverySurvey
  ) => (event: FormEvent<HTMLInputElement>) => {
    const numberInput = event.currentTarget.value;
    const error =
      event.currentTarget.value === ""
        ? undefined
        : new NumberValidator(parseInt(numberInput))
            .min(0, "Days cannot be negative")
            .noNaN("Input must be a number")
            .validate();

    setValidation({
      ...validation,
      [prop]: error,
    });

    // set value
    if (randomized && studySurvey.random) {
      const randomSurvey = { ...studySurvey.random };
      (randomSurvey[prop] as string) = numberInput.toString();
      setStudySurvey({
        ...studySurvey,
        random: randomSurvey,
      });
    } else if (studySurvey.exact) {
      const exactSurvey = { ...studySurvey.exact };
      (exactSurvey[prop] as string) = numberInput.toString();
      setStudySurvey({
        ...studySurvey,
        exact: exactSurvey as ExactDeliverySurvey,
      });
    }
  };

  const updateStringProp = (prop: keyof RandomizedDeliverySurvey) => (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>
  ) => {
    const minLengthPerProperty: Record<string, number> = {
      notificationBody: 0,
      notificationHeader: 3,
      introStepBody: 0,
      introStepHeader: 3,
      completionStepBody: 0,
      completionStepHeader: 3,
    };
    const minLength = minLengthPerProperty[prop];
    // check error
    const error = new StringValidator(event.currentTarget.value)
      .min(minLength, `Item must contain at least ${minLength} characters`)
      .validate();
    setValidation({
      ...validation,
      [prop]: error,
    });
    // set value
    if (randomized && studySurvey.random) {
      const randomSurvey = { ...studySurvey.random };
      (randomSurvey[prop] as string) = event.currentTarget.value;
      setStudySurvey({
        ...studySurvey,
        random: randomSurvey,
      });
    } else if (studySurvey.exact) {
      const exactSurvey = { ...studySurvey.exact };
      (exactSurvey[prop] as string) = event.currentTarget.value;
      setStudySurvey({
        ...studySurvey,
        exact: exactSurvey as ExactDeliverySurvey,
      });
    }
  };

  function updateShowCompletion(shouldShow: boolean) {
    if (randomized && studySurvey.random) {
      setStudySurvey({
        ...studySurvey,
        random: {
          ...studySurvey.random,
          completionStepEnabled: shouldShow,
        },
      });
    } else if (studySurvey.exact) {
      setStudySurvey({
        ...studySurvey,
        exact: {
          ...studySurvey.exact,
          completionStepEnabled: shouldShow,
        },
      });
    }
  }

  return (
    <VerticalSplitPage
      {...props}
      leftChild={
        <LeftPane>
          {randomized && (
            <Row>
              <h1>Randomized Survey</h1>
              <Spacer />
              <BlockButton onClick={handleSubmit}>Save</BlockButton>
            </Row>
          )}
          {!randomized && (
            <Row>
              <h1>Exact Survey</h1>
              <Spacer />
              <BlockButton onClick={handleSubmit}>Save</BlockButton>
            </Row>
          )}
          <p>
            <Required>
              <strong>EMA ID</strong>
            </Required>
            : what would you like to refer to this survey as internally? This
            identifier will appear in your collected data output, and will not
            be visible to participants. (Ex: DailyOne, Daily2A)
          </p>
          <ValidatedInput
            message={validation.surveyId}
            placeholder={"EMA ID"}
            value={studySurvey.exact?.surveyId ?? studySurvey.random?.surveyId}
            onChange={updateSurveyID}
          />
          <p>
            <Required>
              <strong>Notification header</strong>
            </Required>
            : header text that will be displayed in the notification prompting
            participants to initiate this survey
          </p>
          <ValidatedInput
            message={validation.notificationHeader}
            placeholder={"Notification header"}
            value={
              studySurvey.exact?.notificationHeader ??
              studySurvey.random?.notificationHeader ??
              "Error Saving Header"
            }
            onChange={updateStringProp("notificationHeader")}
          />
          <p>
            <strong>Notification body</strong>: body text that will be displayed
            in the notification prompting participants to initiate this survey
          </p>
          <ValidatedTextArea
            message={validation.notificationBody}
            placeholder={"Notification body"}
            value={
              studySurvey.exact?.notificationBody ??
              studySurvey.random?.notificationBody ??
              "Error Saving Body"
            }
            onChange={updateStringProp("notificationBody")}
          />
          <p>
            <Required>
              <strong>Survey instruction page header</strong>
            </Required>
            :
          </p>
          <ValidatedInput
            message={validation.introStepHeader}
            placeholder={"Instruction header"}
            value={
              studySurvey.exact?.introStepHeader ??
              studySurvey.random?.introStepHeader ??
              "Error Saving Header"
            }
            onChange={updateStringProp("introStepHeader")}
          />
          <p>
            <strong>Survey instruction page body</strong>: brief description of
            the survey to guide participants
          </p>
          <ValidatedTextArea
            message={validation.introStepBody}
            placeholder={"Instruction Body"}
            value={
              studySurvey.exact?.introStepBody ??
              studySurvey.random?.introStepBody ??
              "Error Saving Body"
            }
            onChange={updateStringProp("introStepBody")}
          />
          <TopMarginRow>
            <MarginSpan>
              <strong>Show Completion Page?</strong>
            </MarginSpan>
            <Switch
              checked={
                studySurvey.exact?.completionStepEnabled ??
                studySurvey.random?.completionStepEnabled ??
                true
              }
              onChange={updateShowCompletion}
            />
          </TopMarginRow>
          {(studySurvey.exact?.completionStepEnabled ??
            studySurvey.random?.completionStepEnabled ??
            true) && (
            <div>
              <p>
                <Required>
                  <strong>Survey Completion Header</strong>
                </Required>
                :
              </p>
              <ValidatedInput
                message={validation.completionStepHeader}
                placeholder={"Completion header"}
                value={
                  studySurvey.exact?.completionStepHeader ??
                  studySurvey.random?.completionStepHeader ??
                  "Error Saving Header"
                }
                onChange={updateStringProp("completionStepHeader")}
              />
              <p>
                <strong>Survey Completion Body</strong>: what text would you
                like to display when this survey questions have been completed,
                before the survey is submitted?
              </p>
              <ValidatedTextArea
                message={validation.completionStepBody}
                placeholder={"Completion Body"}
                value={
                  studySurvey.exact?.completionStepBody ??
                  studySurvey.random?.completionStepBody ??
                  "Error Saving Completion Body"
                }
                onChange={updateStringProp("completionStepBody")}
              />
            </div>
          )}
          <p>
            <strong>Total Number of Survey Days</strong>:
          </p>
          <ValidatedInput
            placeholder="Leave empty for indefinite days."
            message={validation.totalNumberOfDays}
            type="number"
            value={
              studySurvey.exact?.totalNumberOfDays ??
              studySurvey.random?.totalNumberOfDays
            }
            onChange={updateNumProp("totalNumberOfDays")}
          />
          <p>
            <strong>Days Before First Survey Delivery:</strong>
          </p>
          <Spacer />
          <ValidatedInput
            message={validation.daysBeforeError}
            placeholder="None"
            min={0}
            type="number"
            value={
              studySurvey.exact?.daysBeforeFirstSurveyDelivery ??
              studySurvey.random?.daysBeforeFirstSurveyDelivery
            }
            onChange={updateNumProp("daysBeforeFirstSurveyDelivery")}
          />
          <hr />
          <Row>
            <SmallButton
              className={"clear"}
              onClick={() => setDisplaySchedule(!displaySchedule)}
            >
              <h3>{displaySchedule ? "v " : "> "} Scheduling</h3>
            </SmallButton>
          </Row>
          <hr />
          {displaySchedule && (
            <ScheduleControlPanel
              daysOnChange={changeDay}
              value={{
                multipleBursts:
                  studySurvey.random?.multipleSurveyBursts ??
                  studySurvey.exact?.multipleSurveyBursts ??
                  false,
                daysWithin:
                  studySurvey.random?.daysWithinSurveyBurst ??
                  studySurvey.exact?.daysWithinSurveyBurst ??
                  undefined,
                daysBetween:
                  studySurvey.random?.daysBetweenSurveyBursts ??
                  studySurvey.exact?.daysBetweenSurveyBursts ??
                  undefined,
              }}
              burstOnChange={handleScheduleControlPanel}
              currentDays={
                randomized
                  ? (studySurvey.random?.weekdaysAvailable as Weekday[])
                  : (studySurvey.exact?.weekdaysAvailable as Weekday[])
              }
              validation={validation}
            />
          )}
          <hr />
          <Row>
            <SmallButton
              className={"clear"}
              onClick={() => setDisplayQuestions(!displayQuestions)}
            >
              <h3>
                {displayQuestions ? "v " : "> "} Questions{" "}
                <ValidationError>{validation.questions}</ValidationError>
              </h3>
            </SmallButton>
          </Row>
          <hr />
          {displayQuestions && (
            <QuestionControlPanel
              value={{
                questions: questions,
                currentQuestion: currentQuestion,
                containsRiskAlerts:
                  studySurvey.random?.containsRiskAlerts ??
                  studySurvey.exact?.containsRiskAlerts,
                shuffleSequence:
                  studySurvey.random?.shuffleSequence ??
                  studySurvey.exact?.shuffleSequence,
              }}
              onChange={handleQuestionControlPanel}
              toggleContainsRiskAlerts={toggleContainsRiskAlerts}
              dashboardSettings={dashboardSettings}
            />
          )}
        </LeftPane>
      }
      rightChild={
        <RightPane>
          {!currentQuestion && !currentDay && <h2>Detail Editor</h2>}
          {currentQuestion !== undefined &&
            currentDetailEditorSubject === DETAILEDITORTYPES.Question && (
              <QuestionEditor
                surveyQuestions={questions}
                currentQuestion={currentQuestion}
                onChange={updateQuestion(currentQuestion)}
                questionIDs={getQuestionIDs()}
                canSwitch={setCanSwitchDetailEditor}
                showRiskToggle={
                  studySurvey.random?.containsRiskAlerts ??
                  studySurvey.exact?.containsRiskAlerts
                }
              />
            )}
          {currentDetailEditorSubject === DETAILEDITORTYPES.Schedule &&
            currentDay && (
              <div>
                <h2>{currentDay}</h2>
                {randomized &&
                  studySurvey &&
                  studySurvey.random &&
                  studySurvey.random.weekDeliverySchedule &&
                  studySurvey.random.weekDeliverySchedule.map((item, key) => {
                    if (item && item.dayOfWeek === currentDay) {
                      return (
                        <RandomizedDeliveryForWeekdayComponent
                          value={item}
                          key={key}
                          onChange={updateSchedule(key)}
                          removeSchedule={removeSchedule(key)}
                          allSchedules={
                            studySurvey.random?.weekDeliverySchedule
                          }
                          onUpdateRepeatsEveryDay={replaceSchedule}
                        />
                      );
                    }
                  })}
                {!randomized &&
                  studySurvey &&
                  studySurvey.exact &&
                  studySurvey.exact.weekDeliverySchedule &&
                  studySurvey.exact.weekDeliverySchedule.map((item, key) => {
                    if (item && item.dayOfWeek === currentDay) {
                      return (
                        <ExactDeliveriesForWeekdayUI
                          value={item}
                          key={key}
                          onChange={updateSchedule(key)}
                          removeSchedule={removeSchedule(key)}
                          canSwitch={setCanSwitchDetailEditor}
                          allSchedules={studySurvey.exact?.weekDeliverySchedule}
                          onUpdateRepeatsEveryDay={replaceSchedule}
                        />
                      );
                    }
                  })}
              </div>
            )}
        </RightPane>
      }
    />
  );
};

export default styled(CreateEMA)`
  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
  justify-content: center;
  align-items: center;
`;

const LeftPane = styled("div")``;

const RightPane = styled("div")`
  align-items: center;
`;

const Spacer = styled("div")`
  flex-grow: 1;
`;

const MarginSpan = styled("span")`
  margin: 0 0.4em 0 0;
`;

const TopMarginRow = styled(Row as any)`
  margin: 0.5em 0 0 0;
`;

const ValidationError = styled.span`
  height: 30px;
  color: red;
`;
