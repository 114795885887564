import React, { FC, FormEvent, useEffect } from "react";
import noop from "../../utils/noop";
import Row from "../Row";
import { BoolBranchingRule } from "../../models";
import Column from "../Column";
import Required from "../Required";

/**
 * @param expectedAnswer: boolean
 */
export const BoolBranchingDefaultValue: BoolBranchingRule = {
  defaultQuestionId: "",
  destinationQuestionId: "",
  expectedAnswer: false,
};

interface BoolBranchingRuleProps {
  value?: BoolBranchingRule;
  surveyQuestionIDs: string[];
  onChange?: (value: BoolBranchingRule) => any;
}

export const BoolBranchingRuleComponent: FC<BoolBranchingRuleProps> = (
  props
) => {
  const { value = BoolBranchingDefaultValue, onChange = noop, ...rest } = props;

  function selectChoice(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      expectedAnswer: event.currentTarget.value == "True",
    });
  }

  function selectDefault(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      defaultQuestionId: event.currentTarget.value,
    });
  }

  function selectDestination(event: FormEvent<HTMLSelectElement>) {
    onChange({
      ...value,
      destinationQuestionId: event.currentTarget.value,
    });
  }

  return (
    <Column rowGap={"5px"}>
      <Row gap={".5em"}>
        <span>If</span>
        <select
          onChange={selectChoice}
          value={value.expectedAnswer ? "True" : "False"}
        >
          <option value={"True"}>True</option>
          <option value={"False"}>False</option>
        </select>
      </Row>
      <Row gap={".5em"}>
        <span>is selected, go to question</span>
        <Required>
          <label>
            <select
              onChange={selectDestination}
              value={value.destinationQuestionId}
            >
              <option value="">---SELECT QUESTION---</option>
              {props.surveyQuestionIDs.map((id, idx) => {
                return (
                  <option key={"survey" + id + idx} value={id}>
                    {id}
                  </option>
                );
              })}
              <option value={"completionPage"}>Completion Page</option>
            </select>
          </label>
        </Required>
      </Row>
      <Row gap={".5em"}>
        <span>Otherwise go to</span>
        <Required>
          <label>
            <select onChange={selectDefault} value={value.defaultQuestionId}>
              <option value="">---SELECT QUESTION---</option>
              {props.surveyQuestionIDs.map((id, idx) => {
                return (
                  <option key={"survey" + id + idx} value={id}>
                    {id}
                  </option>
                );
              })}
              <option value={"completionPage"}>Completion Page</option>
            </select>
          </label>
        </Required>
      </Row>
    </Column>
  );
};
