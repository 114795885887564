import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Input from './Input';
import Row from './Row';
import BlockButton from './BlockButton';
import noop from '../utils/noop';
import getFileContents from '../utils/getFileContents';
import { formatParticipantId } from '../shared/utils/formatFns';
import Select from './Select';
import { testParticipantOptions } from '../api/createParticipants';

export interface Props {
  study: string;
  site?: string;
  onSubmit?: (participantID: string, testParticipant: "test" | "actual") => any;
}

const CreateParticipantForm: FC<Props> = props => {
  const [participantID, setParticipantID] = useState<string>("")
  const [testParticipant, setTestParticipant] = useState<testParticipantOptions>("actual")

  const {
    study,
    site,
    onSubmit = noop,
    ...rest
  } = props;

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onSubmit(participantID, testParticipant);
  }

  const handleParticipantIdChange = (event: FormEvent<HTMLInputElement>) => {
    const formatedParticipantID = formatParticipantId(event.currentTarget.value);
    setParticipantID(formatedParticipantID)
  }

  const handleTestParticipantChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setTestParticipant(event.target.value as testParticipantOptions)
  }

  return (
    <Column {...rest}>
      <form onSubmit={handleSubmit}>
        <label>Participant ID</label>
        <Input
          onChange={handleParticipantIdChange}
          value={participantID}
        />
        <label>Participant Type</label>
        <Select
          value={testParticipant}
          onChange={handleTestParticipantChange}
        >
          <option value="actual">Participant</option>
          <option value="test">Test Participant</option>
        </Select>
        {testParticipant === "test" && (
          <WarningText>WARNING: You are designating this participant ID as a test participant. This means ALL DATA for this participant ID will be excluded from data deliveries. This applies to prior, current, and future installs for this participant ID regardless of whether you continue designating them as a test participant on future installs.</WarningText>
        )}
        <StudyRow>
          <Column>
            <label>Study</label>
            <div>{study}</div>
          </Column>
          <Column>
            <label>Site</label>
            <div>{site === 'all' ? ((study === 'test' || study.includes('abcdyear',0)) ? <h1>{'NONE'}</h1> : 'None' ) : site}</div>
          </Column>
        </StudyRow>
        <BlockButton light>Save</BlockButton>
      </form>
    </Column>
  );
};

export default styled(CreateParticipantForm)`
  max-width: 550px;
  background-color: #1378de;
  color: white;

  label {
    font-size: 30px;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  form > input,
  ${Input} {
    margin-bottom: 20px;
  }

  ${BlockButton} {
    width: 120px;
    align-self: center;
  }
`;

const StudyRow = styled(Row as any)`
  justify-content: space-between;
  margin-bottom: 30px;

  label {
    margin-bottom: 10px;
  }
  h1 {
    color: yellow;
  }
`;

const WarningText = styled.p`
  color: #FFEB14;
  font-weight: bold;
`;
