import axios from './axios';

interface Options {
  email: string | undefined;
  token: string | undefined;
}

export default async function sso(options: Options): Promise<void> {
  await axios.post('/sso-login', options);
  window.location.reload();
}
