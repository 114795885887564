import React, {
  FC,
  FormEvent,
  FormEventHandler,
  useRef,
  useEffect
} from 'react';
import styled from 'styled-components/macro';
import Column from './Column';

interface OverlayProps {
  onClick?: FormEventHandler<HTMLDivElement> | null;
}

const Overlay: FC<OverlayProps> = props => {
  const { onClick, ...rest } = props;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const prevBodyOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      if (prevBodyOverflow !== 'hidden') {
        document.body.style.overflow = prevBodyOverflow;
      }
    };
  }, []);

  // Only listen to onClick events from the overlay not its children
  function handleClick(event: FormEvent<HTMLDivElement>) {
    const target = event.target as HTMLDivElement;
    if (onClick && target === ref.current) {
      onClick(event);
    }
  }

  function handleRef(el: HTMLDivElement) {
    ref.current = el;
  }

  return <Column {...rest} ref={handleRef} onClick={handleClick} />;
};

export default styled(Overlay)`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  z-index: 2;
  justify-content: center;
  align-items: center;

  background: linear-gradient(315deg, #4b64aa 0%, #3d4f83 100%);
`;
